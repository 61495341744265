import React from 'react'

const TotalItems = ({total,price}) => {
    return (
        <>
            <div className='wrapper'>
                <div className='flex flex-row justify-between items-center '>
                    <span className='text-lg font-semibold'>{total}</span>
                    <span>{price}</span>

                </div>
                <div className="flex flex-row  items-center justify-center my-4 ">
                    <hr className="w-[100%] border-gray-300" />
                </div>
            </div>
        </>
    )
}

export default TotalItems
