import React from 'react';

const DisplayItem = ({ item }) => {
  return (
    <div className="flex justify-center items-center gap-1">
      {item.icon && (
        <div className="flex justify-center items-center text-gray-300 text-2xl">
          <item.icon />
        </div>
      )}
      <div className="flex justify-center items-center text-gray-300">
        {item.name}
      </div>
    </div>
  );
};

const mapNamesToIcons = (names, referenceList) => {
  return names?.map((name) => {
    if (!referenceList) {
      // If no referenceList is provided, return only the name
      return { name };
    }
    const match = referenceList.find((item) => item.name === name);
    return match ? { name: match.name, icon: match.icon } : { name };
  });
};

const StrainLower = ({ mainHeading, options, referenceList, isLast }) => {
  const containerClass = `flex justify-start items-center py-2 mb-${
    isLast ? '0' : '4'
  }`;
  const mappedOptions = mapNamesToIcons(options, referenceList);

  return (
    <>
      <div className={containerClass}>
        {mappedOptions?.map((item, index) => (
          <React.Fragment key={index}>
            <DisplayItem item={item} />
            {index < mappedOptions.length - 1 && (
              <span className="mx-2 text-gray-300">•</span>
            )}
          </React.Fragment>
        ))}
      </div>
    </>
  );
};

export default StrainLower;
