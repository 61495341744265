import { Skeleton } from '@mui/material';
import React from 'react';

const BrandSinglePageLoader = () => {
  return (
    <div className=" container mx-auto mb-10 ">
      <div>
        <Skeleton
          sx={{
            bgcolor: '#232524',
            height: { xs: 140, md: 400 },
          }}
          variant="rounded"
          className="w-full "
        />
      </div>
      <div className=" flex  items-center justify-start gap-4">
        <Skeleton
          sx={{ bgcolor: '#232524' }}
          variant="circular"
          width={80}
          height={80}
        />
        <div>
          <Skeleton
            sx={{ bgcolor: '#232524' }}
            variant="text"
            width={200}
            height={32}
          />
          <Skeleton
            sx={{ bgcolor: '#232524' }}
            variant="text"
            width={200}
            height={20}
          />
          <Skeleton
            sx={{ bgcolor: '#232524' }}
            variant="text"
            width={200}
            height={20}
          />
          <Skeleton
            sx={{ bgcolor: '#232524' }}
            variant="text"
            width={200}
            height={20}
          />

          <div className=" flex items-center justify-center gap-4">
            <Skeleton
              sx={{ bgcolor: '#232524' }}
              variant="text"
              width={100}
              height={40}
            />
            <Skeleton
              sx={{ bgcolor: '#232524' }}
              variant="text"
              width={100}
              height={40}
            />
          </div>
        </div>
      </div>
      <div className=" flex justify-start items-center gap-4">
        <Skeleton
          sx={{ bgcolor: '#232524' }}
          variant="text"
          width={60}
          height={40}
        />
        <Skeleton
          sx={{ bgcolor: '#232524' }}
          variant="text"
          width={60}
          height={40}
        />
        <Skeleton
          sx={{ bgcolor: '#232524' }}
          variant="text"
          width={60}
          height={40}
        />{' '}
        <Skeleton
          sx={{ bgcolor: '#232524' }}
          variant="text"
          width={60}
          height={40}
        />{' '}
        <Skeleton
          sx={{ bgcolor: '#232524' }}
          variant="text"
          width={60}
          height={40}
        />
      </div>
      <div className=" flex flex-col justify-start gap-6">
        {Array.from({ length: 3 }).map((item, index) => (
          <div key={index + 1}>
            <Skeleton
              sx={{ bgcolor: '#232524' }}
              variant="text"
              width={100}
              height={40}
            />
            <div className="grid grid-cols-2 md:grid-cols-4 gap-4 w-full">
              {Array.from({ length: 4 }).map((_, index) => (
                <div key={index + 1} className="w-full flex flex-col gap-2">
                  <Skeleton
                    sx={{ bgcolor: '#232524' }}
                    variant="rectangular"
                    className="w-full"
                    height={150}
                  />
                  <Skeleton
                    sx={{ bgcolor: '#232524' }}
                    variant="rectangular"
                    className="w-full"
                    height={20}
                  />
                  <Skeleton
                    sx={{ bgcolor: '#232524' }}
                    variant="rectangular"
                    className="w-full"
                    height={10}
                  />
                  <Skeleton
                    sx={{ bgcolor: '#232524' }}
                    variant="rectangular"
                    className="w-full"
                    height={10}
                  />
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BrandSinglePageLoader;
