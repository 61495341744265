import React from 'react';
import Modal from 'react-modal';

const SessionCardModal = ({ isOpen, onRequestClose, children }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={{
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.75)',
          zIndex: 50,
        },
        content: {
          position: 'absolute',
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          border: 'none',
          transform: 'translate(-50%, -50%)',
          background: '#2c2c2c',
          overflow: 'hidden',
          WebkitOverflowScrolling: 'touch',
          outline: 'none',
          padding: '20px',
          zIndex: 1000,
        },
      }}
    >
      {children}
    </Modal>
  );
};

export default SessionCardModal;
