import { Skeleton } from '@mui/material';
import React from 'react';

const ProductSinglePageLoader = () => {
  return (
    <div className=" flex flex-col gap-4">
      <div className="flex flex-col md:flex-row-reverse gap-4">
        <div className=" flex flex-col gap-1">
          <Skeleton
            sx={{ bgcolor: '#232524' }}
            variant="rounded"
            width={180}
            height={18}
          />
          <Skeleton
            sx={{ bgcolor: '#232524' }}
            variant="rounded"
            width={210}
            height={36}
          />
          <Skeleton
            sx={{ bgcolor: '#232524' }}
            variant="rounded"
            width={80}
            height={22}
          />
          <Skeleton
            sx={{ bgcolor: '#232524' }}
            variant="rounded"
            width={60}
            height={22}
          />
        </div>
        <div className=" lg:w-full">
          <Skeleton
            sx={{ bgcolor: '#232524' }}
            variant="rounded"
            className="w-full"
            height={400}
          />
        </div>
      </div>
      <div>
        <Skeleton
          sx={{ bgcolor: '#232524' }}
          variant="rounded"
          className="w-full"
          height={62}
        />
      </div>
      <div>
        <Skeleton
          sx={{ bgcolor: '#232524' }}
          variant="rounded"
          className="w-full"
          height={40}
        />
      </div>
      <div>
        <Skeleton
          sx={{ bgcolor: '#232524' }}
          variant="rounded"
          className="w-full"
          height={40}
        />
      </div>
      <div>
        <Skeleton
          sx={{ bgcolor: '#232524' }}
          variant="rounded"
          className="w-full"
          height={40}
        />
      </div>{' '}
      <div>
        <Skeleton
          sx={{ bgcolor: '#232524' }}
          variant="rounded"
          className="w-full"
          height={40}
        />
      </div>
      <div>
        <Skeleton
          sx={{ bgcolor: '#232524' }}
          variant="rounded"
          className="w-full"
          height={24}
        />
      </div>
      <div className=" flex flex-col gap-2">
        <h1 className=" py-2  text-gray-300 text-2xl">Details</h1>
        <Skeleton
          sx={{ bgcolor: '#232524' }}
          variant="rounded"
          className="w-full"
          height={220}
        />
      </div>
      <div className=" flex flex-col gap-2">
        <h1 className=" py-2  text-gray-300 text-2xl">Brand</h1>
        <Skeleton
          sx={{ bgcolor: '#232524' }}
          variant="rounded"
          className="w-full"
          height={216}
        />
      </div>
      <div className=" flex flex-col gap-2">
        <h1 className=" py-2  text-gray-300 text-2xl">Strain</h1>
        <div className=" grid grid-cols-2 gap-4">
          <Skeleton
            sx={{ bgcolor: '#232524' }}
            variant="rounded"
            className="w-full"
            height={230}
          />
          <Skeleton
            sx={{ bgcolor: '#232524' }}
            variant="rounded"
            className="w-full"
            height={230}
          />
        </div>
      </div>
      <div className=" flex flex-col gap-2">
        <h1 className=" py-2  text-gray-300 text-2xl">Reviews</h1>
        <Skeleton
          sx={{ bgcolor: '#232524' }}
          variant="rounded"
          className="w-full"
          height={44}
        />
      </div>
      <div className=" flex flex-col gap-2">
        <Skeleton
          sx={{ bgcolor: '#232524' }}
          variant="rounded"
          className="w-full"
          height={350}
        />
        <Skeleton
          sx={{ bgcolor: '#232524' }}
          variant="rounded"
          className="w-full"
          height={350}
        />
      </div>
    </div>
  );
};

export default ProductSinglePageLoader;
