import React, { useCallback, useLayoutEffect, useState } from 'react';
import DonatsCharts from '../components/Charts/DonatsCharts';
import SessionCard from '../components/Sessions/SessionCard';
import { useNavigate } from 'react-router-dom';
import {
  addSessionGoalToDB,
  getSession,
  getSessionGoalToDB,
} from '../features/sessions/SessionServices';
import GoalCard from '../components/Sessions/GoalCard';
import { BsFillBarChartLineFill } from 'react-icons/bs';
import { useSnackbar } from 'notistack';

const Sessions = () => {
  // hooks
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [Tab, setTab] = useState('Sessons');
  const [Data, setData] = useState([]);
  const [isUpdate, setisUpdate] = useState(false);
  const [dailyStreaks, setdailyStreaks] = useState();
  const [TotalDayStreak, setTotalDayStreak] = useState(0);
  const [totalUniqueSessions, settotalUniqueSessions] = useState(0);
  const [todayConsumingQty, settodayConsumingQty] = useState([]);

  const [goalData, setGoalData] = useState({
    sessionsPerDay: 0,
    dosagePerSession: 0,
    dosageUnit: 'Gram',
  });

  const fetchData = useCallback(async () => {
    try {
      const res = await getSession();
      setData(res?.sessions);
      setdailyStreaks(res?.dailyStreaks);
      setTotalDayStreak(res?.totalDayStreak);
      settotalUniqueSessions(res?.totalUniqueSessions);
      settodayConsumingQty(res?.todayConsumingQty);
    } catch (error) {
      enqueueSnackbar(error?.message, {
        variant: 'error',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSessionGoal = useCallback(async () => {
    try {
      const res = await getSessionGoalToDB();
      if (res) {
        setGoalData({
          sessionsPerDay: res?.sessions.dailyGoal,
          dosagePerSession: 1,
          dosageUnit: 'Gram',
        });
      }
    } catch (error) {
      console.error('Error getting data from local storage', error);
    }
  }, []);

  useLayoutEffect(() => {
    fetchData();
    getSessionGoal();
  }, [fetchData, getSessionGoal, isUpdate]);

  const setDosageUnit = useCallback(
    (unit) => {
      setGoalData((prev) => ({ ...prev, dosageUnit: unit }));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [goalData.sessionsPerDay]
  );

  const addSessionGoal = async (value) => {
    setLoading(true);
    try {
      const res = await addSessionGoalToDB(value);
      if (res) {
        enqueueSnackbar(res?.message, {
          variant: 'success',
        });
        setLoading(false);
      }
    } catch (error) {
      enqueueSnackbar(error?.message, {
        variant: 'error',
      });
      setLoading(false);
    }
  };

  return (
    <div className=" text-white flex flex-col gap-5 md:w-[80%] xl:w-[60%] w-full p-5 mx-auto   ">
      <div className=" flex justify-between items-center gap-5">
        <div className="w-full bg-[#1d1d1d]  rounded-sm flex justify-between items-center  px-3 py-[8px] gap-5">
          <div
            className={` p-2 cursor-pointer w-full text-center rounded-sm ${
              Tab === 'Sessons' ? 'bg-black ' : ''
            } `}
            onClick={() => setTab('Sessons')}
          >
            Sessions
          </div>
          <div
            className={`p-2 cursor-pointer  w-full text-center rounded-sm ${
              Tab === 'Dosage' ? 'bg-black ' : ''
            } `}
            onClick={() => setTab('Dosage')}
          >
            Dosage
          </div>
        </div>
        <div
          onClick={() => navigate('/sessions-state')}
          className=" text-white flex flex-col justify-center bg-[#1d1d1d] rounded-sm   px-3 py-[10px] cursor-pointer"
        >
          <BsFillBarChartLineFill className="ml-2 text-mary-theme" size={16} />{' '}
          <p className="text-sm">Stats</p>
        </div>
      </div>
      <div>
        {Tab === 'Sessons' && (
          <GoalCard
            title="Set your daily goal"
            Type="Session"
            DayType="Day"
            Qty={goalData?.sessionsPerDay}
            setQty={(item) =>
              setGoalData((pre) => ({
                ...pre,
                sessionsPerDay: item > 0 ? item : goalData?.sessionsPerDay,
              }))
            }
            setGoal={() => {
              addSessionGoal(goalData);
            }}
            loading={loading}
          />
        )}
        {Tab === 'Dosage' && (
          <GoalCard
            title="Set your dosage goal"
            Type="Dosage"
            DayType="Session"
            dosageUnit={goalData?.dosageUnit}
            Qty={goalData?.dosagePerSession}
            setQty={(item) =>
              setGoalData((pre) => ({
                ...pre,
                dosagePerSession: item > 0 ? item : goalData?.dosagePerSession,
              }))
            }
            setDosageUnit={setDosageUnit}
            setGoal={() => {
              addSessionGoal(goalData);
            }}
            loading={loading}
          />
        )}
      </div>

      <div className="flex flex-col justify-center items-center ">
        <div className=" relative w-full">
          <DonatsCharts
            todayStreak={dailyStreaks}
            sessionsPerDay={
              dailyStreaks > goalData?.sessionsPerDay
                ? dailyStreaks
                : goalData?.sessionsPerDay
            }
            dosagePerSession={goalData?.dosagePerSession}
            consumingQuantities={todayConsumingQty}
          />
          <div
            className=" absolute inset-0  flex flex-col items-center justify-center gap-1  "
            style={{
              top: '54%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 1,
            }}
          >
            <button
              onClick={() => navigate('/sessions-review')}
              className="w-12 h-12 text-2xl font-bold text-black bg-white  cursor-pointer rounded-full flex justify-center items-center"
            >
              <span className=" pb-1"> +</span>
            </button>

            <p className=" text-sm">Start sessions</p>
          </div>
        </div>
      </div>
      <div className=" flex justify-between items-center text-xl font-bold px-2">
        <div>
          <span className="text-mary-theme">{totalUniqueSessions}</span>{' '}
          <span>unique sessions</span>
        </div>
        <div>
          <span className="text-mary-theme">{TotalDayStreak}</span>{' '}
          <span>day streak</span>
        </div>
      </div>

      {/* this is card add sessions card */}
      <div className=" pb-20 flex  flex-col gap-5 ">
        {Data.length > 0 &&
          Data?.map((item, index) => (
            <div key={index}>
              <SessionCard
                isUpdate={isUpdate}
                setisUpdate={setisUpdate}
                cardData={item}
              />
            </div>
          ))}
      </div>
    </div>
  );
};

export default Sessions;
