import EmailPassword from "supertokens-auth-react/recipe/emailpassword";
import { parse, isValid, isBefore, isAfter } from "date-fns";
import InputMask from "react-input-mask";

const DobComponent = ({ value, name, onChange }) => {
  return (
    <div data-supertokens="inputContainer">
      <div data-supertokens="inputWrapper ">
        <InputMask
          mask="99/99/9999"
          value={value}
          onChange={(e) => onChange(e.target.value)}
          maskChar={null}
        >
          {({ value, onChange }) => {
            return (
              <input
                autoComplete="off"
                data-supertokens="input input-dob"
                className="supertokens-input"
                type="text"
                name={name}
                placeholder="mm/dd/yyyy"
                maxLength={10}
                value={value}
                onChange={onChange}
              />
            );
          }}
        </InputMask>
      </div>
    </div>
  );
};

const validateDate = (dateString, format) => {
  const parsedDate = parse(dateString, format, new Date());

  if (!isValid(parsedDate)) {
    return false;
  }

  const minDate = new Date(1920, 0, 1); // January 1, 1920
  const today = new Date(); // Current date

  return isAfter(parsedDate, minDate) && isBefore(parsedDate, today);
};

export default function emailPasswordConfig() {
  return EmailPassword.init({
    signInAndUpFeature: {
      signUpForm: {
        formFields: [
          {
            id: "firstName",
            label: "First Name",
            placeholder: "John",
            optional: true,
          },
          {
            id: "lastName",
            label: "Last Name",
            placeholder: "Doe",
            optional: true,
          },
          {
            id: "dob",
            label: "Date Of Birth",
            optional: true,
            validate: (value) => {
              if (!value) {
                return;
              }

              const format = "MM/dd/yyyy";
              const isValidDate = validateDate(value, format);

              if (!isValidDate) {
                return "Invalid date. Please enter a valid date";
              }

              return;
            },
            inputComponent: DobComponent,
          },
          {
            id: "phone",
            label: "Mobile Number",
            placeholder: "+12223334455",
            optional: true,
            validate: (value) => {
              if (!value) {
                return;
              }

              const phoneRegex = /^\+1\d{10}$/;
              if (!phoneRegex.test(value)) {
                return "Please enter a properly formatted phone number like +12223334455";
              }

              return;
            }
          },
          {
            id: "username",
            label: "Username",
            placeholder: "Select username",
          },
          {
            id: "terms",
            label: "",
            optional: false,
            nonOptionalErrorMsg: "Field is not optional",
            inputComponent: ({ name, onChange }) => (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "left",
                  marginBottom: "-12px",
                }}
              >
                <input
                  name={name}
                  type="checkbox"
                  onChange={(e) => onChange(e.target.checked.toString())}
                />
                <span style={{ marginLeft: 5 }}>
                  I agree to the{" "}
                  <a
                    href="https://maryapp.io/terms-and-conditions"
                    data-supertokens="link"
                  >
                    Terms and Conditions
                  </a>
                </span>
              </div>
            ),
          },
        ],
      },
    },
  });
}
