
import { CiCircleCheck } from "react-icons/ci";

const SubscriptionCard = ({Title, Des, Price, Option, duration, buttonText, saving}) => {
   
  return (
    <div className=" bg-[#2c2c2c] border border-gray-600 rounded-lg py-8 px-6 flex flex-col gap-3 text-white align-middle">
      <div className="flex justify-between">
      <h4 className=" text-zinc-300 text-xl font-semibold  text-start">{Title}</h4>
      {saving && (
        <span className="text-zinc-300 rounded-xl px-2.5 h-fit text-sm py-1 bg-slate-500 text-end">{saving}</span>
      )}
      </div>
      <div className=" flex flex-row  items-center justify-start">
        <h1 className=" text-3xl font-bold ">{Price}</h1>
        <span className="text-sm font-normal mx-1 pt-2">{duration}</span>
      </div>
      <p className="text-start text-xl text-[#9CA3AF] md:w-72">
      {Des}
      </p>
      <div>
        <ul className="flex flex-col gap-2 text-[#9CA3AF] pb-2">
           {Option && Option.map((item, index)=>(
              <li key={index} className="flex items-center text-xl gap-2">
                 <CiCircleCheck className="text-[#62AF41] font-bold"/> 
                 <span>{item}</span>
              </li>
           ))}
       
        </ul>
      </div>
      <div>
        <button className="p-3 bg-[#62AF41]  rounded-full w-full">{buttonText}</button>
      </div>
    </div>
  );
};

export default SubscriptionCard;
