import React, { useEffect, useState } from 'react';
import { BsBell } from 'react-icons/bs';
import { FiMenu } from 'react-icons/fi';
import { useLocation, useNavigate } from 'react-router-dom';
import logo from '@/Images/maryLogo.png';
import SearchBarMobile from '@/common-components/search/SearchBarMobile';
import SearchBarDesktop from '@/common-components/search/SearchBarDesktop';
import { searchUiShow } from '../CommonComponent/CommonData/CommonData';

const AuthTopBar = ({
  handleSidebarToggle,
  notificationCount,
  setStickyOffset,
  showTopBarSearch,
  setShowTopBarSearch,
}) => {
  //Hooks
  const location = useLocation();
  const navigate = useNavigate();

  // localState
  const [hidden, setHidden] = useState(false);

  let lastScrollY = 0;

  // sticky header up and down
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      setHidden(currentScrollY > lastScrollY && currentScrollY > 0);
      setStickyOffset(currentScrollY > lastScrollY && currentScrollY > 0);

      if (currentScrollY > 53 && searchUiShow?.includes(location.pathname)) {
        setShowTopBarSearch(true);
      } else {
        setShowTopBarSearch(false);
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
      lastScrollY = currentScrollY;
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [location.pathname]);

  return (
    <div
      className={`transition-transform duration-300 ease-in-out transform ${
        hidden && searchUiShow?.includes(location.pathname)
          ? '-translate-y-full'
          : 'translate-y-0 sticky top-0'
      } z-30 flex items-center justify-between gap-2 bg-[#0f0f0f] py-2 `}
    >
      {
        <div
          className={`w-20 min-w-[77px] h-auto cursor-pointer  `}
          onClick={() => navigate(`/`)}
        >
          <img className="w-full h-full" src={logo} alt="MaryApps Logo" />
        </div>
      }
      <SearchBarDesktop />
      {!showTopBarSearch && <SearchBarMobile insideHeader={true} />}

      <div className={`flex items-center gap-2 `}>
        <div
          className="text-white relative cursor-pointer"
          onClick={() => navigate(`/notifications`)}
        >
          <BsBell size={20} />
          {notificationCount > 0 && (
            <span className="text-center text-[10px] w-4 h-4 bg-[#62AF41] rounded-full absolute -top-2 -right-2 flex items-center justify-center">
              {notificationCount}
            </span>
          )}
        </div>
        <button className="flex items-center" onClick={handleSidebarToggle}>
          <FiMenu color="#ffffff" size={30} />
        </button>
      </div>
    </div>
  );
};

export default AuthTopBar;
