import React from 'react';
import { FaEdit, FaLessThan } from 'react-icons/fa';
import { IoMdSettings } from 'react-icons/io';
import { MdDelete } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const Profile = () => {
    const navigate = useNavigate();

    const userState = useSelector((state) => state.auth.user);

    const handleRedirect = () => {
        navigate(-1);
    }

    // const handleSubscription = () => {

    // }


  return (
    <div className="max-w-md mx-auto w-full pb-12">

      {/* Header Section */}
      <div className="flex justify-between items-center px-2 pb-2">
        <div onClick={handleRedirect} className="flex justify-start items-center text-white">
            <FaLessThan size={18}/>
        </div>
        <div className="flex-grow flex justify-center items-center text-white font-semibold">
          Account & Subscription
        </div>
      </div>
      <hr className='border-b-1 border-gray-700 flex-grow pb-8'/>

      <div className='flex justify-between items-center px-4 pb-4'>
        <div className='flex flex-col justify-start items-start'>
            <div className='flex justify-start items-center text-white'>First name</div>
            <div className='flex justify-start items-center text-gray-400'>{userState.firstname}</div>
        </div>
        <div className='flex justify-start items-center text-[#62AF41]'>
        <FaEdit size={20} />
        </div>
      </div>
      <hr className='border-b-1 border-gray-700 flex-grow pb-4'/>

      <div className='flex justify-between items-center px-4 pb-4'>
        <div className='flex flex-col justify-start items-start'>
            <div className='flex justify-start items-center text-white'>Last name</div>
            <div className='flex justify-start items-center text-gray-400'>{userState.lastname}</div>
        </div>
        <div className='flex justify-start items-center text-[#62AF41]'>
        <FaEdit size={20} />
        </div>
      </div>
      <hr className='border-b-1 border-gray-700 flex-grow pb-4'/>

      <div className='flex justify-between items-center px-4 pb-4'>
        <div className='flex flex-col justify-start items-start'>
            <div className='flex justify-start items-center text-white'>Email address</div>
            <div className='flex justify-start items-center text-gray-400'>{userState.email}</div>
        </div>
        <div className='flex justify-start items-center text-[#62AF41]'>
        <IoMdSettings size={20} />
        </div>
      </div>
      <hr className='border-b-1 border-gray-700 flex-grow pb-4'/>

      <div className='flex justify-between items-center px-4 pb-4'>
        <div className='flex flex-col justify-start items-start'>
            <div className='flex justify-start items-center text-white'>Password 8+ characters</div>
            <div className='flex justify-start items-center text-gray-400'>********</div>
        </div>
        <div className='flex justify-start items-center text-[#62AF41]'>
        <FaEdit size={20} />
        </div>
      </div>
      <hr className='border-b-1 border-gray-700 flex-grow pb-4'/>

      <div className='flex justify-between items-center px-4 pb-4'>
        <div className='flex flex-col justify-start items-start'>
            <div className='flex justify-start items-center text-white'>Link your social accounts</div>
            <div className='flex justify-start items-center text-gray-400'>User-Password-Headspace</div>
        </div>
        <div className='flex justify-start items-center text-[#62AF41]'>
        <IoMdSettings size={20} />
        </div>
      </div>
      <hr className='border-b-1 border-gray-700 flex-grow pb-4'/>

      <div className='flex justify-between items-center px-4 pb-4'>
        <div className='flex flex-col justify-start items-start'>
            <div className='flex justify-start items-center text-white'>Subscription details</div>
            <div className='flex justify-start items-center text-gray-400'>Monthly</div>
        </div>
        <div className='flex justify-start items-center text-[#62AF41]'>
        <FaEdit size={20} />
        </div>
      </div>
      <hr className='border-b-1 border-gray-700 flex-grow pb-4'/>

      <div className='flex justify-between items-center px-4 pb-4'>
        <div className='flex flex-col justify-start items-start'>
            <div className='flex justify-start items-center text-white'>Delete Account</div>
        </div>
        <div className='flex justify-start items-center text-[#62AF41]'>
        <MdDelete  size={20}/>
        </div>
      </div>
      <hr className='border-b-1 border-gray-700 flex-grow pb-4'/>

    </div>
  );
};

export default Profile;
