import React, { useEffect, useState } from 'react';
import { IoArrowBack } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import NotificationItem from '../components/Notification/NotificationItem';
import NotificationPageLoader from '../skeleton/NotificationPageLoader';
import { enqueueSnackbar } from 'notistack';
import { getAllFolders } from '../features/stashFolder/folderSlice';
import { useDispatch } from 'react-redux';
import { useGetUserNotificationsQuery } from '../redux/api/notificationApi';

const Notifications = () => {
  // hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { data, error, isLoading } = useGetUserNotificationsQuery();

  const [isUpdate, setIsUpdate] = useState(false);

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error?.message || 'Error fetching data', {
        variant: 'error',
      });
    }
  }, [error]);

  useEffect(() => {
    let isSubscribe = true;
    if (isSubscribe) {
      dispatch(getAllFolders());
    }
    return () => (isSubscribe = false);
  }, [dispatch, isUpdate]);

  return (
    <div className="container mx-auto px-4 pb-20">
      <div className="flex flex-col gap-4">
        <div className="text-[#7cdb54] text-sm underline flex items-center">
          <IoArrowBack
            size="1.2em"
            style={{ marginRight: '4px', marginTop: '1px' }}
          />
          <button onClick={() => navigate(-1)} type="button">
            Back
          </button>
        </div>
        {isLoading && (
          <>
            <NotificationPageLoader Date="Today" NumberOfCard={3} />
            <NotificationPageLoader Date="This Week" NumberOfCard={3} />
            <NotificationPageLoader Date="Older" NumberOfCard={3} />
          </>
        )}

        {!isLoading && (
          <div className="flex flex-col gap-4 w-full">
            {data?.map((item, index) => {
              return (
                <div key={index}>
                  <h4 className="text-[#bbbbbb] text-md font-bold">
                    {item?.date}
                  </h4>
                  <div className=" flex flex-col gap-4 w-full">
                    {item?.data?.length > 0 ? (
                      item?.data?.map((notification) => (
                        <NotificationItem
                          itemId={
                            notification?.strain
                              ? notification?.strain?._id
                              : notification?.product?._id
                          }
                          item={notification}
                          itemUrl={
                            notification?.strain
                              ? `/strain/${notification?.strain?._id}`
                              : `/product/${notification?.product?._id}`
                          }
                          bussinessImage={
                            notification?.strain
                              ? notification?.strain?.business?.id?.profileImg
                                  ?.url
                              : notification?.product?.brand?.profileImg?.url
                          }
                          type={notification?.strain ? 'strain' : 'product'}
                          postedTime={notification?.createdAt}
                          bussinessName={
                            notification?.strain
                              ? notification?.strain?.business?.id?.title
                              : notification?.product?.brand?.title
                          }
                          title={
                            notification?.strain
                              ? notification?.strain?.title
                              : notification?.product?.title
                          }
                          parent={
                            notification?.strain
                              ? `${notification?.strain?.mother?.title} X ${notification?.strain?.father?.title} `
                              : ''
                          }
                          category={
                            notification?.strain
                              ? notification?.strain?.type
                              : notification?.product?.category[0]
                          }
                          weight={notification?.product?.weight?.value}
                          unit={notification?.product?.weight?.unit}
                          price={notification?.product?.price}
                          itemImage={
                            notification?.strain
                              ? notification?.strain?.strainImg?.url
                              : notification?.product?.productImg?.url
                          }
                          isSeen={notification?.seenBy}
                          onUpdate={() => setIsUpdate(!isUpdate)}
                        />
                      ))
                    ) : (
                      <h6 className=" text-white text-[10px] font-bold py-2 px-4">
                        No Notifications
                      </h6>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default Notifications;
