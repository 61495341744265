import React from 'react';
import Modal from 'react-modal';
import { FaThumbtack, FaTrashAlt } from 'react-icons/fa';

const ReviewModal = ({
  isOpen,
  onRequestClose,
  onPinToggle,
  isPinned,
  onDelete,
  shouldShowDeleteButton,
}) => {
  console.log(isPinned, 'isPinned');
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={{
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.75)',
          zIndex: 50, // Ensure this is higher than any content on the page
        },
        content: {
          position: 'absolute',
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          border: 'none',
          transform: 'translate(-50%, -50%)',
          background: '#0f0f0f', // Updated background color
          overflow: 'hidden', // Disable horizontal scroll
          WebkitOverflowScrolling: 'touch',
          outline: 'none',
          padding: '20px',
          zIndex: 1000, // Ensure content is also above the overlay
        },
      }}
    >
      <div className="bg-[#0f0f0f] p-6 rounded-lg w-72 text-white">
        <button
          onClick={onPinToggle}
          className="flex items-center justify-center w-full py-2 mb-4 bg-[#787777] text-white rounded hover:bg-[#595959]"
        >
          <FaThumbtack className="mr-2" />
          {isPinned ? 'Unpin Review' : 'Pin Review'}
        </button>
        {shouldShowDeleteButton && (
          <button
            onClick={() => {
              onDelete();
              onRequestClose();
            }}
            className="flex items-center justify-center w-full py-2 bg-red-600 text-white rounded hover:bg-red-500"
          >
            <FaTrashAlt className="mr-2" />
            Delete Review
          </button>
        )}
      </div>
    </Modal>
  );
};

export default ReviewModal;
