import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Products from '../components/products/Products';
import { enqueueSnackbar } from 'notistack';
import { getProductsByCategory } from '../features/products/productService';
import StrainCardLoader from '../skeleton/StrainCardLoader';
import { CustomPagination } from '@/common-components/mary-pagination/MaryPagination';

const CategoryProducts = () => {
  // hooks
  const { brandId, categoryName } = useParams();

  // localState
  const [loading, setLoading] = useState(true);
  const [Data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  // get brand prduct by category
  const getData = useCallback(async (brandId, categoryName, page) => {
    try {
      const res = await getProductsByCategory(brandId, categoryName, page);

      if (res) {
        setData(res?.products);
        setPage(res?.pagination?.currentPages);
        setTotalPages(res?.pagination?.totalPages);
        setLoading(false);
      }
    } catch (error) {
      enqueueSnackbar(error?.message, { variant: 'error' });
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getData(brandId, categoryName, page);
  }, [getData, brandId, categoryName, page]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  return (
    <div className="container mx-auto pt-4 pb-20">
      <h1 className="text-xl font-bold text-white mb-4">
        Products in {categoryName}
      </h1>
      {loading && <StrainCardLoader NumberOfCard={12} />}
      {!loading && (
        <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-4">
          {Data.map((product, index) => (
            <Products
              key={index}
              _id={product._id}
              title={product.title}
              price={product.price}
              thc_percentage={product.thc_percentage}
              cbd_percentage={product.cbd_percentage}
              brandId={product.brand}
              strainId={product.strain}
              category={product.category}
              weight={product.weight}
              productImg={product.productImg.url}
            />
          ))}
        </div>
      )}
      {totalPages > 1 && (
        <div className=" py-4">
          <CustomPagination
            count={totalPages}
            page={page}
            onChange={handlePageChange}
            variant="outlined"
          />
        </div>
      )}
    </div>
  );
};

export default CategoryProducts;
