import { Skeleton } from '@mui/material';
import React from 'react';

const AllSubLoader = ({ numberOfItems }) => {
  return (
    <div className="flex justify-start items-start gap-2 overflow-y-auto scrollbar-hide ">
      {Array.from({ length: numberOfItems })?.map((_, index) => (
        <div key={index} className="flex flex-col items-center gap-1 ">
          <Skeleton
            sx={{ bgcolor: '#232524' }}
            variant="circular"
            width={56}
            height={56}
          />
          <Skeleton
            sx={{ bgcolor: '#232524' }}
            variant="text"
            width={56}
            height={16}
          />
        </div>
      ))}
      <div className="sticky right-0 bg-[#232524]   justify-self-stretch">
        <div className="w-[50px] h-[76px] text-white flex items-center  justify-center ">
          All
        </div>
      </div>
    </div>
  );
};

export default AllSubLoader;
