import { Skeleton } from '@mui/material';
import React from 'react';

const SubscriptionItemLoader = ({ numberOfItem }) => {
  return (
    <div className=" flex flex-col gap-4">
      {Array.from({ length: numberOfItem })?.map((item, index) => (
        <div key={index} className=" flex justify-between items-center ">
          <div className=" flex justify-start items-center gap-2">
            <Skeleton
              sx={{ bgcolor: '#232524' }}
              variant="circular"
              width={40}
              height={40}
            />
            <Skeleton
              sx={{ bgcolor: '#232524' }}
              variant="rounded"
              width={150}
              height={24}
            />
          </div>

          <Skeleton
            sx={{ bgcolor: '#232524' }}
            variant="rounded"
            width={70}
            height={40}
          />
        </div>
      ))}
    </div>
  );
};

export default SubscriptionItemLoader;
