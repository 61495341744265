import { createSlice } from "@reduxjs/toolkit";
 
export interface AuthState {
    jwt?: string,
};

const initialState = {
  user: null,
  jwt: null,
  isLoading: true,
  isSignedIn: false
} as AuthState;

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUserToken: (state, action) => {
      state.jwt = action.payload;
    }
  },
});
export const selectSession = (state: { auth: AuthState }) => state.auth;

export const { setUserToken } = authSlice.actions;
export default authSlice.reducer;

