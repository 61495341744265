import React from 'react';
import { Link } from 'react-router-dom';
import { getUrl } from '../../../utils/GetBussinessUrl';

const SubscribedBussiness = ({ Data }) => {
  return (
    <div
      id="hide"
      className={`flex justify-start items-start md:space-x-10 overflow-y-auto scrollbar-hide space-x-4 pl-4 pb-2`}
    >
      {Data?.map((business, index) => {
        return (
          <div key={index} className="flex flex-col items-center gap-1 pl-1">
            <Link to={getUrl(business?.PrimaryVendor, business?.slug)}>
              <img
                src={business?.image?.url}
                alt={`${business.title} Profile`}
                className="w-14 h-14 rounded-full"
              />
            </Link>
            <span className="text-xs w-14 truncate text-center mt-1 text-white">
              {business.title}
            </span>
          </div>
        );
      })}
      <div className="sticky right-0">
        <Link
          to="/allSubscribedBrand"
          className="flex items-center justify-center h-20 w-12 pb-4 bg-[#0f0f0f] text-white text-center text-sm cursor-pointer hover:bg-gray-700"
        >
          All
        </Link>
      </div>
    </div>
  );
};

export default SubscribedBussiness;
