import React, { useEffect, useRef, useState } from 'react';
import Chart from 'chart.js/auto';
import { BsThreeDots } from 'react-icons/bs';
import StateSocialShareModal from './StateSocialShareModal';
import SessionCardModal from '../CommonComponent/SessionCardModal';
import { FaShareSquare } from 'react-icons/fa';
import { MdDownload } from 'react-icons/md';
import html2canvas from 'html2canvas';

const SessionPieChart = ({ title, data }) => {
  const chartRef = useRef(null);
  const myChartRef = useRef(null);
  const cardRef = useRef();

  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [isActionModalOpen, setIsActionModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const ctx = chartRef.current.getContext('2d');

    // Parse data for labels and dataset
    const chartLabels = data?.map((item) => item.name);
    const chartData = data?.map((item) => item.count);

    // Check if there is an existing chart instance and destroy it
    if (myChartRef.current) {
      myChartRef.current.destroy();
    }

    // Create a new chart instance and store it in the ref
    myChartRef.current = new Chart(ctx, {
      type: 'pie',
      data: {
        labels: chartLabels,
        datasets: [
          {
            label: 'Session Data',
            data: chartData,
            backgroundColor: [
              '#4CAF50',
              '#8BC34A',
              '#9E9E9E',
              '#607D8B',
              '#b2850a',
              '#354498',
              '#c944a5',
              '#808276',
              '#76307c',
              '#f8948f',
              '#bb7086',
              '#5778d4',
              '#cda8b7',
              '#24b53e',
              '#1aa9f9',
              '#5d366f',
              '#de72f0',
              '#1f0572',
              '#4b6a49',
              '#30d46e',
            ],
            borderColor: ['rgb(255, 255, 255)'],
            borderWidth: 2,
            hoverOffset: 4,
          },
        ],
      },
      options: {
        plugins: {
          legend: {
            display: true,
            position: 'top',
          },
          tooltip: {
            callbacks: {
              label: function (tooltipItem) {
                let label = data[tooltipItem.dataIndex].name;
                let count = data[tooltipItem.dataIndex].count;
                return `${label}: ${count}`;
              },
            },
          },
        },
      },
    });

    // Cleanup function to destroy the chart instance
    return () => {
      if (myChartRef.current) {
        myChartRef.current.destroy();
      }
    };
  }, [data]);

  const handleShare = () => {
    setIsShareModalOpen(true);
  };

  function myRenderFunction(canvas) {
    var a = document.createElement('a');
    a.href = canvas
      .toDataURL('image/jpeg')
      .replace('image/jpeg', 'image/octet-stream');
    a.download = 'SessionCart.jpg';
    a.click();
  }

  const handleDownload = () => {
    if (cardRef.current === null) {
      return;
    }
    setLoading(true);
    cardRef.current.style.backgroundColor = '#0f0f0f';
    document.fonts.ready.then(() => {
      html2canvas(cardRef.current, { useCORS: true })
        .then((canvas) => {
          cardRef.current.style.backgroundColor = '';
          myRenderFunction(canvas);
          setLoading(false);
        })
        .catch((err) => {
          console.error('Oops, something went wrong!', err);
          cardRef.current.style.backgroundColor = '';
          setLoading(false);
        });
    });
  };
  const shareUrl = `https://maryapp.io/sessions-state/`;

  return (
    <div className="bg-[#27262b] text-white  rounded-md " ref={cardRef}>
      <div className="py-4">
        <div className="flex justify-between items-center gap-2 px-2">
          <h4 className="text-center font-semibold text-[#525252] text-lg">
            {title}
          </h4>
          <BsThreeDots
            onClick={() => setIsActionModalOpen(!isActionModalOpen)}
            size={28}
            className="text-gray-300 cursor-pointer"
          />
          <SessionCardModal
            isOpen={isActionModalOpen}
            onRequestClose={() => setIsActionModalOpen(false)}
          >
            <div className=" p-6 rounded-lg w-72 text-white space-y-4">
              <button
                onClick={handleDownload}
                className="flex items-center justify-center w-full py-2 mb-4 bg-black text-white rounded "
              >
                <MdDownload className="mr-2" size={22} />
                {loading ? 'Loading...' : 'Download'}
              </button>
              <button
                onClick={handleShare}
                className="flex items-center justify-center w-full py-2 bg-blue-600 text-white rounded hover:bg-blue-500"
              >
                <FaShareSquare className="mr-2" />
                Share
              </button>
            </div>
          </SessionCardModal>
          <StateSocialShareModal
            title={title}
            onClose={() => setIsShareModalOpen(!isShareModalOpen)}
            isOpen={isShareModalOpen}
            url={shareUrl}
          />
        </div>
      </div>
      <div className="pb-4">
        <canvas ref={chartRef} />
      </div>
    </div>
  );
};

export default SessionPieChart;
