import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Rating from 'react-rating-stars-component';
import { IoShareOutline } from 'react-icons/io5';
import { FaCheckCircle } from 'react-icons/fa';
import { CiCirclePlus } from 'react-icons/ci';
import SocialShareModal from '../components/StrainDetail/SocialShare';
import { getAllFolders } from '../features/stashFolder/folderSlice';
import 'react-toastify/dist/ReactToastify.css';
import FolderSelectionModal from '../components/StrainDetail/FolderSelectionModal';
import ReviewList from '../components/productDetails/ReviewList';
import { enqueueSnackbar } from 'notistack';

import BussinessCard from '../components/CommonComponent/AddBusinesses/BussinessCard';
import ProductSinglePageLoader from '../skeleton/ProductSinglePageLoader';
import { addStrainToFolder } from '../features/stashFolder/folderService';
import { useGetProductByIdQuery } from '../redux/api/productApi';
import { format } from 'date-fns';
import StrainCard from '@/common-components/strain/StrainCard';

const ProductPage = () => {
  // hooks
  const { id } = useParams();
  const dispatch = useDispatch();
  let navigate = useNavigate();

  // globalState
  const folders = useSelector((state) => state.folder.folders);
  const isUser = useSelector((state) => state.auth.user?._id);

  // localState

  const [isUpdate, setisUpdate] = useState(false);
  const [isFolderModalOpen, setIsFolderModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('Details');

  // eslint-disable-next-line no-unused-vars
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const sectionsRefs = {
    Details: useRef(null),
    Brand: useRef(null),
    Strain: useRef(null),
    Reviews: useRef(null),
  };

  const { data, error, isLoading } = useGetProductByIdQuery(id);

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error?.message, { variant: 'error' });
    }
  }, [error]);

  useEffect(() => {
    let isSubscribe = true;
    if (isSubscribe) {
      dispatch(getAllFolders());
    }
    return () => (isSubscribe = false);
  }, [dispatch, id, isUpdate]);

  const isAddedInFolder = useMemo(() => {
    for (let folder of folders) {
      if (folder.products?.find((item) => item?._id === id)) {
        return true;
      }
    }
    return false;
  }, [id, folders]);

  const scrollToSection = (key) => {
    setActiveTab(key);
    sectionsRefs[key].current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      // Loop through each section and check if it's in view
      Object.keys(sectionsRefs)?.forEach((key) => {
        const sectionRef = sectionsRefs[key];
        const sectionTop = sectionRef?.current && sectionRef.current?.offsetTop;
        const sectionHeight = sectionRef.current?.offsetHeight;
        if (
          scrollPosition >= sectionTop &&
          scrollPosition < sectionTop + sectionHeight
        ) {
          setActiveTab(key);
        }
      });
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleAddToStash = async () => {
    const likedStrainsFolder = folders?.find(
      (folder) => folder.name === 'Liked Products'
    );

    try {
      let Data = {
        itemId: id,
        type: 'product',
      };

      const res = await addStrainToFolder(likedStrainsFolder._id, Data);
      if (res) {
        setisUpdate(!isUpdate);
        enqueueSnackbar(res?.message, { variant: 'success' });
      }
    } catch (error) {
      enqueueSnackbar(error?.message, { variant: 'error' });
    }
  };

  const toggleFolderModal = () => {
    setIsFolderModalOpen(true);
  };

  const renderIcon = () => {
    if (isAddedInFolder) {
      return (
        <FaCheckCircle
          size={28}
          className="text-[#62AF41]"
          onClick={toggleFolderModal}
        />
      );
    } else {
      return (
        <CiCirclePlus
          size={28}
          className="text-white"
          onClick={handleAddToStash}
        />
      );
    }
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleReviewDetailOpen = (productId) => {
    if (isUser) {
      navigate(`/product-detail/${productId}/review-detail`, {
        state: { returnPath: `/product/${productId}` },
      });
    } else {
      setIsPopupOpen(true);
    }
  };

  const handleUpdate = () => {
    setisUpdate(!isUpdate);
  };
  const shareUrl = `https://maryapp.io/product/`;

  function linkify(text) {
    const urlRegex =
      /(\b(?:https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|]|\bwww\.[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|]|\b[-A-Z0-9+&@#/%?=~_|]+\.[A-Z]{2,4}[-A-Z0-9+&@#/%?=~_|]*)/gi;
    return text?.replace(urlRegex, function (url) {
      let href = url;
      if (!/^(?:https?|ftp|file):\/\//i.test(url)) {
        href = 'http://' + url;
      }
      return `<a href="${href}" target="_blank" rel="noopener noreferrer" class="text-blue-500 hover:text-blue-600 underline">${url}</a>`;
    });
  }

  const formattedDescription = linkify(data?.product?.productDescription);

  return (
    <div className="container lg:mx-auto lg:w-[60%] p-4  pb-20">
      {isLoading && <ProductSinglePageLoader />}
      {!isLoading && (
        <>
          <div className="flex flex-col md:flex-row justify-start items-start gap-5">
            <div
              className="flex  items-center  order-2 md:order-none h-[400px] lg:h-[500px]  w-full   z-10"
              style={{ objectFit: 'cover' }}
            >
              <img
                src={data?.product?.productImg}
                alt={data?.product?.title}
                className=" h-full   w-full rounded-xl"
              />
            </div>
            <div className="flex flex-col justify-start items-start w-full md:w-1/2 md:px-4 px-0 py-2">
              <div className="text-xs text-white pb-1 md:pb-2">
                {data?.product?.createdAt
                  ? format(new Date(data?.product?.createdAt), 'yyyy-MM-dd')
                  : 'N/A'}
              </div>
              <div className="text-white text-2xl font-semibold md:text-3xl pb-1 md:pb-4">
                {data?.product?.title}
              </div>
              <div className="text-gray-300 text-sm md:text-xl pb-1 md:pb-4">
                THC: {data?.product?.thc_percentage}%
              </div>
              <div className="text-gray-300 text-sm md:text-xl pb-1 md:pb-4">
                CBD: {data?.product?.cbd_percentage}%
              </div>

              <div className="md:flex md:justify-start md:items-center pb-1 md:pb-4 hidden">
                <span className="text-gray-300 font-semibold text-2xl">
                  ${data?.product?.price}.00 USD
                </span>
              </div>

              <div className="md:flex md:flex-col md:justify-start md:items-start pb-1 md:pb-4 hidden">
                <h1 className="inline-flex items-center py-2 text-2xl pr-1 text-white">
                  Variant
                </h1>
                <button className="inline-flex items-center px-3 py-1 border border-gray-200 rounded bg-gray-100 shadow-sm">
                  <div className="text-sm md:text-xl pr-1">
                    {data?.product?.weight?.value}
                  </div>
                  <div className="text-sm md:text-xl">
                    {data?.product?.weight?.unit}
                  </div>
                </button>
              </div>

              <div className="md:flex md:flex-col md:justify-start md:items-start hidden py-4 gap-4 w-full">
                <button className="rounded-xl bg-[#d9d9d9] hover:bg-[#777777] border border-gray-500 px-4 py-2 text-xl w-80">
                  Add to cart
                </button>
                <button className="rounded-xl bg-[#62AF41] border-[#62AF41] hover:bg-[#3d6b2a] px-5 py-2 text-xl w-80">
                  Buy it now
                </button>
              </div>
            </div>
          </div>

          {/* Stars and Icons container always third on small screens */}
          <div className="flex justify-between items-center  w-full order-last  py-2 ">
            <div className="flex flex-col justify-start  text-white">
              <Rating
                count={5}
                size={20}
                activeColor="#FFFFFF"
                value={5}
                edit={false}
                className="bg-[#253238]"
              />
              <span className="text-xs">{data?.reviews?.length} Reviews</span>
            </div>
            <div className="flex justify-start items-center gap-2">
              <span onClick={toggleModal}>
                <IoShareOutline
                  size={24}
                  className="cursor-pointer text-white"
                />
              </span>
              <span className="cursor-pointer">{renderIcon()}</span>
              {folders && (
                <FolderSelectionModal
                  itemType="product"
                  itemId={id}
                  backBtnText="Back to Product"
                  isOpen={isFolderModalOpen}
                  onClose={() => setIsFolderModalOpen(false)}
                  onUpdate={() => setisUpdate(!isUpdate)}
                />
              )}
            </div>
            {isModalOpen && (
              <SocialShareModal
                isOpen={isModalOpen}
                url={`${shareUrl}${id}`}
                title={data?.product?.title}
                strainImg={data?.product?.productImg.url}
                type={data?.product?.category[0]}
                onClose={toggleModal}
                des={'Share this product'}
              />
            )}
          </div>
          <div className="flex justify-start items-center py-2 ">
            <span className="text-gray-300 font-semibold">
              ${data?.product?.price}.00 USD
            </span>
          </div>
          <div className="flex flex-col justify-start items-start py-2 ">
            <h1 className="inline-flex items-center py-2 text-xl md:text-2xl pr-1 text-white">
              Variant
            </h1>
            <button className="inline-flex items-center px-3 py-1 border border-gray-200 rounded bg-gray-100 shadow-sm">
              <div className="text-sm md:text-xl pr-1">
                {data?.product?.weight?.value}
              </div>
              <div className="text-sm md:text-xl">
                {data?.product?.weight?.unit}
              </div>
            </button>
          </div>
          <div className="flex flex-col justify-start items-start md:hidden py-4 gap-2">
            <button className="rounded-xl bg-[#d9d9d9] hover:bg-[#777777] border border-gray-500 py-2 px-2 w-full">
              Add to cart
            </button>
            <button className="rounded-xl bg-[#62AF41] border-[#62AF41] hover:bg-[#3d6b2a] py-2 px-3 w-full">
              Buy it now
            </button>
          </div>
          <div className="sticky top-[60px] md:top-18 z-10 bg-[#0f0f0f] flex justify-start items-center text-white space-x-2 md:space-x-4 overflow-x-auto scrollbar-hide mb-6 ">
            {Object.keys(sectionsRefs).map((key) => (
              <button
                key={key}
                onClick={() => scrollToSection(key)}
                className={`py-2 px-1 whitespace-nowrap ${
                  activeTab === key ? 'border-b-4 border-primary font-bold' : ''
                }`}
              >
                {key}
              </button>
            ))}
          </div>

          <div
            ref={sectionsRefs.Details}
            className=" py-2  text-gray-300 text-2xl"
          >
            Details
          </div>
          <div className="">
            <div
              className="text-white text-sm"
              style={{ whiteSpace: 'pre-line' }}
              dangerouslySetInnerHTML={{ __html: formattedDescription }}
            ></div>
          </div>

          <div
            ref={sectionsRefs.Brand}
            className=" py-2  text-gray-300 text-2xl"
          >
            Brand
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 ">
            <BussinessCard
              loading={isLoading}
              Data={data?.product?.brand}
              isUpdate={isUpdate}
              OnUpdate={() => handleUpdate()}
            />
          </div>

          <div
            ref={sectionsRefs.Strain}
            className=" py-2 text-gray-300 text-2xl"
          >
            Strain
          </div>
          {data?.product?.strain && (
            <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-4">
              <StrainCard
                itemType="strain"
                key={data?.product?.strain?._id}
                id={data?.product?.strain?._id}
                title={data?.product?.strain.title}
                father={data?.product?.strain?.father?.title ?? 'Not Found'}
                mother={data?.product?.strain?.mother?.title ?? 'Not found'}
                type={data?.product?.strain?.type}
                imageUrl={data?.product?.strain?.strainImg.url}
                business={data?.product?.strain?.business?.id}
                onUpdate={() => setisUpdate(!isUpdate)}
              />
            </div>
          )}

          <div
            ref={sectionsRefs.Reviews}
            className="py-2  text-gray-300 text-2xl"
          >
            Reviews
          </div>
          <div className="py-2 ">
            {data?.reviews?.length > 0 && (
              <div className="flex items-center mb-4 text-white">
                <h2 className="text-xl font-semibold">
                  {data?.title} reviews
                  <span className="text-lg text-[#62AF41] ml-2">
                    {data?.reviews?.length}
                  </span>
                </h2>
              </div>
            )}
            <button
              className="w-full lg:w-80 sm:w-auto bg-white text-black py-2 mb-4 border-2 border-black rounded-full font-semibold hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-opacity-50"
              onClick={() => handleReviewDetailOpen(data?.product?._id)}
            >
              Write a Review
            </button>
            <ReviewList
              reviews={data?.reviews || []}
              onUpdate={() => setisUpdate(!isUpdate)}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default ProductPage;
