import { Skeleton } from '@mui/material';
import React from 'react';

const StrainSinglePageLoader = () => {
  return (
    <div className=" flex flex-col gap-6 pb-20">
      <div className=" flex flex-col gap-2">
        <Skeleton
          sx={{ bgcolor: '#232524' }}
          variant="rounded"
          width={100}
          height={16}
        />
        <Skeleton
          sx={{ bgcolor: '#232524' }}
          variant="rounded"
          width={200}
          height={32}
        />
        <Skeleton
          sx={{ bgcolor: '#232524' }}
          variant="rounded"
          className="w-full"
          width={150}
          height={16}
        />{' '}
        <Skeleton
          sx={{ bgcolor: '#232524' }}
          variant="rounded"
          width={80}
          height={16}
        />
      </div>
      <div>
        <Skeleton
          sx={{ bgcolor: '#232524' }}
          variant="rounded"
          className="w-full"
          height={336}
        />
      </div>
      <div>
        <Skeleton
          sx={{ bgcolor: '#232524' }}
          variant="rounded"
          className="w-full"
          height={50}
        />
      </div>
      <div>
        <Skeleton
          sx={{ bgcolor: '#232524' }}
          variant="rounded"
          className="w-full"
          height={36}
        />
      </div>
      <div className=" flex flex-col gap-2">
        <h1 className=" text-white text-2xl ">Breeder</h1>
        <Skeleton
          sx={{ bgcolor: '#232524' }}
          variant="rounded"
          className="w-full"
          height={216}
        />
      </div>
      <div className=" flex flex-col gap-2">
        <h1 className=" text-white text-2xl ">Genealogy</h1>
        <div className=" grid grid-cols-2 gap-4">
          <Skeleton
            sx={{ bgcolor: '#232524' }}
            variant="rounded"
            className="w-full"
            height={216}
          />{' '}
          <Skeleton
            sx={{ bgcolor: '#232524' }}
            variant="rounded"
            className="w-full"
            height={216}
          />
        </div>
      </div>{' '}
      <div className=" flex flex-col gap-2">
        <h1 className=" text-white text-2xl ">About</h1>
        <div className=" flex flex-col gap-4">
          <Skeleton
            sx={{ bgcolor: '#232524' }}
            variant="rounded"
            className="w-full"
            height={100}
          />
          <Skeleton
            sx={{ bgcolor: '#232524' }}
            variant="rounded"
            className="w-full"
            height={100}
          />
          <Skeleton
            sx={{ bgcolor: '#232524' }}
            variant="rounded"
            className="w-full"
            height={100}
          />
          <Skeleton
            sx={{ bgcolor: '#232524' }}
            variant="rounded"
            className="w-full"
            height={100}
          />
          <Skeleton
            sx={{ bgcolor: '#232524' }}
            variant="rounded"
            className="w-full"
            height={100}
          />{' '}
          <Skeleton
            sx={{ bgcolor: '#232524' }}
            variant="rounded"
            className="w-full"
            height={100}
          />
          <Skeleton
            sx={{ bgcolor: '#232524' }}
            variant="rounded"
            className="w-full"
            height={180}
          />
        </div>
      </div>{' '}
      <div className=" flex flex-col gap-2">
        <h1 className=" text-white text-2xl ">Reviews</h1>
        <div className=" flex flex-col gap-4">
          {' '}
          <Skeleton
            sx={{ bgcolor: '#232524' }}
            variant="rounded"
            className="w-full"
            height={318}
          />{' '}
          <Skeleton
            sx={{ bgcolor: '#232524' }}
            variant="rounded"
            className="w-full"
            height={318}
          />
        </div>
      </div>{' '}
      <div className=" flex flex-col gap-2">
        <h1 className=" text-white text-2xl ">Shop</h1>
        <div className=" grid grid-cols-2 gap-4">
          <Skeleton
            sx={{ bgcolor: '#232524' }}
            variant="rounded"
            className="w-full"
            height={180}
          />
          <Skeleton
            sx={{ bgcolor: '#232524' }}
            variant="rounded"
            className="w-full"
            height={180}
          />
        </div>
      </div>
      <div className=" flex flex-col gap-2">
        <h1 className=" text-white text-2xl ">Variants</h1>
        <div className=" grid grid-cols-2 gap-4">
          <Skeleton
            sx={{ bgcolor: '#232524' }}
            variant="rounded"
            className="w-full"
            height={180}
          />
          <Skeleton
            sx={{ bgcolor: '#232524' }}
            variant="rounded"
            className="w-full"
            height={180}
          />
        </div>
      </div>
    </div>
  );
};

export default StrainSinglePageLoader;
