import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import PendingSession from '../Sessions/PendingSession';
// import AskAnything from '../CommonComponent/ChatModel/AskAnything';

const Footer = () => {
  // Hooks
  const location = useLocation();
  // golabal State
  const { session } = useSelector(((state) => state?.sessions))
  return (
    <>
      {location.pathname !== '/sessions-review' && session !== null && session?.sessionStartTime !== "" && (
        <PendingSession session={session} />
      )}
      {/* <AskAnything /> */}
    </>
  );
};

export default Footer;
