

import axios from 'axios'
import { config } from '../../utils/axiosconfig';
import { base_url } from '../../utils/base_url';

export const addBusiness = async (data) => {
    const configure = config();
    const response = await axios.post(`${base_url}business/`, data, configure);
    return response.data;
};

export const getAllBusinessesHomePage = async () => {
    const configure = config();
    const response = await axios.get(`${base_url}business/get-all-businesses`, configure);
    return response.data;
};


export const getAllBusinesses = async (page, limit) => {
    const configure = config();
    const response = await axios.get(`${base_url}business/user-businesses?page=${page ? page : 1}&limit=${limit ? limit : 20} `, configure);
    return response.data;
};

export const updateBusinesses = async (bussinessType, id, Data) => {
    const configure = config();
    const response = await axios.put(`${base_url}business/update-business?businessType=${bussinessType}&businessId=${id}`, Data, configure);
    return response.data;
};
export const getSingleBusiness = async (slug) => {
    const configure = config();
    const response = await axios.get(`${base_url}vendor/${slug}`, configure);
    return response.data;
};

export const deleteBusiness = async (bussinessType, id) => {
    const configure = config();
    const response = await axios.delete(`${base_url}business?businessType=${bussinessType}&businessId=${id}`, configure);
    return response.data;
};

export const getBusiness = async (bussinessType, page) => {
    const configure = config();
    const response = await axios.get(`${base_url}business/get-all?businessType=${bussinessType}&page=${page}&limit=20`, configure);
    return response.data;
};


export const subscribeBusiness = async (businessType, id) => {
    const configure = config();
    const response = await axios.post(`${base_url}subscribe-business`, { businessType, businessId: id }, configure);
    return response.data;
};

export const unSubscribeBusiness = async (businessType, businessId) => {
    const configure = config();
    const response = await axios.post(`${base_url}subscribe-business/unsubscribe`, { businessType, businessId }, configure);
    return response.data;
};

export const toggleBusinessNotification = async (businessType, businessId, enable) => {
    const configure = config();
    const response = await axios.put(`${base_url}subscribe-business/toggle-notification`, { businessType, businessId, enable }, configure);
    return response.data;
};






