import Answer from '@/components/askAnything/Answer';
import Content from '@/components/askAnything/Content';
import ControleButton from '@/components/askAnything/ControleButton';
import Related from '@/components/askAnything/Related';
import Sources from '@/components/askAnything/Sources';
import {
  getDefaultQuestions,
  getSearchConversations,
  sendMessagetoAi,
} from '@/features/askAnything/AskAnything';
import AskanythingLoader from '@/skeleton/AskanythingLoader';
import { localTimeZone } from '@/utils/TimeAndDate';
import { enqueueSnackbar } from 'notistack';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { GoArrowDownLeft } from 'react-icons/go';
import { useSearchParams } from 'react-router-dom';
import { Divider } from '@mui/material';
import { IoMdSend } from 'react-icons/io';

const MaryAiSearch = () => {
  let [searchParams] = useSearchParams();
  const query = searchParams.get('query');
  const searchValue = searchParams.get('search');
  const newChat = searchParams.get('newAnswer');

  const messagesEndRef = useRef(null);
  const inputRef = useRef(null);
  const optionRef = useRef(null);

  const [loading, setloading] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [message, setmessage] = useState('');
  const [isFocus, setisFocus] = useState(false);
  const [Data, setData] = useState([]);
  const [ConversationID, setConversationID] = useState('');
  const [relatedQuestions, setRelatedQuestions] = useState([]);
  const [DefaultMessages, setDefaultMessages] = useState([]);

  useEffect(() => {
    if (newChat) {
      setIsFullscreen(!isFullscreen);
      setisFocus(true);
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newChat]);

  const getDefaultQuestion = useCallback(async () => {
    setloading(true);
    try {
      const res = await getDefaultQuestions();
      if (res) {
        setDefaultMessages(res);
        setloading(false);
      }
    } catch (error) {
      enqueueSnackbar(error?.message, {
        variant: 'error',
      });
      setloading(false);
    }
  }, []);

  useEffect(() => {
    let isSubscribe = true;
    if (isSubscribe) {
      getDefaultQuestion();
    }
    return () => (isSubscribe = false);
  }, [getDefaultQuestion]);

  const getPreviousConversation = useCallback(async (id) => {
    setloading(true);
    try {
      const res = await getSearchConversations(id);
      if (res) {
        setData(res?.messages);
        setRelatedQuestions(res?.relatedQuestions);
        setConversationID(res?._id);
        setloading(false);
      }
    } catch (error) {
      enqueueSnackbar(error?.message, {
        variant: 'error',
      });
      setloading(false);
    }
  }, []);

  useEffect(() => {
    if (searchValue !== null) {
      getPreviousConversation(searchValue);
      setIsFullscreen(true);
    }
    return () => {};
  }, [getPreviousConversation, searchValue]);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [Data]);

  const sendMessage = async (msg) => {
    if (!msg.trim()) return;
    const newUserMessage = { role: 'user', content: msg };
    setData((prevData) => [...prevData, newUserMessage]);
    setloading(true);
    setmessage('');
    setisFocus(false);

    try {
      const obj = {
        inputText: msg,
        isNewSession: Data?.length > 1 ? false : true,
        userLocalDateTime: localTimeZone(new Date()),
      };

      const res = await sendMessagetoAi(obj);
      if (res) {
        const newAssistantMessages =
          res.queryResponse[res.queryResponse?.length - 1];
        setConversationID(res?.conversationId);
        setData((prevData) => [...prevData, newAssistantMessages]);
        setRelatedQuestions(res?.relatedQuestions);
        setloading(false);
      }
    } catch (error) {
      enqueueSnackbar(error?.message, {
        variant: 'error',
      });
      setloading(false);
    }
  };

  useEffect(() => {
    let subscribe = true;
    if (query && subscribe) {
      sendMessage(query);
    }

    return () => (subscribe = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    if (isFocus) {
      inputRef.current?.focus();
    }
  }, [isFocus]);

  const handleRelatedSearch = (item) => {
    sendMessage(item);
  };

  const handleDefualtMsgSend = (msg) => {
    setisFocus(true);
    sendMessage(msg);
  };

  const handleFocus = () => {
    setisFocus(true);
  };

  const handleOptionClick = (text) => {
    setmessage(text);
    setisFocus(true);
    inputRef.current.focus();
  };

  //handle click outside function is focus false
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        inputRef.current &&
        !inputRef.current.contains(event.target) &&
        optionRef.current &&
        !optionRef.current.contains(event.target)
      ) {
        // Click is outside input, options, and other relevant elements
        setisFocus(false);
        setmessage('');
      }
    };

    // Bind the event listener to the whole document
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on cleanup
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  return (
    <div className="">
      <div className="relative">
        <div className="flex flex-col">
          {!Data?.length &&
            !searchValue &&
            message === '' &&
            DefaultMessages.map((item, index) => (
              <div
                key={index}
                className="text-[#8d8f8e] flex justify-between items-center p-3 border-b-[1px] border-[#323433]"
              >
                <h4>{item}</h4>
                <div className=" w-[50px] flex items-center justify-end">
                  <GoArrowDownLeft
                    className="cursor-pointer mt-[2px]"
                    onClick={() => handleDefualtMsgSend(item)}
                    color="#8d8f8e"
                    size={18}
                  />
                </div>
              </div>
            ))}
        </div>
        {Data?.length && (
          <div className="mx-auto rounded-xl shadow-md flex flex-col overflow-y-scroll scrollbar-hide pb-[15rem]">
            {Data.map((item, index) => (
              <>
                <div key={index} className={`flex flex-col p-3 `}>
                  {item.role === 'user' && (
                    <h4
                      ref={messagesEndRef}
                      className="text-2xl text-white text-left"
                    >
                      {item.content}
                    </h4>
                  )}

                  {item.role === 'assistant' && (
                    <div className="flex flex-col gap-3 w-full">
                      <Sources content={item} />
                      <Answer content={item} />
                      <Content content={item.content} />
                      <ControleButton
                        content={item.content}
                        ConversationID={ConversationID}
                      />
                      {relatedQuestions && index === Data?.length - 1 && (
                        <Related
                          setmessage={handleRelatedSearch}
                          relatedQuestions={relatedQuestions}
                        />
                      )}
                    </div>
                  )}
                </div>
              </>
            ))}

            {loading && <AskanythingLoader />}
          </div>
        )}
        {isFocus && (
          <div
            ref={optionRef}
            className="fixed bottom-[60px] left-0 w-full bg-[#353535] p-1"
          >
            <div className="flex justify-center items-center gap-8 text-[#787c7e] p-3 w-full">
              <div
                onClick={() => handleOptionClick('What is the strain')}
                className=" cursor-pointer"
              >
                Strain
              </div>
              <Divider
                sx={{ backgroundColor: 'white' }}
                orientation="vertical"
                variant={'fullWidth'}
                flexItem
              />
              <div
                className=" cursor-pointer"
                onClick={() => handleOptionClick('Who is the breeder')}
              >
                Breeder
              </div>
              <Divider
                sx={{ backgroundColor: 'white' }}
                orientation="vertical"
                variant={'fullWidth'}
                flexItem
              />
              <div
                className=" cursor-pointer"
                onClick={() => handleOptionClick('Tell me about the product')}
              >
                Product
              </div>
            </div>
          </div>
        )}

        <div
          className={` ${
            isFocus
              ? 'fixed bottom-0 left-0 text-white border-t-[1px] border-[#323433] w-full bg-[#232524] flex gap-1  p-3'
              : 'fixed bottom-2 left-0 right-0 mx-auto w-full max-w-[calc(100%-1rem)] bg-[#2c2e2d] p-3 rounded-full flex '
          } `}
        >
          <input
            ref={inputRef}
            onFocus={handleFocus}
            className={`border-none outline-none bg-transparent w-full overflow-y-hidden  text-[18px] ${
              isFocus ? 'text-left' : 'text-center '
            }`}
            type="text"
            placeholder={
              Data?.length > 0 ? 'Ask follow up...' : ' Ask anything...'
            }
            value={message}
            onChange={(e) => setmessage(e.target.value)}
            style={{ resize: 'none' }}
          />

          {isFocus && (
            <div
              onMouseDown={!loading ? () => sendMessage(message) : null}
              className={`h-9 w-9 p-2.5 rounded-full flex items-center justify-center shadow-lg self-end ${
                message !== '' ? 'bg-mary-theme' : 'bg-[#2d2e2f]'
              }`}
            >
              <IoMdSend className="cursor-pointer" color="#ffffff" size={18} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MaryAiSearch;
