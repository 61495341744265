import { baseApi } from './baseApi';

export const folderApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getSingleFolder: builder.query({
            query: (id) => `folder/${id}`,
        }),
        getAllFolders: builder.query({
            query: (id) => `folder`,

        })
    })
});

// Export the hook for the `getFolder` endpoint
export const { useGetSingleFolderQuery, useGetAllFoldersQuery } = folderApi;