import { Skeleton } from '@mui/material';
import React from 'react';

const StrainCardLoader = ({ NumberOfCard }) => {
  return (
    <div className=" grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-6">
      {Array.from({ length: NumberOfCard }).map((_, index) => (
        <div key={index} className=" flex flex-col gap-2 rounded-lg w-full ">
          <Skeleton
            sx={{ bgcolor: '#232524' }}
            variant="rectangular"
            className="w-full rounded-md"
            height={160}
          />
          <Skeleton
            sx={{ bgcolor: '#232524' }}
            variant="rectangular"
            className="w-full rounded-md"
            height={15}
          />
          <div className="flex justify-between items-center gap-2">
            <Skeleton
              sx={{ bgcolor: '#232524' }}
              variant="rectangular"
              className="w-full rounded-md"
              height={15}
            />
            <Skeleton
              sx={{ bgcolor: '#232524' }}
              variant="circular"
              width={25}
              height={25}
            />
            <Skeleton
              sx={{ bgcolor: '#232524' }}
              variant="rectangular"
              width={23}
              height={23}
            />
          </div>

          <Skeleton
            sx={{ bgcolor: '#232524' }}
            variant="rectangular"
            className=" w-full"
            height={20}
          />
          <Skeleton
            sx={{ bgcolor: '#232524' }}
            variant="rectangular"
            className=" w-full"
            height={15}
          />
        </div>
      ))}
    </div>
  );
};

export default StrainCardLoader;
