import React, { useCallback, useLayoutEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import StrainPopup from '../components/StrainDetail/StrainPopup';
import { IoPersonRemove } from 'react-icons/io5';
import { MdNotificationsActive, MdNotificationsOff } from 'react-icons/md';
import { IoIosArrowBack } from 'react-icons/io';
import { enqueueSnackbar } from 'notistack';
import SubscriptionItemLoader from '../skeleton/SubscriptionItemLoader';
import { getAllSubscribed } from '../features/Subscription/subscriptionService';
import { getUrl } from '../utils/GetBussinessUrl';
import {
  toggleBusinessNotification,
  unSubscribeBusiness,
} from '../features/addBusiness/addBusinessService';
import { CircularProgress } from '@mui/material';

const Dropdown = ({
  OnUpdate,
  loader,
  onUnsubscribe,
  loggedInUser,
  isNotificationEnbale,
  businessType,
  businessId,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  // Handle click for the main button, considering subscription status
  const handleMainButtonClick = () => {
    if (!loggedInUser) {
      // If the user is not logged in, show a login or signup prompt
      setShowPopup(true);
    } else if (!isOpen) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  };

  const handleToggleNotifications = async (
    businessType,
    businessId,
    enable
  ) => {
    try {
      setIsOpen(false);
      const res = await toggleBusinessNotification(
        businessType,
        businessId,
        enable
      );
      if (res) {
        OnUpdate();
        enqueueSnackbar(res?.message, { variant: 'success' });
      }
    } catch (err) {
      enqueueSnackbar(err?.message, { variant: 'error' });
    }
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <>
      <StrainPopup isOpen={showPopup} onClose={handleClosePopup} />
      {loader && <CircularProgress color="success" size={20} />}
      {!loader && (
        <div className="relative inline-block text-center w-full">
          <button
            type="button"
            onClick={handleMainButtonClick}
            className={
              'p-2 rounded-3xl w-full h-full text-center text-sm font-semibold flex justify-center items-center bg-[#262626] hover:bg-[#3f3f3f] text-white'
            }
          >
            {/* button text here */}
            {isNotificationEnbale ? (
              <>
                <MdNotificationsActive className="w-6 h-6 text-white" />
              </>
            ) : (
              <>
                <MdNotificationsOff className="w-6 h-6 text-white" />
              </>
            )}
            <svg
              className="w-6 h-6" // Ensure SVG is visible when subscribed
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
          {isOpen && (
            <div
              className="absolute z-10 w-44 origin-top-right rounded-md shadow-lg bg-[#3F3F3F] ring-1 ring-black ring-opacity-5 focus:outline-none"
              style={{ right: '120%', transform: 'translateX(50%)' }}
            >
              <div
                className="py-1"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="options-menu"
              >
                <button
                  type="button"
                  onClick={() =>
                    handleToggleNotifications(businessType, businessId, true)
                  }
                  className="w-full text-left p-2 text-white hover:bg-gray-100 hover:text-gray-900 flex justify-start items-start gap-2"
                  role="menuitem"
                >
                  <MdNotificationsActive className="w-6 h-6 text-[#DECCC8] mr-2" />
                  All
                </button>
                <button
                  type="button"
                  onClick={() =>
                    handleToggleNotifications(businessType, businessId, false)
                  }
                  className="w-full text-left p-2 text-white hover:bg-gray-100 hover:text-gray-900 flex justify-start items-start gap-2"
                  role="menuitem"
                >
                  <MdNotificationsOff className="w-6 h-6 text-[#DECCC8] mr-2" />
                  None
                </button>
                <button
                  type="button"
                  onClick={onUnsubscribe}
                  className="w-full text-left p-2 text-white hover:bg-gray-100 hover:text-gray-900 flex justify-start items-start gap-2" // Added flex and items-center for alignment
                  role="menuitem"
                >
                  <IoPersonRemove className="mr-1 w-5 h-5 text-[#DECCC8]" />{' '}
                  Unsubscribe {/* Added the icon here */}
                </button>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

const AllSubBrand = () => {
  const navigate = useNavigate();

  const [Tab, setTab] = useState('all-subscribers');
  const [loading, setLoading] = useState(true);
  const [isUpdate, setIsUpdate] = useState(false);
  const [Data, setData] = useState([]);

  const userId = useSelector((state) => state.auth.user?._id);

  const OnUpdate = () => {
    setIsUpdate(!isUpdate);
  };

  const getData = useCallback(async (type) => {
    try {
      const res = await getAllSubscribed(type);
      if (res) {
        setLoading(false);
        setData(res);
      }
    } catch (error) {
      setLoading(false);
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  }, []);

  useLayoutEffect(() => {
    getData(Tab);
    return () => {};
  }, [getData, Tab, isUpdate]);

  const handleUnsubscribe = async (businessType, businessId) => {
    try {
      const res = await unSubscribeBusiness(businessType, businessId);
      if (res) {
        OnUpdate();
        enqueueSnackbar(res?.message, { variant: 'success' });
      }
    } catch (error) {
      enqueueSnackbar(error?.message, { variant: 'error' });
    }
  };

  return (
    <>
      <div className="flex items-center pt-2 pb-4 px-4">
        <IoIosArrowBack
          onClick={() => navigate(-1)}
          size={30}
          className="text-gray-400 cursor-pointer"
        />
      </div>
      <div className=" flex justify-between items-center gap-5 px-4 py-2">
        <div className="w-full bg-[#1d1d1d]  rounded-sm flex justify-between items-center  px-3 py-[5px] gap-5">
          <div
            className={` p-2 cursor-pointer w-full text-center rounded-sm text-white ${
              Tab === 'all-subscribers' ? 'bg-black ' : ''
            } `}
            onClick={() => setTab('all-subscribers')}
          >
            Subscriptions
          </div>
          <div
            className={`p-2 cursor-pointer  w-full text-center rounded-sm text-white ${
              Tab === 'all-memberships' ? 'bg-black ' : ''
            } `}
            onClick={() => setTab('all-memberships')}
          >
            Memberships
          </div>
        </div>
      </div>
      {Tab === 'all-subscribers' && (
        <div className=" text-white px-4 py-4 pb-20">
          {loading && <SubscriptionItemLoader numberOfItem={10} />}
          {!loading && (
            <div className=" flex flex-col gap-6">
              {Data?.map((item) => (
                <div
                  key={item?._id}
                  className="flex justify-between items-center w-full text-left"
                >
                  <div
                    onClick={() =>
                      navigate(`${getUrl(item?.PrimaryBusiness, item?.slug)}`)
                    }
                    className=" flex items-center gap-4"
                  >
                    <img
                      src={item?.image?.url}
                      alt={`${item.title} profile`}
                      className="w-10 h-10 rounded-full "
                    />
                    <div className="flex-grow text-white">{item.title}</div>
                  </div>

                  <div>
                    <Dropdown
                      OnUpdate={OnUpdate}
                      loader={false}
                      onUnsubscribe={() =>
                        handleUnsubscribe(item?.PrimaryBusiness, item?._id)
                      }
                      loggedInUser={userId}
                      businessType={item?.PrimaryBusiness}
                      isNotificationEnbale={item?.isNotificationEnabled}
                      businessId={item?._id}
                    />
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
      {Tab === 'all-memberships' && (
        <div className=" text-white px-4 pb-20">
          {loading && <SubscriptionItemLoader numberOfItem={10} />}
          {!loading && (
            <div className=" flex flex-col gap-6 py-4">
              {Data?.map((item) => (
                <div
                  key={item?._id}
                  className="flex justify-between items-center w-full text-left"
                >
                  <div
                    onClick={() =>
                      navigate(`${getUrl(item?.PrimaryBusiness, item?.slug)}`)
                    }
                    className=" flex items-center gap-4"
                  >
                    <img
                      src={item?.image?.url}
                      alt={`${item.title} profile`}
                      className="w-10 h-10 rounded-full "
                    />
                    <div className="flex-grow text-white">{item.title}</div>
                  </div>
                  <div>
                    <Dropdown
                      OnUpdate={OnUpdate}
                      loader={false}
                      onUnsubscribe={() =>
                        handleUnsubscribe(item?.PrimaryBusiness, item?._id)
                      }
                      loggedInUser={userId}
                      businessType={item?.PrimaryBusiness}
                      isNotificationEnbale={item?.isNotificationEnabled}
                      businessId={item?._id}
                    />
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default AllSubBrand;
