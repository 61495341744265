import React from "react";
import { Provider as ReduxStoreProvider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { SnackbarProvider } from "notistack";
import { persistor, store } from "./redux/store";

import AppRoutes from "./router";
import { AuthProvider } from "@/core/context/auth.context";

// SuperTokens
import SuperTokens, { SuperTokensWrapper } from "supertokens-auth-react";
import Session from "supertokens-auth-react/recipe/session";
import emailPasswordConfig from "./supertokens/emailPasswordConfig";
// import ThirdParty from "supertokens-auth-react/recipe/thirdparty";
// import Multitenancy from "supertokens-auth-react/recipe/multitenancy";

const frontendBaseUrl = process.env.REACT_APP_FRONTEND_BASE_URL;
const backendBaseUrl = process.env.REACT_APP_API_BASE_URL;

SuperTokens.init({
  appInfo: {
    // apiDomain: "https://maryapp.io",
    apiDomain: backendBaseUrl,
    apiBasePath: "/auth",
    appName: "MaryApp",
    websiteDomain: frontendBaseUrl,
    websiteBasePath: "/login",
  },
  recipeList: [
    Session.init({
      ...(process.env.NODE_ENV === "production"
        ? {
            sessionTokenFrontendDomain: ".maryapp.io",
          }
        : {}),
    }),
    emailPasswordConfig()
  ],
});

export default function App() {
  return (
    <SnackbarProvider
      maxSnack={3}
      autoHideDuration={2000}
      preventDuplicate
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <ReduxStoreProvider store={store}>
        <SuperTokensWrapper>
          <AuthProvider>
            <PersistGate loading={null} persistor={persistor}>
              <AppRoutes />
            </PersistGate>
          </AuthProvider>
        </SuperTokensWrapper>
      </ReduxStoreProvider>
    </SnackbarProvider>
  );
}
