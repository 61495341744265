import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import SessionPieChart from '../components/Sessions/SessionPieChart';
import SessionBarChart from '../components/Sessions/SessionBarChart';
import { IoArrowBack } from 'react-icons/io5';
import { getStats } from '../features/sessions/SessionServices';
import { useSnackbar } from 'notistack';

const SessionsState = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { enqueueSnackbar } = useSnackbar();
  const selectedPeriod = searchParams.get('searchBy') || 'Day'; // Fallback to 'Day' if no param
  const [sessionData, setSessionData] = useState({
    effects: [],
    flavors: [],
    selectedMethod: [],
    useReason: [],
    consumptionTime: [],
    consumingPlace: [],
    consumingWith: [],
  });
  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      const res = await getStats(selectedPeriod);
      setSessionData({
        effects: res?.effects,
        flavors: res?.flavors,
        selectedMethod: res?.selectedMethod,
        useReason: res?.useReason,
        consumptionTime: res?.consumptionTime,
        consumingPlace: res?.consumingPlace,
        consumingWith: res?.consumingWith,
      });
    } catch (error) {
      enqueueSnackbar(error?.message, {
        variant: 'error',
      });
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPeriod]);

  const handlePeriodChange = (period) => {
    setSearchParams({ searchBy: period });
  };

  return (
    <div className="p-3 mb-20 w-full lg:w-[60%] mx-auto">
      <div className="flex flex-col gap-4 justify-center">
        <div className="text-[#7cdb54] text-sm underline flex items-center">
          <IoArrowBack
            size="1.2em"
            style={{ marginRight: '4px', marginTop: '1px' }}
          />
          <button onClick={() => navigate('/sessions')} type="button">
            Back to Sessions
          </button>
        </div>
        <div className="flex justify-between item-center gap-3">
          {['Day', 'Week', 'Month', 'Year'].map((period) => (
            <button
              key={period}
              className={`bg-[#2c2c2c] border-[#2c2c2c] hover:bg-[#787777] hover:border-[#787777] capitalize w-full text-white border-2 font-medium rounded-lg text-sm py-1.5 text-center xs:text-xs ${
                selectedPeriod === period ? 'bg-[#4c4c4c]' : ''
              }`}
              onClick={() => handlePeriodChange(period)}
            >
              {period}
            </button>
          ))}
        </div>
        <SessionPieChart
          title="Reasons for consumption"
          data={sessionData?.useReason}
        />

        <SessionPieChart
          title="Method of consumption"
          data={sessionData?.selectedMethod}
        />
        <SessionBarChart
          title="Time of consumption"
          data={sessionData.consumptionTime}
        />
        <SessionPieChart title="Feeling Reported" data={sessionData.effects} />
        <SessionPieChart title="Aromas Reported" data={sessionData.flavors} />
        {sessionData.consumingPlace.length && (
          <SessionPieChart
            title="Places of consumption"
            data={sessionData.consumingPlace}
          />
        )}
        {sessionData.consumingWith.length && (
          <SessionPieChart
            title="Consumed with"
            data={sessionData.consumingWith}
          />
        )}
      </div>
    </div>
  );
};

export default SessionsState;
