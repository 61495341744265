import { baseApi } from "./baseApi";

export const businessApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        // Existing endpoint for fetching all businesses for home page
        getAllBusinessesHomePage: builder.query({
            query: () => ({
                url: 'vendor/get-all-businesses',
                method: 'GET',
            }),
        }),

        // New endpoint for fetching businesses by type and pagination
        getBusiness: builder.query({
            query: ({ bussinessType, page = 1, limit }) => ({
                url: `vendor/get-all`,
                method: 'GET',
                params: { PrimaryVendor: bussinessType, page, limit }
            }),
        }),
    }),
});

// Export hooks generated by RTK Query for both endpoints
export const { useGetAllBusinessesHomePageQuery, useGetBusinessQuery } = businessApi;
