import React from 'react'
import ComingSoon from '../components/Layout/ComingSoon'

const Deal = () => {
  return (
   <ComingSoon/>
  )
}

export default Deal
