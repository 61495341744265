import React from 'react'
import { useNavigate } from 'react-router-dom'

const PageNotFound = () => {
    const navigate =useNavigate()
    setTimeout(()=>{
        navigate('/')
    },[1000])
  return (
    <>
        <div className='flex flex-row justify-center items-center text-center text-white w-screen h-screen'>
            <h1 className='text-3xl'>Sorry Page Not Found!</h1>
        </div>
    </>
  )
}

export default PageNotFound