import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { IoChevronDown, IoChevronUp } from 'react-icons/io5';

export const AccordionSection = ({ title, children, defaultOpen, count }) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  useEffect(() => {
    setIsOpen(defaultOpen);
  }, [defaultOpen]);

  const location = useLocation();
  const currentPath = location.pathname;

  if (currentPath.includes('/admin')) {
    return (
      <div className="w-full lg:px-3 text-black border-t border-gray-500 lg:mr-64 lg:mb-4">
        <button
          type="button"
          className="flex items-center justify-between w-full py-4  focus:outline-none"
          onClick={() => setIsOpen(!isOpen)}
        >
          <div className="lg:flex lg:items-center lg:justify-center">
            <h2 className="font-bold text-lg text-left mb-1">{title}</h2>
            <p className="text-gray-400 text-sm text-left lg:ml-2">
              (Choose up to 3)
            </p>
          </div>
          <div className="flex items-center">
            {/* Counter */}
            {count > 0 && (
              <span className="inline-block bg-gray-300 text-black text-sm font-semibold mr-2 px-2 py-0.5 rounded-full">
                {count}
              </span>
            )}
            {isOpen ? <IoChevronUp /> : <IoChevronDown />}
          </div>
        </button>
        {isOpen && <div className="p-4 lg:p-0 lg:py-2">{children}</div>}
      </div>
    );
  } else {
    return (
      <div className="text-white border-t border-gray-500 py-4 ">
        <button
          type="button"
          className="flex items-center justify-between w-full  lg:p-0 lg:pt-4 focus:outline-none"
          onClick={() => setIsOpen(!isOpen)}
        >
          <div className="lg:flex lg:items-center lg:justify-center">
            <h2 className="font-bold text-lg text-left mb-1">{title}</h2>
            <p className="text-gray-400 text-sm text-left lg:ml-2">
              (Choose up to 4)
            </p>
          </div>
          <div className="flex items-center">
            {/* Counter */}
            {count > 0 && (
              <span className="inline-block bg-gray-300 text-black text-sm font-semibold mr-2 px-2 py-0.5 rounded-full">
                {count}
              </span>
            )}
            {isOpen ? <IoChevronUp /> : <IoChevronDown />}
          </div>
        </button>
        {isOpen && <div className="py-4 lg:p-0 lg:py-2">{children}</div>}
      </div>
    );
  }
};
