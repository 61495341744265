import { Button } from '@/core/ui/button'
import SearchLoading from '@/skeleton/SearchLoading'
import { Circle } from 'lucide-react'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const SearchResult = ({ title, result, slug, SeeAllUrl, isLoading }) => {

    const navigate = useNavigate()

    if (isLoading) {
        return <SearchLoading title={title} />
    }

    return (
        <div className=' px-4' >
            <div className=' flex justify-between items-center gap-3  pt-3 '>
                <h4 className='text-xl font-bold  text-white'>{title}</h4> <Button variant='link' className=' text-white' onClick={() => navigate(SeeAllUrl)}>See all</Button>
            </div>
            <div className='flex flex-col  gap-2'>
                {result?.map((item, index) => (
                    <div key={index} className=" flex  items-center justify-start gap-2 ">
                        <Circle className=" w-5 h-5 text-white  " />
                        <span onClick={() => navigate(`${slug}/${item?._id}`)} className=' text-white cursor-pointer'>{item?.title}</span>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default SearchResult