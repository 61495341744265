import React from 'react';
import { IoClose } from 'react-icons/io5';

const FolderActionModal = ({
  loading,
  isOpen,
  onClose,
  folder,
  onEdit,
  onDelete,
}) => {
  if (!isOpen) return null;

  const isDefaultFolder =
    folder.name === 'Liked Strains' || folder.name === 'Liked Products';

  return (
    <div className="fixed inset-0 bg-opacity-75 flex justify-center items-center z-50">
      {/* Modal Content */}
      <div className="bg-[#2c2c2c] rounded-lg shadow-xl p-4 w-80">
        <div className="flex justify-between items-center border-b-2 border-gray-400">
          <h3 className="text-center text-white text-2xl font-bold flex-grow">
            {folder.name}
          </h3>
          <button onClick={onClose} className="text-white text-2xl">
            <IoClose />
          </button>
        </div>
        <div className="flex justify-between items-center p-6">
          <button
            className={`text-white px-6 py-1 rounded-2xl text-lg bg-gray-500 ${
              isDefaultFolder ? 'bg-gray-400' : 'bg-gray-500'
            }`}
            onClick={() => !isDefaultFolder && onEdit(folder)}
            disabled={isDefaultFolder}
          >
            {loading ? 'loading...' : 'Edit'}
          </button>
          <button
            className={`text-white px-6 py-1 rounded-2xl text-lg ${
              isDefaultFolder ? 'bg-gray-400' : 'bg-red-600'
            }`}
            onClick={() => !isDefaultFolder && onDelete(folder._id)}
            disabled={isDefaultFolder}
          >
            {loading ? 'loading...' : 'Delete'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default FolderActionModal;
