import { Skeleton } from '@mui/material';
import React from 'react';
import StrainCardLoader from './StrainCardLoader';

const StashSinglePageLaoder = () => {
  return (
    <div className=" w-full flex flex-col gap-6 pb-20">
      <div className=" flex justify-between  items-center gap-3 w-full">
        <div className=" flex flex-col  gap-3 ">
          <Skeleton
            sx={{ bgcolor: '#232524' }}
            variant="rounded"
            width={160}
            height={24}
          />
          <Skeleton
            sx={{ bgcolor: '#232524' }}
            variant="rounded"
            width={130}
            height={15}
          />
        </div>
        <div className=" flex items-center gap-2">
          <Skeleton
            sx={{ bgcolor: '#232524' }}
            variant="rounded"
            width={32}
            height={32}
          />
          <Skeleton
            sx={{ bgcolor: '#232524' }}
            variant="rounded"
            width={20}
            height={40}
          />
        </div>
      </div>
      <div className=" flex flex-col  gap-2">
        <div className=" flex items-center gap-2">
          <Skeleton
            sx={{ bgcolor: '#232524' }}
            variant="circular"
            width={24}
            height={24}
          />
          <Skeleton
            sx={{ bgcolor: '#232524' }}
            variant="rounded"
            width={70}
            height={24}
          />
        </div>
        <div className=" flex items-center gap-2">
          <Skeleton
            sx={{ bgcolor: '#232524' }}
            variant="rounded"
            width={20}
            height={20}
          />
          <Skeleton
            sx={{ bgcolor: '#232524' }}
            variant="rounded"
            width={70}
            height={12}
          />
        </div>
      </div>
      <div>
        <h1 className="text-xl font-bold text-white py-4">Strains</h1>
        <StrainCardLoader NumberOfCard={6} />
      </div>
      <div>
        <h1 className="text-xl font-bold text-white py-4">Products</h1>
        <StrainCardLoader NumberOfCard={6} />
      </div>
    </div>
  );
};

export default StashSinglePageLaoder;
