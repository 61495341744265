import React from "react";

import useUserSession from "@/core/hooks/useUser";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
  const { user, isLoading } = useUserSession();

  // If the user session is still loading, return a loading message
  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    // If the user is not authenticated, redirect to /landing
    return <Navigate to="/landing" replace />;
  }

  // If the user is authenticated, render the children components
  return children;
};

export default ProtectedRoute;
