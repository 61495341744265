import { IoCloseOutline } from 'react-icons/io5';
import { ImageList, ImageListItem } from '@mui/material';

const ImageGallery = ({ images, setShowGallery }) => {
  const handleImageError = (event) => {
    event.target.style.display = 'none'; // Hides the image element if it fails to load
  };
  return (
    <div className=" fixed top-0 left-0 h-[100vh]  w-full z-50 overflow-y-auto scrollbar-hide  bg-white p-3">
      <div className=" flex justify-start items-center gap-4 py-2 ">
        <IoCloseOutline
          onClick={() => setShowGallery(false)}
          className=" cursor-pointer"
        />
        <h4>Gallery</h4>
      </div>
      <div>
        <ImageList variant="masonry" cols={2} gap={8}>
          {images.map((item) => (
            <ImageListItem key={item}>
              <img
                srcSet={`${item}?w=248&fit=crop&auto=format&dpr=2 2x`}
                src={`${item}?w=248&fit=crop&auto=format`}
                alt={item}
                loading="lazy"
                onError={handleImageError}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </div>
    </div>
  );
};

export default ImageGallery;
