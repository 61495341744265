import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const SideBarButton = ({ text, icon, value, url }) => {
  const userState = useSelector((state) => state.auth.user);
  const navigate = useNavigate();
  const handleClick = () => {
    if (userState) {
      navigate(url);
    } else {
    }
  };

  return (
    <div className="mb-4 w-60">
      <div
        className={`py-1.5 sideBarGradient flex flex-row w-full rounded-lg capitalize text-white font-semibold cursor-pointer text-sm ${
          userState ? '' : 'opacity-50 cursor-none'
        }`}
        onClick={handleClick} // Use the onClick handler
      >
        {icon}
        {text}
        {text.includes('stash') && (
          <>
            <span className="w-5 h-5 bg-[#253238] rounded-full text-[#62AF41] flex items-center justify-center ml-2 text-sm"></span>
          </>
        )}
      </div>
    </div>
  );
};

export default SideBarButton;
