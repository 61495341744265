import { Button } from '@/core/ui/button'
import { Search, Sparkles, X } from 'lucide-react'
import React from 'react'
const RecentSearch = ({ title, result, clearAll, deleteSearch, seeAllBtn, handleSeeAll, handleSubmit }) => {
    return (
        <div className='text-white flex flex-col gap-3 px-4'>
            <div className='flex justify-between items-center'>
                {title && <h4 className='text-xl font-bold '>{title}</h4>}
                {seeAllBtn && <Button onClick={handleSeeAll} variant='link' className='text-white p-0'>See all</Button>}

            </div>

            {result?.map((item, index) => (
                <div key={index} className="flex items-center justify-between gap-2">
                    <div className='flex items-center gap-2 flex-1 min-w-0'>
                        {item?.type === "search" && <Search className="w-4 h-4" />}
                        {item?.type === "question" && <Sparkles className="w-4 h-4" />}
                        <h4 onClick={() => handleSubmit(item)} className='truncate text-ellipsis overflow-hidden whitespace-nowrap w-full cursor-pointer'>
                            {item?.query}
                        </h4>
                    </div>
                    <div className='flex justify-center items-center   rounded-full hover:bg-[#2c2e2d]'>
                        <X onClick={() => deleteSearch(item?.query, item?._id)} className='w-4 h-4 cursor-pointer' />
                    </div>
                </div>
            ))}

            <div className='text-white'>
                <Button onClick={clearAll} variant='link' className='text-white p-0 cursor-pointer'>Clear all</Button>
            </div>

        </div>
    )
}

export default RecentSearch
