import React from 'react'
import { Link } from 'react-router-dom'
const LowserSiderButton = ({text}) => {
    return (
        <>
            <div className={`w-60 flex flex-row items-center justify-start ml-4`} >
                <button 
                className={`mr-10 flex flex-row items-center bg-transparent hover:underline text-gray-300 hover:text-gray-500    ${text.includes('About') ? ('font-bold text-lg'):('font-semibold text-base')} py-2 px-3 border border-transparent rounded-lg `}
                
                >
                    <Link className="mr-2 capitalize">{text}</Link>
                </button>
            </div>

        </>
    )
}

export default LowserSiderButton
