import axios from 'axios'
import { config } from '../../utils/axiosconfig';
import { base_url } from '../../utils/base_url';


const createBrand = async (data) => {
    const configure = config();

    const response = await axios.post(`${base_url}brand/`, data, configure)

    return response.data
}


export const UploadBreederCSV = async (data) => {
    const configure = config();
    const response = await axios.post(`${base_url}breeder/bulk-create-from-array`, data, configure)
    return response.data
}

const updateBrand = async (brand) => {
    const configure = config()


    const response = await axios.put(`${base_url}brand/${brand.id}`,
        {
            title: brand.brandData.title,
            brandDescription: brand.brandData.brandDescription,
            foundedDate: brand.brandData.foundedDate,
            socialLinks: brand.brandData.socialLinks,
            phoneNumber: brand.brandData.phoneNumber,
            retailers: brand.brandData.retailers,
            aboutUs: brand.brandData.aboutUs,
            amenities: brand.brandData.amenities,
            photos: brand.brandData.photos,
            hoursOfOperation: brand.brandData.hoursOfOperation,
            directions: brand.brandData.directions,
            address: brand.brandData.address,
            orderOptions: brand.brandData.orderOptions,
            profileImg: brand.brandData.profileImg,
            brandImg: brand.brandData.brandImg,
            notificationSubscribers: brand.brandData.notificationSubscribers
        }
        , configure)

    return response.data
}
const deleteBrand = async (id) => {
    const configure = config()

    const response = await axios.delete(`${base_url}brand/${id}`, configure)
    return response.data
}
const uploadBrandImg = async (data) => {
    const configure = config()
    const response = await axios.put(`${base_url}upload/`, data, configure)
    return response.data
}

export const uploadProfileImg = async (data) => {
    const configure = config()
    const response = await axios.put(`${base_url}upload/`, data, configure)
    return response.data
}

export const uploadCoverImg = async (data) => {
    const configure = config()
    console.log(data, "Image")

    const response = await axios.put(`${base_url}upload/`, data, configure)

    return response.data
}

export const deleteCoverImg = async (id) => {
    const configure = config()
    const response = await axios.delete(`${base_url}upload/delete-image/${id}`, configure)

    return response.data
}

const uploadMultipleBrandImg = async (data) => {
    const configure = config()

    const response = await axios.put(`${base_url}upload/multiple`, data, configure)

    return response.data
}

const deleteBrandImg = async (id) => {
    const configure = config()
    const response = await axios.delete(`${base_url}upload/delete-image/${id}`, configure)

    return response.data
}
export const deleteProfileImg = async (id) => {
    const configure = config()

    const response = await axios.delete(`${base_url}upload/delete-image/${id}`, configure)

    return response.data
}
const deleteBrandProfileImg = async (id) => {
    const configure = config()

    const response = await axios.delete(`${base_url}upload/delete-image/${id}`, configure)

    return response.data
}

export const getBrands = async (page, limit) => {
    const configure = config()

    const response = await axios.get(`${base_url}brand?page=${page ? page : 1}&limit=${limit ? limit : 20}`, configure)

    return response.data
}
export const getBrand = async (id) => {
    const configure = config()
    const response = await axios.get(`${base_url}brand/${id}`, configure)
    return response.data
}

export const subscribeBrand = async (brandId) => {
    const configure = config();
    const response = await axios.post(`${base_url}brand/subscribe`, { brandId }, configure);
    return response.data;
};

export const unSubscribeBrand = async (brandId) => {
    const configure = config();
    const response = await axios.post(`${base_url}brand/unsubscribe`, { brandId }, configure);
    return response.data;
};

export const checkSubscriptionStatus = async (brandId) => {
    const configure = config();
    const response = await axios.get(`${base_url}brand/${brandId}/isSubscribed`, configure);
    return response.data;
};

const brandService = {
    createBrand,
    uploadBrandImg,
    deleteBrandProfileImg,
    deleteBrandImg,
    getBrands,
    getBrand,
    updateBrand,
    deleteBrand,
    uploadMultipleBrandImg,
    subscribeBrand,
    unSubscribeBrand,
    checkSubscriptionStatus,
};
export default brandService