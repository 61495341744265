import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { RiDraggable } from 'react-icons/ri';

// Reorder the list based on the drag result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const ControlModal = ({
  isOpen,
  onRequestClose,
  tabVisibility,
  toggleTabVisibility,
  businessId,
}) => {
  const [items, setItems] = useState(Object.keys(tabVisibility));

  useEffect(() => {
    const savedOrder = JSON.parse(
      localStorage.getItem(`tabOrder-${businessId}`)
    );
    if (savedOrder) {
      setItems(savedOrder);
    }
  }, [businessId]);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const newItems = reorder(
      items,
      result.source.index,
      result.destination.index
    );
    setItems(newItems);
    localStorage.setItem(`tabOrder-${businessId}`, JSON.stringify(newItems));
    window.dispatchEvent(new Event('storage'));
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={{
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.75)',
          zIndex: 300, // Ensure this is higher than any content on the page
        },
        content: {
          position: 'absolute',
          top: '25%',
          left: '25%',
          right: 'auto',
          bottom: 'auto',
          border: 'none',
          transform: 'translateX(-50%, -50%)',
          background: '#0f0f0f', // Updated background color
          overflow: 'hidden', // Disable horizontal scroll
          WebkitOverflowScrolling: 'touch',
          outline: 'none',
          padding: '20px',
          zIndex: 1000, // Ensure content is also above the overlay
        },
      }}
    >
      <h2 className="text-white">Control Tab Visibility</h2>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={{
                background: '#0f0f0f',
                padding: 4,
                width: '100%',
                borderRadius: 4,
                overflow: 'hidden', // Disable horizontal scroll
              }}
            >
              {items.map((value, index) => (
                <Draggable key={value} draggableId={value} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={{
                        userSelect: 'none',
                        padding: 8,
                        margin: '0 0 8px 0',
                        backgroundColor: snapshot.isDragging
                          ? '#263B4A'
                          : '#0f0f0f',
                        color: 'white',
                        ...provided.draggableProps.style,
                        opacity: snapshot.isDragging ? 0.5 : 1,
                        // top: 'auto !important', // Ensures top alignment is not affected by modal
                        // left: 'auto !important',
                      }}
                      className="flex items-center justify-between"
                    >
                      <div className="flex items-center">
                        <RiDraggable className="text-xl mr-2" />
                        <span className="mr-2">
                          {value.charAt(0).toUpperCase() + value.slice(1)}
                        </span>
                        <hr className="border-b-2 border-gray-500 flex-grow" />
                      </div>
                      <label className="inline-flex items-center cursor-pointer">
                        <input
                          type="checkbox"
                          checked={tabVisibility[value]}
                          onChange={() => toggleTabVisibility(value)}
                          className="sr-only peer"
                        />
                        <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-600"></div>
                      </label>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Modal>
  );
};

export default ControlModal;
