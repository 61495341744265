import axios from 'axios'
import { config } from '../../utils/axiosconfig';
import { base_url } from '../../utils/base_url';




export const addReview = async (Data) => {
    const configure = config()
    const response = await axios.post(`${base_url}review`, Data, configure)
    return response.data
}

export const deleteReview = async (id) => {
    const configure = config()
    const response = await axios.delete(`${base_url}review/${id}`, configure)
    return response.data
}


export const pinReview = async (id, Data) => {
    const configure = config()
    const response = await axios.put(`${base_url}review/${id}`, Data, configure)
    return response.data
}





