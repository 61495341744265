import axios from 'axios';
import { config } from '../../utils/axiosconfig';
import { base_url } from '../../utils/base_url';

const createNotification = async (notificationData) => {
    const configure = config()
    const response = await axios.post(`${base_url}notification/`, notificationData, configure);
    return response.data;
};

export const getUserNotifications = async () => {
    const configure = config()
    const response = await axios.get(`${base_url}notification/`, configure);
    return response.data;
};

export const getUserNotificationsCount = async () => {
    const configure = config()
    const response = await axios.get(`${base_url}notification/seen-count`, configure);
    return response.data;
};


export const markNotificationAsSeen = async (notificationId, data) => {
    const configure = config()
    const response = await axios.put(`${base_url}notification/${notificationId}`, data, configure);
    return response.data;
};

const toggleNotifications = async (brandId, businessType, enable) => {

    const configure = config();
    const response = await axios.put(`${base_url}notification/toggle-notifications`, { brandId, businessType, enable }, configure);
    return response.data;
};



const notificationService = {
    createNotification,
    getUserNotifications,
    markNotificationAsSeen,
    toggleNotifications,
};

export default notificationService;