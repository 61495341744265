import React, { useCallback, useEffect, useState } from "react";
import Meta from "../components/SEO/Meta";
import Products from "../components/products/Products";
import { Pagination, styled } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import StrainCardLoader from "../skeleton/StrainCardLoader";
import SingleSelectSearchFillter from "../components/CommonComponent/SearchFilters/SingleSelectSearchFillter";
import { useDispatch } from "react-redux";
import { getAllFolders } from "../features/stashFolder/folderSlice";
import { useSearchParams } from "react-router-dom";
import { useGetProductsQuery } from "../redux/api/productApi";

const CustomPagination = styled(Pagination)(({ theme }) => ({
  "& .Mui-selected": {
    backgroundColor: "#62AF41 !important",
    color: "#FFFFFF !important",
  },
  "& .MuiPaginationItem-root": {
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: "rgba(76, 175, 80, 0.1)",
    },
  },
  "& .MuiPaginationItem-ellipsis": {
    color: "#FFFFFF",
  },
}));

const ProductCategories = [
  "Flower",
  "Vape Pens",
  "Prerolls",
  "Edibles",
  "Concentrates",
  "Beverages",
  "Accessories",
  "Gear/merch",
  "Topicals",
  "Tinctures",
  "Capsules",
  "Seeds",
  "Clones",
  "Grow",
];

const AllProducts = () => {
  const [searchParams] = useSearchParams();
  const category = searchParams.get("category");
  const dispatch = useDispatch();

  const [isUpdate, setIsUpdate] = useState(false);
  const [FillterOption, setFillterOption] = useState("");
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (category) {
      setFillterOption(category);
    }
  }, [category]);

  const {
    data: productData,
    error,
    isLoading,
  } = useGetProductsQuery({
    page,
    FillterOption,
  });

  const totalPages = productData?.pagination?.totalPages || 1;

  useEffect(() => {
    let isSubscribe = true;
    if (isSubscribe) {
      dispatch(getAllFolders());
    }
    return () => (isSubscribe = false);
  }, [dispatch, isUpdate]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  // Memoized function to handle category change
  const handleTypeChange = useCallback((value) => {
    setFillterOption(value); // Update the filter option state
  }, []);

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error.message || "Failed to fetch products", {
        variant: "error",
      });
    }
  }, [error]);

  return (
    <div className="">
      <Meta title={"Products"} siteLink={"/products"} />
      <div className="flex flex-col mt-10 ">
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2">
          <SingleSelectSearchFillter
            label="Category"
            options={ProductCategories}
            getOptions={handleTypeChange} // Pass the handler
            selectedValue={FillterOption} // Ensure the selected value is set
          />
        </div>
        <div className="mb-6">
          {isLoading && <StrainCardLoader NumberOfCard={20} />}
          {!isLoading && productData?.products && (
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4">
              {productData?.products?.map((product, index) => (
                <Products
                  key={index}
                  itemId={product._id}
                  itemType="product"
                  title={product.title}
                  price={product.price ?? "Not found"}
                  thc_percentage={product.thc_percentage ?? "Not found"}
                  cbd_percentage={product.cbd_percentage ?? "Not found"}
                  brand={product.brand}
                  strainId={product.strain}
                  category={product.category ?? "Not found"}
                  weight={product.weight ?? "Not found"}
                  productImg={product.productImg}
                  onUpdate={() => setIsUpdate(!isUpdate)}
                />
              ))}
            </div>
          )}
        </div>
        <CustomPagination
          count={totalPages}
          page={page}
          onChange={handlePageChange}
          variant="outlined"
        />
      </div>
    </div>
  );
};

export default AllProducts;
