import { Skeleton } from '@mui/material';
import React from 'react';

const AddToStashPageLoader = ({ NumberOfCard }) => {
  return (
    <div className="w-full pb-20 flex flex-col gap-6">
      <div className=" flex justify-between items-center gap-3 w-full">
        <div className=" flex items-center gap-3 ">
          <Skeleton
            sx={{ bgcolor: '#232524' }}
            variant="circular"
            width={35}
            height={35}
          />
          <Skeleton
            sx={{ bgcolor: '#232524' }}
            variant="rounded"
            width={110}
            height={32}
          />
        </div>
        <Skeleton
          sx={{ bgcolor: '#232524' }}
          variant="rounded"
          width={32}
          height={32}
        />
      </div>
      <div className=" flex flex-col gap-4">
        {Array.from({ length: NumberOfCard }).map((_, index) => (
          <div className=" flex justify-between items-center gap-4" key={index}>
            <div className="flex justify-start items-center gap-2">
              <Skeleton
                sx={{ bgcolor: '#232524' }}
                variant="rounded"
                width={80}
                height={80}
              />
              <div className=" flex flex-col gap-2">
                <Skeleton
                  sx={{ bgcolor: '#232524' }}
                  variant="rounded"
                  width={150}
                  height={32}
                />
                <Skeleton
                  sx={{ bgcolor: '#232524' }}
                  variant="rounded"
                  width={40}
                  height={20}
                />
              </div>
            </div>
            <Skeleton
              sx={{ bgcolor: '#232524' }}
              variant="rounded"
              width={20}
              height={40}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default AddToStashPageLoader;
