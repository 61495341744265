import React from 'react';

const TreasureChestIcon = ({ size, color = 'currentColor', className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    version="1.1"
    width={size}
    height={size}
    viewBox="0 0 32 32"
    style={{ fill: color }}
    className={className}
    xmlSpace="preserve"
  >
    <g>
      <path d="M9,6H8c-3.31,0-6,2.69-6,6v2h7V6z" />
      <path d="M15.22,11.38c0.38-0.48,1.18-0.48,1.56,0l2.1,2.62H21V6H11v8h2.12L15.22,11.38z" />
      <path d="M24,6h-1v8h7v-2C30,8.69,27.31,6,24,6z" />
      <path d="M18.88,16l-2.1,2.62C16.59,18.86,16.3,19,16,19s-0.59-0.14-0.78-0.38L13.12,16H2v8c0,1.1,0.9,2,2,2h24c1.1,0,2-0.9,2-2v-8H18.88z" />
    </g>
  </svg>
);

export default TreasureChestIcon;
