import { useState } from "react";

// Hook to manage localStorage
export const useLocalStorage = (key: string, initialValue: any) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.error(error);
      return initialValue;
    }
  });

  const setValue = (value: any) => {
    try {
      setStoredValue(value);
      window.localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      console.error(error);
    }
  };

  const removeItem = (query: string) => {
    try {
      const filteredValue = storedValue.filter(
        (item: { query: string }) => item.query !== query
      );
      setStoredValue(filteredValue);
      window.localStorage.setItem(key, JSON.stringify(filteredValue));
    } catch (error) {
      console.error(error);
    }
  };

  const clearValue = () => {
    try {
      setStoredValue(initialValue);
      window.localStorage.removeItem(key);
    } catch (error) {
      console.error(error);
    }
  };

  return [storedValue, setValue, removeItem, clearValue];
};
