import React from 'react';
import { Skeleton } from '@mui/material';

const RecentConversationLoader = ({ NumberOfCard }) => {
  return (
    <div className=" gird grid-cols-1 gap-2">
      {Array.from({ length: NumberOfCard })?.map((item, index) => (
        <div
          key={index}
          className=" text-white flex flex-col gap-2 px-5 py-8 border-t-[1px] border-[#323433]"
        >
          <Skeleton
            sx={{ bgcolor: '#232524' }}
            variant="rectangular"
            width={'100%'}
            height={20}
          />
          <Skeleton
            sx={{ bgcolor: '#232524' }}
            variant="rectangular"
            width={'100%'}
            height={10}
          />
          <Skeleton
            sx={{ bgcolor: '#232524' }}
            variant="rectangular"
            width={'100%'}
            height={10}
          />
          <Skeleton
            sx={{ bgcolor: '#232524' }}
            variant="rectangular"
            width={'100%'}
            height={10}
          />
          <div className=" flex justify-start items-start gap-2">
            <Skeleton
              sx={{ bgcolor: '#232524' }}
              variant="rectangular"
              width={'100%'}
              height={80}
            />
            <Skeleton
              sx={{ bgcolor: '#232524' }}
              variant="rectangular"
              width={'100%'}
              height={80}
            />{' '}
            <Skeleton
              sx={{ bgcolor: '#232524' }}
              variant="rectangular"
              width={'100%'}
              height={80}
            />{' '}
            <Skeleton
              sx={{ bgcolor: '#232524' }}
              variant="rectangular"
              width={'100%'}
              height={80}
            />
          </div>
          <div>
            <div className="flex items-center justify-start gap-1 text-[#8d9191]">
              <Skeleton
                sx={{ bgcolor: '#232524' }}
                variant="circular"
                width={20}
                height={20}
              />
              <Skeleton
                sx={{ bgcolor: '#232524' }}
                variant="rectangular"
                width={15}
                height={20}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default RecentConversationLoader;
