import React, { useCallback, useEffect, useState } from 'react';
import { CiSearch } from 'react-icons/ci';
import { FaPlus } from 'react-icons/fa6';
import RecentConversation from '../components/conversations/RecentConversation';
import { getConversations } from '../features/askAnything/AskAnything';
import RecentConversationLoader from '../skeleton/RecentConversationLoader';
import { useNavigate } from 'react-router-dom';

const Conversations = () => {
  // hooks
  const navigate = useNavigate();
  const [Data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  // single page api calls
  const getData = useCallback(async () => {
    try {
      const res = await getConversations();
      if (res) {
        setLoading(false);
        setData(res);
      }
    } catch (error) {
      setLoading(false);
    }
  }, []);
  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <div className=" flex flex-col gap-5">
      <div className=" flex justify-between items-center px-4">
        <CiSearch color={'#ffffff'} size={24} />
        <h1 className="  text-2xl text-white">Answers</h1>
        <FaPlus
          color={'#ffffff'}
          size={24}
          onClick={() => navigate('/ai-search?newAnswer=true')}
        />
      </div>
      {loading && <RecentConversationLoader NumberOfCard={5} />}
      <div className=" flex flex-col gap-2">
        {Data?.map((item) => (
          <RecentConversation data={item} />
        ))}
      </div>
    </div>
  );
};

export default Conversations;
