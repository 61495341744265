import { baseApi } from "./baseApi";

export const productApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        // Existing endpoint for fetching products by category
        getProductByCategory: builder.query({
            query: () => 'product/products-by-category',
        }),

        // New endpoint for fetching products with pagination and filter options
        getProducts: builder.query({
            query: ({ page = 1, FillterOption = '' }) => {
                return `product?page=${page}&limit=20&category=${FillterOption}`;
            },
        }),
        getProductById: builder.query({
            query: (id) => `product/${id}`,  // Dynamically set product ID in the URL
        }),
    }),
});

// Export the hooks generated by RTK Query for both endpoints
export const { useGetProductByCategoryQuery, useGetProductsQuery, useGetProductByIdQuery } = productApi;
