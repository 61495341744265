import { Skeleton } from '@mui/material';
import React from 'react';

const NotificationPageLoader = ({ Date, NumberOfCard }) => {
  return (
    <div className=" flex flex-col gap-4">
      <h4 className="text-[#bbbbbb] text-md  font-bold">{Date}</h4>
      {Array.from({ length: NumberOfCard }).map((_, index) => (
        <div
          key={index}
          className=" flex items-center justify-between gap-2 w-full"
        >
          <div className=" w-[50px] h-[50px] rounded-full  object-contain   relative">
            <Skeleton
              sx={{ bgcolor: '#232524' }}
              variant="circular"
              width={50}
              height={50}
            />
          </div>
          <div className=" text-white flex flex-col gap-1">
            <Skeleton
              sx={{ bgcolor: '#232524' }}
              variant="rectangular"
              width={130}
              height={10}
            />
            <Skeleton
              sx={{ bgcolor: '#232524' }}
              variant="rectangular"
              width={140}
              height={11}
            />
            <Skeleton
              sx={{ bgcolor: '#232524' }}
              variant="rectangular"
              width={146}
              height={18}
            />
            <Skeleton
              sx={{ bgcolor: '#232524' }}
              variant="rectangular"
              width={130}
              height={10}
            />
            <Skeleton
              sx={{ bgcolor: '#232524' }}
              variant="rectangular"
              width={135}
              height={10}
            />
          </div>
          <div className="  w-[80px] h-[80px] self-stretch  rounded-full   object-fill">
            <Skeleton
              sx={{ bgcolor: '#232524' }}
              variant="rectangular"
              width={80}
              height={80}
            />
          </div>
          <div className=" flex flex-col gap-3">
            <Skeleton
              sx={{ bgcolor: '#232524' }}
              variant="rectangular"
              width={24}
              height={24}
            />
            <Skeleton
              sx={{ bgcolor: '#232524' }}
              variant="circular"
              width={24}
              height={24}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default NotificationPageLoader;
