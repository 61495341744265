import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import wishListService from './wishListService'


export const getLocalStrains=createAsyncThunk(
    "localStorage/get-strains",
    async(thunkAPI)=>{
        try {
            return await wishListService.getLocalStrains()
            
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
            
        }
    }
)



export const resetState =createAction("Reset_all")

const initialState={
    localStrains:[],
    isError: false,
    isLoading: false,
    isSuccess: false,
}

export const wishListSlice=createSlice({
    name: 'local',
    initialState,
    reducers: {},
    message:"",
    extraReducers:(builder) =>{

        builder.addCase(getLocalStrains.pending,(state)=>{
            state.isLoading=true;
        })
        .addCase(getLocalStrains.fulfilled,(state,action)=>{
            state.isLoading = false;
            state.isError = false;
            state.isSuccess = true;
            state.localStrains=action.payload
        })
        .addCase(getLocalStrains.rejected,(state,action)=>{
            state.isLoading = false;
            state.isError = true;
            state.isSuccess = false;
            state.message = action.error.message;
    
        }).addCase(resetState,()=> initialState)
    }
})
export default wishListSlice.reducer
