import React from 'react';

const DealsContent = ({ deals }) => {
  return (
    <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 px-5 py-2 md:px-20 md:py-4">
      {deals.map((deal) => (
        <div key={deal._id} className="bg-white rounded-lg shadow-md p-4">
          {/* Image */}
          <img
            src={deal.dealImg.url}
            alt="Deals"
            className="w-full h-40 object-cover rounded-t-lg"
          />
          {/* Content */}
          <div className="p-2">
            <h3 className="text-lg font-semibold">{deal.description}</h3>
            <p>Expires on: {new Date(deal.expiryDate).toLocaleDateString()}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default DealsContent;
