import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import folderService from './folderService';

// Async thunks
export const createFolder = createAsyncThunk(
    'folder/create',
    async (data, thunkAPI) => {
        try {
            return await folderService.createFolder(data);
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const updateFolder = createAsyncThunk(
    'folder/update',
    async ({ id, folderData }, thunkAPI) => {
        try {
            return await folderService.updateFolder(id, folderData);
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const deleteFolder = createAsyncThunk(
    'folder/delete',
    async (id, thunkAPI) => {
        try {
            return await folderService.deleteFolder(id);
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const getAllFolders = createAsyncThunk(
    'folder/getAllFolder',
    async (_, thunkAPI) => {
        try {
            return await folderService.getAllFolders();
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const getAllFoldersForSession = createAsyncThunk(
    'folder/getAllFolderForSession',
    async (_, thunkAPI) => {
        try {
            return await folderService.getAllFoldersForSession();
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const getFolder = createAsyncThunk(
    'folder/get',
    async (id, thunkAPI) => {
        try {
            return await folderService.getFolder(id);
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const addStrainToFolder = createAsyncThunk(
    'folder/addStrain',
    async ({ folderId, strainId }, thunkAPI) => {
        try {
            return await folderService.addStrainToFolder(folderId, strainId);
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const removeStrainFromFolder = createAsyncThunk(
    'folder/removeStrain',
    async ({ folderId, strainId }, thunkAPI) => {
        try {
            return await folderService.removeStrainFromFolder(folderId, strainId);
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const resetState = createAction("Reset_all")

// Initial state
const initialState = {
    folders: [],
    folder: [],
    foldersForSession: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: '',
};

// Slice
export const folderSlice = createSlice({
    name: 'folder',
    initialState,
    reducers: {
        reset: () => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(createFolder.pending, (state) => {
            state.isLoading = true;
        })
            .addCase(createFolder.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.folders.push(action.payload);
            })
            .addCase(createFolder.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
        builder.addCase(updateFolder.pending, (state) => {
            state.isLoading = true;
        })
            .addCase(updateFolder.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                const index = state.folders.findIndex(folder => folder._id === action.payload._id);
                // Replace the old folder data with the new data
                if (index !== -1) {
                    state.folders[index] = action.payload;
                }
            })
            .addCase(updateFolder.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error;
            })
        builder.addCase(getAllFolders.pending, (state) => {
            state.isLoading = true;
        })
            .addCase(getAllFolders.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.folders = action.payload
            })
            .addCase(getAllFolders.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error;
            })
        builder.addCase(getAllFoldersForSession.pending, (state) => {
            state.isLoading = true;
        })
            .addCase(getAllFoldersForSession.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.foldersForSession = action.payload
            })
            .addCase(getAllFoldersForSession.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error;
            })
        builder.addCase(getFolder.pending, (state) => {
            state.isLoading = true;
        })
            .addCase(getFolder.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.folder = action.payload;
            })
            .addCase(getFolder.rejected, (state, action) => {
                state.isLoading = false;
                state.isSuccess = false;
                state.isError = true;
                state.message = action.error;
            })
        builder.addCase(deleteFolder.pending, (state) => {
            state.isLoading = true;
        })
            .addCase(deleteFolder.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.folders = state.folders.filter(folder => folder._id !== action.payload._id);
            })
            .addCase(deleteFolder.rejected, (state, action) => {
                state.isLoading = false;
                state.isSuccess = false;
                state.isError = true;
                state.message = action.error;
            })
        builder.addCase(addStrainToFolder.pending, (state) => {
            state.isLoading = true;
        })
            .addCase(addStrainToFolder.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                const updatedFolder = action.payload;
                // Update the single folder state
                state.folder = updatedFolder;

                // Find the index of the folder in the 'folders' array
                const folderIndex = state.folders.findIndex(folder => folder._id === updatedFolder._id);

                // Update the folder data in the 'folders' array
                if (folderIndex >= 0) {
                    state.folders[folderIndex] = updatedFolder;
                }
            })
            .addCase(addStrainToFolder.rejected, (state, action) => {
                state.isLoading = false;
                state.isSuccess = false;
                state.isError = true;
                state.message = action.error;
            })
        builder.addCase(removeStrainFromFolder.pending, (state) => {
            state.isLoading = true;
        })
            .addCase(removeStrainFromFolder.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;

                const updatedFolder = action.payload;
                // Update the folder data in the state
                const folderIndex = state.folders.findIndex(folder => folder._id === updatedFolder._id);
                if (folderIndex >= 0) {
                    state.folders[folderIndex] = updatedFolder;
                }
            })
            .addCase(removeStrainFromFolder.rejected, (state, action) => {
                state.isLoading = false;
                state.isSuccess = false;
                state.isError = true;
                state.message = action.error;
            }).addCase(resetState, () => initialState)
        // ... Similar patterns for other async thunks
    },
});

export const { reset } = folderSlice.actions;

export default folderSlice.reducer;
