import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from '@reduxjs/toolkit';
import rootReducer from './rootReducer';
import { baseApi } from './api/baseApi';

// Persist configuration
const persistConfig = {
    key: 'root',
    storage,
    blacklist: [baseApi.reducerPath],
};

// Combine the reducers properly
const combinedReducer = combineReducers({
    ...rootReducer,
    [baseApi.reducerPath]: baseApi.reducer, // Add RTK Query reducer
});

// Create the persisted reducer
const persistedReducer = persistReducer(persistConfig, combinedReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }).concat(baseApi.middleware),
});

export const persistor = persistStore(store);



