// import localStorage from 'localStorage'; // or window.localStorage

// const getLocalStrains=async()=>{
//     const strain = 'strain';
//     const item = localStorage.getItem(strain);
//     let strainJson = [];
//     strainJson = JSON.parse(item);

//     return strainJson

// }
const getLocalStrains = async () => {
    const strain = 'strain';
    const item = sessionStorage.getItem(strain); // Use sessionStorage here
    let strainJson = [];

    if (item !== null) {
        strainJson = JSON.parse(item);
    }

    return strainJson;
}

const addLocalStrains = async()=>{
    
}
const deleteLocalStrains = async()=>{

    
}


const wishListService={
    getLocalStrains,
    addLocalStrains,
    deleteLocalStrains,
}

export default wishListService