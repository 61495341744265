import React from 'react'
import ComingSoon from '../components/Layout/ComingSoon'
// import BreadCrums from '../components/BreadCrums/BreadCrums'

const People = () => {
  return (
   <>
        <ComingSoon/>
   </>
  )
}

export default People
