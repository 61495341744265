import { baseApi } from "./baseApi";


export const strainApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        // Existing endpoint for strains by type
        getStrainsByType: builder.query({
            query: () => 'strain/strains-by-type',
        }),

        // New endpoint for strains with pagination and filter options
        getStrains: builder.query({
            query: ({ page = 1, filterOptions = {} }) => {
                const filterOptionsString = JSON.stringify(filterOptions);
                return `strain?page=${page}&limit=20&filterOptions=${filterOptionsString}`;
            },
        }),
        // New endpoint for fetching a strain by ID
        getStrainsById: builder.query({
            query: (id) => `strain/${id}`,  // Strain ID is passed dynamically
        }),

    }),
});

export const { useGetStrainsByTypeQuery, useGetStrainsQuery, useGetStrainsByIdQuery } = strainApi;
