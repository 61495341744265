import { baseApi } from './baseApi';

export const searchHistoryApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        // Get search history for a user by userId
        getSearchHistory: builder.query({
            query: (userId) => ({
                url: `search-history`,
                method: 'GET',
                params: { userId },
            }),
            providesTags: ['SearchHistory'], // Mark this query result with a tag
        }),

        // Get all search history with pagination and limit
        getAllSearchHistory: builder.query({
            query: (userId, page = 1, limit = 20, sortOrder = 'desc') => ({
                url: `search-history/get-all/`,
                method: 'GET',
                params: { userId, page, limit, sortOrder },
            }),
            providesTags: ['SearchHistory'], // Mark this query result with a tag
        }),

        // Clear all search history for a specific user
        clearAllHistory: builder.mutation({
            query: (userId) => ({
                url: `search-history/update-all`,
                method: 'PUT',
                body: { userId }, // Send userId in body for clearing history
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }, // Assuming token-based authentication
            }),
            invalidatesTags: ['SearchHistory'], // Invalidate 'SearchHistory' tag to refetch the data
        }),

        // Clear one specific search history entry by its ID
        clearOneHistory: builder.mutation({
            query: (id) => ({
                url: `search-history/${id}`,
                method: 'PUT',
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }, // Token-based authentication
            }),
            invalidatesTags: ['SearchHistory'], // Invalidate 'SearchHistory' tag to refetch the data
        }),

    }),
});

export const {
    useGetSearchHistoryQuery,
    useGetAllSearchHistoryQuery,
    useClearAllHistoryMutation,
    useClearOneHistoryMutation,
} = searchHistoryApi;
