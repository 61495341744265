// eslint-disable-next-line
import React, { useEffect, useState } from 'react';
// import alert1 from '../../Images/alert.png'
import { Link } from 'react-router-dom';

const StrainPopup = ({ isOpen, onClose }) => {
  const [paragraphText, setParagraphText] = useState(
    'To add strains to your stash, please log in or create an account.'
  );
  const [headerText, setHeaderText] = useState('Save Your Stash!');

  useEffect(() => {
    const url = window.location.href;
    if (
      url === 'http://localhost:3000/strain/65aa55b7ea1a606de4c92a1f#Reviews'
    ) {
      setParagraphText(
        'To leave a review, please log in or create an account.'
      );
      setHeaderText('Leave a Review!');
    }
  }, []);

  const closeModal = () => {
    // Call the `onClose` function to close the popup
    onClose();
  };

  return (
    <>
      {isOpen && (
        <div
          className={`fixed inset-0 z-50 ${
            isOpen ? 'block' : 'hidden'
          } overflow-y-auto`}
        >
          <div className="flex items-center justify-center min-h-screen">
            <div className="fixed inset-0 transition-opacity">
              <div
                className="absolute inset-0 bg-black opacity-95"
                onClick={closeModal}
              ></div>
            </div>

            <div className="lg:w-[30%] md:w-[50%] xs:w-[100%] bg-[#253238] text-white rounded-lg shadow-lg z-50 p-8 flex-col justify-center">
              <h2 className=" flex justify-center items-center text-xl font-semibold mb-4 text-red-500 text-center">
                {headerText}
              </h2>
              <p>{paragraphText}</p>
              <div className="flex flex-col justify-center ">
                <div className="flex flex-col justify-center items-center mb-4 ">
                  <Link
                    className=" w-48 text-sm mt-4 bg-[#62AF41] text-center  text-white font-semibold py-2 px-4 rounded-lg focus:outline-none focus:shadow-outline-blue"
                    to="/signup"
                  >
                    Sign Up
                  </Link>
                  <button
                    className="w-48 text-sm mt-4 border-2 border-[#62AF41]  text-center rounded-lg text-white font-semibold py-2 px-4  focus:outline-none focus:shadow-outline-blue"
                    onClick={closeModal}
                  >
                    Close
                  </button>
                </div>
                <p className="text-center ">
                  <Link to="/login" className="text-[#62AF41] underline">
                    Already a member? Log In
                  </Link>{' '}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default StrainPopup;
