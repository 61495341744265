import React from 'react';

const parseResponse = (response) => {
  const lines = response.split('\n').filter((line) => line.trim() !== '');
  const elements = [];

  lines.forEach((line, index) => {
    // Match bold text wrapped in **
    const boldMatch = line.match(/\*\*(.+?)\*\*/);
    // Check for heading text marked with ###
    const headingMatch = line.match(/^###\s*(.+)$/);

    if (headingMatch) {
      elements.push(
        <h3 key={index} className="font-bold text-2xl py-2">
          {headingMatch[1]}
        </h3>
      );
    } else if (boldMatch) {
      // Check if the line is a list item
      if (line.startsWith('-')) {
        const restOfLine = line
          .replace(/\*\*(.+?)\*\*/, '')
          .trim()
          .slice(1)
          .trim(); // Remove the bold part and hyphen
        elements.push(
          <li key={index}>
            <strong>{boldMatch[1]}</strong>
            {restOfLine}
          </li>
        );
      } else {
        elements.push(
          <p key={index}>
            <strong>{boldMatch[1]}</strong>
            {line.replace(/\*\*(.+?)\*\*/, '')}
          </p>
        );
      }
    } else {
      elements.push(<p key={index}>{line}</p>);
    }
  });

  return elements;
};

const Content = ({ content }) => {
  return (
    <div>
      <div className="text-white fade-in">{parseResponse(content)}</div>
    </div>
  );
};

export default Content;
