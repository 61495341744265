import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { FaRegCircle, FaCheckCircle } from 'react-icons/fa';
import CustomModal from '../cutom/CustomModal';
import { MdOutlineKeyboardBackspace } from 'react-icons/md';
import { IoCloseCircleOutline } from 'react-icons/io5';
import placeHolderImage from '../../Images/placeholderImage.png';
import CreateFolderModal from '../stash/CreateFolderModal';
import { enqueueSnackbar } from 'notistack';
import {
  addStrainToFolder,
  removeStrainFromFolder,
} from '../../features/stashFolder/folderService';

const FolderSelectionModal = ({
  itemId,
  itemType,
  isOpen,
  onClose,
  backBtnText,
  onUpdate,
}) => {
  // globalState
  const folders = useSelector((state) => state.folder.folders);
  // localState
  const [newFolderModalOpen, setNewFolderModalOpen] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

  // add item to folder
  const addItemToFolder = async (folderId, itemId, itemType) => {
    try {
      const res = await addStrainToFolder(folderId, { itemId, type: itemType });
      if (res) {
        onUpdate();
        enqueueSnackbar(res?.message, { variant: 'success' });
      }
    } catch (error) {
      enqueueSnackbar(error?.message, { variant: 'success' });
    }
  };

  // remove item to folder
  const confirmRemoveStrain = async (folderId) => {
    try {
      const res = await removeStrainFromFolder(folderId, {
        itemId,
        type: itemType,
      });
      if (res) {
        onUpdate();
        enqueueSnackbar(res?.message, { variant: 'success' });
      }
    } catch (error) {
      enqueueSnackbar(error?.message, { variant: 'success' });
    }

    setConfirmationModalOpen(false);
  };

  const getLastImageUrl = (folder) => {
    const hasProducts = folder.products && folder.products.length > 0;
    const hasStrains = folder.strains && folder.strains.length > 0;

    if (hasProducts) {
      return folder.products[folder.products.length - 1]?.productImg?.url;
    } else if (hasStrains) {
      return folder.strains[folder.strains.length - 1]?.strainImg?.url;
    } else {
      return `${placeHolderImage}`;
    }
  };

  const calculateCounts = (folders) => {
    const productCount = folders.products ? folders.products.length : 0;
    const strainCount = folders.strains ? folders.strains.length : 0;
    const totalCount = productCount + strainCount;
    return totalCount;
  };

  const checkItemAdded = (folder) => {
    if (
      folder.products?.find((item) => item?._id === itemId) ||
      folder.strains?.find((item) => item?._id === itemId)
    ) {
      return true;
    }

    return false;
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-opacity-75 flex justify-center items-center z-40 ">
      <div className="bg-[#2c2c2c] rounded-lg shadow-xl w-[340px] md:w-85 max-h-full overflow-auto px-3 py-4">
        <div className="flex justify-between items-center ">
          <button
            onClick={onClose}
            className="flex justify-start items-center gap-2 text-white text-lg bg-transparent border-none cursor-pointer p-2 focus:outline-none"
          >
            <MdOutlineKeyboardBackspace
              className=" cursor-pointer"
              size={18}
              color="#ffffff"
            />
            <span className="flex justify-start items-center">
              {backBtnText}
            </span>
          </button>
          <IoCloseCircleOutline onClick={onClose} size={24} color="#ff0000" />
        </div>
        {newFolderModalOpen && (
          <CreateFolderModal
            isOpen={newFolderModalOpen}
            onClose={() => setNewFolderModalOpen(false)}
            onUpdate={() => onUpdate()}
          />
        )}

        <div className="flex flex-col justify-start items-center text-white gap-4 max-h-96 overflow-auto scrollbar-hide mt-4">
          {folders?.map((folder) => (
            <div
              key={folder._id}
              className="flex justify-between items-center w-full bg-[#393939] rounded-md px-2 py-2 shadow-lg "
            >
              <div className="flex justify-start items-center gap-2">
                <div className="flex justify-start items-center w-14 h-14 object-cover rounded-full overflow-hidden">
                  <img
                    src={getLastImageUrl(folder)}
                    alt="Item"
                    className=" w-full h-full "
                  />
                </div>
                <div className="flex flex-col justify-start items-start">
                  <div className="text-white text-xl">{folder.name}</div>
                  <div className="text-gray-400 text-sm">
                    {calculateCounts(folder)} Item
                  </div>
                </div>
              </div>
              <div>
                {checkItemAdded(folder) ? (
                  <FaCheckCircle
                    onClick={() => confirmRemoveStrain(folder?._id)}
                    size={24}
                    className="text-[#62AF41]"
                  />
                ) : (
                  <FaRegCircle
                    onClick={() =>
                      addItemToFolder(folder._id, itemId, itemType)
                    }
                    size={24}
                    className="text-gray-600"
                  />
                )}
              </div>
            </div>
          ))}
        </div>
        <div className="flex justify-start items-center text-white pt-4">
          <div className="flex justify-center items-center">
            <button
              onClick={() => setNewFolderModalOpen(true)}
              className="rounded-3xl bg-white text-black px-4 py-1.5 font-semibold text-sm"
            >
              New Folder
            </button>
          </div>
        </div>
      </div>
      <CustomModal
        hideModal={() => setConfirmationModalOpen(false)}
        performAction={confirmRemoveStrain}
        open={confirmationModalOpen}
        title={`Are you sure you want to remove this ${
          itemType === 'product' ? 'Product' : 'Strain'
        } from the folder?`}
      />
    </div>
  );
};

export default FolderSelectionModal;
