import React, { useRef, useEffect, useState } from 'react';
import Chart from 'chart.js/auto';
import { BsThreeDots } from 'react-icons/bs';
import SessionCardModal from '../CommonComponent/SessionCardModal';
import { MdDownload } from 'react-icons/md';
import { FaShareSquare } from 'react-icons/fa';
import html2canvas from 'html2canvas';
import StateSocialShareModal from './StateSocialShareModal';


const SessionBarChart = ({ title, data }) => {
    const chartRef = useRef(null);
    const chartInstanceRef = useRef(null);
    const cardRef = useRef();


    const [isActionModalOpen, setIsActionModalOpen] = useState(false);
    const [isShareModalOpen, setIsShareModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);





    useEffect(() => {
        const ctx = chartRef.current.getContext('2d');

        // Extract labels and counts from the data prop
        const labels = data?.map(item => item.name);
        const counts = data?.map(item => item.count);

        // Clean up the previous chart instance if it exists
        if (chartInstanceRef.current) {
            chartInstanceRef.current.destroy();
        }

        // Initialize new chart instance
        chartInstanceRef.current = new Chart(ctx, {
            type: 'bar',
            data: {
                labels: labels, // Use extracted labels here
                datasets: [
                    {
                        label: title,
                        data: counts, // Use extracted counts here
                        backgroundColor: ['#4CAF50', '#657D3E', '#BDBDBD', '#546E7A'],
                        borderColor: ['#346751', '#486B2A', '#7D7D7D', '#2F4550'],
                        borderWidth: 1
                    },
                ],
            },
            options: {
                scales: {
                    y: {
                        beginAtZero: true,
                    },
                },
                plugins: {
                    legend: {
                        display: false, // Set this to true if you want the dataset label to show
                    },
                    tooltip: {
                        callbacks: {
                            label: function (context) {
                                // This function defines the text to be displayed in the tooltip.
                                return `${context.chart.data.labels[context.dataIndex]}: ${context.raw}`;
                            }
                        }
                    }
                },
            },
        });

        // Cleanup function to destroy chart on unmount
        return () => {
            if (chartInstanceRef.current) {
                chartInstanceRef.current.destroy();
            }
        };
    }, [title, data]); // Include data in the dependencies array to re-render chart when data changes


    const handleShare = () => {
        setIsShareModalOpen(true);
    };

    function myRenderFunction(canvas) {
        var a = document.createElement('a');
        a.href = canvas
            .toDataURL('image/jpeg')
            .replace('image/jpeg', 'image/octet-stream');
        a.download = 'SessionCart.jpg';
        a.click();

    }
    const handleDownload = () => {

        if (cardRef.current === null) {
            return;
        }
        setLoading(true)
        cardRef.current.style.backgroundColor = '#0f0f0f';
        document.fonts.ready.then(() => {
            html2canvas(cardRef.current, { useCORS: true })
                .then((canvas) => {
                    cardRef.current.style.backgroundColor = '';
                    myRenderFunction(canvas);
                    setLoading(false)

                })
                .catch((err) => {
                    console.error('Oops, something went wrong!', err);
                    cardRef.current.style.backgroundColor = '';
                    setLoading(false)
                });
        });

    };
    const shareUrl = `https://maryapp.io/sessions-state/`;
    return (
        <div className="bg-[#27262b] text-white w-full mx-auto rounded-md" ref={cardRef}>
            <div className="py-4">
                <div className='flex justify-between items-center gap-2 px-2'>
                    <h4 className="text-center font-semibold text-[#525252] text-lg">
                        {title}
                    </h4>
                    <BsThreeDots
                        size={28}
                        className="text-gray-300 cursor-pointer"
                        onClick={() => setIsActionModalOpen(!isActionModalOpen)}

                    />
                    <SessionCardModal
                        isOpen={isActionModalOpen}
                        onRequestClose={() => setIsActionModalOpen(false)}
                    >
                        <div className=" p-6 rounded-lg w-72 text-white space-y-4">
                            <button
                                onClick={handleDownload}
                                className="flex items-center justify-center w-full py-2 mb-4 bg-black text-white rounded "
                            >
                                <MdDownload className="mr-2" size={22} />
                                {loading ? 'Loading...' : 'Download'}
                            </button>
                            <button
                                onClick={handleShare}
                                className="flex items-center justify-center w-full py-2 bg-blue-600 text-white rounded hover:bg-blue-500"
                            >
                                <FaShareSquare className="mr-2" />
                                Share
                            </button>
                        </div>
                    </SessionCardModal>
                    <StateSocialShareModal
                        title={title}
                        onClose={() => setIsShareModalOpen(!isShareModalOpen)}
                        isOpen={isShareModalOpen}
                        url={shareUrl}
                    />
                </div>
            </div>
            <div className='pb-4'>
                <canvas ref={chartRef} width={'100%'} height={'100%'}></canvas>
            </div>
        </div>
    );
};

export default SessionBarChart;
