import { React, useState, useRef, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import AllSubLoader from '../../skeleton/AllSubLoader';
import SubscribedBussiness from '../CommonComponent/SubscribedBussiness/SubscribedBussiness';
import SideBar from '../CommonComponent/side-bar/SideBar';
import MenuTabs from '../CommonComponent/menu-tab/MenuTabs';
import 'swiper/css';
import { useGetUserNotificationsCountQuery } from '../../redux/api/notificationApi';
import { useGetAllSubscribedQuery } from '../../redux/api/subscriptionApi';
import SearchBarMobile from '@/common-components/search/SearchBarMobile';
import UnAuthTopBar from './UnAuthTopBar';
import AuthTopBar from './AuthTopBar';
import { searchUiShow } from '../CommonComponent/CommonData/CommonData';
import useUserSession from '@/core/hooks/useUser';

const Header = () => {
    //Hooks
    const location = useLocation();

    // globalState
    const { user } = useUserSession();

    const { data: notificationCount } = useGetUserNotificationsCountQuery(
        undefined,
        {
            skip: !user, // Skip query if user is not logged in
        }
    );

    const { data: subscribedData, isLoading: subscribedLoading } =
        useGetAllSubscribedQuery(undefined, {
            skip: !user, // Skip query if user is not logged in
        });

    // useRefence
    const swiperRef = useRef(null);

    // local State
    const [dropDown, setDropdown] = useState(false);
    const [showSidebar, setShowSidebar] = useState(false);
    const [arrowToggle, setArrowToggle] = useState(false);
    const [stickyOffset, setStickyOffset] = useState(false);
    const [showTopBarSearch, setShowTopBarSearch] = useState(false);

    const handleSidebarToggle = () => {
        setShowSidebar((prevShowSidebar) => !prevShowSidebar);
        setDropdown(false);
    };

    useEffect(() => {
        setShowSidebar(false);
    }, [location.pathname]);

    return (
        <div className="container mx-auto px-2 md:px-0 space-y-3  ">
            {!user && (
                <UnAuthTopBar
                    handleSidebarToggle={handleSidebarToggle}
                    setStickyOffset={setStickyOffset}
                />
            )}
            {user && (
                <AuthTopBar
                    notificationCount={notificationCount}
                    handleSidebarToggle={handleSidebarToggle}
                    setStickyOffset={setStickyOffset}
                    showTopBarSearch={showTopBarSearch}
                    setShowTopBarSearch={setShowTopBarSearch}
                />
            )}
            {user &&
                showTopBarSearch &&
                searchUiShow?.includes(location.pathname) && (
                    <div
                        className={`sm:block md:hidden sticky transition-all duration-300 ease-in-out z-40 ${stickyOffset ? 'top-[12px]' : 'top-[53px]'
                            } `}
                    >
                        <SearchBarMobile SearchBarMobile={false} />
                    </div>
                )}
            {!user && searchUiShow?.includes(location.pathname) && (
                <div
                    className={`sm:block md:hidden sticky transition-all duration-300 ease-in-out z-40 ${stickyOffset ? 'top-[12px]' : 'top-[53px]'
                        } `}
                >
                    <SearchBarMobile SearchBarMobile={false} />
                </div>
            )}
            <div className=" container px-1 mx-auto z-30 flex flex-col gap-4">
                {location.pathname === '/' && (
                    <>
                        {subscribedLoading && <AllSubLoader numberOfItems={30} />}
                        {subscribedData && (
                            <SubscribedBussiness Data={[]} />
                        )}
                    </>
                )}
                <MenuTabs setArrowToggle={setArrowToggle} swiperRef={swiperRef} />
                <Outlet />
            </div>

            <SideBar
                showSidebar={showSidebar}
                handleSidebarToggle={handleSidebarToggle}
                setArrowToggle={setArrowToggle}
                arrowToggle={arrowToggle}
                dropDown={dropDown}
            />
        </div>
    );
};

export default Header;
