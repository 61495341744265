import axios from 'axios'
import { config } from '../../utils/axiosconfig';
import { base_url } from '../../utils/base_url';

const saveCartData = async (id) => {
    const configure = config()

    const response = await axios.put(`${base_url}user/saveUserCart`, { id }, configure);
    return response.data;
    

  };

const fetchCartData = async () => {
    const configure = config();
    const response = await axios.get(`${base_url}user/getSaveUserCart`, configure);
    return response.data;
  };

  const cartService ={

    saveCartData,
    fetchCartData,


  }

  export default cartService