export const getUrl = (type, slug) => {
    switch (type) {
        case 'Brand':
            return `/brand-detail/${slug}`;
        case 'Breeder':
            return `/breeder-detail/${slug}`;
        case 'HashMaker':
            return `/hashMaker-detail/${slug}`;
        case 'Dispensary':
            return `/dispensary-detail/${slug}`;
        case 'GrowShop':
            return `/growshop-detail/${slug}`;
        case 'Grower':
            return `/grower-detail/${slug}`;
        case 'HempShop':
            return `/hempShop-detail/${slug}`;
        case 'SeedBank':
            return `/seedBank-detail/${slug}`;
        case 'SocialClub':
            return `/socialClub-detail/${slug}`;
        case 'Delivery':
            return `/delivery-detail/${slug}`;

        default:
            return '';
    }
};