import { useState } from 'react';
import SubscriptionCard from '../components/subscription/SubscriptionCard';

const Subscription = () => {
  const [pricingType, setPricingType] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [bussiness, setbussiness] = useState([
    'Manage your profile',
    'Verified badge',
    'Subscriptions',
    'Memberships 15%',
    'Manage menu',
    'Create strains and products',
    'Verification tags',
    'Analytics',
    'Community post',
  ]);
  // eslint-disable-next-line no-unused-vars
  const [proSeller, setProSeller] = useState([
    'Sell on Mary',
    'Manage your profile',
    'Verified badge',
    'Subscriptions',
    'Memberships 10%',
    'Manage shop',
    'Create strains and products',
    'Verification tags',
    'Advanced analytics',
    'Community post',
    'Offer deals',
  ]);
  // eslint-disable-next-line no-unused-vars
  const [enterprise, setEnterprise] = useState([
    'Manage multiple businesses',
    'Multiple user accounts',
    'Sell on Mary',
    'Verified Badge',
    'Subscriptions',
    'Memberships',
    'Manage shops',
    'Create strains and products',
    'Verification tags',
    'Custom analytics',
    'Community post',
    'Offer deals',
  ]);

  return (
    <div className="bg-[#0f0f0f] px-4">
      <div className=" flex flex-col justify-center items-center py-2">
        <h2 className="text-white text-3xl font-bold">Professional Plans</h2>
        <p className="text-white text-xl pt-1 pb-6">
          Choose the plan that's right for you
        </p>
        <div>
          <label className="inline-flex items-center cursor-pointer">
            <input
              type="checkbox"
              value={false}
              className="sr-only peer"
              onChange={() => setPricingType(!pricingType)}
            />
            <span className="text-md font-medium text-gray-900 dark:text-gray-300 pr-3">
              Monthly
            </span>
            <div className="relative w-11 h-6 bg-[#62AF41] peer-focus:outline-none  rounded-full peer dark:bg-[#2c2c2c] peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-[#62AF41] after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-[#62AF41] after:border-[#62AF41] after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-[#2c2c2c]"></div>
            <span className="ms-3 text-md font-medium text-gray-900 dark:text-gray-300">
              Yearly
            </span>
          </label>
        </div>
      </div>
      {!pricingType ? (
        <>
          <div className="flex flex-col md:flex-row md:justify-center md:items-center gap-5 md:gap-4 m-4 pb-10 md:pb-14">
            <SubscriptionCard
              Title="Professional"
              Des="Essential features you need to get started"
              Price="$100"
              Option={bussiness}
              duration={'/month'}
              buttonText={'Get Started'}
            />
            <SubscriptionCard
              Title="Pro Seller"
              Des="Perfect for owners of small & medium businesses"
              Price="$250"
              Option={proSeller}
              duration={'/month'}
              buttonText={'Get Started'}
            />
            <SubscriptionCard
              Title="Enterprise "
              Des="Dedicated support and infrastructure to fit the needs of large businesses"
              Price="Custom"
              Option={enterprise}
              // duration={"month"}
              buttonText={'Contact Sales'}
            />
          </div>
        </>
      ) : (
        <>
          <div className="flex flex-col md:flex-row md:justify-center md:items-center gap-5 md:gap-4 m-4 pb-10 md:pb-14">
            <SubscriptionCard
              Title="Professional"
              Des="Essential features you need to get started"
              Price="$900"
              Option={bussiness}
              duration={'/year'}
              saving={'Save 300$'}
              buttonText={'Get Started'}
            />
            <SubscriptionCard
              Title="Pro Seller"
              Des="Perfect for owners of small & medium businesses"
              Price="$2250"
              Option={proSeller}
              duration={'/year'}
              saving={'Save 750$'}
              buttonText={'Get Started'}
            />
            <SubscriptionCard
              Title="Enterprise "
              Des="Dedicated support and infrastructure to fit the needs of large businesses"
              Price="Custom"
              Option={enterprise}
              // duration={"year"}
              // saving={"Save 30%"}
              buttonText={'Contact Sales'}
            />
          </div>
        </>
      )}

      <section className="bg-[#0f0f0f] flex justify-center items-center pb-10">
        <div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
          <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-center text-gray-900 dark:text-white">
            Contact Us
          </h2>
          <p className="mb-8 lg:mb-16 font-light text-center text-gray-500 dark:text-gray-400 sm:text-xl">
            Contact us to help you choose the right plan for your business.
          </p>
          <form action="#" className="space-y-8">
            <div>
              <label
                htmlFor="text"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Business Name
              </label>
              <input
                type="text"
                id="text"
                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                placeholder="Your Business Name"
                required
              ></input>
            </div>
            <div>
              <label
                htmlFor="text"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Contact Name
              </label>
              <input
                type="text"
                id="text"
                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                placeholder="Your Name"
                required
              ></input>
            </div>
            <div>
              <label
                htmlFor="email"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                placeholder="example@gmail.com"
                required
              ></input>
            </div>
            <div>
              <label
                htmlFor="phone"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Phone
              </label>
              <input
                type="phone"
                id="phone"
                className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                placeholder="Your Phone Number"
                required
              ></input>
            </div>
            <div className="sm:col-span-2">
              <label
                htmlFor="message"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
              >
                Your message
              </label>
              <textarea
                id="message"
                rows="6"
                className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                placeholder="Leave a comment..."
              ></textarea>
            </div>
            <button
              type="submit"
              className="py-3 px-5 text-sm font-medium text-center text-white rounded-full bg-[#62AF41] sm:w-fit hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
            >
              Send message
            </button>
          </form>
        </div>
      </section>
    </div>
  );
};

export default Subscription;
