// AmenitiesComponent.jsx
import React from 'react';
import { FaParking, FaWheelchair, FaWifi } from 'react-icons/fa';
import { RiBankLine } from 'react-icons/ri'; // Example icons for ATM

const iconSize = 28;

const amenityIcons = {
    'ATM': <RiBankLine size={iconSize}/>,
    'Wheelchair': <FaWheelchair size={iconSize}/>,
    'Parking': <FaParking size={iconSize}/>,
    'Wi-Fi': <FaWifi size={iconSize}/>,
    // Add more mappings as needed
  };


// AmenitiesComponent.jsx

const AmenitiesComponent = ({ amenities }) => {
    return (
        <div className="bg-[#0f0f0f] shadow lg:shadow-none overflow-hidden sm:rounded-lg">
            <div className="px-4 py-2 sm:px-6">
                <h3 className="text-lg leading-6 font-bold text-gray-300 mt-8">Amenities</h3>
            </div>
            <div className="border-t border-gray-700 text-gray-300 lg:border-none lg:-mt-2">
                <ul className="flex flex-row flex-wrap justify-start ml-4">
                    {amenities.map((amenity, index) => (
                        <li key={index} className="flex items-center mr-8 mt-4 mb-4 py-2">
                            {amenityIcons[amenity]}
                            <span className="ml-2">{amenity}</span>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default AmenitiesComponent;