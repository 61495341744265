import React, { useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { FaCaretUp, FaSortDown } from 'react-icons/fa6';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 'auto',
      backgroundColor: '#2c2c2c', // Background color of dropdown
    },
  },
};

function getStyles(name, selectedValue, theme) {
  return {
    fontWeight:
      selectedValue.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
    backgroundColor: selectedValue.indexOf(name) === -1 ? 'inherit' : '#62b041', // Background color of selected item
    color: selectedValue.indexOf(name) === -1 ? '#fff' : '#fff', // Text color of selected item
  };
}

const MultipleSelectSearchFillter = ({ options, label, getOptions }) => {
  const theme = useTheme();
  const [selectedValue, setselectedValue] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setselectedValue(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };
  useEffect(() => {
    getOptions(selectedValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValue]);

  return (
    <>
      <FormControl
        sx={{
          m: 1,
          width: '100%', // Make FormControl width full
          borderColor: '#62b041', // Border color
          '& .MuiOutlinedInput-root': {
            padding: '0px', // Reduce padding
            '& fieldset': {
              borderColor: '#62b041', // Border color of input
            },
            '&:hover fieldset': {
              borderColor: '#4a8e31', // Border color on hover
            },
            '&.Mui-focused fieldset': {
              borderColor: '#62b041', // Border color when focused
            },
            color: '#fff', // Text color
            borderRadius: '32px', // Adjust border radius
          },
        }}
      >
        <InputLabel
          id="demo-multiple-name-label"
          sx={{
            color: '#ffffff',
            '&.Mui-focused': {
              color: '#ffffff', // Label color when focused
            },
          }}
        >
          {label}
        </InputLabel>
        <Select
          labelId="demo-multiple-name-label"
          id="demo-multiple-name"
          multiple
          value={selectedValue}
          onChange={handleChange}
          input={<OutlinedInput label={label} sx={{ color: '#ffffff' }} />}
          MenuProps={MenuProps}
          IconComponent={(props) =>
            selectedValue.length > 0 ? (
              <FaCaretUp color="#ffffff" className="mr-3 bg-transparent" />
            ) : (
              <FaSortDown color="#ffffff" className="mr-3 bg-transparent" />
            )
          }
          sx={{
            width: '100%', // Make Select width full
            padding: '8px', // Adjust padding inside the select box
            textOverflow: 'ellipsis', // Ensure long text is truncated
            whiteSpace: 'nowrap', // Prevent text from wrapping to the next line
            overflow: 'hidden', // Hide any overflowing text
            '& .MuiSelect-select': {
              backgroundColor: '#0f0f0f', // Background color of selected input
              color: '#fff', // Text color of selected input
            },
            '.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input':
              {
                padding: '12px',
              },
          }}
        >
          {options.map((name) => (
            <MenuItem
              sx={{ color: '#ffffff' }} // Ensure all text is white
              key={name}
              value={name}
              style={getStyles(name, selectedValue, theme)}
            >
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export default MultipleSelectSearchFillter;
