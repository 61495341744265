// OrderOptionsComponent.jsx
import React from 'react';
import { FaStore, FaTruck, FaShoppingCart, FaWalking } from 'react-icons/fa';

const iconSize = 28;

const orderOptionIcons = {
    'online': <FaShoppingCart size={iconSize} />,
    'inStore': <FaStore size={iconSize} />,
    'pickup': <FaTruck size={iconSize} />,
    'curbsidePickup': <FaWalking size={iconSize} />,
};


const OrderOptionsComponent = ({ orderOptions }) => {
    const activeOptions = Object.entries(orderOptions).filter(([key, value]) => value === true);

    return (
        <div className="bg-[#0f0f0f] shadow lg:shadow-none overflow-hidden sm:rounded-lg">
            <div className="px-4 py-2 sm:px-6">
                <h3 className="text-lg leading-6 font-bold text-gray-300 mt-8">Order Options</h3>
            </div>
            <div className="border-t border-gray-700 text-gray-300 lg:border-none lg:-mt-2">
                <ul className="flex flex-row flex-wrap justify-start ml-4">
                    {activeOptions.map(([option, _], index) => (
                        <li key={index} className="flex items-center mr-8 mt-4 mb-4 py-2">
                            {orderOptionIcons[option]}
                            <span className="ml-2 capitalize">{option}</span>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default OrderOptionsComponent;
