import React from 'react';

const AboutUsContent = ({ AboutUs }) => {
    function linkify(text) {
        const urlRegex = /(\b(?:https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|]|\bwww\.[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|]|\b[-A-Z0-9+&@#/%?=~_|]+\.[A-Z]{2,4}[-A-Z0-9+&@#/%?=~_|]*)/ig;
        return text?.replace(urlRegex, function(url) {
            let href = url;
            if (!/^(?:https?|ftp|file):\/\//i.test(url)) {
                href = 'http://' + url;
            }
            return `<a href="${href}" target="_blank" rel="noopener noreferrer" class="text-blue-500 hover:text-blue-600 underline">${url}</a>`;
        });
      }
      const formattedDescription = linkify(AboutUs);
    return (
        <div className="bg-[#0f0f0f] shadow lg:shadow-none overflow-hidden sm:rounded-lg">
            <div className="px-4 py-2 sm:px-6">
                <h3 className="text-lg leading-6 font-bold text-gray-300 mt-8">About Us</h3>
            </div>
            <div className="border-t border-gray-700 text-gray-300 lg:border-none lg:-mt-2">
                <div className="p-4">
                    <p className="text-sm mt-2" style={{ whiteSpace: 'pre-line'}} dangerouslySetInnerHTML={{ __html: formattedDescription }} 
                ></p>
                </div>
            </div>
        </div>
    );
};

export default AboutUsContent;