import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const HeaderButtons = ({ buttonText, buttonLink, funPass }) => {
  const location = useLocation();
  return (
    // ${buttonText === 'All' ?('w-[30%] float-right'):(' w-full')}
    <>
      <div className={`flex flex-row mx-1 justify-center text-left `}>
        <Link
          type="button"
          onClick={funPass}
          className={` ${
            location.pathname === buttonLink
              ? 'bg-[#62AF41] border-[#62AF41]'
              : 'bg-[#2c2c2c] border-[#2c2c2c] hover:bg-[#787777] hover:border-[#787777]'
          } capitalize  w-full text-white border-2    font-medium rounded-lg text-sm  py-1.5 text-center  xs:text-xs  `}
          to={buttonLink}
        >
          {buttonText}
        </Link>
      </div>
    </>
  );
};

export default HeaderButtons;
