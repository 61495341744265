import axios from 'axios'
import { config } from '../../utils/axiosconfig';
import { base_url } from '../../utils/base_url';


export const sendMessagetoAi = async (msg) => {
    const configure = config();
    const response = await axios.post(`${base_url}query`, msg, configure);
    return response.data;
};

export const getConversations = async () => {
    const configure = config();
    const response = await axios.get(`${base_url}query`, configure);
    return response.data;
};
export const getSearchConversations = async (id) => {
    const configure = config();
    const response = await axios.get(`${base_url}query/single-conversation?search=${id}`, configure);
    return response.data;
};

export const getDefaultQuestions = async () => {
    const configure = config();
    const response = await axios.get(`${base_url}query/default-questions`, configure);
    return response.data;
};