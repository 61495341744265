import React from 'react'
import { Helmet } from "react-helmet";
const Meta = ({title,siteLink}) => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{title}</title>
                <link rel="canonical" href={siteLink} />
            </Helmet>
        </>
    )
}

export default Meta
