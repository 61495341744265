import { Avatar } from '@mui/material';
import React from 'react';

const SourceContent = ({ item }) => {
  return (
    <>
      <div className="bg-[#535353]   w-3/4  p-3 rounded-md text-white  ">
        <a
          href={item?.link}
          target="_blank"
          rel="noopener noreferrer"
          className=" cursor-pointer"
        >
          <p className="t line-clamp-2 text-bold">{item?.title}</p>
          <div className=" flex items-center gap-2 mt-2">
            <Avatar
              alt={item?.title}
              src={item?.logo}
              sx={{ width: 25, height: 25 }}
            />

            <div className="  line-clamp-1  w-full">{item?.link}</div>
          </div>
        </a>
      </div>
    </>
  );
};

export default SourceContent;
