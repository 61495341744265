import React, { useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { FaCaretUp, FaSortDown } from 'react-icons/fa6';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 'auto',
      backgroundColor: '#2c2c2c',
    },
  },
};

function getStyles(name, selectedValue, theme) {
  return {
    fontWeight:
      name === selectedValue
        ? theme.typography.fontWeightMedium
        : theme.typography.fontWeightRegular,
    backgroundColor: name === selectedValue ? '#62b041' : 'inherit',
    color: '#fff',
  };
}

const SingleSelectSearchFillter = ({ options, label, getOptions }) => {
  const theme = useTheme();
  const [selectedValue, setSelectedValue] = React.useState('');

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  useEffect(() => {
    getOptions(selectedValue);
  }, [getOptions, selectedValue]);

  return (
    <FormControl
      sx={{
        m: 1,
        width: '100%',
        borderColor: '#62b041',
        '& .MuiOutlinedInput-root': {
          padding: '0px',
          '& fieldset': {
            borderColor: '#62b041',
          },
          '&:hover fieldset': {
            borderColor: '#4a8e31',
          },
          '&.Mui-focused fieldset': {
            borderColor: '#62b041',
          },
          color: '#fff',
          borderRadius: '32px',
        },
      }}
    >
      <InputLabel
        id="demo-single-name-label"
        sx={{
          color: '#ffffff',
          '&.Mui-focused': {
            color: '#ffffff',
          },
        }}
      >
        {label}
      </InputLabel>
      <Select
        labelId="demo-single-name-label"
        id="demo-single-name"
        value={selectedValue}
        onChange={handleChange}
        input={<OutlinedInput label={label} sx={{ color: '#ffffff' }} />}
        MenuProps={MenuProps}
        IconComponent={(props) =>
          selectedValue ? (
            <FaCaretUp color="#ffffff" className="mr-3 bg-transparent" />
          ) : (
            <FaSortDown color="#ffffff" className="mr-3 bg-transparent" />
          )
        }
        sx={{
          width: '100%',
          padding: '8px',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          '& .MuiSelect-select': {
            backgroundColor: '#0f0f0f',
            color: '#fff',
          },
          '.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input':
            {
              padding: '12px',
            },
        }}
      >
        {options.map((name) => (
          <MenuItem
            sx={{ color: '#ffffff' }}
            key={name}
            value={name}
            style={getStyles(name, selectedValue, theme)}
          >
            {name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SingleSelectSearchFillter;
