import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { LiaExternalLinkAltSolid } from 'react-icons/lia';
import {
  getCustomProduct,
  getSessionProducts,
  getSessionStrains,
} from '../../features/sessions/SessionServices';
import { enqueueSnackbar } from 'notistack';

const PendingSession = () => {
  const navigate = useNavigate();
  const { session } = useSelector((state) => state.sessions);

  const [timer, setTimer] = useState('00:00:00');
  const [userAddedItem, setuserAddedItem] = useState([]);
  const [strains, setStrains] = useState([]);
  const [products, setProducts] = useState([]);

  const getAllStrain = useCallback(async () => {
    try {
      const res = await getSessionStrains();
      if (res) {
        setStrains(res);
      }
    } catch (error) {
      enqueueSnackbar(error?.message, { variant: 'error' });
    }
  }, []);

  const getAllProducts = useCallback(async (brandId) => {
    try {
      const res = await getSessionProducts(brandId);
      if (res) {
        setProducts(res);
      }
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
    }
  }, []);

  useEffect(() => {
    let isSubscribe = true;
    if (isSubscribe) {
      getAllStrain();
      if (session?.selectedItem === 'Product') {
        getAllProducts(session?.selectedBussines);
      }
    }

    return () => (isSubscribe = false);
  }, [getAllProducts, getAllStrain, session]);

  const userAddedProduct = useCallback(async () => {
    const res = await getCustomProduct();
    setuserAddedItem(res);
    return res;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Find strain or product or stash
  const selectedStrain = useMemo(() => {
    if (session?.selectedItem === 'Strain') {
      return strains.find((item) => item._id === session?.strain);
    } else if (session?.selectedItem === 'Product') {
      return products.find((item) => item._id === session?.selectedProduct);
    } else if (session?.selectedItem === 'Stash') {
      return (
        products.find((item) => item._id === session?.selectedProduct) ||
        strains.find((item) => item._id === session?.selectedProduct)
      );
    } else if (session?.selectedItem === 'UserAdded') {
      return userAddedItem.find((item) => item._id === session?.userAdded);
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    session?.selectedItem,
    session?.strain,
    session?.selectedProduct,
    products,
    userAddedItem,
  ]);

  const imageUrl = selectedStrain?.strainImg
    ? selectedStrain.strainImg.url
    : selectedStrain?.productImg?.url;

  useEffect(() => {
    const isSubscribe = true;
    if (isSubscribe) {
      userAddedProduct();
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStrain?.title]);

  useEffect(() => {
    let interval;

    if (session?.sessionStartTime) {
      const startTimer = () => {
        const startTime = new Date(session.sessionStartTime).getTime();
        interval = setInterval(() => {
          const now = Date.now();
          const difference = now - startTime;
          const hours = Math.floor(
            (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
          );
          const minutes = Math.floor(
            (difference % (1000 * 60 * 60)) / (1000 * 60)
          );
          const seconds = Math.floor((difference % (1000 * 60)) / 1000);
          setTimer(`${pad(hours)}:${pad(minutes)}:${pad(seconds)}`);
        }, 1000);
      };

      startTimer();
    }

    return () => clearInterval(interval);
  }, [session?.sessionStartTime]);

  const pad = (num) => num.toString().padStart(2, '0');

  return (
    <div
      className={` shadow-2xl shadow-white w-full md:w-1/2 lg:w-1/4 h-[80px] bg-[#1d1d1d] rounded-sm  fixed bottom-0 right-0 z-40`}
    >
      <div className="flex items-center gap-4">
        {imageUrl && (
          <div
            className="w-[100px] h-[80px] bg-cover bg-center"
            style={{
              backgroundImage: `url(${imageUrl})`,
            }}
          ></div>
        )}
        <div className={` flex-1 min-w-0 ${!imageUrl ? 'ml-4' : ''}`}>
          <h6 className="text-md font-bold text-white">
            {selectedStrain?.title}
          </h6>
          <p className="text-sm  text-white font-normal text-ellipsis overflow-hidden whitespace-nowrap flex gap-1">
            {session?.selectedMethod?.map((method, index) => (
              <span key={index}>
                {method}
                {index < session?.selectedMethod.length - 1 && ','}
              </span>
            ))}
          </p>
        </div>
        <div className="pr-2 space-y-1 flex flex-col justify-center items-center w-[80px] ">
          <p className="text-center font-extrabold text-white  ">{timer}</p>
          <button
            onClick={() => navigate('/sessions-review')}
            className=" rounded-full w-8 h-8 p-2 text-white text-xl font-bold bg-[#62AF41] hover:bg-[#4c8633]"
          >
            <LiaExternalLinkAltSolid size={18} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default PendingSession;
