import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  session: null,
  loading: false,
};

const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    startSession: (state, action) => {
      state.session = action.payload;
    },
    updateSession: (state, action) => {
      state.session = { ...state.session, ...action.payload };
    },
    endSession: (state) => {
      state.session = null;
    },

    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const { startSession, updateSession, endSession, setLoading } =
  sessionSlice.actions;
export default sessionSlice.reducer;
