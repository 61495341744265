import { styled, Pagination } from '@mui/material';

export const CustomPagination = styled(Pagination)(({ theme }) => ({
  '& .Mui-selected': {
    backgroundColor: '#62AF41 !important',
    color: '#FFFFFF !important',
  },
  '& .MuiPaginationItem-root': {
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: 'rgba(76, 175, 80, 0.1)',
    },
  },
  '& .MuiPaginationItem-ellipsis': {
    color: '#FFFFFF',
  },
}));
