import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import {
  Star,
  EffectItem,
  FlavourItem,
  CheckboxItem,
} from '../StrainReviews/Effects';
import { AccordionSection } from '../StrainReviews/AccordionSection';
import { FaPlus, FaUserEdit } from 'react-icons/fa';
import { IoArrowBack } from 'react-icons/io5';
import {
  conditionsList,
  effectsList,
  flavorsList,
} from '../CommonComponent/CommonData/CommonData';
import { getStrainsById } from '../../features/strain/strainService';
import { enqueueSnackbar } from 'notistack';
import { addReview } from '../../features/review/reveiwService';

// Review Detail Main function
const ReviewDetail = () => {
  // hooks
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const returnPath = location.state?.returnPath;

  // localState
  const [Data, setData] = useState({});
  const [method, setMethod] = useState('');
  const [selectedEffects, setSelectedEffects] = useState([]);
  const [flavorAndSmell, setFlavorAndSmell] = useState([]);
  const [selectedHelpWith, setSelectedHelpWith] = useState([]);
  const [isExpandedFlavor, setIsExpandedFlavor] = useState(false);
  const [isExpandedHelpWith, setIsExpandedHelpWith] = useState(false);
  const [displayItemCount, setDisplayItemCount] = useState(getDisplayCount());
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [reviewData, setReviewData] = useState({
    rating: 0,
    method: '',
    effects: [],
    flavor: [],
    helpsWith: [],
    additionalThoughts: '',
  });

  const [customEffects, setCustomEffects] = useState([]);
  const [customEffectInput, setCustomEffectInput] = useState('');
  const [customFlavors, setCustomFlavors] = useState([]);
  const [customFlavorInput, setCustomFlavorInput] = useState('');

  // get single strain
  const getData = useCallback(async (id) => {
    try {
      const res = await getStrainsById(id);
      if (res) {
        setData(res?.strain);
      }
    } catch (error) {
      enqueueSnackbar(error?.message, { variant: 'error' });
    }
  }, []);

  useEffect(() => {
    getData(id);
  }, [getData, id]);

  // Function to update the star rating
  const handleStarClick = (index) => {
    setReviewData({ ...reviewData, rating: index });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // Function to handle the method check boxes
  const handleMethodChange = (newMethod) => {
    setMethod(newMethod);
    setReviewData({ ...reviewData, method: newMethod });
  };

  // Function to handle changes in the textarea
  const handleAdditionalThoughtsChange = (event) => {
    setReviewData({ ...reviewData, additionalThoughts: event.target.value });
  };

  // Toggle function for expand/collapse for Flavor
  const toggleExpandForFlavor = () => {
    setIsExpandedFlavor(!isExpandedFlavor);
  };

  // Toggle function for expand/collapse for Helps With
  const toggleExpandForHelpsWith = () => {
    setIsExpandedHelpWith(!isExpandedHelpWith);
  };

  // Hnadler for going back to previous page
  const handleGoBack = () => {
    // navigate(-1);
    navigate(returnPath);
  };

  function getDisplayCount() {
    return window.innerWidth >= 1024 ? 21 : 7;
  }

  // Handler for effect selection
  const handleSelectEffect = (effect) => {
    let updatedSelectedEffects;
    if (selectedEffects.includes(effect)) {
      updatedSelectedEffects = selectedEffects.filter((e) => e !== effect);
    } else if (selectedEffects.length < 4) {
      updatedSelectedEffects = [...selectedEffects, effect];
    } else {
      // If we already have 4 effects, we don't add more
      return;
    }
    setSelectedEffects(updatedSelectedEffects);
    // Update reviewData state as well if needed
    setReviewData({ ...reviewData, effects: updatedSelectedEffects });
  };

  // Handler for flavour selection
  const handleSelectFlavor = (flavor) => {
    let updatedSelectedFlavors;
    if (flavorAndSmell.includes(flavor)) {
      updatedSelectedFlavors = flavorAndSmell.filter((e) => e !== flavor);
    } else if (flavorAndSmell.length < 4) {
      updatedSelectedFlavors = [...flavorAndSmell, flavor];
    } else {
      // If we already have 4 effects, we don't add more
      return;
    }
    setFlavorAndSmell(updatedSelectedFlavors);
    // Update reviewData state as well if needed
    setReviewData({ ...reviewData, flavor: updatedSelectedFlavors });
  };

  // Handler for HelpWith selection
  const handleSelectHelpsWith = (helpWith) => {
    let updatedSelectedHelpWith;
    if (selectedHelpWith.includes(helpWith)) {
      updatedSelectedHelpWith = selectedHelpWith.filter((e) => e !== helpWith);
    } else if (selectedHelpWith.length < 4) {
      updatedSelectedHelpWith = [...selectedHelpWith, helpWith];
    } else {
      // If we already have 4 effects, we don't add more
      return;
    }
    setSelectedHelpWith(updatedSelectedHelpWith);
    // Update reviewData state as well if needed
    setReviewData({ ...reviewData, helpsWith: updatedSelectedHelpWith });
  };

  const handleAddCustomEffect = () => {
    if (customEffectInput.trim() !== '') {
      const newCustomEffects = [
        ...customEffects,
        { name: customEffectInput, icon: FaUserEdit },
      ];
      setCustomEffects(newCustomEffects);
      setReviewData({
        ...reviewData,
        effects: [...reviewData.effects, customEffectInput],
      });
      setCustomEffectInput('');
    }
  };

  // Handler for adding custom flavors
  const handleAddCustomFlavor = () => {
    if (customFlavorInput.trim() !== '') {
      const newCustomFlavors = [
        ...customFlavors,
        { name: customFlavorInput, icon: FaUserEdit },
      ];
      setCustomFlavors(newCustomFlavors);
      setReviewData({
        ...reviewData,
        flavor: [...reviewData.flavor, customFlavorInput],
      });
      setCustomFlavorInput('');
    }
  };

  // Submit button's call and toast messages here
  const submitReview = async () => {
    setIsSubmitting(true);
    try {
      const res = await addReview({
        itemType: 'Strain',
        itemId: id,
        ...reviewData,
      });
      if (res) {
        navigate(-1);
        setIsSubmitting(false);
        enqueueSnackbar(res?.message, { variant: 'success' });
      }
    } catch (error) {
      enqueueSnackbar(error?.message, { variant: 'error' });
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    function handleResize() {
      setDisplayItemCount(getDisplayCount());
    }

    window.addEventListener('resize', handleResize);

    // Cleanup
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="mb-20 px-4 flex flex-col gap-6 md:w-[40%] mx-auto">
      <div className="text-[#7cdb54] text-sm  underline  flex items-center">
        <IoArrowBack
          size="1.2em"
          style={{ marginRight: '4px', marginTop: '1px' }}
        />
        <button onClick={handleGoBack}>Back to {Data?.title}</button>
      </div>
      <div className="flex items-center">
        <img
          src={Data?.strainImg?.url}
          alt={Data?.title}
          className="rounded-full w-10 h-10 lg:w-14 lg:h-14"
        />
        <h2 className="text-white text-lg ml-2 lg:font-bold">{Data?.title}</h2>
      </div>
      <div className="flex flex-col justify-start  gap-4 ">
        <div className="bg-[#4b4646] p-4 shadow-md flex flex-col items-center justify-center rounded ">
          <p className="text-white text-lg text-center">
            How many stars do you give it?
          </p>
          <div className="flex justify-center text-white text-3xl mt-1 xs:gap-2 lg:gap-2">
            {[1, 2, 3, 4, 5].map((index) => (
              <Star
                key={index}
                selected={index <= reviewData.rating}
                onSelect={() => handleStarClick(index)}
              />
            ))}
          </div>
        </div>

        <div className="bg-[#4b4646] p-4 shadow-md  flex flex-col items-center justify-center rounded ">
          <p className="text-white text-lg text-center">Consumption Method</p>
          <div className="grid grid-cols-2 gap-4 w-full mt-2 lg:ml-4">
            {['Flower', 'Concentrate', 'Edible', 'Topical'].map((item) => (
              <label
                key={item}
                className="flex items-center space-x-2 text-white"
              >
                <input
                  type="radio"
                  name="method"
                  value={item}
                  checked={method === item}
                  onChange={() => handleMethodChange(item)}
                  className="form-radio h-5 w-5"
                />
                <span>{item}</span>
              </label>
            ))}
          </div>
        </div>
      </div>

      <div className="">
        <AccordionSection
          title="Which effects did you feel?"
          defaultOpen={true}
          count={selectedEffects.length}
        >
          {/* Render the effects with selection functionality */}
          <div className="grid grid-cols-3 lg:grid-cols-6 gap-4">
            {effectsList.map((effect) => (
              <EffectItem
                key={effect.name}
                effectName={effect.name}
                effectIcon={effect.icon}
                isMaxSelected={selectedEffects.length >= 4}
                onSelect={handleSelectEffect}
                isSelected={selectedEffects.includes(effect.name)}
              />
            ))}
            {customEffects.map((effect, index) => (
              <EffectItem
                key={`custom-${index}`}
                effectName={effect.name}
                effectIcon={effect.icon}
                isMaxSelected={selectedEffects.length >= 4}
                onSelect={handleSelectEffect}
                isSelected={selectedEffects.includes(effect.name)}
              />
            ))}
          </div>
          <div className="mt-4 flex justify-center items-center">
            <input
              type="text"
              value={customEffectInput}
              onChange={(e) => setCustomEffectInput(e.target.value)}
              className="bg-[#4b4646] p-3 lg:p-4 text-white rounded-l-md focus:outline-none"
              placeholder="Add effect"
            />
            <button
              onClick={handleAddCustomEffect}
              className="bg-[#62AF41] hover:bg-[#4c8633] p-4 lg:p-5 rounded-r-md text-white"
            >
              <FaPlus />
            </button>
          </div>
        </AccordionSection>

        <AccordionSection
          title="Describe the flavor & smell?"
          defaultOpen={false}
          count={flavorAndSmell.length}
        >
          <div className="grid grid-cols-3 lg:grid-cols-6 gap-4">
            {flavorsList
              .slice(
                0,
                isExpandedFlavor
                  ? flavorsList.length
                  : window.innerWidth >= 1024
                  ? 18
                  : 7
              )
              .map((flavor) => (
                <FlavourItem
                  key={flavor.name}
                  flavorName={flavor.name}
                  flavorIcon={flavor.icon}
                  isMaxSelected={flavorAndSmell.length >= 4}
                  onSelect={handleSelectFlavor}
                  isSelected={flavorAndSmell.includes(flavor.name)}
                />
              ))}
            {customFlavors.map((flavor, index) => (
              <FlavourItem
                key={`custom-${index}`}
                flavorName={flavor.name}
                flavorIcon={flavor.icon}
                isMaxSelected={flavorAndSmell.length >= 4}
                onSelect={handleSelectFlavor}
                isSelected={flavorAndSmell.includes(flavor.name)}
              />
            ))}
          </div>
          <div className="mt-4 flex justify-center items-center">
            <input
              type="text"
              value={customFlavorInput}
              onChange={(e) => setCustomFlavorInput(e.target.value)}
              className="bg-[#4b4646] p-3 lg:p-4 text-white rounded-l-md focus:outline-none"
              placeholder="Add flavor"
            />
            <button
              onClick={handleAddCustomFlavor}
              className="bg-[#62AF41] hover:bg-[#4c8633] p-4 lg:p-5 rounded-r-md text-white"
            >
              <FaPlus />
            </button>
          </div>
          <button
            onClick={toggleExpandForFlavor}
            className="mt-2 text-[#62AF41]"
          >
            {isExpandedFlavor ? 'Collapse all' : 'Expand all'}
          </button>
        </AccordionSection>

        {/* Accordion for 'Helps with?' */}
        <AccordionSection
          title="Helps with?"
          defaultOpen={false}
          count={selectedHelpWith.length}
        >
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-2">
            {conditionsList
              .slice(
                0,
                isExpandedHelpWith ? conditionsList.length : displayItemCount
              )
              .map((condition) => (
                <CheckboxItem
                  key={condition}
                  itemName={condition}
                  isMaxSelected={selectedHelpWith.length >= 4}
                  onSelect={handleSelectHelpsWith}
                  isSelected={selectedHelpWith.includes(condition)}
                />
              ))}
          </div>
          <button
            onClick={toggleExpandForHelpsWith}
            className="mt-2 text-[#62AF41]"
          >
            {isExpandedHelpWith ? 'Collapse all' : 'Expand all'}
          </button>
        </AccordionSection>

        <hr className="border-t border-gray-500 " />
        <div className="shadow-md rounded py-2">
          <h2 className="text-white font-bold text-lg mb-2">
            Any additional thoughts?
          </h2>
          <p className="text-gray-300 text-sm mb-4">
            Your insights contribute to our community's understanding and
            connection with this remarkable plant.
          </p>
          <textarea
            className="bg-[#ffffff] w-full p-2 lg:p-4 lg:h-52 text-black border lg:border-b-2 rounded-lg focus:outline-none lg:border-black lg:mb-4"
            rows="6"
            placeholder="Share your experience"
            value={reviewData.additionalThoughts}
            onChange={handleAdditionalThoughtsChange}
          />
        </div>

        {/* Submit Button */}
        <div className="flex justify-center lg:justify-start lg:mt-0 lg:ml-80">
          <button
            type="Button"
            className={`w-82 rounded-full py-2 text-white text-xl font-bold ${
              isSubmitting
                ? 'bg-gray-400 cursor-not-allowed'
                : 'bg-[#62AF41] hover:bg-[#4c8633]'
            }`}
            onClick={submitReview}
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Submitting...' : 'Submit Review'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReviewDetail;
