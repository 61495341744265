import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import SearchBarDesktop from '@/common-components/search/SearchBarDesktop';
import logo from '@/Images/maryLogo.png';
import { Button } from '@/core/ui/button';
import { FiMenu } from 'react-icons/fi';

const UnAuthTopBar = ({ handleSidebarToggle, setStickyOffset }) => {
  //Hooks
  const location = useLocation();
  const navigate = useNavigate();

  // localState
  const [hidden, setHidden] = useState(false);
  let lastScrollY = 0;

  // sticky header up and down
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      setHidden(currentScrollY > lastScrollY && currentScrollY > 0);
      setStickyOffset(currentScrollY > lastScrollY && currentScrollY > 0);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      lastScrollY = currentScrollY;
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div
      className={`transition-transform duration-300 ease-in-out transform ${
        hidden && location.pathname === '/'
          ? '-translate-y-full'
          : 'translate-y-0 sticky top-0'
      } z-30 flex items-center justify-between bg-[#0f0f0f] py-2 px-1`}
    >
      <div
        className={`w-20 min-w-[77px] h-auto cursor-pointer `}
        onClick={() => navigate(`/`)}
      >
        <img className="w-full h-full" src={logo} alt="MaryApps Logo" />
      </div>
      <SearchBarDesktop />
      <div className=" flex items-center gap-2">
        <div className=" flex items-center gap-2">
          <Button
            variant="link"
            onClick={() => navigate('/login')}
            className=" bg-transparent text-white py-1 h-7 hover:bg-mary-theme outline-none border-none"
          >
            Sign in
          </Button>
          <Button
            variant="outline"
            onClick={() => navigate('/signup')}
            className=" bg-mary-theme text-white py-1 h-7 hover:bg-mary-theme outline-none border-none"
          >
            Create account
          </Button>
        </div>
        <button className="flex items-center" onClick={handleSidebarToggle}>
          <FiMenu color="#ffffff" size={30} />
        </button>
      </div>
    </div>
  );
};

export default UnAuthTopBar;
