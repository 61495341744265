import { baseApi } from './baseApi';

export const searchApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        // Search suggestions endpoint with query
        getSearchSuggestions: builder.query({
            query: (searchQuery) => ({
                url: `search-suggestions?query=${searchQuery}`,
                method: 'GET',
            }),
        }),

        getSearchAiSuggestions: builder.query({
            query: (searchQuery) => ({
                url: `search-ai-suggestions?query=${searchQuery}`,
                method: 'GET',

            })
        }),
        getSearchResult: builder.query({
            query: (searchQuery) => ({
                url: `search-result?query=${searchQuery}`,
                method: "GET",
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
            }),

        })
    }),
});

export const { useGetSearchSuggestionsQuery, useGetSearchAiSuggestionsQuery, useGetSearchResultQuery } = searchApi;
