import React from 'react';

const Select = ({ value, options, multiple, handleChange, HelpfullText }) => {
  return (
    <select
      multiple={multiple ? multiple : false}
      onChange={(e) => handleChange(e)}
      value={value}
      className="p-2 mt-2 bg-white border border-gray-300 rounded-md shadow-sm text-black w-full "
    >
      <option className=" w-full" value="" disabled>
        {HelpfullText ? HelpfullText : 'Select'}
      </option>

      {options?.map((option, index) => (
        <option key={index} className=" w-full" value={option?.value}>
          {option?.name}
        </option>
      ))}
    </select>
  );
};

export default Select;
