import axios from 'axios';
import { config } from '../../utils/axiosconfig';
import { base_url } from '../../utils/base_url';

const createFeed = async (data) => {
    const configure = config();
    const response = await axios.post(`${base_url}feeds/`, data, configure);
    return response.data;
};

const updateFeed = async (feed) => {
    const configure = config();
    const response = await axios.put(`${base_url}feeds/${feed.id}`,
        {
            announcement: feed.feedData.announcement,
            announcementDate: feed.feedData.announcementDate,
            feedImg: feed.feedData.feedImg,
        }, configure);
    return response.data;

};

const deleteFeed = async (id) => {
    const configure = config();
    const response = await axios.delete(`${base_url}feeds/${id}`, configure);
    return response.data;
};

const uploadFeedImg = async (data) => {
    const configure = config();
    const response = await axios.put(`${base_url}upload/`, data, configure);
    return response.data;
};

const deleteFeedImg = async (id) => {
    const configure = config();
    const response = await axios.delete(`${base_url}upload/delete-image/${id}`, configure);
    return response.data;
};

export const getFeeds = async () => {
    const configure = config();
    const response = await axios.get(`${base_url}feeds/`, configure);
    return response.data;
};

const getFeed = async (id) => {
    const configure = config();
    const response = await axios.get(`${base_url}feeds/${id}`, configure);
    return response.data;
};

const feedService = {
    createFeed,
    updateFeed,
    deleteFeed,
    uploadFeedImg,
    deleteFeedImg,
    getFeeds,
    getFeed
};

export default feedService;