import { React, useEffect } from 'react'
// import BreadCrums from '../components/BreadCrums/BreadCrums'
import Meta from '../components/SEO/Meta'
import StrainItems from '../components/StrainCart/StrainItems'
import TotalItems from '../components/StrainCart/TotalItems'
import { Link, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from "react-redux";

import { fetchUserCart } from '../features/cart/cartSlice';



const AddToCart = () => {
    const location = useLocation()
    const dispatch = useDispatch()

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        if (location.pathname === '/cart') {
        // Dispatch the fetchUserCart action whenever the component mounts
        // or the URL changes to '/cart'.
        dispatch(fetchUserCart());
    }
  }, []);

    const cartItems = useSelector((state) => state.cart.items);
    return (
        <>
            {/* <BreadCrums
                title2={"Cart"}
            /> */}
            <Meta
                title={"Strain Cart"}
            />
            <div className=''>
                <div className='w-full h-screen bg-white border rounded-3xl'>
                    <div className='grid grid-cols-5 '>
                        <span className='text-center text-black font-bold'>
                            Product
                        </span>
                        <span className='text-center text-black font-bold'>
                            Title
                        </span>
                        <span className='text-center text-black font-bold'>
                            Price
                        </span>
                        <span className='text-center text-black font-bold'>
                            Quantity
                        </span>
                        <span className='text-center text-black font-bold'>
                            Delete
                        </span>
                    </div>
                    <div className="flex items-center justify-center my-4 ">
                        <hr className="w-[90%] border-gray-300" />
                    </div>

                    {/* Cart Items */}

                    {cartItems.map((item) => (
                    <StrainItems
                        key={item.id}
                        strainId={item.id} // Assuming each item has a unique strainId
                        strainTitle={item.title}
                        strainPrice={"200$"} // You might want to get this from item as well
                        // StrainTotalPrice={"200$"} // Likewise for this one
                    />
          ))}
                    {/* Total Price  */}

                    <div className='flex flex-row-reverse w-full  mt-10'>
                        <div className='flex flex-col justify-end lg:w-1/5 mr-[11%] md:w-1/3 xs:w-1/2'>
                            <TotalItems
                                total={"Subtotal:"}
                                price={"$200"}
                            />
                            <TotalItems
                                total={"Grandtotal:"}
                                price={"$200"}
                            />
                            <div className='grid  grid-cols-1 gap-2'>
                               <Link to="/">
                               <button className='bg-[#253238] text-white w-[100%] py-2 hover:bg-white border-2 border-[#253238] hover:text-[#253238] hover:border-2 hover:border-[#253238] '>
                                    Continue Shopping
                                </button>
                               </Link>
                                <button className='bg-[#253238] text-white w-[100%] py-2 border-2 border-[#253238]  hover:bg-white hover:text-[#253238] hover:border-2 hover:border-[#253238]'>
                                    Checkout
                                </button>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </>
    )
}

export default AddToCart
