import axios from 'axios'
import { config } from '../../utils/axiosconfig';
import { base_url } from '../../utils/base_url';

const configure = config()

export const getProductByCategory = async (data) => {
    const response = await axios.get(`${base_url}product/products-by-category`, data, configure)
    return response.data
}


const createProduct = async (data) => {
    const response = await axios.post(`${base_url}product/`, data, configure)

    return response.data
}
const deleteProduct = async (id) => {
    const response = await axios.delete(`${base_url}product/${id}`, configure)

    return response.data
}
const updateProduct = async (product) => {

    const response = await axios.put(`${base_url}product/${product.id}`,
        {
            title: product.productData.title,
            productDescription: product.productData.productDescription,
            price: product.productData.price,
            weight: product.productData.weight,
            category: product.productData.category,
            thc_percentage: product.productData.thc_percentage,
            cbd_percentage: product.productData.cbd_percentage,
            terpenes: product.productData.terpenes,
            variants: product.productData.variants,
            brand: product.productData.brand,
            strain: product.productData.strain,
            productImg: product.productData.productImg
        }
        , configure)

    return response.data
}
const uploadProductImg = async (data) => {
    const response = await axios.put(`${base_url}upload/`, data, configure)

    return response.data
}
const deleteProductImg = async (id) => {
    const response = await axios.delete(`${base_url}upload/delete-image/${id}`, configure)

    return response.data
}
export const getProducts = async (page, FillterOption) => {
    const response = await axios.get(`${base_url}product?page=${page ? page : 1}&limit=${20}&category=${FillterOption ? FillterOption : ""}`)
    return response.data
}
export const getAProduct = async (id) => {
    const response = await axios.get(`${base_url}product/${id}`, configure)
    return response.data
}

// Add new logic for reviews
export const addReviewToProduct = async (reviewData) => {
    const configure = config();
    const response = await axios.post(`${base_url}review`, reviewData, configure);
    return response.data;
};

// Add new logic for reviews
export const getProductsByCategory = async (brandId, category, page) => {
    const configure = config();

    const response = await axios.get(`${base_url}business/brand-products-by-category?brandId=${brandId}&categoryName=${category}&page=${page}&limit=20`, configure);
    return response.data;
};

// Delete review
const deleteReview = async (productId, reviewId) => {
    const configure = config();

    try {
        const response = await axios.delete(`${base_url}product/${productId}/reviews/${reviewId}`, configure);
        return response.data;
    } catch (error) {
        console.error('Error deleting the review: ', error.response || error.message);
        throw error;
    }
}
const productService = {
    createProduct,
    updateProduct,
    deleteProduct,
    getAProduct,
    uploadProductImg,
    deleteProductImg,
    getProducts,
    addReviewToProduct,
    deleteReview,
};
export default productService