import React, { useRef, useState } from 'react';

import { deleteSession } from '../../features/sessions/SessionServices';
import { useSnackbar } from 'notistack';
import { BsThreeDots } from 'react-icons/bs';
import SessionCardModal from '../CommonComponent/SessionCardModal';
import SocialShareModal from '../StrainDetail/SocialShare';
import CustomMultiRangeSlider from '../CommonComponent/multiRangeSlider/CustomMultiRangeSlider';
import { FaCopy, FaShareSquare, FaTrashAlt } from 'react-icons/fa';
import CustomRangeSlider from '../CommonComponent/multiRangeSlider/CustomRangeSlider';
import html2canvas from 'html2canvas';
import { MdDownload } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { startSession } from '../../features/sessions/sessionsSlice';
import {
  EffectIcon,
  FlavourIcon,
  HelpsWithText,
} from '../CommonComponent/CommonData/CommonData';
import { format, addMinutes } from 'date-fns';

const SessionCard = ({ cardData, isUpdate, setisUpdate }) => {
  // Hooks
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const cardRef = useRef();
  // eslint-disable-next-line no-unused-vars

  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [isActionModalOpen, setIsActionModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleDuplicate = async (item) => {
    const {
      consumingQty,
      product,
      consumingPlace,
      reviewType,
      selectedItem,
      consumingWith,
      selectedMethod,
      strain,
      useReason,
      consumingUnit,
      selectedBussines,
      selectedFolder,
      userAdded,
    } = item;

    const newId =
      selectedItem === 'UserAdded'
        ? ''
        : selectedItem === 'Product'
        ? product?._id
        : selectedItem === 'Stash'
        ? product?._id || strain._id
        : '';

    dispatch(
      startSession({
        selectedProduct: newId,

        consumingPlace,
        reviewType,
        selectedItem,
        selectedBussines,
        consumingWith,
        selectedMethod,
        strain: strain?._id,
        useReason: useReason.map((item) => ({ ...item, endLevel: 0 })),
        consumingQty,
        consumingUnit,
        selectedFolder,
        userAdded: userAdded?._id,
      })
    );
    enqueueSnackbar('Session duplicate successfully', { variant: 'success' });
    navigate('/sessions-review');
  };

  const handleDelete = async (sessionId) => {
    try {
      const res = await deleteSession(sessionId);

      setisUpdate(!isUpdate);
      enqueueSnackbar(res?.message, {
        variant: 'success',
      });
      setIsActionModalOpen(false);
    } catch (error) {
      enqueueSnackbar(error?.message, {
        variant: 'error',
      });
      setIsActionModalOpen(false);
    }
  };

  const handleShare = () => {
    setIsShareModalOpen(true);
  };

  const toggleShareModal = () => {
    setIsShareModalOpen(!isShareModalOpen);
  };

  function myRenderFunction(canvas) {
    var a = document.createElement('a');
    a.href = canvas
      .toDataURL('image/jpeg')
      .replace('image/jpeg', 'image/octet-stream');
    a.download = 'sessionCard.jpg';
    a.click();
  }

  const handleDownload = () => {
    if (cardRef.current === null) {
      return;
    }
    setLoading(true);
    cardRef.current.style.backgroundColor = '#1d1d1d';
    document.fonts.ready.then(() => {
      html2canvas(cardRef.current, { useCORS: true })
        .then((canvas) => {
          cardRef.current.style.backgroundColor = '';
          myRenderFunction(canvas);
          setLoading(false);
        })
        .catch((err) => {
          console.error('Oops, something went wrong!', err);
          cardRef.current.style.backgroundColor = '';
          setLoading(false);
        });
    });
  };

  const shareUrl = `https://maryapp.io/sessions/`;

  return (
    <div
      className="bg-[#1d1d1d] flex flex-col gap-3 p-2 rounded-md"
      ref={cardRef}
    >
      <div className="flex justify-between items-center relative">
        <div>
          <h6 className="text-sm">
            {format(new Date(cardData?.createdAt), 'MMM, do yy')}
          </h6>
          <h6 className="text-sm">
            {format(
              addMinutes(
                new Date(cardData?.createdAt),
                -new Date(cardData?.createdAt).getTimezoneOffset()
              ),
              'h:mm a'
            )}
          </h6>
        </div>
        <div>
          <div>
            <div onClick={() => setIsActionModalOpen(true)}>
              <BsThreeDots size={28} className="text-gray-300 cursor-pointer" />
            </div>
            <SessionCardModal
              isOpen={isActionModalOpen}
              onRequestClose={() => setIsActionModalOpen(false)}
            >
              <div className=" p-6 rounded-lg w-72 text-white flex  flex-col gap-2">
                <button
                  onClick={() => handleDuplicate(cardData)}
                  className="flex items-center justify-center w-full py-2  bg-green-700 text-white rounded hover:bg-green-600"
                >
                  <FaCopy className="mr-2" />
                  Duplicate
                </button>

                <button
                  onClick={() => handleDelete(cardData?._id)}
                  className="flex items-center justify-center w-full py-2  bg-red-600 text-white rounded hover:bg-red-500"
                >
                  <FaTrashAlt className="mr-2" />
                  Delete
                </button>

                <button
                  onClick={handleShare}
                  className="flex items-center justify-center w-full py-2 bg-blue-600 text-white rounded hover:bg-blue-500"
                >
                  <FaShareSquare className="mr-2" />
                  Share
                </button>
                <button
                  onClick={!loading && handleDownload}
                  className="flex items-center justify-center w-full py-2  bg-black text-white rounded hover:bg-green-600"
                >
                  <MdDownload className="mr-2" size={22} />
                  {loading ? 'Loading...' : 'Download'}
                </button>
              </div>
            </SessionCardModal>
            <SocialShareModal
              url={`${shareUrl}${cardData._id}`}
              title={
                cardData?.strain
                  ? cardData?.strain?.title
                  : cardData?.product?.title
              }
              strainImg={
                cardData?.strain
                  ? cardData?.strain?.strainImg?.url
                  : cardData?.product?.productImg?.url
              }
              type={cardData.type}
              onClose={toggleShareModal}
              isOpen={isShareModalOpen} // Ensure the share modal opens only when this state is true
              des={'Share this session'}
            />
          </div>
        </div>
      </div>

      <div className="w-full pl-1 flex overflow-x-auto justify-between items-center rounded-md gap-2">
        <div className="flex flex-col gap-1 justify-center overflow-hidden rounded-sm">
          <h4>
            {cardData?.strain
              ? cardData?.strain?.title
              : cardData?.product
              ? cardData?.product?.title
              : cardData?.userAdded?.title}
          </h4>
          {(cardData?.strain || cardData?.userAdded?.strainFields) && (
            <>
              {cardData?.strain && (
                <h5>
                  {cardData?.strain?.mother?.title} x{' '}
                  {cardData?.strain?.father?.title}
                </h5>
              )}
              {cardData?.userAdded?.strainFields && (
                <h5>
                  {cardData?.userAdded?.strainFields?.mother} x{' '}
                  {cardData?.userAdded?.strainFields?.father}
                </h5>
              )}
            </>
          )}
          <h6 className="text-xs">
            {cardData?.strain
              ? cardData?.strain?.type
              : cardData?.product?.category[0]}
          </h6>
        </div>
        {!cardData?.userAdded ? (
          <div className="h-full w-20 overflow-hidden">
            <img
              src={
                cardData?.strain
                  ? cardData?.strain?.strainImg?.url
                  : cardData?.product?.productImg?.url
              }
              alt="productImage"
            />
          </div>
        ) : (
          <></>
        )}
      </div>
      <div>
        <div>
          <hr />
          <div className="grid grid-cols-4 md:grid-cols-4 lg:grid-cols-6 gap-4 py-4">
            {cardData?.selectedMethod?.map((item, index) => (
              <span key={index}>{item}</span>
            ))}
          </div>
          <hr />
          <div className="py-3">
            {cardData?.consumingQty} {cardData?.consumingUnit}
          </div>
          <hr />
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 py-2">
            {cardData?.useReason?.map((item, index) => (
              <div
                key={index}
                className="flex justify-center items-center gap-4 "
              >
                <HelpsWithText key={index} helpWith={item?.reason} />

                <CustomMultiRangeSlider
                  value1={item?.startLevel}
                  value2={item?.endLevel}
                  min={0}
                  max={10}
                  readOnly={true}
                />
              </div>
            ))}
          </div>
          <hr />
          <div className="grid grid-cols-3 py-4 gap-4">
            {cardData?.consumingPlace?.map((item, index) => (
              <div key={index}>{item}</div>
            ))}
          </div>
          <hr />
          <div className="grid grid-cols-3 py-4 gap-4">
            {cardData?.consumingWith?.map((item, index) => (
              <div key={index}>{item}</div>
            ))}
          </div>
          <hr />
          <div className="py-4">
            <div className=" w-full flex flex-col ">
              <h4 className=" text-white">Overall potency</h4>
              <CustomRangeSlider
                value={cardData?.intendedUse}
                min={1}
                max={10}
                readOnly={true}
                // onChange={handleRangeChange}
              />
            </div>
          </div>

          <hr />
          <div className="grid grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-4 py-4">
            {cardData?.effects?.map((effect) => (
              <EffectIcon key={effect} effect={effect} />
            ))}
          </div>
          <hr />
          <div className="grid grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-4 py-4">
            {cardData?.flavors?.map((flavor) => (
              <FlavourIcon key={flavor} flavor={flavor} />
            ))}
          </div>
          <hr />
          <div className="my-5">
            {/* <MultiRangeSlider min={0} max={10} /> */}
          </div>
          <div className="py-2">
            <p>
              {cardData?.additionalThoughts !== ''
                ? cardData?.additionalThoughts
                : 'No additional thoughts'}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SessionCard;
