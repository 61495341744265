import React, { useMemo, useState } from 'react';
import { IoPersonRemove } from 'react-icons/io5';
import { MdNotificationsActive, MdNotificationsOff } from 'react-icons/md';
import { CircularProgress } from '@mui/material';
import { toggleBusinessNotification } from '../../features/addBusiness/addBusinessService';
import { enqueueSnackbar } from 'notistack';

const SubscribeDropdown = ({
  OnUpdate,
  loader,
  isSubscribed,
  onUnsubscribe,
  onSubscribe,
  isNotificationEnbale,
  businessType,
  businessId,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const notificationEnable = useMemo(
    () => isNotificationEnbale?.find((item) => item === isSubscribed),
    [isNotificationEnbale, isSubscribed]
  );

  const handleToggleNotifications = async (
    businessType,
    businessId,
    enable
  ) => {
    try {
      setIsOpen(false);
      const res = await toggleBusinessNotification(
        businessType,
        businessId,
        enable
      );
      if (res) {
        OnUpdate();
        enqueueSnackbar(res?.message, { variant: 'success' });
      }
    } catch (err) {
      enqueueSnackbar(err?.message, { variant: 'error' });
    }
  };

  const OpenNoficationPopup = (isSubscribed) => {
    if (isSubscribed) {
      setIsOpen(!isOpen);
    } else {
      onSubscribe();
    }
  };

  const handelUnsubscribe = () => {
    onUnsubscribe();
    setIsOpen(false);
  };

  return (
    <>
      {/* <StrainPopup isOpen={showPopup} onClose={() => setShowPopup(false)} /> */}
      <div className="relative inline-block text-center w-full">
        {loader && <CircularProgress color="success" size={20} />}
        {!loader && (
          <button
            onClick={() => OpenNoficationPopup(isSubscribed)}
            className={`rounded-3xl w-full h-full p-2 text-center text-sm font-semibold flex justify-center items-center ${
              isSubscribed
                ? 'bg-[#262626] hover:bg-[#3f3f3f] text-white'
                : 'bg-[#d9d9d9] hover:bg-[#777777] text-black'
            }`}
          >
            {isSubscribed ? (
              notificationEnable ? (
                <>
                  <MdNotificationsActive className="w-6 h-6 text-white mr-2" />
                  Subscribed
                </>
              ) : (
                <>
                  <MdNotificationsOff className="w-6 h-6 text-white mr-2" />
                  Subscribed
                </>
              )
            ) : (
              'Subscribe'
            )}
            {isSubscribed && (
              <svg
                className="w-4 h-4 ml-2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            )}
          </button>
        )}
        {isOpen && (
          <div
            className="absolute right-0 z-20 w-44 md:w-36 origin-top-right rounded-md shadow-lg bg-[#3F3F3F] ring-1 ring-black ring-opacity-5 focus:outline-none"
            style={{ right: '50%', transform: 'translateX(50%)' }}
          >
            <div
              className="py-1"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="options-menu"
            >
              <button
                onClick={() =>
                  handleToggleNotifications(businessType, businessId, true)
                }
                className="w-full text-left p-2 text-white hover:bg-gray-100 hover:text-gray-900 flex justify-start items-start gap-2"
                role="menuitem"
              >
                <MdNotificationsActive className="w-6 h-6 text-white mr-2" />
                All
              </button>
              <button
                onClick={() =>
                  handleToggleNotifications(businessType, businessId, false)
                }
                className="w-full text-left p-2 text-white hover:bg-gray-100 hover:text-gray-900 flex justify-start items-start gap-2"
                role="menuitem"
              >
                <MdNotificationsOff className="w-6 h-6 text-white mr-2" />
                None
              </button>
              <button
                onClick={handelUnsubscribe}
                className="w-full text-left p-2 text-white hover:bg-gray-100 hover:text-gray-900 flex justify-start items-start gap-2"
                role="menuitem"
              >
                <IoPersonRemove className="mr-1 w-5 h-5 text-white" />{' '}
                Unsubscribe
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default SubscribeDropdown;
