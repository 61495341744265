import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FaPlus } from 'react-icons/fa6';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { getAllFolders } from '../features/stashFolder/folderSlice';
import CreateFolderModal from '../components/stash/CreateFolderModal';
import EditFolderModal from '../components/stash/EditFolderModal';
import FolderActionModal from '../components/stash/FolderActionModal';
import { Link } from 'react-router-dom';
import { FaLock, FaLockOpen } from 'react-icons/fa';
import placeHolderImage from '../Images/placeholderImage.png';
import { enqueueSnackbar } from 'notistack';
import {
  deleteFolder,
  updateFolder,
} from '../features/stashFolder/folderService';
import AddToStashPageLoader from '../skeleton/AddToStashPageLoader';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';

const AddToStash = () => {
  // hooks
  const dispatch = useDispatch();
  // global state
  const folders = useSelector((state) => state.folder.folders);
  const user = useSessionContext();
  const isLoading = useSelector((state) => state.folder.isLoading);

  const [loading, setLoading] = useState(true);
  const [newFolderModalOpen, setNewFolderModalOpen] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [actionModalOpen, setActionModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);

  useEffect(() => {
    dispatch(getAllFolders());
  }, [dispatch, isUpdate]);

  const handleEditFolder = (folder) => {
    setSelectedFolder(folder);
    setEditModalOpen(true);
  };

  const submitEditFolder = async (updatedFolder) => {
    console.log(updatedFolder, 'updatedFolder');
    setLoading(true);
    const folderData = {
      name: updatedFolder.name,
      description: updatedFolder.description,
    };
    try {
      const res = await updateFolder(updatedFolder._id, folderData);
      if (res) {
        setEditModalOpen(false);
        setIsUpdate(!isUpdate);
        setActionModalOpen(false);
        enqueueSnackbar(res?.message, { variant: 'success' });
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      enqueueSnackbar(error?.message, { variant: 'error' });
    }
  };

  const handleDeleteFolder = async (folderId) => {
    setLoading(true);
    try {
      const res = await deleteFolder(folderId);
      if (res) {
        setEditModalOpen(false);
        setIsUpdate(!isUpdate);
        setLoading(false);
        setActionModalOpen(false);
        enqueueSnackbar(res?.message, { variant: 'success' });
      }
    } catch (error) {
      setLoading(false);
      enqueueSnackbar(error?.message, { variant: 'error' });
    }
  };

  const openActionModal = (folder) => {
    setSelectedFolder(folder);
    setActionModalOpen(true);
  };

  const getLastImageUrl = (folder) => {
    const hasProducts = folder.products && folder.products.length > 0;
    const hasStrains = folder.strains && folder.strains.length > 0;

    if (hasProducts) {
      return folder.products[folder.products.length - 1]?.productImg?.url;
    } else if (hasStrains) {
      return folder.strains[folder.strains.length - 1]?.strainImg?.url;
    } else {
      return `${placeHolderImage}`;
    }
  };

  const calculateCounts = (folders) => {
    const productCount = folders.products ? folders.products.length : 0;
    const strainCount = folders.strains ? folders.strains.length : 0;
    const totalCount = productCount + strainCount;
    return totalCount;
  };

  return (
    <div className="flex flex-col justify-start items-start py-2 px-4 md:py-4 md:px-10 pb-20">
      {isLoading && <AddToStashPageLoader NumberOfCard={7} />}

      {!isLoading && (
        <>
          <div className="flex justify-between items-start w-full max-h-full pb-4">
            <div className="flex justify-start items-center gap-2">
              <div className="flex justify-start items-center text-white">
                <img
                  src={user?.userImg?.url}
                  alt="User"
                  className="w-8 h-8 rounded-full"
                />
              </div>

              <div className="flex justify-start items-center">
                <div className="text-white text-2xl">Your Stash</div>
              </div>
            </div>
            <div
              onClick={() => setNewFolderModalOpen(true)}
              className="flex justify-start items-center pt-1"
            >
              <FaPlus size={24} className="text-white items-center" />
            </div>
            {newFolderModalOpen && (
              <CreateFolderModal
                isOpen={newFolderModalOpen}
                onClose={() => setNewFolderModalOpen(false)}
                onUpdate={() => setIsUpdate(!isUpdate)}
              />
            )}
          </div>
          {folders?.map((folder) => {
            return (
              <div
                key={folder._id}
                className="flex justify-between items-center w-full pb-3"
              >
                <Link
                  to={`/stash-details/${folder._id}`}
                  className="flex justify-start items-center gap-2"
                >
                  <div>
                    <img
                      src={getLastImageUrl(folder)}
                      alt="Folder"
                      className="w-20 h-20"
                    />
                  </div>

                  <div className="flex flex-col justify-start items-start">
                    <div className="flex justify-start items-center text-white text-2xl">
                      {folder.name}
                    </div>
                    <div className="flex flex-row justify-start items-start w-full">
                      <div className="text-gray-500 text-sm pt-0.5 pr-2">
                        {folder.visibility === 'private' ? (
                          <FaLock />
                        ) : (
                          <FaLockOpen />
                        )}
                      </div>
                      <div className="text-gray-500 text-sm">
                        {calculateCounts(folder)} Items
                      </div>
                    </div>
                  </div>
                </Link>
                <div className="flex justify-start items-center">
                  <BsThreeDotsVertical
                    size={28}
                    className="text-white"
                    onClick={() => openActionModal(folder)}
                  />
                </div>
              </div>
            );
          })}
          {actionModalOpen && (
            <FolderActionModal
              // loading={loading}
              isOpen={actionModalOpen}
              onClose={() => setActionModalOpen(false)}
              folder={selectedFolder}
              onEdit={handleEditFolder}
              onDelete={handleDeleteFolder}
            />
          )}
          {editModalOpen && (
            <EditFolderModal
              loading={loading}
              isOpen={editModalOpen}
              onClose={() => setEditModalOpen(false)}
              folder={selectedFolder}
              editFolder={submitEditFolder}
            />
          )}
        </>
      )}
    </div>
  );
};

export default AddToStash;
