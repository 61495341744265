import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import strainService from "./strainService";






export const getStrains = createAsyncThunk(
    "strain/get-strains",
    async (thunkAPI) => {
        try {
            return await strainService.getStrains()
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)
export const getAStrain = createAsyncThunk(
    "strain/get-A-strain",
    async (id, thunkAPI) => {
        try {
            return await strainService.getAStrain(id)
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

export const getStrainsById = createAsyncThunk(
    "strain/get-strain-by-id",
    async (id, thunkAPI) => {
        try {
            return await strainService.getStrainsById(id)
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)
export const createStrain = createAsyncThunk(
    "strain/create-strains",
    async (strainsData, thunkAPI) => {
        try {
            return await strainService.createStrain(strainsData)
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)
export const createMotherStrain = createAsyncThunk(
    "strain/create-mother-strain",
    async (motherStrainData, thunkAPI) => {
        try {
            return await strainService.createMotherStrain(motherStrainData)
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)
export const createFatherStrain = createAsyncThunk(
    "strain/create-father-strain",
    async (fatherStrainData, thunkAPI) => {
        try {
            return await strainService.createFatherStrain(fatherStrainData)
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)
export const updateStrain = createAsyncThunk(
    "strain/update-strains",
    async (strain, thunkAPI) => {

        try {
            return await strainService.updateStrain(strain)
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)
export const deleteStrain = createAsyncThunk(
    "strain/delete-strains",
    async (id, thunkAPI) => {
        try {
            return await strainService.deleteStrain(id)
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)
export const getStrainsByVariant = createAsyncThunk(
    "strain/get-variants",
    async (thunkAPI) => {
        try {
            return await strainService.getSrainsByVariant()
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)
export const uploadStrainImg = createAsyncThunk(
    "strain/uploadImg",
    async (data, thunkAPI) => {
        try {
            const formData = new FormData();
            for (let i = 0; i < data.length; i++) {
                formData.append("image", data[i])
            }
            return await strainService.uploadStrainImg(formData)
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)
export const deleteStrainImg = createAsyncThunk(
    "strain/deleteImg",
    async (id, thunkAPI) => {
        try {
            return await strainService.deleteStrainImg(id)
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)
export const uploadStrainModalImg = createAsyncThunk(
    "strainModal/uploadImg",
    async (data, thunkAPI) => {
        try {
            const formData = new FormData();
            for (let i = 0; i < data.length; i++) {
                formData.append("image", data[i])
            }
            return await strainService.uploadStrainModalImg(formData)
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)
export const deleteStrainModalImg = createAsyncThunk(
    "strainModal/deleteImg",
    async (id, thunkAPI) => {
        try {
            return await strainService.deleteStrainModalImg(id)
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)
export const uploadMotherImg = createAsyncThunk(
    "mother-strain/uploadImg",
    async (data, thunkAPI) => {
        try {
            const formData = new FormData();
            for (let i = 0; i < data.length; i++) {
                formData.append("image", data[i])
            }
            return await strainService.uploadMotherImg(formData)
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)
export const uploadMotherModalImg = createAsyncThunk(
    "mother-modal-strain/uploadImg",
    async (data, thunkAPI) => {
        try {
            const formData = new FormData();
            for (let i = 0; i < data.length; i++) {
                formData.append("image", data[i])
            }
            return await strainService.uploadMotherModalImg(formData)
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)


export const deleteMotherImg = createAsyncThunk(
    "mother/deleteImg",
    async (id, thunkAPI) => {
        try {
            return await strainService.deleteMotherImg(id)
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)
export const deleteMotherModalImg = createAsyncThunk(
    "motherModal/deleteImg",
    async (id, thunkAPI) => {
        try {
            return await strainService.deleteMotherModalImg(id)
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)
export const uploadFatherImg = createAsyncThunk(
    "father-strain/uploadImg",
    async (data, thunkAPI) => {
        try {
            const formData = new FormData();
            for (let i = 0; i < data.length; i++) {
                formData.append("image", data[i])
            }
            return await strainService.uploadFatherImg(formData)
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)
export const uploadFatherModalImg = createAsyncThunk(
    "father-modal-strain/uploadImg",
    async (data, thunkAPI) => {
        try {
            const formData = new FormData();
            for (let i = 0; i < data.length; i++) {
                formData.append("image", data[i])
            }
            return await strainService.uploadFatherModalImg(formData)
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)


export const deleteFatherImg = createAsyncThunk(
    "father/deleteImg",
    async (id, thunkAPI) => {
        try {
            return await strainService.deleteFatherImg(id)
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)
export const deleteFatherModalImg = createAsyncThunk(
    "fatherModal/deleteImg",
    async (id, thunkAPI) => {
        try {
            return await strainService.deleteFatherModalImg(id)
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)


export const getUserWishlist = createAsyncThunk(
    'strain/getwishlist',
    async (thunkAPI) => {
        try {
            return await strainService.getUserWishlist()
        } catch (error) {
            return thunkAPI.rejectWithValue(error)

        }
    }
)
export const addToWishList = createAsyncThunk(
    'strain/addwishlist',
    async (id, thunkAPI) => {
        try {


            return await strainService.addToWishList(id)
        } catch (error) {
            return thunkAPI.rejectWithValue(error)

        }
    }
)


// Add review
export const addReviewToStrain = createAsyncThunk(
    "strain/add-review",
    async ({ strainId, reviewData }, thunkAPI) => {
        try {
            return await strainService.addReviewToStrain(strainId, reviewData);
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

// Delete review
export const deleteReview = createAsyncThunk(
    "strain/delete-review",
    async ({ strainId, reviewId }, thunkAPI) => {
        try {
            return await strainService.deleteReview(strainId, reviewId);
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const resetState = createAction("Reset_all")

const initialState = {
    strains: [],
    variants: [],
    strainImg: [],
    motherImg: [],
    fatherImg: [],
    strainModalImg: [],
    motherModalImg: [],
    fatherModalImg: [],
    wishList: [],
    strain: [],
    userWishList: [],
    isError: false,
    isLoading: false,
    isSuccess: false,
    message: "",
}

export const strainSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getAStrain.pending, (state) => {
            state.isLoading = true;

        })

            .addCase(getAStrain.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.title = action.payload.title;
                state.strainImg = action.payload.strainImg;
                state.description = action.payload.description;
                state.type = action.payload.type;
                state.dominantterpene = action.payload.dominantterpene;
                state.business = action.payload.business;
                state.businessType = action.payload.business?.type?.toLowerCase();
                state.collabBusiness = action.payload.collabBusiness;
                state.collabBusinessType = action.payload.collabBusiness?.type?.toLowerCase();
                state.mother = action.payload.mother;
                state.motherState = action.payload.motherState;
                state.motherImg = action.payload.motherImg;
                state.father = action.payload.father;
                state.fatherState = action.payload.fatherState;
                state.fatherImg = action.payload.fatherImg;
                state.effects = action.payload.effects;
                state.feelings = action.payload.feelings;
                state.flavors = action.payload.flavors;
                state.helpsWith = action.payload.helpsWith;
                state.varaint = action.payload.varaint;
                state.reviews = action.payload.reviews;
            })
            .addCase(getAStrain.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error.message;

            })
        builder.addCase(getStrains.pending, (state) => {
            state.isLoading = true;
        })
            .addCase(getStrains.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.strains = action.payload;
            })
            .addCase(getStrains.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error;

            })
        builder.addCase(createStrain.pending, (state) => {
            state.isLoading = true;
        })
            .addCase(createStrain.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.create = action.payload;
            })
            .addCase(createStrain.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error;

            })
        builder.addCase(createMotherStrain.pending, (state) => {
            state.isLoading = true;
        })
            .addCase(createMotherStrain.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.strains.push(action.payload);
                state.createMotherModal = action.payload;
            })
            .addCase(createMotherStrain.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error;

            })
        builder.addCase(createFatherStrain.pending, (state) => {
            state.isLoading = true;
        })
            .addCase(createFatherStrain.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.strains.push(action.payload);
                state.createFatherModal = action.payload;
            })
            .addCase(createFatherStrain.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error;

            })
        builder.addCase(updateStrain.pending, (state) => {
            state.isLoading = true;
        })
            .addCase(updateStrain.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.updated = action.payload;
            })
            .addCase(updateStrain.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error;

            })
        builder.addCase(deleteStrain.pending, (state) => {
            state.isLoading = true;
        })
            .addCase(deleteStrain.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.deleted = action.payload;
            })
            .addCase(deleteStrain.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error;

            })
        builder.addCase(getStrainsByVariant.pending, (state) => {
            state.isLoading = true;
        })
            .addCase(getStrainsByVariant.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.variants = action.payload;
            })
            .addCase(getStrainsByVariant.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error;

            })
        builder.addCase(getStrainsById.pending, (state) => {
            state.isLoading = true;
        })
            .addCase(getStrainsById.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.strain = [action.payload];
            })
            .addCase(getStrainsById.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error;
            })
        builder.addCase(uploadStrainImg.pending, (state) => {
            state.isLoading = true;
        })
            .addCase(uploadStrainImg.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.strainImg = action.payload;
            })
            .addCase(uploadStrainImg.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error;

            })
        builder.addCase(uploadStrainModalImg.pending, (state) => {
            state.isLoading = true;
        })
            .addCase(uploadStrainModalImg.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.strainModalImg = action.payload;
            })
            .addCase(uploadStrainModalImg.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error;

            })
        builder.addCase(deleteStrainImg.pending, (state) => {
            state.isLoading = true;
        })
            .addCase(deleteStrainImg.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.strainImg = [];
            })
            .addCase(deleteStrainImg.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.payload;

            })
        builder.addCase(deleteStrainModalImg.pending, (state) => {
            state.isLoading = true;
        })
            .addCase(deleteStrainModalImg.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.strainModalImg = [];
            })
            .addCase(deleteStrainModalImg.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.payload;

            })
        builder.addCase(uploadMotherImg.pending, (state) => {
            state.isLoading = true;
        })
            .addCase(uploadMotherImg.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.motherImg = action.payload;
            })
            .addCase(uploadMotherImg.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error;

            })
        builder.addCase(uploadMotherModalImg.pending, (state) => {
            state.isLoading = true;
        })
            .addCase(uploadMotherModalImg.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.motherModalImg = action.payload;
            })
            .addCase(uploadMotherModalImg.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error;

            })
        builder.addCase(deleteMotherImg.pending, (state) => {
            state.isLoading = true;
        })
            .addCase(deleteMotherImg.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.motherImg = [];
            })
            .addCase(deleteMotherImg.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.payload;

            })
        builder.addCase(deleteMotherModalImg.pending, (state) => {
            state.isLoading = true;
        })
            .addCase(deleteMotherModalImg.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.motherModalImg = [];
            })
            .addCase(deleteMotherModalImg.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.payload;

            })
        builder.addCase(uploadFatherImg.pending, (state) => {
            state.isLoading = true;
        })
            .addCase(uploadFatherImg.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.fatherImg = action.payload;
            })
            .addCase(uploadFatherImg.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error;

            })
        builder.addCase(uploadFatherModalImg.pending, (state) => {
            state.isLoading = true;
        })
            .addCase(uploadFatherModalImg.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.fatherModalImg = action.payload;
            })
            .addCase(uploadFatherModalImg.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error;

            })
        builder.addCase(deleteFatherImg.pending, (state) => {
            state.isLoading = true;
        })
            .addCase(deleteFatherImg.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.fatherImg = [];
            })
            .addCase(deleteFatherImg.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.payload;

            })
        builder.addCase(deleteFatherModalImg.pending, (state) => {
            state.isLoading = true;
        })
            .addCase(deleteFatherModalImg.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.fatherModalImg = [];
            })
            .addCase(deleteFatherModalImg.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.payload;

            })
        builder.addCase(getUserWishlist.pending, (state) => {
            state.isLoading = true;
        })
            .addCase(getUserWishlist.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.userWishList = action.payload;
            })
            .addCase(getUserWishlist.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error;

            })
        builder.addCase(addToWishList.pending, (state) => {
            state.isLoading = true;
        })
            .addCase(addToWishList.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                // state.add = action.payload;
                state.wishList = action.payload
                state.message = "Successfully Added"
            })
            .addCase(addToWishList.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error;

            })
        builder.addCase(addReviewToStrain.pending, (state) => {
            state.isLoading = true;
        })
            .addCase(addReviewToStrain.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.create = action.payload;
            })
            .addCase(addReviewToStrain.rejected, (state, action) => {
                state.isLoading = false;
                state.isSuccess = false;
                state.isError = true;
                state.message = action.error;
            })
        builder.addCase(deleteReview.pending, (state) => {
            state.isLoading = true;
        })
            .addCase(deleteReview.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                // Extract strainId and reviewId from the payload
                const { strainId, reviewId } = action.meta.arg;

                // Find the index of the strain from which the review is being deleted
                const strainIndex = state.strain.findIndex(strain => strain._id === strainId);
                if (strainIndex !== -1) {
                    // Filter out the review that is being deleted
                    state.strain[strainIndex].reviews = state.strain[strainIndex].reviews.filter(review => review._id !== reviewId);
                }
            })
            .addCase(deleteReview.rejected, (state, action) => {
                state.isLoading = false;
                state.isSuccess = false;
                state.isError = true;
                state.message = action.error.message
            }).addCase(resetState, () => initialState)
    }

})


export default strainSlice.reducer
