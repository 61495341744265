import React from 'react';

const HoursContent = ({ hoursOfOperation }) => {
    const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

    return (
        <div className="bg-[#0f0f0f] shadow lg:shadow-none overflow-hidden sm:rounded-lg">
            <div className="px-4 py-2 sm:px-6">
                <h3 className="text-lg leading-6 font-bold text-gray-300 mt-8">Hours of Operation</h3>
            </div>
            <div className="border-t border-gray-700 text-gray-300 lg:border-none lg:-mt-2">
                {daysOfWeek.map(day => (
                    <div key={day} className="p-4 border-b border-gray-700 lg:border-none">
                        <div className="flex justify-between">
                            <span className="font-bold">{day}</span>
                            <span>
                                {hoursOfOperation[day]?.open} - {hoursOfOperation[day]?.close}
                            </span>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default HoursContent;