import React, { useCallback, useState } from 'react';

const CustomMultiRangeSlider = ({
  min,
  max,
  onChange1,
  onChange2,
  value1,
  value2,
}) => {
  const [minVal, setMinVal] = useState(value1);
  const [maxVal, setMaxVal] = useState(value2);
  const [showLabel1, setShowLabel1] = useState(true);
  // const [showLabel2, setShowLabel2] = useState(true);

  // Convert value to percentage
  const getPercent = useCallback(
    (value) => Math.round(((value - min) / (max - min)) * 100),
    [min, max]
  );

  // Update values
  const handleMinChange = (e) => {
    const value = Math.min(Number(e.target.value), maxVal - 1);
    setMinVal(value);
    onChange1 && onChange1(e);
  };

  const handleMaxChange = (e) => {
    const value = Math.max(Number(e.target.value));
    setMaxVal(value);
    onChange2 && onChange2(e);
  };

  return (
    <div className="flex w-full m-auto items-center h-16 justify-center">
      <div className="relative w-full">
        <input
          type="range"
          min={min}
          max={max}
          readOnly
          value={minVal}
          onChange={onChange1 && handleMinChange}
          className="absolute w-full h-2 opacity-0 cursor-pointer z-10"
          onMouseDown={() => setShowLabel1(true)}
          onMouseUp={() => setShowLabel1(false)}
        />
        <input
          type="range"
          min={min}
          max={max}
          value={maxVal}
          readOnly
          onChange={onChange2 && handleMaxChange}
          className="absolute w-full h-2 opacity-0 cursor-pointer z-10"
          // onMouseDown={() => setShowLabel2(true)}
          // onMouseUp={() => setShowLabel2(false)}
        />

        <div className="relative h-2 bg-gray-200 rounded-full">
          <div
            className="absolute h-2 bg-mary-theme rounded-full"
            style={{
              left: `${Math.min(getPercent(minVal), getPercent(maxVal))}%`,
              width: `${Math.abs(getPercent(maxVal) - getPercent(minVal))}%`,
            }}
          ></div>

          <div
            className="absolute h-6 w-6 text-white bg-[#0075ff] text-[10px] border-gray-300 rounded-full flex items-center justify-center cursor-pointer transform -translate-y-1/3"
            style={{ left: `calc(${getPercent(minVal)}% - 12px)` }}
          >
            {minVal}
            {showLabel1 && (
              <div className="absolute bottom-6 bg-white text-black text-[10px] rounded p-1 shadow-md">
                start
              </div>
            )}
          </div>

          <div
            className="absolute h-6 w-6 bg-[#0075ff] text-[10px]  text-white border-gray-300 rounded-full flex items-center justify-center cursor-pointer transform -translate-y-1/3"
            style={{ left: `calc(${getPercent(maxVal)}% - 12px)` }}
          >
            {maxVal}
            {/* {showLabel2 && ( */}
            <div className="absolute bottom-6 bg-white text-black text-[10px] rounded p-1 shadow-md">
              end
            </div>
            {/* )} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomMultiRangeSlider;
