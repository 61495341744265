/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable @typescript-eslint/no-unused-vars */

import {
    Check,
    Download,
    History,
    Link,
    MoreVertical,
    Plus,
    Share
} from 'lucide-react';
import React, { useMemo, useRef, useState } from 'react';

import html2canvas from 'html2canvas';
import { useNavigate } from 'react-router-dom';
import { getUrl } from '../../utils/GetBussinessUrl';

import { Button } from '@/core/ui/button';
import { Card } from '@/core/ui/card';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@/core/ui/dropdown-menu';
import { enqueueSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import FolderSelectionModal from '../../components/StrainDetail/FolderSelectionModal';
import SocialShareModal from '../../components/StrainDetail/SocialShare';
import { addStrainToFolder } from '../../features/stashFolder/folderService';

// Define the interface for the component props
interface StrainCardProps {
    id: string;
    title: string;
    father: string;
    mother: string;
    type: string;
    imageUrl: string;
    itemType: string;
    onUpdate: Function;
    business: Record<string, any>;
}

const StrainCard: React.FC<StrainCardProps> = ({
    id,
    title,
    father,
    mother,
    type,
    imageUrl,
    itemType,
    business,
    onUpdate,
}) => {
    // hooks
    const navigate = useNavigate();

    // globalState
    const folders = useSelector((state: any) => state.folder.folders);
    // useref
    const cardRef = useRef<HTMLDivElement | null>(null);
    // localState
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showShareOptions, setShowShareOptions] = useState(false);
    const [isFolderModalOpen, setIsFolderModalOpen] = useState(false);

    const handleDownloadImage = () => {
        setIsModalOpen(false);
        setTimeout(() => {
            if (cardRef.current === null) {
                return;
            }
            cardRef.current.style.backgroundColor = '#0f0f0f'; // Temporary background color for visibility
            document.fonts.ready.then(() => {
                html2canvas(cardRef.current, { useCORS: true })
                    .then((canvas) => {
                        cardRef.current.style.backgroundColor = ''; // Revert background color
                        myRenderFunction(canvas);
                    })
                    .catch((err) => {
                        console.error('Oops, something went wrong!', err);
                        cardRef.current.style.backgroundColor = ''; // Revert background color even in case of error
                    });
            });
        }, 200); // Delay to ensure the modal is fully closed
    };

    function myRenderFunction(canvas) {
        var a = document.createElement('a');
        // toDataURL defaults to png, so we need to request a jpeg, then convert for file download.
        a.href = canvas
            .toDataURL('image/jpeg')
            .replace('image/jpeg', 'image/octet-stream');
        a.download = 'strain.jpg';
        a.click();
    }

    const toggleFolderModal = () => {
        setIsFolderModalOpen(true);
    };

    const isAddedInFolder = useMemo(() => {
        for (let folder of folders) {
            if (folder.strains?.find((item) => item?._id === id)) {
                return true;
            }
        }
        return false;
    }, [id, folders]);

    const handleAddToStash = async () => {
        const likedStrainsFolder = folders?.find(
            (folder) => folder.name === 'Liked Strains'
        );
        try {
            let Data = {
                itemId: id,
                type: itemType,
            };
            const res = await addStrainToFolder(likedStrainsFolder._id, Data);
            if (res) {
                onUpdate();
                enqueueSnackbar(res?.message, { variant: 'success' });
            }
        } catch (error) {
            enqueueSnackbar(error?.message, { variant: 'error' });
        }
    };

    const shareUrl = `https://maryapp.io/strain/${id}`;
    return (
        <Card
            ref={cardRef}
            className="w-full overflow-hidden bg-transparent border-0">
            <div className="relative rounded-lg overflow-hidden aspect-square mb-3">
                <img
                    onClick={() => navigate(`/strain/${id}`)}
                    src={imageUrl}
                    alt={title}
                    loading="lazy"
                    className="object-cover cursor-pointer"
                />
                <Button
                    variant="outline"
                    size="icon"

                    className={`absolute top-2 right-2 rounded-full transition-colors ${isAddedInFolder
                            ? 'bg-green-500 hover:bg-green-600 border-0'
                            : 'bg-gray-900/50 backdrop-blur-sm hover:bg-gray-900/70 border-gray-800'
                        }`}
                    onClick={toggleFolderModal}
                    aria-label={isAddedInFolder ? "Remove from selection" : "Add to selection"}
                >
                    {isAddedInFolder ? (
                        <Check className="h-4 w-4 bg-mary-theme text-white" />
                    ) : (
                        <Plus onClick={handleAddToStash} className="h-4 w-4 text-gray-300" />
                    )}
                </Button>
            </div>
            <div className="px-1">
                <div className="flex justify-between items-start">
                    <h2 onClick={() => navigate(`/strain/${id}`)} className="cursor-pointer text-lg font-semibold truncate text-gray-100" title={title}>{title}</h2>
                    <Button size="icon" className="-mt-2 -mr-2 text-gray-400 bg-transparent" aria-label="More options">
                        <DropdownMenu>
                            <DropdownMenuTrigger asChild>
                                <MoreVertical className="w-5 h-5 text-white cursor-pointer" />
                            </DropdownMenuTrigger>
                            <DropdownMenuContent
                                className="bg-[#2c2c2c] border-none  text-white"
                                align="end"
                            >

                                <DropdownMenuItem
                                    onClick={() =>
                                        navigate(
                                            `${getUrl(business?.PrimaryVendor, business?._id)}`
                                        )
                                    }
                                >
                                    <Link className="mr-2 h-4 w-4" />
                                    Go to Business
                                </DropdownMenuItem>

                                <DropdownMenuItem onClick={() => setShowShareOptions(true)}>
                                    <Share className="mr-2 h-4 w-4" />
                                    Share
                                </DropdownMenuItem>
                                <DropdownMenuItem onClick={handleDownloadImage}>
                                    <Download className="mr-2 h-4 w-4" />
                                    Download
                                </DropdownMenuItem>
                                <DropdownMenuItem
                                    onClick={() =>
                                        navigate(`/sessions-review?type=Strain&id=${id}`)
                                    }
                                >
                                    <History className="mr-2 h-4 w-4" />
                                    Add session
                                </DropdownMenuItem>
                            </DropdownMenuContent>
                        </DropdownMenu>
                    </Button>
                </div>
                <p className="text-sm text-gray-400 -mt-1">  {mother} X {father}</p>
                <p className="text-xs text-gray-500 mt-2">{type}</p>
            </div>
            {/* <CardContent className="p-1">
                <div className="flex justify-between  items-start gap-2 ">
                    <h2
                        onClick={() => navigate(`/strain/${id}`)}
                        className="text-xl font-semibold line-clamp-2 py-1 cursor-pointer"
                    >
                        {title}
                    </h2>
                    <div className="flex items-center justify-end  gap-2 my-2">
                        {isAddedInFolder ? (
                            <div
                                onClick={toggleFolderModal}
                                className="w-6 h-6 bg-mary-theme rounded-full flex justify-center items-center cursor-pointer"
                            >
                                <Check className=" w-4 h-4 text-black" />
                            </div>
                        ) : (
                            <CirclePlus onClick={handleAddToStash} className="w-6 h-6 text-white cursor-pointer" />
                        )}

                        <DropdownMenu>
                            <DropdownMenuTrigger asChild>
                                <MoreVertical className="w-6 h-6 text-white cursor-pointer" />
                            </DropdownMenuTrigger>
                            <DropdownMenuContent
                                className="bg-[#2c2c2c] border-none  text-white"
                                align="end"
                            >

                                <DropdownMenuItem
                                    onClick={() =>
                                        navigate(
                                            `${getUrl(business?.PrimaryVendor, business?._id)}`
                                        )
                                    }
                                >
                                    <Link className="mr-2 h-4 w-4" />
                                    Go to Business
                                </DropdownMenuItem>

                                <DropdownMenuItem onClick={() => setShowShareOptions(true)}>
                                    <Share className="mr-2 h-4 w-4" />
                                    Share
                                </DropdownMenuItem>
                                <DropdownMenuItem onClick={handleDownloadImage}>
                                    <Download className="mr-2 h-4 w-4" />
                                    Download
                                </DropdownMenuItem>
                                <DropdownMenuItem
                                    onClick={() =>
                                        navigate(`/sessions-review?type=Strain&id=${id}`)
                                    }
                                >
                                    <History className="mr-2 h-4 w-4" />
                                    Add session
                                </DropdownMenuItem>
                            </DropdownMenuContent>
                        </DropdownMenu>
                    </div>
                </div>

                <h6 className=" line-clamp-1 py-1">
                    {mother} X {father}
                </h6>
                <h6 className="py-1">{type}</h6>
            </CardContent> */}
            {showShareOptions && (
                <SocialShareModal
                    isOpen={showShareOptions}
                    url={shareUrl}
                    title={title}
                    strainImg={imageUrl}
                    type={type}
                    onClose={() => setShowShareOptions(false)}
                    des={'Share this strain'}
                />
            )}
            {folders && (
                <FolderSelectionModal
                    itemType="strain"
                    itemId={id}
                    backBtnText="Back to Strain"
                    isOpen={isFolderModalOpen}
                    onClose={() => setIsFolderModalOpen(false)}
                    onUpdate={() => onUpdate()}
                />
            )}
        </Card>
    );
};

export default StrainCard;
