import React from 'react'
import ComingSoon from '../components/Layout/ComingSoon'

const Trending = () => {
  return (
  <ComingSoon/>
  )
}

export default Trending
