import React, { useState } from 'react';
import { addCustomProduct } from '../../features/sessions/SessionServices';
import Select from '../CommonComponent/Select';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

function AddCustomProductModal({ isOpen, onRequestClose, onProductAdded }) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    selectType: '',
    title: '',
    type: [],
    business: '',
    mother: '',
    father: '',
    productTitle: '',
    thc_Percentage: '',
    cbd_Percentage: '',
    productBusiness: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const payload =
      formData.selectType === 'Strain'
        ? {
            selectType: formData.selectType,
            title: formData.title,
            typeDetail: formData.typeDetail,
            business: formData.business,
            mother: formData.mother,
            father: formData.father,
            thc_Percentage: formData.thc_Percentage,
            cbd_Percentage: formData.cbd_Percentage,
          }
        : {
            selectType: formData.selectType,
            title: formData.productTitle,
            thc_Percentage: formData.thc_Percentage,
            cbd_Percentage: formData.cbd_Percentage,
            business: formData?.productBusiness,
          };
    try {
      const res = await addCustomProduct(payload);
      navigate('/sessions-review?userAdded=true');
      enqueueSnackbar(res?.message, {
        variant: 'success',
      });
      setLoading(false);
      onProductAdded();
      onRequestClose();
    } catch (error) {
      enqueueSnackbar(error?.message, {
        variant: 'error',
      });
      setLoading(false);
      onProductAdded();
      onRequestClose();
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 m-4" onClick={onRequestClose}>
      <div
        className="flex items-center justify-center min-h-screen"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="relative w-full max-w-md p-4 mx-auto bg-[#2c2c2c] rounded-lg shadow">
          <div className="flex items-center justify-between p-5  rounded-t">
            <h3 className="text-xl font-semibold text-white">
              Add Strain or Product
            </h3>

            <button
              onClick={onRequestClose}
              className="text-gray-600 hover:text-gray-900"
            >
              <svg
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div className="p-5">
            <form onSubmit={handleSubmit} className="space-y-4">
              <Select
                HelpfullText="Add strain or product"
                value={formData.selectType}
                options={[
                  { name: 'Strain', value: 'Strain' },
                  { name: 'Product', value: 'Product' },
                ]}
                handleChange={(e) =>
                  setFormData({ ...formData, selectType: e.target.value })
                }
              />
              {formData.selectType === 'Strain' && (
                <div className="space-y-4">
                  {/* Strain-specific fields */}
                  <input
                    type="text"
                    name="title"
                    placeholder="Title"
                    onChange={handleChange}
                    className=" w-full bg-white p-3  text-black rounded-md focus:outline-none "
                    required
                  />

                  <Select
                    HelpfullText="Select"
                    value={formData?.typeDetail}
                    options={[
                      { name: 'Indica', value: 'Indica' },
                      { name: 'Sativa', value: 'Sativa' },
                      { name: 'Hybrid', value: 'Hybrid' },
                    ]}
                    handleChange={(e) => {
                      setFormData({
                        ...formData,
                        typeDetail: e.target.value,
                      });
                    }}
                  />
                  <input
                    type="text"
                    name="business"
                    placeholder="Business"
                    onChange={handleChange}
                    className=" w-full bg-white p-3  text-black rounded-md focus:outline-none "
                  />
                  <input
                    type="text"
                    name="mother"
                    placeholder="Mother"
                    onChange={handleChange}
                    className=" w-full bg-white p-3  text-black rounded-md focus:outline-none "
                  />
                  <input
                    type="text"
                    name="father"
                    placeholder="Father"
                    onChange={handleChange}
                    className=" w-full bg-white p-3  text-black rounded-md focus:outline-none "
                  />
                  <input
                    type="text"
                    name="thc_Percentage"
                    placeholder="THC Percentage"
                    onChange={handleChange}
                    className=" w-full bg-white p-3  text-black rounded-md focus:outline-none "
                  />
                  <input
                    type="text"
                    name="cbd_Percentage"
                    placeholder="CBD Percentage"
                    onChange={handleChange}
                    className=" w-full bg-white p-3  text-black rounded-md focus:outline-none "
                  />
                </div>
              )}
              {formData.selectType === 'Product' && (
                <div className="space-y-4">
                  {/* Product-specific fields */}
                  <input
                    type="text"
                    name="productTitle"
                    placeholder="Product Title"
                    onChange={handleChange}
                    className=" w-full bg-white p-3  text-black rounded-md focus:outline-none "
                    required
                  />
                  <input
                    type="text"
                    name="productBusiness"
                    placeholder="Business"
                    onChange={handleChange}
                    className=" w-full bg-white p-3  text-black rounded-md focus:outline-none "
                  />
                  <input
                    type="text"
                    name="thc_Percentage"
                    placeholder="THC Percentage"
                    onChange={handleChange}
                    className=" w-full bg-white p-3  text-black rounded-md focus:outline-none "
                  />
                  <input
                    type="text"
                    name="cbd_Percentage"
                    placeholder="CBD Percentage"
                    onChange={handleChange}
                    className=" w-full bg-white p-3  text-black rounded-md focus:outline-none "
                  />
                </div>
              )}
              <button
                type="submit"
                className="w-full rounded-md py-3 text-white text-xl font-bold bg-[#62AF41] hover:bg-[#4c8633]"
              >
                {loading ? 'loading...' : 'Submit'}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddCustomProductModal;
