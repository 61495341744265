/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState } from 'react';
import { HiOutlineMenuAlt1 } from 'react-icons/hi';
import { PiImages } from 'react-icons/pi';
import ImageGallery from './ImageGallery';

const Answer = ({ content }) => {
  const [ShowGallery, setShowGallery] = useState(false);
  const images = content?.braveSearchResults[0]?.images || [];
  // Handler for image load error
  const handleImageError = (event) => {
    console.log('Error loading image:', event.target.src);
    event.target.parentNode.style.display = 'none';
  };

  return (
    <div className="flex flex-col gap-3 w-full">
      <div className="flex items-center justify-between text-white">
        <div className="flex items-center justify-start gap-2 text-xl">
          <HiOutlineMenuAlt1 /> Answer
        </div>
      </div>
      <div className="flex justify-start gap-3 scrollbar-hide overflow-y-auto p-3 w-full md:hidden">
        {/* Main Image */}
        {images.length > 0 && (
          <div className="flex-shrink-0 rounded-md text-white w-[90%] md:w-[50%]">
            <img
              className="rounded-md w-full h-[150px] md:h-auto object-cover"
              src={images[0] || images[1]}
              alt="Main Image"
              onError={handleImageError}
            />
          </div>
        )}

        {/* Stacked Smaller Images */}
        {images.length > 1 && (
          <div className="flex flex-col gap-2 flex-shrink-0 h-[150px] md:h-auto  rounded-md text-white ">
            {images.slice(1, 3).map((item, index) => (
              <img
                key={index}
                className="rounded-md w-[150px] md:w-[100%] h-[70px]  object-cover"
                src={item}
                alt={`Secondary Image ${index + 1}`}
                onError={handleImageError}
              />
            ))}
          </div>
        )}

        {/* Additional Images in another column */}
        {images.length > 3 && (
          <div className="flex flex-col gap-2 flex-shrink-0 h-[150px] w-[150px] rounded-md text-white ">
            {images.slice(4, 5 || 6 || 7).map((item, index) => (
              <img
                key={index}
                className="rounded-md w-[150px] h-[150px]  md:h-1/2 object-cover"
                src={item}
                alt={`Secondary Image ${index + 1}`}
                onError={handleImageError}
              />
            ))}
          </div>
        )}
        {images.length > 4 && (
          <div
            className="relative rounded-md "
            onClick={() => setShowGallery(true)}
          >
            {images.slice(4, 5 || 6 || 7 || 8 || 9).map((item, index) => (
              <div
                key={index}
                className="relative w-[150px] h-[150px] overflow-hidden rounded-md bg-no-repeat bg-cover"
                style={{ backgroundImage: `url(${item})` }}
              >
                <div className="absolute inset-0 bg-black opacity-50"></div>

                <div className="absolute left-0 w-full flex flex-col items-center justify-center gap-2 top-[50px] z-10">
                  <PiImages size={18} color="#ffffff" />{' '}
                  <div className="text-white text-sm">Show More</div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      {/* this is for large devices */}
      <div
        className="hidden md:flex overflow-x-auto scrollbar-hide"
        style={{ gap: '8px' }}
      >
        {images.slice(1, 5).map(
          (
            item,
            index // Note: simplified the slice for clarity
          ) => (
            <div
              key={index}
              className={`${
                index === 0 ? 'w-[40%]' : 'w-[20%]'
              } flex-none relative`}
              style={{ height: '250px' }}
            >
              <img
                src={item}
                alt=""
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                }}
                onError={handleImageError}
              />
              {index === 3 && (
                <>
                  <div className="absolute inset-0 bg-black opacity-50"></div>
                  <div
                    onClick={() => setShowGallery(true)}
                    className="absolute left-0 top-[110px] w-full flex flex-col items-center justify-center gap-2  z-10 cursor-pointer"
                  >
                    <PiImages size={18} color="#ffffff" />{' '}
                    <div className="text-white text-sm">Show More</div>
                  </div>
                </>
              )}
            </div>
          )
        )}
      </div>

      {ShowGallery && (
        <ImageGallery images={images} setShowGallery={setShowGallery} />
      )}
    </div>
  );
};

export default Answer;
