import StrainCard from "@/common-components/strain/StrainCard";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "swiper/css";
import BussinessCard from "../components/CommonComponent/AddBusinesses/BussinessCard";
import Products from "../components/products/Products";
import { useGetAllBusinessesHomePageQuery } from "../redux/api/businessApi";
import { useGetProductByCategoryQuery } from "../redux/api/productApi";
import { useGetStrainsByTypeQuery } from "../redux/api/strainApi";
import BreederCardLoader from "../skeleton/BreederCardLoader";
import StrainCardLoader from "../skeleton/StrainCardLoader";

import { getAllFolders } from "@/features/stashFolder/folderSlice";
import { useDispatch, useSelector } from "react-redux";

const Home = () => {
  // hooks
  const dispatch = useDispatch();
  // Hooks for fetching API data

  // globalState
  const user = useSelector((state) => state.auth?.user);
  const {
    data: businessData,
    error: businessError,
    isLoading: businessLoading,
  } = useGetAllBusinessesHomePageQuery(undefined, {
    skip: !user, // Skip query if user is not logged in
  });
  const {
    data: strainData,
    error: strainError,
    isLoading: strainLoading,
  } = useGetStrainsByTypeQuery();
  const {
    data: productData,
    error: productError,
    isLoading: productLoading,
  } = useGetProductByCategoryQuery();

  const [isUpdate, setisUpdate] = useState(false);

  useEffect(() => {
    if (businessError)
      enqueueSnackbar("Error fetching businesses", { variant: "error" });
    if (strainError)
      enqueueSnackbar("Error fetching strains", { variant: "error" });
    if (productError)
      enqueueSnackbar("Error fetching products", { variant: "error" });
  }, [businessError, strainError, productError]);

  const handleUpdate = () => {
    setisUpdate(!isUpdate);
  };

  useEffect(() => {
    dispatch(getAllFolders());
  }, [dispatch, isUpdate]);

  return (
    <>
      <div className="flex flex-col">
        {/* Strain Section */}
        {strainLoading && <StrainCardLoader NumberOfCard={6} />}
        {!strainLoading &&
          strainData?.map((strainType, index) => (
            <div key={index} className="mb-6">
              <div className="flex justify-between items-center p-2">
                <h2 className="text-xl font-bold text-white">
                  {strainType?.type?.charAt(0).toUpperCase() +
                    strainType?.type?.slice(1)}{" "}
                  Strains
                </h2>
                <Link
                  to={`/strains?type=${strainType?.type}`}
                  className="text-sm ml-2 text-[#62AF41]"
                >
                  View All
                </Link>
              </div>

              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4">
                {strainType?.data?.map((strain, index) => (
                  <StrainCard
                    itemType="strain"
                    key={strain._id}
                    id={strain._id}
                    title={strain?.title}
                    father={
                      strain?.father?.title
                        ? strain?.father?.title
                        : "Not Found"
                    }
                    mother={
                      strain?.mother?.title
                        ? strain?.mother?.title
                        : "Not found"
                    }
                    type={strain?.type}
                    imageUrl={strain?.strainImg?.url}
                    business={strain?.business?.id}
                    onUpdate={() => setisUpdate(!isUpdate)}
                  />
                ))}
              </div>
            </div>
          ))}

        {/* Product Section */}
        {productLoading && <StrainCardLoader NumberOfCard={6} />}
        {!productLoading &&
          productData?.map((productType, index) => (
            <div key={index} className="mb-6">
              <div className="flex justify-between items-center p-2">
                <h2 className="text-xl font-bold text-white">
                  {productType?.type
                    ? productType.type?.charAt(0)?.toUpperCase() +
                      productType?.type?.slice(1)
                    : ""}
                </h2>
                <Link
                  to={`/products?category=${productType?.type}`}
                  className="text-sm ml-2 text-[#62AF41]"
                >
                  View All
                </Link>
              </div>

              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4">
                {productType?.data?.map((product, index2) => (
                  <Products
                    key={index2}
                    itemId={product._id}
                    title={product.title}
                    price={product?.price}
                    productImg={product.productImg}
                    category={product?.category}
                    weight={product?.weight}
                    onUpdate={() => setisUpdate(!isUpdate)}
                  />
                ))}
              </div>
            </div>
          ))}

        {/* Business Section */}
        {businessLoading && <BreederCardLoader NumberOfCard={6} />}
        {!businessLoading &&
          businessData?.map((item, index) => (
            <>
              {item.data?.length > 0 && (
                <div key={item?.primaryVendor} className="mb-6">
                  <div className="flex justify-between items-center p-2">
                    <h2 className="text-xl font-bold text-white">
                      {item?.PrimaryVendor}{" "}
                    </h2>
                    <Link
                      to={`/${item?.PrimaryVendor}`}
                      className="text-sm ml-2 text-[#62AF41]"
                    >
                      View All
                    </Link>
                  </div>
                  <div className="grid xs:grid-cols-1 gap-4 md:grid-cols-1 lg:grid-cols-3">
                    {item?.data.map((bussiness, index) => (
                      <BussinessCard
                        key={index}
                        Data={bussiness}
                        isUpdate={isUpdate}
                        OnUpdate={handleUpdate}
                      />
                    ))}
                  </div>
                </div>
              )}
            </>
          ))}
      </div>
    </>
  );
};

export default Home;
