import { enqueueSnackbar } from 'notistack';
import React, { useState } from 'react';
import { IoCopyOutline, IoShareOutline } from 'react-icons/io5';
import SocialShareModal from '../StrainDetail/SocialShare';

const ControleButton = ({ content, ConversationID }) => {
  const [OpenShare, setOpenShare] = useState(false);

  const handleCopy = (ans) => {
    const textToCopy = ans;

    // Check if navigator.clipboard is available
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard
        .writeText(textToCopy)
        .then(() => {
          enqueueSnackbar('Copied Successfully', {
            variant: 'success',
          });
        })
        .catch((err) => {
          enqueueSnackbar(`Failed to copy: ${err.message}`, {
            variant: 'error',
          });
        });
    } else {
      // Fallback to older method using textarea
      const textArea = document.createElement('textarea');
      textArea.value = textToCopy;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        document.execCommand('copy');
        enqueueSnackbar('Copied Successfully', {
          variant: 'success',
        });
      } catch (err) {
        enqueueSnackbar(`Fallback: Failed to copy: ${err.message}`, {
          variant: 'error',
        });
      }
      document.body.removeChild(textArea);
    }
  };

  return (
    <div className="flex justify-between items-center">
      <div className="flex justify-start items-center gap-5">
        <IoShareOutline
          color="#ffffff"
          size={22}
          onClick={() => setOpenShare(true)}
        />

        <IoCopyOutline
          color="#ffffff"
          size={18}
          onClick={() => handleCopy(content)}
        />
      </div>

      {OpenShare && (
        <SocialShareModal
          isOpen={OpenShare}
          url={`https://maryapp.io?search=${ConversationID}`}
          onClose={() => setOpenShare(false)}
          des={'Share answer'}
        />
      )}
    </div>
  );
};

export default ControleButton;
