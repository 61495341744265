import { React } from 'react';
import Header from './Header';
import Footer from './Footer';
import { register } from 'swiper/element/bundle';
register();
const Layout = () => {
    return (
        <>
            <Header />
            <Footer />
        </>
    );
};

export default Layout;
