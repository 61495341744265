import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import HeaderButtons from '../../CustomButtons/HeaderButtons';
import { useSelector } from 'react-redux';

const MenuTabs = ({ setArrowToggle, swiperRef }) => {
  // hooks
  const location = useLocation();

  //globalState
  const userState = useSelector((state) => state.auth.user);

  //   useRefrence

  //   localState

  const handleDropDownSection = () => {
    setArrowToggle(false);
  };

  useEffect(() => {
    // Reset Swiper to the first slide on component mount
    if (swiperRef.current && swiperRef.current) {
      swiperRef.current.swiper.slideTo(0, 0); // slideTo(index, speed, runCallbacks)
    }
  }, [location, swiperRef]);

  return (
    <div
      className={`${
        !location.pathname.includes('/strain') &&
        !location.pathname.includes('/brand-detail') &&
        !location.pathname.includes('/product') &&
        !location.pathname.includes('/allSubscribedBrand') &&
        !location.pathname.includes('/stash') &&
        !location.pathname.includes('/notifications') &&
        !location.pathname.includes('/breeder-detail') &&
        !location.pathname.includes('/grower-detail') &&
        !location.pathname.includes('/dispensary-detail') &&
        !location.pathname.includes('/socialClub-detail') &&
        !location.pathname.includes('/hashMaker-detail') &&
        !location.pathname.includes('/seedBank-detail') &&
        !location.pathname.includes('/hempShop-detail') &&
        !location.pathname.includes('/growShop-detail') &&
        !location.pathname.includes('/sessions') &&
        !location.pathname.includes('/profile') &&
        !location.pathname.includes('/subscription') &&
        !location.pathname.includes('/answers') &&
        !location.pathname.includes('/search') &&
        !location.pathname.includes('/join')
          ? 'block'
          : 'hidden'
      }   bg-[#0f0f0f]   z-20 text-white`}
    >
      <swiper-container
        spaceBetween={50}
        css-mode="true"
        ref={swiperRef}
        init="true"
      >
        <swiper-slide class="allSwipe">
          <HeaderButtons
            funPass={handleDropDownSection}
            buttonText={'All'}
            buttonLink={'/'}
          />
        </swiper-slide>
        <swiper-slide class="otherSwipe">
          <div className=" flex flex-row justify-center mx-1 ">
            <Link
              type="button"
              onClick={handleDropDownSection}
              className={` text-white hover:text-white ${
                location.pathname.includes('discover') ||
                location.pathname.includes('foryou')
                  ? 'bg-gradient-to-r from-[#62b041] to-[#4291e5] rounded-lg opacity-1 border-2 border-[#62b041]'
                  : 'bg-transparent gradient-border'
              }  w-full font-medium  text-sm  py-1.5 text-center  xs:text-xs`}
              to={userState !== null ? '/foryou' : '/discover'}
            >
              {userState !== null ? 'For you' : 'Discover'}
            </Link>
          </div>
        </swiper-slide>
        <swiper-slide class="otherSwipe">
          <HeaderButtons
            funPass={handleDropDownSection}
            buttonText={'Trending'}
            buttonLink={'/trending'}
          />
        </swiper-slide>

        <swiper-slide class="otherSwipe">
          <HeaderButtons
            funPass={handleDropDownSection}
            buttonText={'Strains'}
            buttonLink={'/strains'}
          />
        </swiper-slide>
        <swiper-slide class="otherSwipe">
          <HeaderButtons
            funPass={handleDropDownSection}
            buttonText={'Products'}
            buttonLink={'/products'}
          />
        </swiper-slide>
        <swiper-slide class="otherSwipe">
          <HeaderButtons
            funPass={handleDropDownSection}
            buttonText={'Brands'}
            buttonLink={'/Brand'}
          />
        </swiper-slide>
        <swiper-slide class="otherSwipe">
          <HeaderButtons
            funPass={handleDropDownSection}
            buttonText={'Breeders'}
            buttonLink={'/Breeder'}
          />
        </swiper-slide>
        <swiper-slide class="otherSwipe">
          <HeaderButtons
            funPass={handleDropDownSection}
            buttonText={'Growers'}
            buttonLink={'/grower'}
          />
        </swiper-slide>

        <swiper-slide class="otherSwipe">
          <HeaderButtons
            funPass={handleDropDownSection}
            buttonText={'Hash Makers'}
            buttonLink={'/HashMaker'}
          />
        </swiper-slide>

        <swiper-slide class="otherSwipe">
          <HeaderButtons
            funPass={handleDropDownSection}
            buttonText={'Dispensaries'}
            buttonLink={'/Dispensary'}
          />
        </swiper-slide>
        <swiper-slide class="otherSwipe">
          <HeaderButtons
            funPass={handleDropDownSection}
            buttonText={'Social Clubs'}
            buttonLink={'/Socialclub'}
          />
        </swiper-slide>
        <swiper-slide class="otherSwipe">
          <HeaderButtons
            funPass={handleDropDownSection}
            buttonText={'Delivery'}
            buttonLink={'/Delivery'}
          />
        </swiper-slide>
        <swiper-slide class="otherSwipe">
          <HeaderButtons
            funPass={handleDropDownSection}
            buttonText={'Seed Banks'}
            buttonLink={'/Seedbank'}
          />
        </swiper-slide>
        <swiper-slide class="otherSwipe">
          <HeaderButtons
            funPass={handleDropDownSection}
            buttonText={'Hemp Shops'}
            buttonLink={'/Hempshop'}
          />
        </swiper-slide>
        <swiper-slide class="otherSwipe">
          <HeaderButtons
            funPass={handleDropDownSection}
            buttonText={'Grow Shops'}
            buttonLink={'/Growshops'}
          />
        </swiper-slide>
        <swiper-slide class="otherSwipe">
          <HeaderButtons
            funPass={handleDropDownSection}
            buttonText={'Doctors'}
            buttonLink={'/Doctors'}
          />
        </swiper-slide>

        <swiper-slide class="otherSwipe">
          <HeaderButtons
            funPass={handleDropDownSection}
            buttonText={'Events'}
            buttonLink={'/Events'}
          />
        </swiper-slide>

        <swiper-slide class="otherSwipe">
          <HeaderButtons
            funPass={handleDropDownSection}
            buttonText={'Articles'}
            buttonLink={'/Articles'}
          />
        </swiper-slide>
        <swiper-slide class="otherSwipe">
          <HeaderButtons
            funPass={handleDropDownSection}
            buttonText={'Artists'}
            buttonLink={'/Artists'}
          />
        </swiper-slide>

        <swiper-slide class="otherSwipe">
          <HeaderButtons
            funPass={handleDropDownSection}
            buttonText={'People'}
            buttonLink={'/People'}
          />
        </swiper-slide>
        <swiper-slide class="otherSwipe">
          <HeaderButtons
            funPass={handleDropDownSection}
            buttonText={'Deals'}
            buttonLink={'/Deals'}
          />
        </swiper-slide>
        <swiper-slide class="otherSwipe">
          <div className=" flex flex-row justify-center mx-1 ">
            <Link
              type="button"
              className=" w-full text-[#62AF41] hover:text-white   lg:hover:bg-[#62AF41]   font-medium rounded-lg text-sm  py-1.5 text-center  xs:text-xs  "
              onClick={handleDropDownSection}
              to="/feedback"
            >
              Send Feedback
            </Link>
          </div>
        </swiper-slide>
      </swiper-container>
    </div>
  );
};

export default MenuTabs;
