import React, { useEffect, useState, useCallback } from "react";
import Meta from "../components/SEO/Meta";
import { useDispatch } from "react-redux";
import { getAllFolders } from "../features/stashFolder/folderSlice";
import StrainCardLoader from "../skeleton/StrainCardLoader";
import { enqueueSnackbar } from "notistack";
import { CustomPagination } from "@/common-components/mary-pagination/MaryPagination";
import {
  conditionsList,
  effectsList,
  flavorsList,
} from "../components/CommonComponent/CommonData/CommonData";
import MultipleSelectSearchFillter from "../components/CommonComponent/SearchFilters/MultipleSelectSearchFillter";
import { useSearchParams } from "react-router-dom";
import { useGetStrainsQuery } from "../redux/api/strainApi";
import StrainCard from "../common-components/strain/StrainCard";

const Type = ["indica", "hybrid", "sativa"];

const AllStrains = () => {
  // hooks
  const [searchParams] = useSearchParams();
  const type = searchParams.get("type");
  const dispatch = useDispatch();

  // localState
  const [isUpdate, setIsUpdate] = useState(false);
  const [filterOptions, setFilterOptions] = useState({
    type: [],
    flavors: [],
    effects: [],
    helpsWith: [],
  });
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (type) {
      setFilterOptions((prev) => ({ ...prev, type: [...prev.type, type] }));
    }
  }, [type]);

  // Use the RTK Query hook to fetch strains
  const {
    data: strainsData,
    error,
    isLoading,
  } = useGetStrainsQuery({
    page,
    filterOptions,
  });

  // Update totalPages from the response data
  const totalPages = strainsData?.pagination?.totalPages || 1;

  useEffect(() => {
    dispatch(getAllFolders());
  }, [dispatch, isUpdate]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  // Memoized functions to prevent unnecessary re-renders
  const handleTypeChange = useCallback(
    (value) => setFilterOptions((prev) => ({ ...prev, type: value })),
    []
  );

  const handleFlavorChange = useCallback(
    (value) => setFilterOptions((prev) => ({ ...prev, flavors: value })),
    []
  );

  const handleEffectsChange = useCallback(
    (value) => setFilterOptions((prev) => ({ ...prev, effects: value })),
    []
  );

  const handleHelpsWithChange = useCallback(
    (value) => setFilterOptions((prev) => ({ ...prev, helpsWith: value })),
    []
  );

  return (
    <div className="">
      {/* <VendorCard /> */}
      <Meta title={"Strains"} siteLink={"/strains"} />
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-2 md:gap-4 ">
        <MultipleSelectSearchFillter
          label="Type"
          options={Type}
          getOptions={handleTypeChange}
        />
        <MultipleSelectSearchFillter
          label="Flavor"
          options={flavorsList.map((item) => item?.name)}
          getOptions={handleFlavorChange}
        />
        <MultipleSelectSearchFillter
          label="Effects"
          options={effectsList.map((item) => item?.name)}
          getOptions={handleEffectsChange}
        />
        <MultipleSelectSearchFillter
          label="Helps With"
          options={conditionsList}
          getOptions={handleHelpsWithChange}
        />
      </div>
      {isLoading && (
        <div className="mt-10">
          <StrainCardLoader NumberOfCard={20} />
        </div>
      )}
      {!isLoading && strainsData && (
        <div className="flex flex-col mt-10 mb-20">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4">
            {strainsData?.strains?.map((strain, index) => (
              <StrainCard
                itemType="strain"
                key={strain._id}
                id={strain._id}
                title={strain?.title}
                father={strain?.father?.title ?? "Not Found"}
                mother={strain?.mother?.title ?? "Not found"}
                type={strain?.type}
                imageUrl={strain?.strainImg?.url}
                business={strain?.business?.id}
                onUpdate={() => setIsUpdate(!isUpdate)}
              />
            ))}
          </div>
        </div>
      )}
      {error && (
        <div className="mt-10">
          {enqueueSnackbar(error.message || "Failed to fetch strains", {
            variant: "error",
          })}
        </div>
      )}
      <div className="my-5">
        <CustomPagination
          count={totalPages}
          page={page}
          onChange={handlePageChange}
          variant="outlined"
        />
      </div>
    </div>
  );
};

export default AllStrains;
