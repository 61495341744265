// import React, { useEffect, useRef } from 'react';
// import Chart from 'chart.js/auto';

// const DonutsChart = ({
//   todayStreak,
//   sessionsPerDay,
//   dosagePerSession,
//   consumingQuantities,
// }) => {
//   const chartRef = useRef(null);
//   const chartInstanceRef = useRef(null);

//   useEffect(() => {
//     const ctx = chartRef.current.getContext('2d');

//     // Destroy any previous chart instance
//     if (chartInstanceRef.current) {
//       chartInstanceRef.current.destroy();
//     }

//     // Inner Doughnut Data for Sessions
//     const sessionData = Array(sessionsPerDay).fill(100 / sessionsPerDay);
//     const sessionColors = sessionData.map((_, index) =>
//       index < todayStreak ? '#62AF41' : '#5e6367'
//     );

//     // Outer Doughnut Data for Total Dosage Consumption
//     const totalDosage = dosagePerSession * sessionsPerDay;
//     const totalConsumed = consumingQuantities.reduce(
//       (acc, qty) => acc + qty,
//       0
//     );
//     const consumedPercentage = (totalConsumed / totalDosage) * 100;
//     const dosageData = [consumedPercentage, 100 - consumedPercentage];
//     const dosageColors = ['#62AF41', '#5e6367']; // Green for consumed, grey for remaining

//     console.log({ dosageData });

//     // Create new chart instance
//     chartInstanceRef.current = new Chart(ctx, {
//       type: 'doughnut',
//       data: {
//         labels: ['Consumed Dosage', 'Remaining Dosage'],
//         datasets: [
//           {
//             label: 'Total Dosage',
//             data: dosageData,
//             backgroundColor: dosageColors,
//             borderColor: '#FFFFFF',
//             borderWidth: 2,
//             weight: 0.5, // Outer doughnut
//           },
//           {
//             label: 'Session Progress',
//             data: sessionData,
//             backgroundColor: sessionColors,
//             borderColor: '#000',
//             borderWidth: 3,
//             weight: 1, // Inner doughnut
//           },
//         ],
//       },
//       options: {
//         responsive: true,
//         maintainAspectRatio: false,
//         animation: {
//           animateScale: true,
//           animateRotate: true,
//         },
//         plugins: {
//           legend: {
//             display: false,
//           },
//           tooltip: {
//             enabled: true,
//           },
//         },
//         cutout: '50%', // Adjust for better visual distinction
//       },
//     });

//     return () => {
//       if (chartInstanceRef.current) {
//         chartInstanceRef.current.destroy();
//       }
//     };
//   }, [todayStreak, sessionsPerDay, dosagePerSession, consumingQuantities]);

//   return (
//     <div style={{ width: '100%', height: '250px' }}>
//       <canvas ref={chartRef} width="300" height="300"></canvas>
//     </div>
//   );
// };

// export default DonutsChart;

import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

const DonutsChart = ({
  todayStreak,
  sessionsPerDay,
  dosagePerSession,
  consumingQuantities,
}) => {
  const chartRef = useRef(null);
  const chartInstanceRef = useRef(null);

  useEffect(() => {
    const ctx = chartRef.current.getContext('2d');

    // Destroy any previous chart instance
    if (chartInstanceRef.current) {
      chartInstanceRef.current.destroy();
    }

    // Inner Doughnut Data for Sessions
    const sessionData = Array(sessionsPerDay).fill(100 / sessionsPerDay);
    const sessionColors = sessionData.map((_, index) =>
      index < todayStreak ? '#62AF41' : '#5e6367'
    );

    // Outer Doughnut Data for Daily Dosage Consumption
    // This uses dosagePerSession as the base for 100%
    // const totalConsumed = consumingQuantities.reduce(
    //   (acc, qty) => acc + qty,
    //   0
    // );
    // const consumedPercentage = (totalConsumed / dosagePerSession) * 100;
    // const dosageData = [consumedPercentage, 100 - consumedPercentage];
    // const dosageColors = ['#62AF41', '#5e6367']; // Green for consumed, grey for remaining

    // Create new chart instance
    chartInstanceRef.current = new Chart(ctx, {
      type: 'doughnut',
      data: {
        labels: ['Consumed Dosage', 'Remaining Dosage'],
        datasets: [
          // {
          //   label: 'Daily Dosage',
          //   data: dosageData,
          //   backgroundColor: dosageColors,
          //   borderColor: '#000',
          //   borderWidth: 0,
          //   weight: 0.5, // Outer doughnut
          // },
          {
            label: 'Session Progress',
            data: sessionData,
            backgroundColor: sessionColors,
            borderColor: '#000',
            borderWidth: 3,
            weight: 0.5, // Inner doughnut
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        animation: {
          animateScale: true,
          animateRotate: true,
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false,
          },
        },
        cutout: '80%', // Adjust for better visual distinction
      },
    });

    return () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }
    };
  }, [todayStreak, sessionsPerDay, dosagePerSession, consumingQuantities]);

  return (
    <div style={{ width: '100%', height: '250px' }}>
      <canvas ref={chartRef} width="300" height="300"></canvas>
    </div>
  );
};

export default DonutsChart;
