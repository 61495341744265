import { cannabisQuestions } from "@/components/CommonComponent/CommonData/CommonData";
import { Sparkles } from "lucide-react";
import React, { useState } from "react";

const AiSuggestions = ({ handleSubmit }) => {
  const [isPaused, setIsPaused] = useState(false);
  return (
    <>
      <h2 className="flex items-center gap-1 font-semibold text-lg mb-4 px-4 text-white">
        Ask Mary AI <Sparkles className="w-4 h-4" />
      </h2>
      <div className="relative overflow-x-auto scrollbar-hide">
        <div
          className="flex flex-col space-y-3 animate-marquee w-[200%]"
          style={{ animationPlayState: isPaused ? "paused" : "running" }}
          onMouseEnter={() => setIsPaused(true)}
          onMouseLeave={() => setIsPaused(false)}
        >
          {/* First row */}
          <div className="flex space-x-3">
            {cannabisQuestions.slice(0, Math.ceil(cannabisQuestions.length / 3)).map((question, index) => (
              <div
                onClick={() => handleSubmit(question)}
                key={index}
                className="bg-[#333333] text-white rounded-full py-1 px-4 shadow-md whitespace-nowrap cursor-pointer"
              >
                {question}
              </div>
            ))}
            {/* Duplicate items for seamless scroll */}
            {cannabisQuestions.slice(0, Math.ceil(cannabisQuestions.length / 3)).map((question, index) => (
              <div
                onClick={() => handleSubmit(question)}
                key={`dup-${index}`}
                className="bg-[#333333] text-white rounded-full py-1 px-4 shadow-md whitespace-nowrap cursor-pointer"
              >
                {question}
              </div>
            ))}
          </div>

          {/* Second row */}
          <div className="flex space-x-3">
            {cannabisQuestions.slice(Math.ceil(cannabisQuestions.length / 3), 2 * Math.ceil(cannabisQuestions.length / 3)).map((question, index) => (
              <div
                onClick={() => handleSubmit(question)}
                key={index}
                className="bg-[#333333] text-white rounded-full py-1 px-4 shadow-md whitespace-nowrap cursor-pointer"
              >
                {question}
              </div>
            ))}
            {/* Duplicate items for seamless scroll */}
            {cannabisQuestions.slice(Math.ceil(cannabisQuestions.length / 3), 2 * Math.ceil(cannabisQuestions.length / 3)).map((question, index) => (
              <div
                onClick={() => handleSubmit(question)}
                key={`dup-${index}`}
                className="bg-[#333333] text-white rounded-full py-1 px-4 shadow-md whitespace-nowrap cursor-pointer"
              >
                {question}
              </div>
            ))}
          </div>

          {/* Third row */}
          <div className="flex space-x-3">
            {cannabisQuestions.slice(2 * Math.ceil(cannabisQuestions.length / 3), cannabisQuestions.length).map((question, index) => (
              <div
                onClick={() => handleSubmit(question)}
                key={index}
                className="bg-[#333333] text-white rounded-full py-1 px-4 shadow-md whitespace-nowrap cursor-pointer"
              >
                {question}
              </div>
            ))}
            {/* Duplicate items for seamless scroll */}
            {cannabisQuestions.slice(2 * Math.ceil(cannabisQuestions.length / 3), cannabisQuestions.length).map((question, index) => (
              <div
                onClick={() => handleSubmit(question)}
                key={`dup-${index}`}
                className="bg-[#333333] text-white rounded-full py-1 px-4 shadow-md whitespace-nowrap cursor-pointer"
              >
                {question}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default AiSuggestions;
