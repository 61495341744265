import { Input } from '@/core/ui/input';
import React, { useState, useEffect, useRef } from 'react';
import maryIcon from '../../Images/maryIcon.png';
import { Send, X } from 'lucide-react';
import AiSuggestions from '@/common-components/search/AiSuggestions';
import SearchSuggestions from './SearchSuggestions';
import SearchResult from '@/common-components/search/SearchResult';
import RecentSearch from '@/common-components/search/RecentSearch';
import { Button } from '@/core/ui/button';
import { useLocalStorage } from '@/utils/useLocalStorage';
import { enqueueSnackbar } from 'notistack';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  useGetSearchAiSuggestionsQuery,
  useGetSearchResultQuery,
  useGetSearchSuggestionsQuery,
} from '@/redux/api/searchApi';
import { useSelector } from 'react-redux';
import {
  useClearAllHistoryMutation,
  useClearOneHistoryMutation,
  useGetAllSearchHistoryQuery,
  useGetSearchHistoryQuery,
} from '@/redux/api/recentHistoryApi';

const SearchBarMobile = ({ insideHeader }) => {
  // hooks
  const navigate = useNavigate();
  const location = useLocation();

  // global State
  const user = useSelector((state) => state?.auth?.user);

  // localState
  const [searchQuery, setSearchQuery] = useState('');
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [seeAllRecentSearch, setSeeAllRecentSearch] = useState(false);
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState('');
  const [triggerSearch, setTriggerSearch] = useState(false);
  const [localSearchResult, setLocalSearchResult] = useState(null);
  const [
    recentSearches,
    setRecentSearches,
    removeSearch,
    clearAllRecentSearches,
  ] = useLocalStorage('recentSearches', []);

  const searchInputRef = useRef(null);
  const debounceTimeout = useRef(null);
  const stickyOffset = 100;

  // Fetch search suggestions
  const { data: resultSuggestions } = useGetSearchSuggestionsQuery(
    debouncedSearchQuery,
    {
      skip: !debouncedSearchQuery,
    }
  );

  const { data: suggestions } = useGetSearchAiSuggestionsQuery(
    debouncedSearchQuery,
    {
      skip: !debouncedSearchQuery,
    }
  );
  //search result from the db
  const { data: searchResult } = useGetSearchResultQuery(debouncedSearchQuery, {
    skip: !triggerSearch,
  });

  const { data: searchHistory } = useGetSearchHistoryQuery(user?._id, {
    skip: !user || !isFullscreen,
  });

  const { data: AllSearchHistory } = useGetAllSearchHistoryQuery(user?._id, {
    skip: !user || !isFullscreen || !seeAllRecentSearch,
  });

  // clear all history
  const [clearAllHistoryFromBackend] = useClearAllHistoryMutation();

  // clear on hsitory
  const [clearOneHistoryFromBackend] = useClearOneHistoryMutation();

  const handleToggleFullscreen = () => {
    setIsFullscreen(!isFullscreen);
    setSearchQuery('');
    setSeeAllRecentSearch(false);
  };

  const handleScroll = () => {
    const inputDiv = searchInputRef.current;
    const offsetTop = inputDiv?.getBoundingClientRect().top || 0;

    if (offsetTop <= stickyOffset) {
      setIsSticky(true);
    }
    if (window.scrollY === 0) {
      setIsSticky(false);
    }
  };

  //set search result
  useEffect(() => {
    if (searchResult) {
      setLocalSearchResult(searchResult);
    }
  }, [searchResult]);

  // when search click so the search dropdown is close
  useEffect(() => {
    if (isFullscreen) {
      setIsFullscreen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);
  //handle background scroll
  useEffect(() => {
    if (isFullscreen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isFullscreen]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (searchQuery.trim() === '') return;

    // call the result api
    setTriggerSearch(true);

    //add recent search history and dubplicate remove and show at the first
    const newSearch = { type: 'search', query: searchQuery };
    const filteredSearches = recentSearches.filter(
      (search) => search.query !== searchQuery
    );
    setRecentSearches([newSearch, ...filteredSearches]);

    setSearchQuery('');
  };

  // delete single history from backend and localstorage as well
  const deleteSearch = async (query, searchId) => {
    try {
      if (user && searchId) {
        await clearOneHistoryFromBackend(searchId);
      }
      // Remove the search from local storage
      removeSearch(query);
    } catch (error) {
      enqueueSnackbar('Failed to delete search item', {
        variant: 'error',
      });
    }
  };

  // clear all history from local storage and backend
  const handleClearAll = async () => {
    try {
      if (user) {
        await clearAllHistoryFromBackend();
        // Clear local storage in all cases
        clearAllRecentSearches();
      }
    } catch (error) {
      enqueueSnackbar('Failed to clear history', {
        variant: 'error',
      });
    }
    clearAllRecentSearches();
    setSeeAllRecentSearch(false);
  };

  const aiHandleSubmit = (query) => {
    const newSearch = { type: 'question', query };
    const filteredSearches = recentSearches.filter(
      (search) => search.query !== query
    );
    setRecentSearches([newSearch, ...filteredSearches]);
    navigate(`/ai-search?query=${query}`);
    setIsFullscreen(false);
  };

  const handleSubmitSuggestion = (query) => {
    // call the result api
    setTriggerSearch(true);
    setDebouncedSearchQuery(query);

    //add recent search history and dubplicate remove and show at the first
    const newSearch = { type: 'search', query: query };
    const filteredSearches = recentSearches.filter(
      (search) => search.query !== query
    );
    setRecentSearches([newSearch, ...filteredSearches]);
    setSearchQuery('');
  };

  //handle recent search
  const handleRecentSearch = (seachData) => {
    const { type, query } = seachData;
    if (type === 'question') {
      const newSearch = { type, query };
      const filteredSearches = recentSearches.filter(
        (search) => search.query !== query
      );
      setRecentSearches([newSearch, ...filteredSearches]);
      navigate(`/ai-search?query=${query}`);
      setIsFullscreen(false);
    } else {
      handleSubmitSuggestion(query);
    }
  };

  // handleOnchnage
  const onChange = (e) => {
    const inputValue = e.target.value;

    setSearchQuery(inputValue);

    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(() => {
      // Only set the debounced search query if inputValue is not an empty string
      if (inputValue.trim() !== '' && inputValue.length <= 100) {
        setDebouncedSearchQuery(inputValue);
      } else if (inputValue.length > 100) {
        enqueueSnackbar('100 character limit exceeded', {
          variant: 'error',
          autoHideDuration: 1000,
        });
      }
    }, 500);
  };

  const handleInputFocus = () => {
    setSeeAllRecentSearch(false);
    setLocalSearchResult(null);
    setLocalSearchResult(null);
    setTriggerSearch(false);
  };

  return (
    <>
      <div
        ref={searchInputRef}
        className=" block md:hidden z-40 mx-auto w-full bg-[#2c2e2d] rounded-xl px-2"
        style={{ boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px' }}
      >
        <form
          action=""
          className="flex items-center justify-between w-full px-4 xs:px-1"
        >
          {isSticky ? (
            <img src={maryIcon} className="w-5 h-5 ml-3" alt="mary ai logo" />
          ) : (
            <></>
          )}
          <Input
            onClick={handleToggleFullscreen}
            className={`text-white border-none outline-none bg-transparent focus:outline-none focus:border-none ${
              isSticky ? 'text-left' : 'text-center'
            }`}
            type="text"
            value={''}
            placeholder="Ask anything or search"
            onChange={(e) => {}}
          />
        </form>
      </div>

      {isFullscreen && (
        <div
          className={`fixed inset-0 bg-black z-40 h-[100vh] overflow-y-auto pb-4 transition-all ease-in-out ${
            insideHeader ? '-mx-2' : ''
          } `}
        >
          <div className="flex justify-between items-center gap-2 py-4 px-4">
            <X
              onClick={handleToggleFullscreen}
              className="w-7 h-7 text-white cursor-pointer"
            />
            <div
              className="w-full bg-[#2c2e2d] rounded-xl"
              style={{ boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px' }}
            >
              <form
                onSubmit={handleSubmit}
                className="flex items-center justify-between w-full"
              >
                <img
                  src={maryIcon}
                  className="w-5 h-5 ml-3"
                  alt="mary ai logo"
                />
                <Input
                  autoFocus
                  onFocus={handleInputFocus}
                  className="text-white border-none outline-none bg-transparent focus:outline-none focus:border-none focus-visible:ring-0"
                  type="text"
                  value={searchQuery}
                  placeholder="Ask anything or search"
                  onChange={onChange}
                />
                <Button
                  className={`self-stretch p-0 w-12 flex justify-center items-center rounded-full focus:outline-none shadow-sm shadow-[#2c2c2c] transition-all ease-in-out ${
                    searchQuery !== ''
                      ? ' bg-mary-theme hover:bg-mary-theme'
                      : 'bg-[#333333] text-black hover:bg-[#333333]'
                  }`}
                >
                  <Send className="w-5 h-5" />
                </Button>
              </form>
            </div>
          </div>

          <div className="space-y-3">
            {!seeAllRecentSearch &&
              !localSearchResult &&
              searchQuery === '' && (
                <AiSuggestions handleSubmit={aiHandleSubmit} />
              )}
            {searchQuery !== '' && (
              <div className="space-y-3">
                <SearchSuggestions
                  title=""
                  icon="search"
                  result={suggestions?.query}
                  handleSubmit={(query) => handleSubmitSuggestion(query)}
                  isLoading={suggestions?.query ? false : true}
                />
                <SearchSuggestions
                  title="Ask Mary AI"
                  icon="ai"
                  result={suggestions?.ai}
                  handleSubmit={aiHandleSubmit}
                  isLoading={suggestions?.ai ? false : true}
                />
                <SearchResult
                  title="Strain"
                  result={resultSuggestions?.strains}
                  slug="strain"
                  SeeAllUrl="/strains"
                  isLoading={resultSuggestions?.strains ? false : true}
                />
                <SearchResult
                  title="Product"
                  result={resultSuggestions?.products}
                  slug="product"
                  SeeAllUrl="/products"
                  isLoading={resultSuggestions?.products ? false : true}
                />
                <SearchResult
                  title="Vendors"
                  result={resultSuggestions?.vendors}
                  slug="vendors"
                  SeeAllUrl="/vendors"
                  isLoading={resultSuggestions?.vendors ? false : true}
                />
              </div>
            )}

            {/* search result */}
            {localSearchResult && (
              <div>
                <SearchResult
                  title={'Strain'}
                  result={localSearchResult?.strains}
                  slug="strain"
                  SeeAllUrl="/strains"
                  isLoading={localSearchResult?.strains ? false : true}
                />
                {localSearchResult?.products.map((products) => (
                  <SearchResult
                    title={products?.category}
                    result={products?.products}
                    slug="product"
                    SeeAllUrl="/products"
                    isLoading={localSearchResult?.products ? false : true}
                  />
                ))}

                <SearchResult
                  title={'Vendors'}
                  result={localSearchResult?.vendors}
                  slug="vendors"
                  SeeAllUrl="/vendors"
                  isLoading={localSearchResult?.vendors ? false : true}
                />
              </div>
            )}
            {!localSearchResult &&
              !seeAllRecentSearch &&
              searchQuery === '' &&
              ((user && searchHistory?.length > 0) ||
                (!user && recentSearches.length > 0)) && (
                <RecentSearch
                  title="Recent"
                  result={
                    user && searchHistory?.length > 0
                      ? searchHistory
                      : recentSearches.slice(0, 5)
                  }
                  clearAll={handleClearAll}
                  deleteSearch={deleteSearch}
                  seeAllBtn={
                    (user && searchHistory?.length > 4) ||
                    (!user && recentSearches.length > 4)
                  }
                  handleSeeAll={() => setSeeAllRecentSearch(true)}
                  handleSubmit={handleRecentSearch}
                />
              )}

            {!localSearchResult &&
              seeAllRecentSearch &&
              ((user && AllSearchHistory?.history?.length > 0) ||
                (!user && recentSearches.length > 0)) && (
                <RecentSearch
                  title="Recent"
                  result={user ? AllSearchHistory?.history : recentSearches}
                  clearAll={handleClearAll}
                  deleteSearch={deleteSearch}
                  seeAllBtn={false}
                  handleSeeAll={() => setSeeAllRecentSearch(true)}
                  handleSubmit={handleRecentSearch}
                />
              )}
          </div>
        </div>
      )}
    </>
  );
};

export default SearchBarMobile;
