import React from 'react'
import ComingSoon from '../components/Layout/ComingSoon'
// import BreadCrums from '../components/BreadCrums/BreadCrums'

const Shop = () => {
  return (
    <>
       <ComingSoon/>
    </>
  )
}

export default Shop
