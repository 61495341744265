import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import SocialLinksModal from '../../brands/SocialLinksModal';
import SubscribeDropdown from '../../brands/SubscribeDropdown';
import { enqueueSnackbar } from 'notistack';
import {
  subscribeBusiness,
  unSubscribeBusiness,
} from '../../../features/addBusiness/addBusinessService';
import { getUrl } from '../../../utils/GetBussinessUrl';

const BussinessCard = ({ Data, OnUpdate, loading }) => {
  const userId = useSelector((state) => state.auth.user?._id);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleSubscribe = async (businessType, businessId) => {
    if (!isSubscribed) {
      try {
        const res = await subscribeBusiness(businessType, businessId);
        if (res) {
          OnUpdate();
          enqueueSnackbar(res?.message, { variant: 'success' });
        }
      } catch (error) {
        enqueueSnackbar(error?.message, { variant: 'error' });
      }
    }
  };

  const handleUnsubscribe = async (businessType, businessId) => {
    try {
      const res = await unSubscribeBusiness(businessType, businessId);
      if (res) {
        OnUpdate();
        enqueueSnackbar(res?.message, { variant: 'success' });
      }
    } catch (error) {
      enqueueSnackbar(error?.message, { variant: 'error' });
    }
  };

  const linksForModal = [
    { id: 'email', link: Data?.email, platform: 'email' },
    ...(Data?.socialLinks
      ? Data?.socialLinks?.map((link) => ({
          ...link,
          id: link?._id,
          platform: link?.platform,
        }))
      : []),
  ];

  const websiteLink = linksForModal?.find(
    (item) => item.platform === 'website'
  );

  const isSubscribed = useMemo(
    () => Data?.subscribers.find((item) => item === userId),
    [Data?.subscribers, userId]
  );
  return (
    <div className="w-full bg-[#2c2c2c] bg-opacity-50 shadow-md rounded-lg p-4">
      {console.log(getUrl(Data?.PrimaryVendor, Data?.slug), 'sssssss')}
      <Link
        to={getUrl(Data?.PrimaryVendor, Data?.slug)}
        className="flex items-center justify-between"
      >
        <img
          src={Data?.profileImg?.url ? Data?.profileImg?.url : Data?.profileImg}
          alt={Data?.title}
          className="rounded-full w-20 h-20 object-cover mr-4"
          loading="lazy"
        />
        <div className="flex-1 min-w-0 pb-2">
          <div className="flex justify-start items-center">
            {[
              Data?.PrimaryVendor || '',
              ...(Array.isArray(Data?.vendorTypes) ? Data.vendorTypes : []),
            ].map((item, index) => (
              <span key={index} className="text-white text-xs mr-2">
                {item?.charAt(0).toUpperCase() + item.slice(1)}{' '}
              </span>
            ))}
          </div>
          <div className="flex justify-between items-center">
            <span className="text-xl font-semibold capitalize whitespace-normal text-white">
              {Data?.title}
            </span>
          </div>
          <div className="flex items-center mt-1">
            <span className="text-sm font-semibold text-white">
              {Data?.subscribersCount} subscribers
            </span>
          </div>
        </div>
      </Link>
      <div
        onClick={openModal}
        className="truncate w-full pl-24 flex justify-start items-center"
      >
        <span className="text-gray-300 whitespace-nowrap truncate text-sm">
          {Data?.aboutUs}
        </span>
        <span className="text-lg text-gray-300">{'>'}</span>
      </div>
      <div className="flex justify-start items-center pl-24">
        <a
          href={websiteLink?.link}
          target="_blank"
          rel="noopener noreferrer"
          className="text-gray-300 hover:text-gray-500 text-sm overflow-hidden"
        >
          {websiteLink?.link}
        </a>
        <button
          onClick={openModal}
          className="text-white font-medium text-sm whitespace-nowrap"
        >
          2 More Links
        </button>
      </div>
      <SocialLinksModal
        isOpen={isModalOpen}
        onClose={closeModal}
        aboutUs={Data?.aboutUs}
        socialLinks={linksForModal}
        currentBusiness={Data}
      />
      <div className="py-2">
        <SubscribeDropdown
          OnUpdate={OnUpdate}
          loader={loading}
          isSubscribed={isSubscribed}
          onSubscribe={() => handleSubscribe(Data?.PrimaryVendor, Data?._id)}
          onUnsubscribe={() =>
            handleUnsubscribe(Data?.PrimaryVendor, Data?._id)
          }
          loggedInUser={userId}
          businessType={Data?.PrimaryVendor}
          isNotificationEnbale={Data?.notificationSubscribers}
          businessId={Data?._id}
        />
      </div>
    </div>
  );
};

export default BussinessCard;
