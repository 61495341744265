import { baseApi } from "./baseApi";

export const notificationApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getUserNotificationsCount: builder.query({
            query: () => 'notification/seen-count',
        }),
        getUserNotifications: builder.query({
            query: () => ({
                url: 'notification/',
                method: 'GET',
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }, // Include token if needed
            }),
        }),
    }),
});

export const { useGetUserNotificationsCountQuery, useGetUserNotificationsQuery } = notificationApi;
