import React, { useState } from 'react';
import { updateFolder } from '../../features/stashFolder/folderService';
import { IoClose, IoShareOutline } from 'react-icons/io5';
import SocialShareModal from '../StrainDetail/SocialShare';
import { FaEdit } from 'react-icons/fa';
import { MdDelete } from 'react-icons/md';
import { enqueueSnackbar } from 'notistack';

const FolderMenuModal = ({
  isOpen,
  onClose,
  folder,
  onEdit,
  onDelete,
  userState,
  Data,
  onVisibilityChange,
}) => {
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  if (!isOpen) return null;

  const handleVisibilityChange = async (folderId, visibility) => {
    setLoading(true);
    try {
      console.log(folderId, 'folderId');
      const res = await updateFolder(folderId, { visibility: visibility });
      if (res) {
        enqueueSnackbar(res?.message, { variant: 'success' });
        onVisibilityChange(visibility);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      enqueueSnackbar(error?.message, { variant: 'error' });
    }
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const isDefaultFolder =
    folder.name === 'Liked Strains' || folder.name === 'Liked Products';

  const shareUrl = `https://maryapp.io/stash-details/`;

  return (
    <div className="fixed inset-0 bg-opacity-75 flex justify-center items-center z-50">
      <div className="bg-[#2c2c2c] rounded-lg shadow-xl p-4 w-80">
        <div className="flex justify-between items-center border-b-2 border-gray-400">
          <h3 className="text-center text-white text-2xl font-bold flex-grow">
            {folder.name}
          </h3>
          <button onClick={onClose} className="text-white text-2xl">
            <IoClose />
          </button>
        </div>

        <div className="flex flex-col justify-start items-start p-6 gap-4">
          <div className="flex justify-center items-center gap-4 w-full">
            {!loading && (
              <>
                <button
                  className={`px-4 py-1.5 border text-white rounded-xl ${
                    Data?.visibility === 'private'
                      ? 'bg-[#62AF41] border-[#62AF41] hover:bg-[#457c2e]'
                      : 'bg-gray-700 hover:bg-gray-800'
                  }`}
                  onClick={() => handleVisibilityChange(Data?._id, 'private')}
                >
                  Private
                </button>
                <button
                  className={`px-4 py-1.5 border text-white rounded-xl ${
                    Data?.visibility === 'public'
                      ? 'bg-[#62AF41] border-[#62AF41] hover:bg-[#457c2e]'
                      : 'bg-gray-700 hover:bg-gray-800'
                  }`}
                  onClick={() => handleVisibilityChange(Data?._id, 'public')}
                >
                  Public
                </button>
              </>
            )}
            {loading && <h1 className=" text-white">Loading....</h1>}
          </div>
          <div className="flex justify-start items-center gap-2 pl-1">
            <FaEdit
              className="cursor-pointer text-white"
              size={28}
              onClick={() => !isDefaultFolder && onEdit(folder)}
              disabled={isDefaultFolder}
            />
            <span className="text-gray-300">Edit</span>
          </div>

          <div className="flex justify-start items-center gap-2">
            <MdDelete
              className="cursor-pointer text-white"
              size={32}
              onClick={() => !isDefaultFolder && onDelete(folder._id)}
              disabled={isDefaultFolder}
            />
            <span className="text-gray-300">Delete</span>
          </div>

          <div className="flex justify-start items-center gap-2">
            <IoShareOutline
              size={32}
              onClick={toggleModal}
              className="cursor-pointer text-white"
            />
            <span className="text-gray-300">Share</span>
          </div>
        </div>

        {isModalOpen && (
          <SocialShareModal
            isOpen={isModalOpen}
            url={`${shareUrl}${folder?._id}`}
            title={folder.name}
            strainImg={userState.userImg?.url}
            type={folder.description}
            onClose={toggleModal}
            des={'Share this folder'}
          />
        )}
      </div>
    </div>
  );
};

export default FolderMenuModal;
