import React from 'react'
import smiley from '../../Images/smiley.svg'
// import {  useLocation } from "react-router-dom"

const ComingSoon = () => {

    // const location = useLocation()
    return (
        <>
            <div className={` text-white flex flex-row justify-start items-center mx-20 mt-10 mb-16 text-center p-5 text-2xl font-semibold xs:text-lg`}>
                Coming Soon
                <img
                    src={smiley}
                    className='w-10 h-10'
                    alt=''
                />
            </div>
        </>
    )
}

export default ComingSoon
