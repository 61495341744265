import React from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import galeto from '../../Images/gelato.jpg';
import { BsFillCartFill } from 'react-icons/bs';
import { BsPlusSquareFill } from "react-icons/bs";
import { AiFillMinusSquare } from "react-icons/ai";
import { MdDelete } from "react-icons/md";
import { AiFillDelete } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { getLocalStrains } from '../../features/wishlist/wishListSlice';
import { addToWishList } from '../../features/strain/strainSlice';
import { updateQuantity, saveCartData } from '../../features/cart/cartSlice'

const StrainItems = ({ strainId, strainTitle, strainPrice }) => {
    const dispatch = useDispatch();
    const userState = useSelector((state) => [state.auth.user])
    const navigate = useNavigate();
    const location = useLocation();
    const [quantity, setQuantity] = React.useState(1);


    const handleRemove = (strainId) => {
        dispatch(saveCartData({id: strainId}));
      };

    const handleIncrement = () => {
        setQuantity((prevQuantity) => {
          const newQuantity = prevQuantity + 1;
          dispatch(updateQuantity({ id: strainId, quantity: newQuantity }));
          return newQuantity;
        });
      };
    
      const handleDecrement = () => {
        if (quantity > 1) {
          setQuantity((prevQuantity) => {
            const newQuantity = prevQuantity - 1;
            dispatch(updateQuantity({ id: strainId, quantity: newQuantity }));
            return newQuantity;
          });
        }
      };


    const handleShareClick = () => {
        // If the user is logged in
        if (userState[0]) {
            dispatch(saveCartData({id: strainId}));
            dispatch(addToWishList(strainId))
    
        } else {
            // redirect to signin
            navigate("/login")
        }
    };

    const deleteItems = (strainId) => {
        try {
            if(userState[0]){
                dispatch(addToWishList(strainId))
                .then((result) => {
                    if (addToWishList.fulfilled.match(result)) {
                        // addToWishList completed successfully, now dispatch getUserWishlist
                        // dispatch(getUserWishlist());
                        // dispatch(addToWishList());
                    } else {
                        // Handle addToWishList rejection here
                    }
                });
                
            }else{
                const strain = 'strain';
                const item = sessionStorage.getItem(strain); // Use sessionStorage here
                let strainJson = [];
        
                if (item !== null) {
                    strainJson = JSON.parse(item);
        
                    // Check if the _id already exists in the array
                    const existingIndex = strainJson.findIndex((strainItem) => strainItem.id === strainId);
                    if (existingIndex !== -1) {
                        // If it exists, remove it from the array
                        strainJson.splice(existingIndex, 1);
                        let updatedStrainJson = JSON.stringify(strainJson);
                        sessionStorage.setItem(strain, updatedStrainJson); // Use sessionStorage here
        
                        // Dispatch an action to update the local strains in Redux store
                        dispatch(getLocalStrains());
                    }
                }
            }
        
        } catch (error) {
            console.log(error, "StrainItems");
        }
        
    };
    
   
    if(location.pathname === '/stash'){
        return (
            <>
                <div className='grid grid-cols-4 '>
                    <div className=' flex flex-row-reverse md:flex-col-reverse justify-center items-center xs:flex-col-reverse xs:text-sm '>
                        <img
                            src={galeto}
                            alt='gelato'
                            className='p-2 lg:w-16 md:w-16 xs:w-12 mr-4 md:mr-0 xs:mr-0  border border-[#62AF41] rounded-full'
                        />
                    </div>
                    <div className=' text-center lg:text-lg md:text-lg xs:text-md pt-2 flex flex-row justify-center items-center'>
                        {strainTitle}
                    </div>
                    <div className='flex flex-row justify-center items-center w-full'>
                        <button
                            className=' flex flex-row justify-center items-center hover:text-red-500'
                            onClick={() => {
                                deleteItems(strainId);
                            }}
                        >
                            <AiFillDelete size={24} />
                        </button>
                    </div>
                    <div className=' text-center text-md pt-2 flex flex-row justify-center items-center'>
                        <button className='flex flex-row justify-center items-center  bg-[#62AF41] text-white px-2 py-2 rounded'
                         onClick={handleShareClick}>
                            <BsFillCartFill size={14} className='mr-2 xs:hidden' /> Add
                        </button>
                    </div>
                </div>
                <div className="flex items-center justify-center my-4 ">
                    <hr className="w-[80%] border-gray-300" />
                </div>
            </>
        );
    }
    else{
        return (
            <>
                <div className='grid grid-cols-5 '>
                    <div className=' flex flex-row-reverse md:flex-col-reverse justify-center items-center xs:flex-col-reverse xs:text-sm '>
                        <img
                            src={galeto}
                            alt='gelato'
                            className='p-2 lg:w-16 md:w-16 xs:w-12 mr-4 md:mr-0 xs:mr-0  border border-[#62AF41] rounded-full'
                        />
                    </div>
                    <div className=' text-center lg:text-lg md:text-lg xs:text-md pt-2 flex flex-row justify-center items-center'>
                        {strainTitle}
                    </div>
                    <div className=' text-center lg:text-lg md:text-lg xs:text-md pt-2 flex flex-row justify-center items-center'>
                        {strainPrice}
                    </div>
                    <div className="flex flex-row text-center text-md pt-2 justify-center items-center">
                      <button
                        className=" flex flex-row justify-center items-center px-1 py-1 rounded hover:text-[#62AF41]"
                        onClick={handleIncrement}
                      >
                        {<BsPlusSquareFill size={20} />}
                      </button>
                      {quantity}
                      <button
                        className=" flex flex-row justify-center items-center hover:text-[#62AF41]"
                        onClick={handleDecrement}
                      >
                        {<AiFillMinusSquare size={25} />}
                      </button>
                    </div>
                    <div className="flex flex-row text-center text-md pt-2 justify-center items-center">
                     <button
                       className="flex flex-row justify-center items-center hover:text-[#62AF41]"
                       onClick={() => handleRemove(strainId)}
                     >
                       {<MdDelete size={25} />}
                     </button>
                   </div>
                </div>
                <div className="flex items-center justify-center my-4 ">
                    <hr className="w-[90%] border-gray-300" />
                </div>
            </>
        );
    }
    
};

export default StrainItems;