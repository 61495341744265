import SearchLoading from '@/skeleton/SearchLoading';
import { Search, Sparkles } from 'lucide-react';
import React from 'react';


const SearchSuggestions = ({ title, icon, result, handleSubmit, isLoading }) => {

    if (isLoading) {
        return <SearchLoading title={title} />
    }
    return (
        <div className=' text-white flex flex-col gap-3 px-4'>
            {title && <h4 className=' text-xl font-bold pt-3 flex items-center gap-1'>{title} {title === "Ask Mary AI" && <Sparkles className="w-4 h-4" />} </h4>}
            {result?.map((item, index) => (
                <div onClick={() => handleSubmit(item)} key={index} className=" flex  items-center justify-start gap-2 cursor-pointer">
                    {icon === "search" && <Search className=" w-5 h-5  " />}
                    {icon === "ai" && <Sparkles className=" w-5 h-5  " />}
                    <span>{item}</span>
                </div>
            ))}

        </div>
    );
};

export default SearchSuggestions;
