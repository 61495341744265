import { useState } from 'react';
import { FaRegStar, FaStar } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';
import { MdDelete, MdEdit } from 'react-icons/md';

// Star component for individual stars
export const Star = ({ selected, onSelect }) => (
  <span onClick={onSelect} style={{ cursor: 'pointer' }}>
    {selected ? <FaStar /> : <FaRegStar />}
  </span>
);

// this is Effect Item section
export const EffectItem = ({
  effectName,
  effectIcon,
  onSelect,
  isSelected,
  isMaxSelected,
  handleEdit,
  handleDelete,
  isEditable,
}) => {
  const [showEditable, setShowEditable] = useState(false);
  const IconComponent = effectIcon; // This is the icon component imported from `react-icons`
  let borderColor, backgroundColor;

  const location = useLocation();
  const currentPath = location.pathname;

  if (isSelected) {
    borderColor = backgroundColor = '#878585'; // Color for selected items
  } else if (currentPath.includes('/admin')) {
    if (isMaxSelected) {
      borderColor = backgroundColor = '#4b4646'; // Special color for /admin/add-strain when max items are selected
    } else {
      borderColor = backgroundColor = '#252323'; // Special default color for /admin/add-strain
    }
  } else {
    if (isMaxSelected) {
      borderColor = backgroundColor = '#252323'; // Color when max items are selected
    } else {
      borderColor = backgroundColor = '#4b4646'; // Default color
    }
  }

  return (
    <div
      className="relative"
      onMouseEnter={() => setShowEditable(true)}
      onMouseLeave={() => setShowEditable(false)}
    >
      <div
        className={`flex flex-col items-center justify-center text-white p-2 rounded-md whitespace-nowrap `}
        onClick={() => onSelect(effectName)}
        style={{
          cursor: 'pointer',
          borderColor: borderColor,
          backgroundColor: backgroundColor,
        }}
      >
        {/* Replace with actual icon rendering logic */}
        {IconComponent && <IconComponent size={24} />}
        <p className="text-white">{effectName}</p>
      </div>
      {isEditable && showEditable && !isSelected && (
        <div className=" absolute top-0 right-0  flex flex-col gap-1 p-[4px]">
          <MdEdit
            className=" cursor-pointer"
            size={24}
            color="#ff0000"
            onClick={() => handleEdit(effectName)}
          />
          <MdDelete
            className=" cursor-pointer"
            color="#ff0000"
            size={24}
            onClick={() => handleDelete(effectName)}
          />
        </div>
      )}
    </div>
  );
};

// this is Flavour Item section
export const FlavourItem = ({
  flavorName,
  flavorIcon,
  onSelect,
  isSelected,
  isMaxSelected,
  handleEdit,
  handleDelete,
  isEditable,
}) => {
  const [showEditable, setShowEditable] = useState(false);
  const IconComponent = flavorIcon; // This is the icon component imported from `react-icons`
  let borderColor, backgroundColor;

  const location = useLocation();
  const currentPath = location.pathname;

  if (isSelected) {
    borderColor = backgroundColor = '#878585'; // Color for selected items
  } else if (currentPath.includes('/admin/strain-add')) {
    if (isMaxSelected) {
      borderColor = backgroundColor = '#403d3d'; // Special color for /admin/add-strain when max items are selected
    } else {
      borderColor = backgroundColor = '#252323'; // Special default color for /admin/add-strain
    }
  } else {
    if (isMaxSelected) {
      borderColor = backgroundColor = '#252323'; // Color when max items are selected
    } else {
      borderColor = backgroundColor = '#4b4646'; // Default color
    }
  }

  return (
    <div
      className="relative"
      onMouseEnter={() => setShowEditable(true)}
      onMouseLeave={() => setShowEditable(false)}
    >
      <div
        className={`flex flex-col items-center justify-center text-white p-2 rounded-md whitespace-nowrap`}
        onClick={() => onSelect(flavorName)}
        style={{
          cursor: 'pointer',
          borderColor: borderColor,
          backgroundColor: backgroundColor,
        }}
      >
        {/* Replace with actual icon rendering logic */}
        {IconComponent && <IconComponent size={24} />}
        <p className="text-white">{flavorName}</p>
      </div>

      {isEditable && showEditable && !isSelected && (
        <div className=" absolute top-0 right-0  flex flex-col gap-1 p-[4px]">
          <MdEdit
            className=" cursor-pointer"
            size={24}
            color="#ff0000"
            onClick={() => handleEdit(flavorName)}
          />
          <MdDelete
            className=" cursor-pointer"
            color="#ff0000"
            size={24}
            onClick={() => handleDelete(flavorName)}
          />
        </div>
      )}
    </div>
  );
};

export const CheckboxItem = ({
  itemName,
  onSelect,
  isSelected,
  isMaxSelected,
}) => {
  const checkboxClass = isSelected
    ? 'custom-checkbox selected'
    : isMaxSelected
    ? 'custom-checkbox max-selected'
    : 'custom-checkbox';

  return (
    <label className="flex items-center space-x-2">
      <input
        type="checkbox"
        checked={isSelected}
        onChange={() => onSelect(itemName)}
        className={checkboxClass}
        disabled={isMaxSelected && !isSelected}
      />
      <span>{itemName}</span>
    </label>
  );
};
