import React from 'react'
import { useSelector } from 'react-redux'

const DropButton = ({ text }) => {
  const userState = useSelector((state) => state.auth.user)

  return (
    <>
      {
        userState ? (
          <div className={`capitalize w-full ${ text === 'Articles' ? ('None') : ('border-b')}   border-[#62b041] flex flex-row justify-left`}>
            {text}
          </div>
        ) :
          (
            <div className={`capitalize w-full ${text === 'other'  ? ('None') : ('border-b')}   border-[#62b041] flex flex-row justify-left`}>
              {text}
            </div>
          )
      }

    </>
  )
}

export default DropButton
