import { TextField } from '@mui/material';
import { React, useEffect, useState } from 'react';
import { IoClose } from 'react-icons/io5';

const EditFolderModal = ({ loading, isOpen, onClose, folder, editFolder }) => {
  const [folderName, setFolderName] = useState('');
  const [folderDescription, setFolderDescription] = useState('');

  useEffect(() => {
    if (folder) {
      setFolderName(folder.name);
      setFolderDescription(folder.description);
    }
  }, [folder]);

  const handleSaveChanges = () => {
    editFolder({ ...folder, name: folderName, description: folderDescription });
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-opacity-75 flex justify-center items-center z-50">
      <div className="bg-[#2c2c2c] rounded-lg shadow-xl p-4 w-80">
        <div className="flex justify-between items-center border-b-2 border-gray-400">
          <h3 className="text-center text-white text-2xl font-bold ">
            Edit Your Folder
          </h3>
          <button onClick={onClose} className="text-white text-2xl">
            <IoClose />
          </button>
        </div>
        <div className="flex flex-col gap-6 w-full   py-4">
          <TextField
            id="folder-name"
            label="Folder Name"
            variant="standard"
            value={folderName}
            onChange={(e) => setFolderName(e.target.value)}
            InputLabelProps={{
              style: { color: 'white' },
            }}
            InputProps={{
              style: { color: 'white' },

              inputStyle: {
                '&::placeholder': {
                  color: 'white',
                },
              },
            }}
          />
          <TextField
            id="folder-description"
            label="Folder Description"
            variant="standard"
            value={folderDescription}
            onChange={(e) => setFolderDescription(e.target.value)}
            InputLabelProps={{
              style: { color: 'white' },
            }}
            InputProps={{
              style: { color: 'white' },
              inputStyle: {
                '&::placeholder': {
                  color: 'white',
                },
              },
            }}
          />
        </div>

        <div className="flex justify-center items-center">
          <button
            className="text-white px-6 py-1 rounded-2xl text-lg bg-gray-500"
            onClick={handleSaveChanges}
          >
            {loading ? 'Laoding...' : 'Save Changes'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditFolderModal;
