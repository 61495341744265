import React from 'react';

const CustomRangeSlider = ({ value, min, max, onChange, readOnly }) => {
  return (
    <div className=" flex flex-col justify-center items-center mt-2">
      <input
        className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
        type="range"
        value={value}
        min={min}
        max={max}
        readOnly={readOnly && readOnly}
        onChange={onChange && onChange}
      />
      <div className="w-full flex justify-between items-center">
        {Array.from({ length: max - min + 1 }).map((_, index) => (
          <span
            className="text-sm text-gray-500 dark:text-gray-400 ml-1.5"
            key={index}
          >
            {min + index}
          </span>
        ))}
      </div>
    </div>
  );
};

export default CustomRangeSlider;
