import React, { createContext, ReactNode } from "react";

import { useSessionContext } from "supertokens-auth-react/recipe/session";
import { SessionContextType, SessionContextUpdate } from "supertokens-auth-react/lib/build/recipe/session/types";

interface AuthContextType {
  user: {} | null;
  isLoading: boolean;
}

export const AuthContext = createContext<AuthContextType | null>(null);

interface AuthProviderProps {
  children: ReactNode;
}

export function AuthProvider({ children }: AuthProviderProps) {
  const session: SessionContextType & SessionContextUpdate = useSessionContext() as any;

  const contextPayload = {
    user: session.doesSessionExist ? session.accessTokenPayload : null,
    isLoading: session.loading,
  } as AuthContextType;
    

  return (
    <AuthContext.Provider value={contextPayload}>
      {children}
    </AuthContext.Provider>
  );
}