import {
    FaLeaf,
    FaQuestionCircle,
    FaCheckCircle,
    FaComments,
    FaSnowflake,
    FaUtensils,
    FaBed,
    FaRegLightbulb,
    FaBrain,
    FaEye,
    FaCloud,
    FaBolt,
    FaCouch,
    FaSmile,
    FaPeace,
    FaGrin,
    FaHeart,
    FaFeather,
    FaSpa,
    FaLaughBeam,
    FaRunning,
    FaBell,
    FaUserEdit

} from 'react-icons/fa';
import { IoIosColorFilter } from 'react-icons/io';
import {
    FaGasPump,
    FaFire,
    FaLemon,
    FaTree,
    FaPepperHot,
    FaCheese,
    FaFlask,
    FaSoap,
    FaIceCream,
    FaCandyCane,
    FaAppleAlt,
    FaTint,
} from 'react-icons/fa';
import {
    GiWoodPile,
    GiFruitTree,
    GiFruitBowl,
    GiKiwiFruit,
    GiPalmTree,
    GiRaspberry,
    GiOrange,
    GiGrapes,
    GiPineapple,
    GiStrawberry,
    GiBanana,
    GiChiliPepper,
    GiChocolateBar,
    GiMeat,
    GiWheat,
    GiHoneycomb,
    GiCook,
    GiWaterDrop,
    GiBubbles,
    GiWatermelon,
    GiPeach,
} from 'react-icons/gi';

import { IoMdNutrition, IoMdFlower } from 'react-icons/io';
import { TbLemon } from 'react-icons/tb';
import { RiCake3Fill } from 'react-icons/ri';
import { SiRootssage } from 'react-icons/si';



export const effectsList = [
    { name: 'Forgetful', icon: FaQuestionCircle },
    { name: 'Creative', icon: IoIosColorFilter },
    { name: 'Productive', icon: FaCheckCircle },
    { name: 'Chatty', icon: FaComments },
    { name: 'Chill', icon: FaSnowflake },
    { name: 'Hungry', icon: FaUtensils },
    { name: 'Sleepy', icon: FaBed },
    { name: 'Reflective', icon: FaRegLightbulb },
    { name: 'Focused', icon: FaBrain },
    { name: 'Clear', icon: FaEye },
    { name: 'Dreamy', icon: FaCloud },
    { name: 'Energetic', icon: FaBolt },
    { name: 'Lazy', icon: FaCouch },
    { name: 'Optimistic', icon: FaSmile },
    { name: 'Peaceful', icon: FaPeace },
    { name: 'Happy', icon: FaGrin },
    { name: 'Comfy', icon: FaCouch },
    { name: 'Frisky', icon: FaHeart },
    { name: 'Light', icon: FaFeather },
    { name: 'Mellow', icon: FaLeaf },
    { name: 'Relaxed', icon: FaSpa },
    { name: 'Silly', icon: FaLaughBeam },
    { name: 'Active', icon: FaRunning },
    { name: 'Alert', icon: FaBell },
];


export const flavorsList = [
    { name: 'Fuel', icon: FaGasPump },
    { name: 'Gas', icon: FaGasPump },
    { name: 'Burnt', icon: FaFire },
    { name: 'Lemon', icon: FaLemon },
    { name: 'Lime', icon: TbLemon },
    { name: 'Earth', icon: FaLeaf },
    { name: 'Musky', icon: IoMdFlower },
    { name: 'Wood', icon: GiWoodPile },
    { name: 'Bright', icon: FaLeaf },
    { name: 'Etheral', icon: FaLeaf },
    { name: 'Cirtus', icon: GiKiwiFruit },
    { name: 'Camphoric', icon: GiFruitTree },
    { name: 'Mentholic', icon: FaLeaf },
    { name: 'Floral', icon: IoMdFlower },
    { name: 'Grass', icon: FaLeaf },
    { name: 'Green', icon: FaLeaf },
    { name: 'Herbal', icon: FaLeaf },
    { name: 'Pine', icon: FaTree },
    { name: 'Plant', icon: GiFruitTree },
    { name: 'Vegetables', icon: FaLeaf },
    { name: 'Pungent', icon: FaPepperHot },
    { name: 'Sulphuric', icon: FaFlask },
    { name: 'Anisic', icon: FaLeaf },
    { name: 'Pepper', icon: FaPepperHot },
    { name: 'Rosemary', icon: SiRootssage },
    { name: 'Spicy', icon: GiChiliPepper },
    { name: 'Bitter', icon: FaLeaf },
    { name: 'Fatty', icon: GiMeat },
    { name: 'Fresh', icon: GiFruitBowl },
    { name: 'Sharp', icon: FaLeaf },
    { name: 'Sour', icon: FaLeaf },
    { name: 'Terpenic', icon: FaLeaf },
    { name: 'Waxy', icon: FaSoap },
    { name: 'Berry', icon: GiRaspberry },
    { name: 'Banana', icon: GiBanana },
    { name: 'Blueberry', icon: FaTint },
    { name: 'Grape', icon: GiGrapes },
    { name: 'Grapefruit', icon: GiFruitBowl },
    { name: 'Guava', icon: GiFruitTree },
    { name: 'Jam', icon: GiFruitBowl },
    { name: 'Lychee', icon: FaAppleAlt },
    { name: 'Mango', icon: IoMdNutrition },
    { name: 'Papaya', icon: GiFruitTree },
    { name: 'Peach', icon: GiPeach },
    { name: 'Pineapple', icon: GiPineapple },
    { name: 'Raspberry', icon: GiRaspberry },
    { name: 'Red fruit', icon: GiFruitTree },
    { name: 'Ripe', icon: GiFruitTree },
    { name: 'Stone fruit', icon: GiPeach },
    { name: 'Strawberry', icon: GiStrawberry },
    { name: 'Tangerine', icon: GiOrange },
    { name: 'Tropical', icon: GiPalmTree },
    { name: 'Yellow fruit', icon: GiFruitTree },
    { name: 'Cherry', icon: GiFruitBowl },
    { name: 'Coconut', icon: FaLeaf },
    { name: 'Dark Berry', icon: GiGrapes },
    { name: 'Fruit', icon: GiFruitTree },
    { name: 'Melon', icon: GiWatermelon },
    { name: 'Sweet', icon: RiCake3Fill },
    { name: 'Tart', icon: FaLeaf },
    { name: 'Bready', icon: FaLeaf },
    { name: 'Cereal', icon: GiWheat },
    { name: 'Butterscotch', icon: GiChocolateBar },
    { name: 'Honey', icon: GiHoneycomb },
    { name: 'Bubblegum', icon: GiBubbles },
    { name: 'Candy', icon: FaCandyCane },
    { name: 'Chocolate', icon: GiChocolateBar },
    { name: 'Cream', icon: FaIceCream },
    { name: 'Alliaceous', icon: GiCook },
    { name: 'Astringent', icon: GiWaterDrop },
    { name: 'Cheese', icon: FaCheese },
    { name: 'Chemical', icon: FaFlask },
    { name: 'Moth balls', icon: FaSoap },
    { name: 'Savory', icon: GiMeat },
];

// Conditions list for 'Helps with?' Here
export const conditionsList = [
    'ADD/ADHD',
    "Alzheimer's",
    'Anorexia',
    'Anxiety',
    'Arthritis',
    'Asthma',
    'Bipolar disorder',
    'Cachexia',
    'Cancer',
    'Cramps',
    "Crohn's disease",
    'Depression',
    'Epilepsy',
    'Eye pressure',
    'Fatigue',
    'Fibromyalgia',
    'Gastrointestinal disorder',
    'Glaucoma',
    'HIV/AIDS',
    'Headaches',
    'Hypertension',
    'Inflammation',
    'Insomnia',
    'Lack of appetite',
    'Migraines',
    'Multiple sclerosis',
    'Muscle spasms',
    'Muscular dystrophy',
    'Nausea',
    'PMS',
    'PTSD',
    'Pain',
    "Parkinson's",
    'Phantom limb pain',
    'Seizures',
    'Spasticity',
    'Spinal cord injury',
    'Stress',
    'Tinnitus',
    'Recreation',
    'Spirituality',
    'Socialization',
];


export const EffectIcon = ({ effect }) => {
    const effectItem = effectsList.find((e) => e.name === effect);
    const IconComponent = effectItem ? effectItem.icon : FaUserEdit;
    return (
        <div className="flex items-center mr-2">
            <IconComponent className="text-2xl mr-1" />
            <span className="text-sm">{effect}</span>
        </div>
    );
};

export const FlavourIcon = ({ flavor }) => {
    const flavorItem = flavorsList.find((e) => e.name === flavor);
    const IconComponent = flavorItem ? flavorItem.icon : FaUserEdit;
    return (
        <div className="flex items-center mr-2">
            <IconComponent className="text-2xl mr-1" />
            <span className="text-sm">{flavor}</span>
        </div>
    );
};

export const HelpsWithText = ({ helpWith }) => (
    <div className="flex items-center mr-2">
        <span className="text-sm">{helpWith}</span>
    </div>
);


export const AllBussiness = [

]



export const searchUiShow = [
    "/",
    "/discover",
    "/trending",
    "/strains",
    "/products",
    "/Brand",
    "/Breeder",
    "/grower",
    "/HashMaker",
    "/Dispensary",
    "/Events",
    "/Articles",
    "/People",
    "/Deals",
]

export const cannabisQuestions = [
    "🌿 What is cannabis used for?",
    "🍃 How does cannabis affect sleep?",
    "🧠 Can cannabis cause anxiety?",
    "🚭 Is smoking cannabis harmful?",
    "🌱 What are cannabis edibles?",
    "🔬 How is cannabis tested?",
    "🏥 Can cannabis treat pain?",
    "🧘‍♂️ Does cannabis help with stress?",
    "📈 What are cannabis market trends?",
    "⚖️ Is cannabis legal in my state?",
    "🌍 How does cannabis affect society?",
    "💊 What are the effects of CBD?",
    "🎉 Are there cannabis festivals?",
    "👩‍⚕️ How to use medical cannabis safely?",
    "📅 When was cannabis first used?",
    "🚗 Can I drive after using cannabis?",
    "🥗 Can you cook with cannabis oil?",
    "🔄 What is cannabis dependency?",
    "📚 Where to learn about cannabis?",
    "💡 What are THC and CBD differences?",
    "🌌 Does cannabis affect creativity?",
    "🎭 How does cannabis impact mood?",
    "📉 What are risks of heavy use?",
    "🏆 Which strains are best for relaxation?",
    "🍽️ What are the munchies effect?",
    "🎤 Who advocates for cannabis reform?",
    "🔍 How to identify quality weed?",
    "👩‍🔬 What research exists on cannabis effects?",
    "🌿 Can I grow my own cannabis plants?",
    "🚫 Are there age restrictions on use?",
    "🧠 What is cannabis CBD?",
    "💊 Does CBD relieve pain?",
    "🌱 Is CBD oil effective?",
    "🌍 Where is cannabis legal?",
    "🔍 What is medical cannabis?",
    "🚫 Is cannabis addictive?",
    "📚 What is cannabis culture?",
    "💡 What are edibles effects?",
    "🎯 What is THC's impact?",
    "🧠 How does cannabis work?",
    "🚶‍♂️ Is cannabis safe?",
    "🌿 What are terpenes?",
    "📈 Cannabis and the economy?",
    "🔍 How strong is CBD?",
    "🎭 Can cannabis lift mood?",
    "🏆 Best strain for focus?",
    "💡 What is CBD isolate?",
    "🍽️ Are edibles safe?",
    "🚶‍♂️ Can you overdose?",
    "🏡 Can I grow cannabis?",
    "🎤 Who supports legal cannabis?",
    "🔬 What is CBD used for?",
    "🧘‍♂️ Is cannabis good for yoga?",
    "🚶 Can I walk after cannabis?",
    "📉 What is CBD tolerance?",
    "💊 Can I take CBD daily?",
    "🏥 Can CBD help anxiety?",
    "🔍 How to test CBD oil?",
    "🚫 Is CBD oil legal?",
    "🌿 Can I grow hemp?",
    "🚶‍♂️ Is CBD addictive?",
    "🔬 What is full-spectrum CBD?",
    "📈 What is CBD pricing?",
    "🎤 Can CBD improve focus?",
    "🎯 Which CBD is best?",
    "💊 Can CBD replace meds?",
    "🧠 Does THC make you high?",
    "📚 What are CBD gummies?",
    "🌿 What are cannabis oils?",
    "🔍 What is cannabis resin?",
    "🚫 Is THC safe for kids?",
    "🎯 What is hemp extract?",
    "💉 Is CBD used in medicine?",
    "🏆 What are top CBD strains?",
    "📦 How is cannabis packaged?",
    "🌱 What are the best seeds?",
    "🛏️ Can cannabis aid sleep?",
    "🍽️ What is microdosing cannabis?",
    "🚶‍♂️ Can CBD help recovery?",
    "🧘‍♂️ How to meditate with cannabis?",
    "🚗 Can I drive after CBD?",
    "🎯 What are cannabis flowers?",
    "🛏️ How long does CBD last?",
    "🎨 Does cannabis boost creativity?",
    "💉 Can THC help with pain?",
    "🚫 Is CBD legal worldwide?",
    "🔬 What is nano CBD?",
    "🧠 What does CBD feel like?",
    "🚨 Is cannabis a gateway drug?",
    "🌿 What is hemp used for?",
    "🛏️ Can cannabis improve REM sleep?",
    "🏆 What is the strongest THC?",
    "🚶‍♂️ How does CBD affect exercise?",
    "💡 Can I vape CBD oil?",
    "🎤 Who promotes cannabis use?",
    "🚗 Does cannabis impair driving?",
    "🍽️ What is cannabis tea?",
    "🧘‍♂️ Can CBD help relax?",
    "🎯 What is the best cannabis?",
    "🔍 How to identify CBD oil quality?",
    "💼 How to open a cannabis store?",
    "🧠 Can cannabis improve focus and concentration?",
    "🚭 What are the dangers of smoking cannabis?",
    "🌱 How do cannabis tinctures work?",
    "🔬 What is the difference between CBD and THC?",
    "🏥 How does cannabis affect chronic pain?",
    "🧘‍♂️ Can cannabis be used in yoga or meditation?",
    "📈 What is the future of the cannabis industry?",
    "⚖️ What are the different types of cannabis licenses?",
    "🌍 How does cannabis legalization affect crime rates?",
    "💊 Can CBD help with seizures?",
    "🎉 What happens at cannabis expos?",
    "👩‍⚕️ How do doctors decide on cannabis prescriptions?",
    "📅 How long has cannabis been used medicinally?",
    "🚗 How does cannabis impact driving ability?",
    "🥗 What are the benefits of using cannabis oil in cooking?",
    "🔄 Can you develop a tolerance to cannabis?",
    "📚 Where can I find research on cannabis?",
    "💡 What is the entourage effect in cannabis?",
    "🎭 Does cannabis help stabilize emotions?",
    "📉 What are the withdrawal symptoms from cannabis?",
    "🏆 What strains are best for anxiety relief?",
    "🍽️ What are the most common effects of edibles?",
    "🎤 Who are the key advocates for cannabis reform?",
    "🔍 What are the signs of quality cannabis products?",
    "👩‍🔬 What is the current research on cannabis?",
    "🌿 How many cannabis plants can you legally grow?",
    "🚫 What happens if minors are caught with cannabis?",
    "🧔 What are the common side effects of cannabis use?",
    "🏬 Can cannabis be bought in stores legally?",
    "📦 How are cannabis deliveries regulated?",
    "🚨 What are the penalties for illegal cannabis use?",
    "🍄 How does cannabis compare to other natural substances?",
    "🍵 What are the benefits of cannabis teas?",
    "🎨 How does cannabis influence creativity in artists?",
    "🏡 What are the legal requirements for growing cannabis at home?",
    "📉 What is the impact of cannabis legalization on jobs?",
    "👩‍🔧 What is CO2 extraction in cannabis processing?",
    "🌍 Can cannabis be grown sustainably?",
    "🍫 What are the effects of cannabis chocolate?",
    "🚶 Is it possible to overdose on THC?",
    "💼 How to start a cannabis dispensary?",
    "👥 Does cannabis use impact social relationships?",
    "🚴 Is cannabis allowed in professional sports?",
    "🛏️ How can cannabis improve sleep quality?",
    "💼 What jobs are available in the cannabis industry?",
    "💉 Can cannabis help reduce opioid use?",
    "🎯 Which strains are best for concentration?",
    "🌿 What are the benefits of cannabis?",
    "🧘‍♂️ How does cannabis relax the body?",
    "💡 Can cannabis boost creativity?",
    "🏥 How does cannabis ease pain?",
    "🍽️ How does cannabis affect appetite?",
    "📅 When is the best time to use cannabis?",
    "💭 How does cannabis impact sleep?",
    "🚶 How long do cannabis effects last?",
    "💊 Can cannabis reduce anxiety?",
    "🎯 Which strains help focus?",
    "💼 Can cannabis improve productivity?",
    "💊 Can cannabis replace medication?",
    "🏆 What is the best strain for sleep?",
    "🎨 Does cannabis enhance artistic skills?",
    "🏋️ Can cannabis aid workout recovery?",
    "🚶 Does cannabis help with stress relief?",
    "🎯 What strains are best for creativity?",
    "💊 Can cannabis help with PTSD?",
    "🎭 Does cannabis help with depression?",
    "💡 How does cannabis affect the brain?",
    "🌱 Is cannabis good for health?",
    "🧘‍♂️ How does cannabis improve mood?",
    "🍽️ Does cannabis increase appetite?",
    "💼 Can cannabis improve mental clarity?",
    "💉 Can cannabis treat chronic pain?",
    "🚶 How fast does cannabis take effect?",
    "🛏️ Does cannabis help with insomnia?",
    "🌱 What are the calming effects of cannabis?",
    "📅 Can I use cannabis daily?",
    "💡 What are the mental effects of cannabis?",
    "🍃 How does cannabis affect relaxation?",
    "💉 Can cannabis replace opioids?",
    "🧠 How does cannabis affect the mind?",
    "🎯 Which strains help with creativity?",
    "🛏️ Does cannabis make you sleepy?",
    "🌿 How does cannabis help with recovery?",
    "🚶 Is cannabis safe for daily use?",
    "🏋️ Can cannabis enhance workouts?",
    "📈 How does cannabis impact focus?",
    "🎨 Does cannabis boost imagination?",
    "🎯 Which strains boost energy?",
    "💡 Does cannabis help with problem-solving?",
    "🧘‍♂️ Can cannabis help with meditation?",
    "🌱 How does cannabis relieve tension?",
    "💊 Is cannabis good for mental health?",
    "🏥 Can cannabis help with migraines?",
    "💡 Does cannabis improve concentration?",
    "🛏️ Can cannabis improve deep sleep?",
    "🎭 Can cannabis lift your mood?",
    "💉 Does cannabis help with inflammation?",
    "🌿 How does cannabis aid digestion?",
    "🚶 Is cannabis good for relaxation?",
    "🎨 Can cannabis spark creativity?",
    "💭 How does cannabis affect thoughts?",
    "🎯 What is the best time to consume cannabis?",
    "💡 Can cannabis make you more alert?",
    "🚶 How long do cannabis effects last?",
    "🛏️ Is cannabis effective for rest?",
    "🧘‍♂️ Can cannabis calm the mind?",
    "🏋️ How does cannabis help muscle recovery?",
    "🎯 What strains are best for focus?",
    "🛏️ Can cannabis help with jet lag?",
    "🧘‍♂️ Does cannabis help with mindfulness?",
    "💭 Can cannabis reduce negative thoughts?",
    "💡 How does cannabis affect decision-making?",
    "🍽️ Does cannabis improve appetite control?",
    "🏥 Can cannabis help with arthritis?",
    "🎭 Can cannabis reduce irritability?",
    "💊 Can cannabis ease chemotherapy symptoms?",
    "🚶 How does cannabis improve mobility?",
    "🛏️ Is cannabis good for REM sleep?",
    "🎯 What strains help with social anxiety?",
    "🎭 Does cannabis reduce anxiety in public?",
    "💡 Can cannabis sharpen thinking?",
    "🧘‍♂️ How does cannabis help relaxation?",
    "💭 Does cannabis reduce overthinking?",
    "🎯 What strains increase focus?",
    "🧘‍♂️ Can cannabis help clear the mind?",
    "🍽️ Does cannabis help with digestion?",
    "🏋️ Can cannabis relieve muscle soreness?",
    "💉 Does cannabis help with chronic pain?",
    "🛏️ Does cannabis improve sleep quality?",
    "💭 Can cannabis reduce brain fog?",
    "🧘‍♂️ How does cannabis affect stress levels?",
    "🌿 What are the soothing effects of cannabis?",
    "🚶 How does cannabis improve flexibility?",
    "💉 Can cannabis replace traditional painkillers?",
    "🍽️ Does cannabis promote healthy digestion?",
    "🛏️ Does cannabis aid restful sleep?",
    "🎯 What is the best strain for focus?",
    "💊 Can cannabis help with panic attacks?",
    "🏋️ Can cannabis improve workout endurance?",
    "💡 Does cannabis improve creativity?",
    "🧘‍♂️ How does cannabis help calm nerves?",
    "🛏️ Does cannabis reduce nighttime anxiety?"
];

