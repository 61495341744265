import React, { useEffect, useState, useRef, useMemo } from 'react';
import DetailsContent from '../brandDetail/DetailsAboutContent';
import AmenitiesComponent from '../brandDetail/AmenitiesComponent';
import AboutUsContent from '../brandDetail/aboutUsContent';
import HoursContent from '../brandDetail/HoursContent';
import OrderOptionsComponent from '../brandDetail/OrderOptionsComponent';
import { RxCross2 } from 'react-icons/rx';

// TabHeaders component
const TabHeaders = ({ tabs, activeTab, refs, handleTabClick }) => {
  return (
    <div className="flex p-3 bg-[#0f0f0f] lg:hidden overflow-x-auto no-about-tab-scrollbar">
      {tabs.map((tab) => (
        <button
          key={tab}
          className={`relative mr-4 flex-grow whitespace-nowrap text-white ${
            activeTab === tab ? 'font-bold' : ''
          }`}
          onClick={() => handleTabClick(tab, refs[tab])}
        >
          {tab}
          {activeTab === tab && (
            <div className="absolute bottom-0 top-8 left-0 right-0 h-1 bg-white"></div>
          )}
        </button>
      ))}
    </div>
  );
};

// AboutContent component
const AboutContent = ({ currentBrand, onClose }) => {
  const [activeTab, setActiveTab] = useState('Details');
  // eslint-disable-next-line
  const [isTabClicked, setIsTabClicked] = useState(false);
  // eslint-disable-next-line
  const [scrollPosition, setScrollPosition] = useState(0);
  // eslint-disable-next-line
  const [tabClickInProgress, setTabClickInProgress] = useState(false);

  const hasValidAmenities = useMemo(
    () =>
      currentBrand.amenities &&
      currentBrand.amenities.some((amenity) => amenity),
    [currentBrand.amenities]
  );

  const hasValidHours = useMemo(() => {
    const hours = currentBrand.hoursOfOperation;
    return (
      hours &&
      Object.keys(hours).some((day) => {
        const timings = hours[day];
        return (
          timings.open &&
          timings.open.trim() !== '' &&
          timings.open.trim().toLowerCase() !== 'closed' &&
          timings.close &&
          timings.close.trim() !== '' &&
          timings.close.trim().toLowerCase() !== 'closed'
        );
      })
    );
  }, [currentBrand.hoursOfOperation]);

  const tabs = useMemo(() => {
    let baseTabs = ['Details', 'Order Options', 'About Us'];
    if (hasValidAmenities) baseTabs.push('Amenities');
    if (hasValidHours) baseTabs.push('Hours of Operation');
    return baseTabs;
  }, [hasValidAmenities, hasValidHours]);

  const modalContentRef = useRef(null);
  const detailsRef = useRef(null);
  const amenitiesRef = useRef(null);
  const orderOptionsRef = useRef(null);
  const aboutUs = useRef(null);
  const hoursRef = useRef(null);

  const headerHeight = 118; // The height of your fixed header
  const additionalBuffer = 100; // Additional space for a comfortable switch
  const threshold = headerHeight + additionalBuffer;

  const handleScroll = () => {
    if (tabClickInProgress) {
      // Do not update the active tab if a tab click is in progress
      return;
    }

    // The current scroll position plus the threshold will give you the point in the page to compare against
    const scrollCheckPosition = modalContentRef.current.scrollTop + threshold;

    let selectedTab = null;
    Object.keys(contentRefs).forEach((tab) => {
      const ref = contentRefs[tab];
      if (ref.current) {
        const sectionTop = ref.current.offsetTop; // The top position of the section
        const sectionBottom = sectionTop + ref.current.offsetHeight; // The bottom position of the section

        // If the scroll check position is within the bounds of the section
        if (
          scrollCheckPosition >= sectionTop &&
          scrollCheckPosition < sectionBottom
        ) {
          selectedTab = tab;
        }
      }
    });

    if (selectedTab !== null) {
      setActiveTab(selectedTab);
    }
  };

  const contentRefs = useMemo(
    () => ({
      Details: detailsRef,
      Amenities: amenitiesRef,
      'Order Options': orderOptionsRef,
      'About Us': aboutUs,
      'Hours of Operation': hoursRef,
    }),
    [detailsRef, amenitiesRef, orderOptionsRef, aboutUs, hoursRef]
  );

  // Modify how you add the event listener to use a passive event listener
  useEffect(() => {
    const modalContent = modalContentRef.current;
    if (modalContent) {
      const opts = { passive: true };
      modalContent.addEventListener('scroll', handleScroll, opts);
    }

    return () => {
      if (modalContent) {
        modalContent.removeEventListener('scroll', handleScroll);
      }
    };
    // eslint-disable-next-line
  }, [contentRefs]);

  useEffect(() => {
    // Add the no-about-previouse-scroll class when the component mounts
    document.body.classList.add('no-about-previouse-scroll');

    // Cleanup function to remove the no-about-previouse-scroll class
    return () => {
      document.body.classList.remove('no-about-previouse-scroll');
    };
  }, []);

  // Modify your existing onClose function or method
  // eslint-disable-next-line
  const modifiedOnClose = () => {
    document.body.classList.remove('no-about-previouse-scroll');
    if (onClose) {
      onClose();
    }
  };

  const scrollToContent = (tab, ref) => {
    if (ref.current && modalContentRef.current) {
      const modalContent = modalContentRef.current;
      const offsetTop = ref.current.offsetTop;
      const stickyHeaderHeight = document.querySelector('.sticky.top-0')
        ? document.querySelector('.sticky.top-0').offsetHeight
        : 0;

      modalContent.scrollTo({
        top: offsetTop - stickyHeaderHeight,
        behavior: 'smooth',
      });
    }
  };

  const handleTabClick = (tab, ref) => {
    scrollToContent(tab, ref);
  };

  useEffect(() => {
    if (isTabClicked) {
      // Agar tab click se scroll hua hai, to is logic ko execute nahi karenge
      return;
    }

    // eslint-disable-next-line
    let selectedTab = null;
    Object.keys(contentRefs).forEach((tab) => {
      const ref = contentRefs[tab];
      if (ref.current) {
        const sectionTop =
          ref.current.offsetTop - headerHeight - additionalBuffer;
        const sectionBottom = sectionTop + ref.current.offsetHeight;
        if (scrollPosition >= sectionTop && scrollPosition < sectionBottom) {
          selectedTab = tab;
        }
      }
    });
  }, [scrollPosition, contentRefs, isTabClicked]);

  return (
    <div className="fixed inset-0 z-40 bg-[#0f0f0f] bg-opacity-76 transition-opacity">
      <div
        className="fixed flex-grow inset-0 z-50 overflow-y-auto"
        ref={modalContentRef}
      >
        <div className="sticky top-0 bg-[#0f0f0f] shadow-md lg:shadow-none lg:pt-12">
          <div className="bg-[#0f0f0f] px-4 py-2 h-16 flex items-center justify-between lg:justify-end border-[#424242] lg:-mt-2">
            <h3 className="text-xl leading-6 font-semibold text-gray-300 flex-1 lg:text-left  ">
              Business Details
            </h3>
            <button
              onClick={onClose}
              className="text-gray-300 hover:text-gray-500 z-50 lg:absolute lg:text-right"
            >
              <RxCross2 size={22} color={'#ffffff'} />
            </button>
          </div>
          <div
            className="xs:hidden sm:hidden lg:block bg-gray-300 "
            style={{ height: '1px', width: '100%' }}
          ></div>
          <TabHeaders
            tabs={tabs}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            refs={contentRefs}
            modalContentRef={modalContentRef}
            scrollToContent={scrollToContent}
            handleTabClick={handleTabClick}
          />
        </div>
        <div className="bg-[#0f0f0f] pt-4  h-full">
          <div className=" ">
            <div className="   " ref={detailsRef}>
              <DetailsContent data={currentBrand} />
            </div>

            <div>
              {currentBrand.orderOptions && (
                <div ref={orderOptionsRef}>
                  <OrderOptionsComponent
                    orderOptions={currentBrand.orderOptions}
                  />
                </div>
              )}
            </div>

            <div ref={aboutUs} className="">
              <AboutUsContent AboutUs={currentBrand.aboutUs} />
            </div>

            <div className="">
              {hasValidAmenities && (
                <div ref={amenitiesRef}>
                  <AmenitiesComponent amenities={currentBrand.amenities} />
                </div>
              )}

              {hasValidHours && (
                <div ref={hoursRef} className="">
                  <HoursContent
                    hoursOfOperation={currentBrand.hoursOfOperation}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutContent;
