import { useEffect, useState } from 'react';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

const GoalCard = ({
  title,
  Qty,
  Type,
  DayType,
  setQty,
  dosageUnit,
  setDosageUnit,
  setGoal,
  loading,
}) => {
  const units = ['Gram', 'Milligram', 'Dab', 'Ounce', 'Unit'];

  const [currentIndex, setCurrentIndex] = useState(0);

  const handleUpClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? units.length - 1 : prevIndex - 1
    );
  };

  const handleDownClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === units.length - 1 ? 0 : prevIndex + 1
    );
  };
  useEffect(() => {
    setDosageUnit && setDosageUnit(units[currentIndex]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex, setDosageUnit]);

  return (
    <div className=" bg-[#1d1d1d] px-6 py-5 flex  flex-col gap-4">
      <div className=" text-center text-[#8b8c86]">{title}</div>
      <div className=" flex justify-between items-center">
        <div
          onClick={() => setQty(Qty - 1)}
          className="w-7 h-7 rounded-full bg-black flex justify-center item-center cursor-pointer"
        >
          -
        </div>
        <div
          className={` flex flex-col justify-center items-center ${
            Type === 'Session' ? 'py-2 ' : ''
          } `}
        >
          <span className="text-4xl  font-bold">{Qty}</span>
          {Type === 'Dosage' ? (
            <div>
              <span className=" text-sm flex justify-center items-center gap-3">
                <span className=" flex flex-col gap-2">
                  <IoIosArrowUp
                    className="cursor-pointer"
                    onClick={handleUpClick}
                  />
                  <IoIosArrowDown
                    className="cursor-pointer"
                    onClick={handleDownClick}
                  />
                </span>{' '}
                {units[currentIndex]}/{DayType}
              </span>
            </div>
          ) : (
            <span className=" text-sm">
              {Type}/{DayType}
            </span>
          )}
        </div>
        <div
          onClick={() => setQty(Qty + 1)}
          className="w-7 h-7 rounded-full bg-black flex justify-center item-center cursor-pointer"
        >
          +
        </div>
      </div>
      <div>
        <button
          onClick={() => setGoal()}
          className=" w-full p-2 rounded-lg bg-mary-theme text-black"
        >
          {loading ? 'loading...' : ' Set Goal'}
        </button>
      </div>
    </div>
  );
};

export default GoalCard;
