
import authReducer from '../features/auth/authSlice';
import cartReducer from '../features/cart/cartSlice';
import folderReducer from '../features/stashFolder/folderSlice';
import sessionReducer from '../features/sessions/sessionsSlice';
import userAddedDataReducer from "../features/userAddedData/userAddedData";


const rootReducer = {
    auth: authReducer,
    cart: cartReducer,
    folder: folderReducer,
    sessions: sessionReducer,
    userAddedData: userAddedDataReducer,
};

export default rootReducer;
