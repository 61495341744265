import axios from 'axios';
import { config } from '../../utils/axiosconfig';
import { base_url } from '../../utils/base_url';

const createDeal = async (data) => {
    const configure = config();
    const response = await axios.post(`${base_url}deals/`, data, configure);
    return response.data;
};

const updateDeal = async (deal) => {
    const configure = config();
    const response = await axios.put(`${base_url}deals/${deal.id}`,
        {
            description: deal.dealData.description,
            expiryDate: deal.dealData.expiryDate,
            dealImg: deal.dealData.dealImg,
        }, configure);
    return response.data;
};

const deleteDeal = async (id) => {
    const configure = config();
    const response = await axios.delete(`${base_url}deals/${id}`, configure);
    return response.data;
};

const uploadDealImg = async (data) => {
    const configure = config();
    const response = await axios.put(`${base_url}upload/`, data, configure);
    return response.data;
};

const deleteDealImg = async (id) => {
    const configure = config();
    const response = await axios.delete(`${base_url}upload/delete-image/${id}`, configure);
    return response.data;
};

export const getDeals = async () => {
    const configure = config();
    const response = await axios.get(`${base_url}deals/`, configure);
    return response.data;
};

const getDeal = async (id) => {
    const configure = config();
    const response = await axios.get(`${base_url}deals/${id}`, configure);
    return response.data;
};

const dealService = {
    createDeal,
    updateDeal,
    deleteDeal,
    uploadDealImg,
    deleteDealImg,
    getDeals,
    getDeal
};

export default dealService;