import React from 'react'
import ComingSoon from '../components/Layout/ComingSoon'

const FeedBack = () => {
  return (
    <ComingSoon/>
  )
}

export default FeedBack
