import { Skeleton } from '@mui/material';
import React from 'react';

const SearchLoading = ({ title }) => {
  return (
    <div className="flex flex-col gap-3 px-4 ">
      <h4 className="text-xl font-bold  text-white">{title}</h4>
      <div className="flex flex-col gap-2">
        <Skeleton
          sx={{ bgcolor: '#232524' }}
          variant="rounded"
          width={300}
          height={16}
        />
        <Skeleton
          sx={{ bgcolor: '#232524' }}
          variant="rounded"
          width={350}
          height={16}
        />
        <Skeleton
          sx={{ bgcolor: '#232524' }}
          variant="rounded"
          width={290}
          height={16}
        />
        <Skeleton
          sx={{ bgcolor: '#232524' }}
          variant="rounded"
          width={310}
          height={16}
        />
        <Skeleton
          sx={{ bgcolor: '#232524' }}
          variant="rounded"
          width={270}
          height={16}
        />
      </div>
    </div>
  );
};

export default SearchLoading;
