import React from 'react';
import { Skeleton } from '@mui/material';

const BreederCardLoader = ({ NumberOfCard }) => {
  return (
    <div className=" grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
      {Array.from({ length: NumberOfCard }).map((_, index) => (
        <div
          key={index}
          className=" flex flex-col gap-5 bg-[#2c2c2c] rounded-lg w-full p-6"
        >
          <div className=" flex items-center gap-5 ">
            <Skeleton variant="circular" width={40} height={40} />
            <div className="flex flex-col gap-2">
              <Skeleton variant="rectangular" width={200} height={10} />
              <Skeleton variant="rectangular" width={200} height={10} />
              <Skeleton variant="rectangular" width={200} height={20} />
            </div>
          </div>
          <Skeleton variant="rectangular" width={'100%'} height={30} />
        </div>
      ))}
    </div>
  );
};

export default BreederCardLoader;
