import { TextField } from '@mui/material';
import { useState } from 'react';
import { MdOutlineKeyboardBackspace } from 'react-icons/md';
import { enqueueSnackbar } from 'notistack';
import { createFolder } from '../../features/stashFolder/folderService';

const CreateFolderModal = ({ isOpen, onClose, onUpdate }) => {
  const [loading, setLoading] = useState(false);
  const [folderName, setFolderName] = useState('');
  const [folderDescription, setFolderDescription] = useState('');

  const handleCreateFolder = async () => {
    if (folderName.trim() !== '') {
      setLoading(true);
      try {
        const res = await createFolder({
          name: folderName,
          description: folderDescription,
        });
        if (res) {
          setFolderName('');
          setFolderDescription('');
          onClose();
          onUpdate();
          enqueueSnackbar(res?.message, { variant: 'success' });
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        enqueueSnackbar(error?.message, { variant: 'error' });
      }
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-opacity-75 flex justify-center items-center z-50">
      <div className="bg-[#2c2c2c] rounded-lg shadow-xl px-4 py-8 w-[340px] md:w-85 max-h-full overflow-auto">
        <div className="flex flex-col justify-between items-center w-full ">
          <div className="flex justify-start items-center w-full  border-gray-500">
            <button
              onClick={onClose}
              className="flex justify-start items-center gap-2 text-white text-lg bg-transparent border-none cursor-pointer p-2 focus:outline-none"
            >
              <MdOutlineKeyboardBackspace
                className=" cursor-pointer"
                onClick={onClose}
                size={18}
                color="#ffffff"
              />
            </button>
            <span className="flex-grow text-start text-white text-xl">
              Add new stash folder
            </span>
          </div>
          <div className="flex flex-col gap-6 w-full   py-4">
            <TextField
              id="folder-name"
              label="Folder Name"
              variant="standard"
              value={folderName}
              onChange={(e) => setFolderName(e.target.value)}
              InputLabelProps={{
                style: { color: 'white' },
              }}
              InputProps={{
                style: { color: 'white' },

                inputStyle: {
                  '&::placeholder': {
                    color: 'white',
                  },
                },
              }}
            />
            <TextField
              id="folder-description"
              label="Folder Description"
              variant="standard"
              value={folderDescription}
              onChange={(e) => setFolderDescription(e.target.value)}
              InputLabelProps={{
                style: { color: 'white' },
              }}
              InputProps={{
                style: { color: 'white' },
                inputStyle: {
                  '&::placeholder': {
                    color: 'white',
                  },
                },
              }}
            />
          </div>
        </div>
        <button
          onClick={handleCreateFolder}
          className="rounded-3xl bg-white text-black px-4 py-2 font-semibold text-sm"
        >
          {loading ? 'Loading....' : 'Create Folder'}
        </button>
      </div>
    </div>
  );
};

export default CreateFolderModal;
