import { BsThreeDotsVertical } from 'react-icons/bs';
import { CiCirclePlus } from 'react-icons/ci';
import { FaCheckCircle } from 'react-icons/fa';
import { formatDistanceToNow, endOfDay } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import { markNotificationAsSeen } from '../../features/notifications/notificationService';
import TrippleDotModal from '../CommonComponent/trippleDotModal/TrippleDotModal';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { addStrainToFolder } from '../../features/stashFolder/folderService';
import FolderSelectionModal from '../StrainDetail/FolderSelectionModal';

const NotificationItem = ({
  item,
  itemId,
  itemUrl,
  bussinessImage,
  type,
  postedTime,
  bussinessName,
  title,
  parent,
  category,
  weight,
  unit,
  price,
  itemImage,
  isSeen,
  onUpdate,
}) => {
  // hooks
  const navigate = useNavigate();

  // globalState
  const folders = useSelector((state) => state.folder.folders);

  // local State
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFolderModalOpen, setIsFolderModalOpen] = useState(false);

  const handleViewNotification = async (notificationId, data) => {
    try {
      const res = await markNotificationAsSeen(notificationId, data);
      if (res) {
        onUpdate();
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  const handleClick = (itemUrl) => {
    if (!isSeen) {
      handleViewNotification(item?._id, { seenBy: true });
    }
    navigate(itemUrl);
  };

  const toggleFolderModal = () => {
    setIsFolderModalOpen(true);
  };
  const isAddedInFolder = useMemo(() => {
    if (type === 'strain') {
      for (let folder of folders) {
        if (folder.strains?.find((item) => item?._id === itemId)) {
          return true;
        }
      }
    } else if (type === 'product') {
      for (let folder of folders) {
        if (folder.products?.find((item) => item?._id === itemId)) {
          return true;
        }
      }
    }

    return false;
  }, [itemId, folders, type]);

  const handleAddToStash = async () => {
    const likedStrainsFolder = folders?.find(
      (folder) =>
        folder.name === (type === 'strain' ? 'Liked Strains' : 'Liked Products')
    );

    console.log(likedStrainsFolder, 'ssssssssssss', type);
    try {
      let Data = {
        itemId,
        type: type,
      };
      const res = await addStrainToFolder(likedStrainsFolder._id, Data);
      if (res) {
        onUpdate();
        enqueueSnackbar(res?.message, { variant: 'success' });
      }
    } catch (error) {
      enqueueSnackbar(error?.message, { variant: 'error' });
    }
  };

  const renderIcon = () => {
    if (isAddedInFolder) {
      return (
        <FaCheckCircle
          size={28}
          className="text-[#62AF41]"
          onClick={toggleFolderModal}
        />
      );
    } else {
      return (
        <CiCirclePlus
          size={28}
          className="text-white"
          onClick={handleAddToStash}
        />
      );
    }
  };
  return (
    <div className=" flex items-center justify-between gap-2 w-full">
      <div
        onClick={() => handleClick(itemUrl)}
        className="flex justify-between items-center gap-3 cursor-pointer w-full"
      >
        <div className="min-w-[50px] w-[50px] h-[50px] rounded-full  object-contain   relative">
          <img
            className=" min-w-[50px] w-[50px] h-full rounded-full "
            src={bussinessImage}
            alt={title}
          />
          {!isSeen && (
            <div className=" w-[10px] h-[10px] rounded-full bg-mary-theme absolute bottom-0 right-2"></div>
          )}
        </div>
        <div className=" text-white w-full">
          <h4 className=" text-[10px] text-gray-400 line-clamp-1">
            New {type} posted
            {formatDistanceToNow(endOfDay(new Date(postedTime)), {
              addSuffix: true,
            })}
          </h4>
          <h3 className="text-[11px] line-clamp-1">{bussinessName}</h3>
          <h1 className=" text-[18px] font-bold line-clamp-1">{title}</h1>
          {parent && (
            <h2 className=" text-[12px]  font-bold line-clamp-1">{parent}</h2>
          )}
          <p className=" text-[10px] line-clamp-1 ">
            {category} {`${weight ? `-${weight}` : ''}`}{' '}
            {`${unit ? `${unit}` : ''}`}
            {`${price ? `- $${price}` : ''}`}
          </p>
        </div>
        <div className=" min-w-[70px] w-[80px] h-[80px]  rounded-full   self-stretch  object-fill">
          <img
            className="min-w-[70px] w-full h-full"
            src={itemImage}
            alt={title}
          />
        </div>
      </div>
      <div className=" flex flex-col gap-3">
        <BsThreeDotsVertical
          onClick={() => setIsModalOpen(true)}
          size={24}
          color="#ffffff"
        />
        {renderIcon()}
      </div>
      {folders && (
        <FolderSelectionModal
          itemType={type}
          itemId={itemId}
          backBtnText={`Back to ${type === 'strain' ? 'Strain' : 'Product'}`}
          isOpen={isFolderModalOpen}
          onClose={() => setIsFolderModalOpen(false)}
          onUpdate={() => onUpdate()}
        />
      )}
      <TrippleDotModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={item?.strain ? item?.strain?.title : item?.product?.title}
        itemImg={
          item?.strain
            ? item?.strain?.strainImg?.url
            : item?.product?.productImg?.url
        }
        itemId={item?.strain ? item?.strain?._id : item?.product?._id}
        itemType={item?.strain ? 'strain' : 'product'}
        onUpdate={onUpdate}
        business={
          item?.strain ? item?.strain?.business?.id : item?.product?.brand
        }
        shareUrl={
          item?.strain
            ? `https://maryapp.io/strain/${item?.strain?._id}`
            : `https://maryapp.io/product/${item?.product?._id}`
        }
      />
    </div>
  );
};

export default NotificationItem;
