import axios from 'axios';
import { config } from '../../utils/axiosconfig';

const baseUrl = process.env.REACT_APP_API_BASE_URL;

export const AddUserData = async (Data) => {
    try {
        const configure = config()
        const res = await axios.post(`${baseUrl}/user-data/add-data`, Data, configure);
        return res
    } catch (error) {
        return error;

    }

};
export const getUserData = async () => {
    try {
        const configure = config()
        const response = await axios.get(`${baseUrl}/user-data/get-data`, configure);
        return response.data;

    } catch (error) {
        return error;

    }

};

