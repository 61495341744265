import React from 'react'
import {  Modal } from 'antd';

const CustomModal = (props) => {
    const{open,performAction,hideModal,title}=props
  return (
    <>
         <Modal
        title={title}
        open={open}
        onOk={performAction}
        onCancel={hideModal}
        okText="OK"
        cancelText="Cancel"
      >
        {/* <p>{title}</p> */}
      </Modal>
    </>
  )
}

export default CustomModal
