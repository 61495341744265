import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { BsThreeDots } from 'react-icons/bs';
import ReviewModal from '../StrainDetail/ReviewModal';

import { FaStar, FaRegStar, FaThumbtack, FaUserEdit } from 'react-icons/fa';

import {
  effectsList,
  flavorsList,
} from '../CommonComponent/CommonData/CommonData';
import { deleteReview, pinReview } from '../../features/review/reveiwService';
import { enqueueSnackbar } from 'notistack';

const ReviewList = ({ reviews, onUpdate }) => {
  // global State
  const user = useSelector((state) => state.auth.user);

  // localState
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedReview, setSelectedReview] = useState(null);

  const handlePinToggle = async (review) => {
    const reviewPinnedBy = review?.reviewPinnedBy?.includes(user?._id);
    try {
      const res = await pinReview(review?._id, { reviewPin: !reviewPinnedBy });
      if (res) {
        onUpdate();
        setModalIsOpen(false);
        enqueueSnackbar(res?.message, { variant: 'success' });
      }
    } catch (error) {
      enqueueSnackbar(error?.message, { variant: 'error' });
    }
  };

  const handleDeleteReview = async (reviewId) => {
    try {
      const res = await deleteReview(reviewId);
      if (res) {
        onUpdate();
        setModalIsOpen(false);
        enqueueSnackbar(res?.message, { variant: 'success' });
      }
    } catch (error) {
      enqueueSnackbar(error?.message, { variant: 'error' });
    }
  };

  const shouldShowDeleteButton = (review) => {
    return user?.role === 'admin' || review.postedby?._id === user._id;
  };

  const renderStars = (rating) => {
    return (
      <div className="flex flex-col-1 text-white gap-1">
        {[...Array(5)].map((_, i) => (
          <div key={i}>{i < rating ? <FaStar /> : <FaRegStar />}</div>
        ))}
      </div>
    );
  };

  const openModal = (review) => {
    setSelectedReview(review);
    setModalIsOpen(true);
  };

  return (
    <>
      {reviews.map((review, index) => (
        <div
          key={index}
          className="bg-[#2c2c2c] bg-opacity-50 shadow-md p-4 rounded-lg mb-4 lg:w-3/5 lg:mr-auto"
        >
          <div className="flex justify-between items-start">
            <div className="flex flex-col justify-start items-start">
              <div className="flex justify-start items-center text-sm">
                {renderStars(review.rating)}
              </div>
              <div className="flex flex-row justify-start items-center pt-3">
                <div className="flex justify-start items-start text-sm text-white whitespace-nowrap">
                  {formatDate(review.createdAt)}
                </div>
                <div className="flex justify-start items-start text-sm text-white px-2">
                  {review?.reviewPinnedBy &&
                    review?.reviewPinnedBy?.includes(user?._id) && (
                      <FaThumbtack className="text-white cursor-pointer" />
                    )}
                </div>
              </div>
            </div>

            <div className="w-full flex justify-end items-start">
              <BsThreeDots
                onClick={() => openModal(review)}
                size={28}
                className="text-gray-300"
              />
            </div>
          </div>

          <div className="text-gray-300 text-lg py-2 flex items-center justify-start gap-2">
            <div className="w-12 h-12 rounded-full object-fill">
              <img
                className="w-full h-full rounded-full overflow-hidden"
                src={review?.postedby?.userImg?.url}
                alt={review?.postedby?.firstname}
                loading="lazy"
              />
            </div>
            {review?.postedby?.firstname} {review?.postedby?.lastname}
          </div>

          <div className="flex flex-row text-gray-300 mt-4 gap-2 flex-wrap">
            {review.effects.map((effect) => (
              <EffectIcon key={effect} effect={effect} />
            ))}
          </div>

          <hr className="border-t border-gray-600 my-4" />

          <div className="flex flex-row text-gray-300 mt-4 gap-2 flex-wrap">
            {review.flavor.map((flavor) => (
              <FlavourIcon key={flavor} flavor={flavor} />
            ))}
          </div>

          <hr className="border-t border-gray-600 my-4" />

          <div className="flex flex-row text-gray-300 font-semibold mt-4 gap-2 flex-wrap">
            {review.helpsWith.map((helpWith, index) => (
              <HelpsWithText key={index} helpWith={helpWith} />
            ))}
          </div>

          <hr className="border-t border-gray-600 my-4" />

          <div className="text-gray-300 mt-2">{review.additionalThoughts}</div>
        </div>
      ))}

      {selectedReview && (
        <ReviewModal
          isOpen={modalIsOpen}
          onRequestClose={() => setModalIsOpen(false)}
          onPinToggle={() => handlePinToggle(selectedReview)}
          isPinned={selectedReview.reviewPinnedBy?.includes(user?._id)}
          onDelete={() => handleDeleteReview(selectedReview._id)}
          shouldShowDeleteButton={shouldShowDeleteButton(selectedReview)}
        />
      )}
    </>
  );
};

const formatDate = (dateString) => {
  const options = { year: 'numeric', day: 'numeric', month: 'long' };
  return new Date(dateString).toLocaleDateString('en-US', options);
};

const EffectIcon = ({ effect }) => {
  const effectItem = effectsList.find((e) => e.name === effect);
  const IconComponent = effectItem ? effectItem.icon : FaUserEdit;
  return (
    <div className="flex items-center mr-2">
      <IconComponent className="text-2xl mr-1" />
      <span className="text-sm">{effect}</span>
    </div>
  );
};

const FlavourIcon = ({ flavor }) => {
  const flavorItem = flavorsList.find((e) => e.name === flavor);
  const IconComponent = flavorItem ? flavorItem.icon : FaUserEdit;
  return (
    <div className="flex items-center mr-2">
      <IconComponent className="text-2xl mr-1" />
      <span className="text-sm">{flavor}</span>
    </div>
  );
};

const HelpsWithText = ({ helpWith }) => (
  <div className="flex items-center mr-2">
    <span className="text-sm">{helpWith}</span>
  </div>
);

export default ReviewList;
