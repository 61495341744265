import { Avatar, AvatarGroup } from '@mui/material';
import React, { useState } from 'react';
import { BsMenuButton } from 'react-icons/bs';
import { MdOutlineKeyboardArrowDown } from 'react-icons/md';
import SourceContent from './SourceContent';

const Sources = ({ content }) => {
  const [ShowContent, setShowContent] = useState(false);
  const Data = content?.braveSearchResults[0];

  return (
    <div className="flex flex-col rounded-md w-full bg-[#232524]">
      <div
        onClick={() => setShowContent(!ShowContent)}
        className="flex justify-between items-center rounded-md w-full p-3 bg-[#232524] cursor-pointer"
      >
        <div className="flex items-center gap-2">
          <BsMenuButton color="#ffffff" />
          <h4 className="text-white">Related Sources </h4>
        </div>
        <div className="flex items-center gap-2">
          <div
            className={`transition-opacity duration-500 ease-in-out ${
              ShowContent ? 'opacity-0 pointer-events-none' : 'opacity-100'
            }`}
          >
            <AvatarGroup max={5}>
              {Data?.structuredResults?.map((item, index) => (
                <Avatar
                  key={index}
                  alt="Remy Sharp"
                  src={item?.logo}
                  sx={{ width: 24, height: 24 }}
                />
              ))}
            </AvatarGroup>
          </div>
          <MdOutlineKeyboardArrowDown
            className={`transform transition-transform duration-300 ${
              ShowContent ? 'rotate-180' : ''
            }`}
            color="#ffffff"
            size={18}
          />
        </div>
      </div>
      <div
        className={`transition-max-height duration-500 ease-in-out overflow-hidden ${
          ShowContent ? 'max-h-screen' : 'max-h-0'
        }`}
      >
        <div className="flex justify-start gap-3 scrollbar-hide overflow-y-auto p-3 w-full">
          {Data?.structuredResults?.map((item, index) => (
            <SourceContent item={item} key={index} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Sources;
