import { Skeleton } from '@mui/material';
import React from 'react';

const JoinPageLoader = () => {
  return (
    <div className="  flex  justify-center p-4  mb-32 w-full md:w-[430px] md:mx-auto  ">
      <div className=" text-white rounded-lg flex flex-col  justify-center gap-4   w-full">
        <div className="flex items-center ">
          <Skeleton
            sx={{
              bgcolor: '#232524',
            }}
            variant="rounded"
            width={30}
            height={30}
          />
        </div>

        {/* Subscription Header */}
        <div className=" relative flex  items-center justify-center gap-2 ">
          <Skeleton
            sx={{ bgcolor: '#232524' }}
            variant="circular"
            width={60}
            height={60}
          />
          <Skeleton
            sx={{ bgcolor: '#232524' }}
            variant="circular"
            className=" absolute top-0  right-[122px]"
            width={60}
            height={60}
          />
        </div>
        <div className=" flex flex-col justify-center items-center">
          <Skeleton
            sx={{ bgcolor: '#232524' }}
            variant="rounded"
            width={215}
            height={30}
          />
          <Skeleton
            sx={{ bgcolor: '#232524' }}
            variant="rounded"
            width={215}
            height={20}
          />
        </div>

        {/* Subscription Offers */}
        <div className="space-y-4 ">
          <Skeleton
            sx={{ bgcolor: '#232524' }}
            variant="text"
            width={300}
            height={24}
          />
          <ul className="list-disc list-inside text-sm text-gray-400  flex flex-col gap-4">
            <Skeleton
              sx={{ bgcolor: '#232524' }}
              variant="text"
              width={200}
              height={20}
            />
            <Skeleton
              sx={{ bgcolor: '#232524' }}
              variant="text"
              width={250}
              height={20}
            />
            <Skeleton
              sx={{ bgcolor: '#232524' }}
              variant="text"
              width={230}
              height={20}
            />
            <Skeleton
              sx={{ bgcolor: '#232524' }}
              variant="text"
              width={280}
              height={20}
            />
            <Skeleton
              sx={{ bgcolor: '#232524' }}
              variant="text"
              width={220}
              height={20}
            />
          </ul>
        </div>
        <div className=" flex justify-center items-center ">
          <Skeleton
            sx={{ bgcolor: '#232524' }}
            variant="rounded"
            width={140}
            height={32}
          />
        </div>

        {/* Manage Button */}
        <div className="flex flex-col justify-center items-center gap-3">
          <Skeleton
            sx={{ bgcolor: '#232524' }}
            variant="rounded"
            className="w-full"
            height={36}
          />
          <Skeleton
            sx={{ bgcolor: '#232524' }}
            variant="rounded"
            className="w-full"
            height={36}
          />
        </div>
      </div>
    </div>
  );
};

export default JoinPageLoader;
