import React from 'react';
import Modal from 'react-modal';
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookMessengerShareButton,
  PinterestShareButton,
  TelegramShareButton,
  LinkedinShareButton,
  FacebookIcon,
  XIcon,
  WhatsappIcon,
  FacebookMessengerIcon,
  PinterestIcon,
  TelegramIcon,
  LinkedinIcon,
} from 'react-share';
import { MdContentCopy, MdEmail } from 'react-icons/md';
import { FaInstagram } from 'react-icons/fa';

const StateSocialShareModal = ({ url, title, onClose, isOpen }) => {
  const encodedUrl = encodeURIComponent(url);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(url).then(
      () => {
        alert('URL copied to clipboard!');
      },
      () => {
        alert('Failed to copy URL');
      }
    );
  };

  const emailSubject = encodeURIComponent('Check out this product');
  const emailBody = encodeURIComponent(`${title} - ${url}`);

  const mailtoLink = `mailto:?subject=${emailSubject}&body=${emailBody}`;

  const handleInstagramStoryShare = () => {
    const instagramStoryUrl = `instagram://story-camera`;
    window.open(instagramStoryUrl, '_blank');
    alert('Open Instagram and paste the content to your story!');
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={{
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.75)',
          zIndex: 50,
        },
        content: {
          position: 'absolute',
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          border: 'none',
          transform: 'translate(-50%, -50%)',
          background: '#0f0f0f',
          overflow: 'hidden',
          WebkitOverflowScrolling: 'touch',
          outline: 'none',
          padding: '20px',
          zIndex: 1000,
        },
      }}
    >
      <div className="bg-[#0f0f0f] p-6 rounded-lg w-72 text-white">
        <button
          onClick={onClose}
          className="text-white absolute top-2 right-2 text-xl"
        ></button>
        <div className="flex items-start mb-4 pb-6 lg:pb-0">
          <div>
            <h2 className="text-gray-200 text-xl font-bold capitalize mt-2">
              {title}
            </h2>
          </div>
        </div>
        <h2 className="text-white text-xl font-bold mb-4 capitalize">
          Share this graph
        </h2>
        <div className="flex items-center justify-between bg-[#1f1f1f] w-full p-2 mb-4 rounded py-6 lg:py-2">
          <span className="text-gray-300 truncate whitespace-normal">
            {url}
          </span>
          <button onClick={copyToClipboard} className="ml-2">
            <MdContentCopy size={24} className="text-gray-300" />
          </button>
        </div>
        <hr className="border border-gray-600"></hr>
        <div className="flex flex-wrap justify-center items-center gap-4 lg:gap-6 mt-4">
          <div className="flex flex-col items-center">
            <FacebookShareButton
              url={url}
              quote={title}
              className="hover:bg-blue-600 hover:text-white transition-colors"
            >
              <FacebookIcon size={48} round />
            </FacebookShareButton>
            <span className="text-gray-500 mt-2">Facebook</span>
          </div>
          <div className="flex flex-col items-center">
            <button
              onClick={handleInstagramStoryShare}
              className="bg-[#f73464] hover:bg-[#f73464] hover:text-white transition-colors rounded-xl"
            >
              <FaInstagram size={48} />
            </button>
            <span className="text-gray-500 mt-2">Instagram</span>
          </div>
          <div className="flex flex-col items-center">
            <TwitterShareButton
              url={url}
              title={title}
              className="hover:bg-blue-400 hover:text-white transition-colors"
            >
              <XIcon size={48} round />
            </TwitterShareButton>
            <span className="text-gray-500 mt-2">Twitter</span>
          </div>
          <div className="flex flex-col items-center">
            <WhatsappShareButton
              url={url}
              title={`${title} - Check out this strain:`}
              className="hover:bg-green-600 hover:text-white transition-colors"
            >
              <WhatsappIcon size={48} round />
            </WhatsappShareButton>
            <span className="text-gray-500 mt-2">WhatsApp</span>
          </div>
          <div className="flex flex-col items-center">
            <FacebookMessengerShareButton
              url={encodedUrl}
              appId={'your-app-id'}
              className="hover:bg-blue-400 hover:text-white transition-colors"
            >
              <FacebookMessengerIcon size={48} round />
            </FacebookMessengerShareButton>
            <span className="text-gray-500 mt-2">Messenger</span>
          </div>
          <div className="flex flex-col items-center -ml-2 lg:-ml-2">
            <PinterestShareButton
              url={url}
              description={title}
              className="hover:bg-blue-400 hover:text-white transition-colors"
            >
              <PinterestIcon size={48} round />
            </PinterestShareButton>
            <span className="text-gray-500 mt-2">Pinterest</span>
          </div>
          <div className="flex flex-col items-center">
            <TelegramShareButton
              url={url}
              title={title}
              className="hover:bg-blue-400 hover:text-white transition-colors"
            >
              <TelegramIcon size={48} round />
            </TelegramShareButton>
            <span className="text-gray-500 mt-2">Telegram</span>
          </div>
          <div className="flex flex-col items-center">
            <LinkedinShareButton
              url={url}
              title={title}
              summary="Check out this strain"
              source={url}
              className="hover:bg-blue-400 hover:text-white transition-colors"
            >
              <LinkedinIcon size={48} round />
            </LinkedinShareButton>
            <span className="text-gray-500 mt-2">LinkedIn</span>
          </div>
          <div className="flex flex-col items-center">
            <a
              href={mailtoLink}
              className="hover:text-white transition-colors rounded-full ml-4"
            >
              <MdEmail size={48} style={{ color: 'white' }} />
            </a>
            <span className="text-gray-500 mt-2 ml-4">Email</span>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default StateSocialShareModal;
