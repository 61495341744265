import { baseApi } from "./baseApi";

export const subscriptionApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getAllSubscribed: builder.query({
            query: (type = 'all-subscription') => `all-subscriptions?search=${type}`, // Add search query parameter
        }),
    }),
});

export const { useGetAllSubscribedQuery } = subscriptionApi;
