import React from 'react';
import {
  MdPhone,
  MdLocationOn,
  MdEmail,
  MdLanguage,
  MdFacebook,
} from 'react-icons/md';
// import { FaTwitter } from 'react-icons/fa';

const TwitterIcon = ({ size }) => (
  <svg width={size} height={size} viewBox="0 0 512 512" fill="currentColor">
    <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8l164.9-188.5L26.8 48h145.6l100.5 132.9L389.2 48zm-24.8 373.8h39.1L151.1 88h-42l255.3 333.8z"></path>
  </svg>
);

// Helper function to get icons
const getIcon = (type) => {
  const iconSize = 24; // Set the desired icon size
  switch (type) {
    case 'address':
      return <MdLocationOn size={iconSize} />;
    case 'email':
      return <MdEmail size={iconSize} />;
    case 'phoneNumber':
      return <MdPhone size={iconSize} />;
    case 'website':
      return <MdLanguage size={iconSize} />;
    case 'facebook':
      return <MdFacebook size={iconSize} />;
    case 'X':
      return <TwitterIcon size={iconSize} />;
    // ... add other cases if needed
    default:
      return null;
  }
};

// DetailItem component
const DetailItem = ({
  icon,
  value,
  isUnderlined,
  type,
  email,
  website,
  facebook,
  twitter,
}) => {
  const valueClass = isUnderlined ? 'underline' : '';
  let content;

  if (type === 'email' && email) {
    content = (
      <a
        href={`mailto:${email}`}
        className={`text-sm font-bold ${valueClass} flex-1 hover:text-primary`}
      >
        {value}
      </a>
    );
  } else if (type === 'website' && website) {
    content = (
      <a
        href={website}
        target="_blank"
        rel="noopener noreferrer"
        className={`text-sm font-bold ${valueClass} flex-1 hover:text-primary`}
      >
        {value}
      </a>
    );
  } else if (type === 'facebook' && facebook) {
    content = (
      <a
        href={facebook}
        target="_blank"
        rel="noopener noreferrer"
        className={`text-sm font-bold ${valueClass} flex-1 hover:text-primary`}
      >
        {value}
      </a>
    );
  } else if (type === 'X' && twitter) {
    content = (
      <a
        href={twitter}
        target="_blank"
        rel="noopener noreferrer"
        className={`text-sm font-bold ${valueClass} flex-1 hover:text-primary`}
      >
        {value}
      </a>
    );
  } else {
    content = (
      <div className={`text-sm font-bold ${valueClass} flex-1`}>{value}</div>
    );
  }

  return (
    <div className="flex items-center space-x-4 space-y-2 p-2">
      <span className="-mb-2">{icon}</span>
      {content}
    </div>
  );
};

// DetailsContent component
const DetailsContent = ({ data }) => {
  const details = [
    ...(data.address
      ? [
          {
            type: 'address',
            value: data.address,
            isUnderlined: true,
          },
        ]
      : []),
    {
      type: 'email',
      value: 'Email',
      email: data.email,
      isUnderlined: true,
    },
    {
      type: 'phoneNumber',
      value: data.phoneNumber,
      isUnderlined: true,
    },
    {
      type: 'website',
      value: 'Website',
      website: data.website,
      isUnderlined: true,
    },
    {
      type: 'facebook',
      value: 'Facebook',
      facebook: data.socialLinks[0]?.link,
      isUnderlined: true,
    },
    {
      type: 'X',
      value: 'X',
      twitter: data.socialLinks[1]?.link,
      isUnderlined: true,
    },
    // ... add other details if needed
  ];

  return (
    <div className="bg-[#0f0f0f] shadow lg:shadow-none overflow-hidden sm:rounded-lg">
      <div className="px-4 py-2 sm:px-6 lg:hidden">
        <h3 className="text-lg leading-6 font-medium text-gray-300">Details</h3>
      </div>
      <div className="border-t border-gray-700 text-gray-300 lg:border-none">
        {details.map((detail) => (
          <DetailItem
            key={detail.type}
            icon={getIcon(detail.type)}
            value={detail.value}
            isUnderlined={detail.isUnderlined}
            type={detail.type}
            email={detail.email}
            website={detail.website}
            facebook={detail.facebook}
            twitter={detail.twitter}
          />
        ))}
      </div>
    </div>
  );
};

export default DetailsContent;
