import React, { useMemo, useState } from 'react';
import { CiCirclePlus } from 'react-icons/ci';
import { FaCheckCircle, FaLink } from 'react-icons/fa';
import { IoMdClose } from 'react-icons/io';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { addStrainToFolder } from '../../../features/stashFolder/folderService';
import { enqueueSnackbar } from 'notistack';
import { IoShareOutline, IoTimer } from 'react-icons/io5';
import SocialShareModal from '../../StrainDetail/SocialShare';
import { getUrl } from '../../../utils/GetBussinessUrl';
import FolderSelectionModal from '../../StrainDetail/FolderSelectionModal';

const TrippleDotModal = ({
  isOpen,
  onClose,
  title,
  itemImg,
  itemId,
  itemType,
  onUpdate,
  business,
  shareUrl,
  // handleDownloadImage,
}) => {
  const navigate = useNavigate();
  // global states
  const folders = useSelector((state) => state.folder.folders);
  // local states
  const [isFolderModalOpen, setIsFolderModalOpen] = useState(false);
  const [showShareOptions, setShowShareOptions] = useState(false);

  const toggleFolderModal = () => {
    setIsFolderModalOpen(true);
  };

  const isAddedInFolder = useMemo(() => {
    if (itemType === 'strain') {
      for (let folder of folders) {
        if (folder.strains?.find((item) => item?._id === itemId)) {
          return true;
        }
      }
    } else if (itemType === 'product') {
      for (let folder of folders) {
        if (folder.products?.find((item) => item?._id === itemId)) {
          return true;
        }
      }
    }

    return false;
  }, [itemId, folders, itemType]);

  const handleAddToStash = async () => {
    const likedStrainsFolder = folders?.find(
      (folder) => folder.name === 'Liked Strains'
    );
    try {
      let Data = {
        itemId: itemId,
        type: itemType,
      };
      const res = await addStrainToFolder(likedStrainsFolder._id, Data);
      if (res) {
        onUpdate();
        enqueueSnackbar(res?.message, { variant: 'success' });
      }
    } catch (error) {
      enqueueSnackbar(error?.message, { variant: 'error' });
    }
  };

  const renderIcon = () => {
    if (isAddedInFolder) {
      return (
        <FaCheckCircle
          size={28}
          className="text-[#62AF41]"
          onClick={toggleFolderModal}
        />
      );
    } else {
      return (
        <CiCirclePlus
          size={28}
          className="text-white"
          onClick={handleAddToStash}
        />
      );
    }
  };

  return (
    isOpen && (
      <div className="fixed inset-0 bg-opacity-75 flex justify-center items-center z-30">
        <div className="bg-[#2c2c2c] rounded-lg shadow-xl p-4 m-4 w-80 md:w-85 max-h-full overflow-auto">
          <div className="flex justify-between items-center">
            <div className="flex justify-start items-center space-x-2">
              <img src={itemImg} alt={title} className="h-10 w-10 rounded-md" />
              <h2 className="text-white text-md font-semibold">{title}</h2>
            </div>
            <button
              onClick={onClose}
              className="text-gray-600 hover:text-gray-900"
            >
              <IoMdClose size={24} color="#bbbbbb" />
            </button>
          </div>
          <div className="flex flex-col justify-start items-start gap-2 pt-8">
            <div className="flex justify-start items-center space-x-2">
              <span className="cursor-pointer">{renderIcon(itemId)}</span>
              <span className="flex justify-start items-center text-gray-300">
                Add to Stash
              </span>
            </div>

            <div
              onClick={() =>
                navigate(`${getUrl(business?.PrimaryVendor, business?._id)}`)
              }
              className="flex justify-start items-center space-x-2"
            >
              <span className="cursor-pointer">
                <FaLink size={28} className="rounded-full text-white" />
              </span>
              <span className="flex justify-start items-center text-gray-300">
                Go to Business
              </span>
            </div>
            <div
              onClick={() => navigate(`/strain/${itemId}`)}
              className="flex justify-start items-center space-x-2"
            >
              <span className="cursor-pointer">
                <FaLink size={28} className="rounded-full text-white" />
              </span>
              <span className="flex justify-start items-center text-gray-300">
                Go to Strain
              </span>
            </div>
            <div className="flex justify-start items-center space-x-2">
              <span
                onClick={() => setShowShareOptions(true)}
                className="cursor-pointer text-white"
              >
                <IoShareOutline size={28} className="cursor-pointer" />
              </span>
              <span className="flex justify-start items-center text-gray-300">
                Share
              </span>
              {showShareOptions && (
                <SocialShareModal
                  isOpen={showShareOptions}
                  url={shareUrl}
                  title={title}
                  strainImg={itemImg}
                  type=""
                  onClose={() => setShowShareOptions(false)}
                  des={'Share this strain'}
                />
              )}
            </div>
            {/* <div className="flex justify-start items-center space-x-2">
              <button
                onClick={handleDownloadImage}
                className="flex justify-start items-center"
              >
                <FaCamera size={24} className="text-white" />
              </button>
              <span className="flex justify-start items-center text-gray-300">
                Download
              </span>
            </div> */}
            <div
              onClick={() =>
                navigate(`/sessions-review?type=Strain&id=${itemId}`)
              }
              className="flex justify-start items-center space-x-2  cursor-cell"
            >
              <div className="flex justify-start items-center">
                <IoTimer size={24} className="text-white" />
              </div>
              <span className="flex justify-start items-center text-gray-300">
                Add session
              </span>
            </div>
          </div>
        </div>
        {showShareOptions && (
          <SocialShareModal
            isOpen={showShareOptions}
            url={shareUrl}
            title={title}
            strainImg={itemImg}
            type=""
            onClose={() => setShowShareOptions(false)}
            des={'Share this strain'}
          />
        )}
        {folders && (
          <FolderSelectionModal
            itemType="strain"
            itemId={itemId}
            backBtnText="Back to Strain"
            isOpen={isFolderModalOpen}
            onClose={() => setIsFolderModalOpen(false)}
            onUpdate={() => onUpdate()}
          />
        )}
      </div>
    )
  );
};

export default TrippleDotModal;
