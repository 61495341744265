import React from 'react';
import { BsMenuButton } from 'react-icons/bs';
import { HiOutlineMenuAlt1 } from 'react-icons/hi';
import { Skeleton } from '@mui/material';
import { HiOutlineSquare3Stack3D } from 'react-icons/hi2';

export const SourcesLoader = () => {
  return (
    <div className="p-3">
      <div className=" flex justify-between items-center  rounded-md w-full p-3 bg-[#232524] ">
        <div className="flex items-center gap-2 ">
          <BsMenuButton color="#ffffff" />
          <h4 className=" text-white">Related Sources</h4>
        </div>
      </div>
    </div>
  );
};

export const AnswerLoader = () => {
  return (
    <div className=" flex flex-col gap-2 p-3">
      <div className=" flex items-center justify-between text-white">
        <div className=" flex items-center justify-start gap-2  text-xl">
          <HiOutlineMenuAlt1 /> Answer
        </div>
      </div>
      <div className="flex items-center gap-2 ">
        <Skeleton
          sx={{
            bgcolor: '#232524',
            height: { xs: 162, md: 300 },
          }}
          variant="rounded"
          className="w-3/4 "
        />

        <div className=" flex flex-col gap-2 ">
          <Skeleton
            sx={{
              bgcolor: '#232524',
              height: { xs: 76, md: 150 },
              width: { xs: 109, md: 200 },
            }}
            variant="rounded"
          />
          <Skeleton
            sx={{
              bgcolor: '#232524',
              height: { xs: 76, md: 150 },
              width: { xs: 109, md: 200 },
            }}
            variant="rounded"
          />
        </div>
      </div>
      <Skeleton
        sx={{ bgcolor: '#232524' }}
        variant="rounded"
        width={'100%'}
        height={20}
      />{' '}
      <Skeleton
        sx={{ bgcolor: '#232524' }}
        variant="rounded"
        width={'100%'}
        height={20}
      />{' '}
      <Skeleton
        sx={{ bgcolor: '#232524' }}
        variant="rounded"
        width={'100%'}
        height={20}
      />{' '}
      <Skeleton
        sx={{ bgcolor: '#232524' }}
        variant="rounded"
        width={'100%'}
        height={20}
      />
    </div>
  );
};

export const RelatedLoader = () => {
  return (
    <div className=" flex flex-col gap-3 p-3">
      <div className=" flex items-center gap-2">
        <HiOutlineSquare3Stack3D color="#ffffff" size={18} />
        <h4 className="text-white  text-xl">Related</h4>
      </div>

      <ul className=" flex flex-col gap-2 text-white">
        <Skeleton
          sx={{ bgcolor: '#232524' }}
          variant="rectangular"
          width={'100%'}
          height={20}
        />
      </ul>
    </div>
  );
};

const AskanythingLoader = () => {
  return (
    <>
      <SourcesLoader />
      <AnswerLoader />
      <RelatedLoader />
    </>
  );
};

export default AskanythingLoader;
