import axios from 'axios'
import { base_url } from '../../utils/base_url';
import { config } from '../../utils/axiosconfig';


export const getStrainsByType = async () => {
    const response = await axios.get(`${base_url}strain/strains-by-type`)
    return response.data
}
export const getStrains = async (page, filterOptions) => {
    const filterOptionsString = JSON.stringify(filterOptions)
    const response = await axios.get(`${base_url}strain?page=${page ? page : 1}&limit=20&filterOptions=${filterOptions ? filterOptionsString : ""}`);
    return response.data;

}

const getAStrain = async (id) => {
    const response = await axios.get(`${base_url}strain/${id}`)

    return response.data
}
export const getStrainsById = async (id) => {
    const response = await axios.get(`${base_url}strain/${id}`)
    return response.data
}
const getSrainsByVariant = async () => {
    const configure = config()

    const response = await axios.get(`${base_url}strain/getvaraints`, configure)

    return response.data
}
const uploadStrainImg = async (data) => {
    const configure = config()

    const response = await axios.put(`${base_url}upload/`, data, configure)

    return response.data
}
const uploadStrainModalImg = async (data) => {
    const configure = config()

    const response = await axios.put(`${base_url}upload/`, data, configure)

    return response.data
}
const uploadMotherImg = async (data) => {
    const configure = config()

    const response = await axios.put(`${base_url}upload/`, data, configure)

    return response.data
}
const uploadMotherModalImg = async (data) => {
    const configure = config()

    const response = await axios.put(`${base_url}upload/`, data, configure)

    return response.data
}
const deleteStrainImg = async (id) => {
    const configure = config()

    const response = await axios.delete(`${base_url}upload/delete-image/${id}`, configure)

    return response.data
}
const deleteMotherImg = async (id) => {
    const configure = config()

    const response = await axios.delete(`${base_url}upload/delete-image/${id}`, configure)

    return response.data
}
const deleteMotherModalImg = async (id) => {
    const configure = config()

    const response = await axios.delete(`${base_url}upload/delete-image/${id}`, configure)

    return response.data
}
const deleteStrainModalImg = async (id) => {
    const configure = config()

    const response = await axios.delete(`${base_url}upload/delete-image/${id}`, configure)

    return response.data
}
const uploadFatherImg = async (data) => {
    const configure = config()

    const response = await axios.put(`${base_url}upload/`, data, configure)

    return response.data
}
const uploadFatherModalImg = async (data) => {
    const configure = config()

    const response = await axios.put(`${base_url}upload/`, data, configure)

    return response.data
}
const deleteFatherImg = async (id) => {
    const configure = config()

    const response = await axios.delete(`${base_url}upload/delete-image/${id}`, configure)

    return response.data
};
const deleteFatherModalImg = async (id) => {
    const configure = config()

    const response = await axios.delete(`${base_url}upload/delete-image/${id}`, configure)

    return response.data
};
const createStrain = async (strain) => {
    const configure = config()

    const response = await axios.post(`${base_url}strain/`, strain, configure)

    return response.data

}
const createMotherStrain = async (strain) => {
    const configure = config()

    const response = await axios.post(`${base_url}strain/`, strain, configure)

    return response.data

}
const createFatherStrain = async (strain) => {
    const configure = config()

    const response = await axios.post(`${base_url}strain/`, strain, configure)

    return response.data

}

const updateStrain = async (strain) => {
    const configure = config();
    const response = await axios.put(`${base_url}strain/${strain.id}`, strain, configure);
    return response.data;
};
const deleteStrain = async (id) => {
    const configure = config()

    const response = await axios.delete(`${base_url}strain/${id}`, configure)
    return response.data
}

const getUserWishlist = async () => {
    const configure = config()

    const response = await axios.get(`${base_url}strain/getwishlist`, configure);

    return response.data;


}
const addToWishList = async (id) => {
    const configure = config()

    const response = await axios.put(`${base_url}strain/wishlist`, { id }, configure);

    return response.data;
}

// Add new logic for reviews
export const addReviewToStrain = async (strainId, reviewData) => {
    const configure = config();
    const response = await axios.post(`${base_url}strain/${strainId}/add-review`, reviewData, configure);
    return response.data;
};

// Delete review 
export const deleteReview = async (strainId, reviewId) => {
    const configure = config();

    try {
        const response = await axios.delete(`${base_url}strain/${strainId}/reviews/${reviewId}`, configure);
        return response.data;
    } catch (error) {
        console.error('Error deleting the review: ', error.response || error.message);
        throw error;
    }
}

const strainService = {
    getStrainsById,
    getAStrain,
    getStrains,
    createStrain,
    updateStrain,
    deleteStrain,
    getSrainsByVariant,
    uploadStrainImg,
    uploadMotherImg,
    uploadFatherImg,
    deleteFatherImg,
    deleteStrainImg,
    addToWishList,
    getUserWishlist,
    addReviewToStrain,
    uploadStrainModalImg,
    deleteStrainModalImg,
    uploadMotherModalImg,
    deleteMotherImg,
    deleteMotherModalImg,
    uploadFatherModalImg,
    deleteFatherModalImg,
    createMotherStrain,
    createFatherStrain,
    deleteReview


};
export default strainService