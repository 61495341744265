import React, { useState, useEffect, useRef } from 'react';
import { Input } from '@/core/ui/input';
import RecentSearch from './RecentSearch';
import { Button } from '@/core/ui/button';
import { Send } from 'lucide-react';
import AiSuggestions from './AiSuggestions';
import SearchSuggestions from './SearchSuggestions';
import SearchResult from './SearchResult';
import { useLocalStorage } from '@/utils/useLocalStorage';
import { enqueueSnackbar } from 'notistack';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  useGetSearchAiSuggestionsQuery,
  useGetSearchResultQuery,
  useGetSearchSuggestionsQuery,
} from '@/redux/api/searchApi';

import { useSelector } from 'react-redux';
import {
  useGetAllSearchHistoryQuery,
  useGetSearchHistoryQuery,
  useClearAllHistoryMutation,
  useClearOneHistoryMutation,
} from '@/redux/api/recentHistoryApi';
const SearchDesktop = () => {
  // hooks
  const navigate = useNavigate();
  const location = useLocation();

  //use refrence
  const debounceTimeout = useRef(null);
  const containerRef = useRef(null);

  // global State
  const user = useSelector((state) => state?.auth?.user);

  // localState
  const [searchQuery, setSearchQuery] = useState('');
  const [isFocus, setIsFocus] = useState(false);
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState('');
  const [localSearchResult, setLocalSearchResult] = useState(null);
  const [triggerSearch, setTriggerSearch] = useState(false);

  const [
    recentSearches,
    setRecentSearches,
    removeSearch,
    clearAllRecentSearches,
  ] = useLocalStorage('recentSearches', []);
  const [seeAllRecentSearch, setSeeAllRecentSearch] = useState(false);

  // when search click so the search dropdown is close
  useEffect(() => {
    if (isFocus) {
      setIsFocus(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  // Fetch search suggestions
  const { data: resultSuggestions } = useGetSearchSuggestionsQuery(
    debouncedSearchQuery,
    {
      skip: !debouncedSearchQuery,
    }
  );

  const { data: suggestions } = useGetSearchAiSuggestionsQuery(
    debouncedSearchQuery,
    {
      skip: !debouncedSearchQuery,
    }
  );
  //search result from the db
  const { data: searchResult } = useGetSearchResultQuery(debouncedSearchQuery, {
    skip: !triggerSearch,
  });

  const { data: searchHistory } = useGetSearchHistoryQuery(user?._id, {
    skip: !user || !isFocus,
  });

  const { data: AllSearchHistory } = useGetAllSearchHistoryQuery(user?._id, {
    skip: !user || !isFocus || !seeAllRecentSearch,
  });

  // clear all history
  const [clearAllHistoryFromBackend] = useClearAllHistoryMutation();

  // clear on hsitory
  const [clearOneHistoryFromBackend] = useClearOneHistoryMutation();

  //click outside  to close search
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setIsFocus(false);
        setSearchQuery('');
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  //set search result
  useEffect(() => {
    if (searchResult) {
      setLocalSearchResult(searchResult);
    }
  }, [searchResult]);

  // delete single history from backend and localstorage as well
  const deleteSearch = async (query, searchId) => {
    try {
      if (user && searchId) {
        await clearOneHistoryFromBackend(searchId);
      }
      // Remove the search from local storage
      removeSearch(query);
    } catch (error) {
      enqueueSnackbar('Failed to delete search item', {
        variant: 'error',
      });
    }
  };

  // clear all history from local storage and backend
  const handleClearAll = async () => {
    try {
      if (user) {
        await clearAllHistoryFromBackend();
        // Clear local storage in all cases
        clearAllRecentSearches();
      }
    } catch (error) {
      enqueueSnackbar('Failed to clear history', {
        variant: 'error',
      });
    }
    clearAllRecentSearches();
    setSeeAllRecentSearch(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (searchQuery.trim() === '') return;
    // call the result api
    setTriggerSearch(true);
    //add recent search history and dubplicate remove and show at the first
    const newSearch = { type: 'search', query: searchQuery };
    const filteredSearches = recentSearches.filter(
      (search) => search.query !== searchQuery
    );
    setRecentSearches([newSearch, ...filteredSearches]);
    setSearchQuery('');
  };

  const aiHandleSubmit = (query) => {
    const newSearch = { type: 'question', query };
    const filteredSearches = recentSearches.filter(
      (search) => search.query !== query
    );
    setRecentSearches([newSearch, ...filteredSearches]);
    navigate(`/ai-search?query=${query}`);
    setSearchQuery('');
    setIsFocus(false);
  };

  const handleSubmitSuggestion = (query) => {
    setTriggerSearch(true);
    setDebouncedSearchQuery(query);

    //add recent search history and dubplicate remove and show at the first
    const newSearch = { type: 'search', query: query };
    const filteredSearches = recentSearches.filter(
      (search) => search.query !== query
    );
    setRecentSearches([newSearch, ...filteredSearches]);
    setSearchQuery('');
  };

  //handle recent search
  const handleRecentSearch = (seachData) => {
    const { type, query } = seachData;

    if (type === 'question') {
      const newSearch = { type, query };
      const filteredSearches = recentSearches.filter(
        (search) => search.query !== query
      );
      setRecentSearches([newSearch, ...filteredSearches]);
      navigate(`/ai-search?query=${query}`);
      setIsFocus(false);
    } else {
      handleSubmitSuggestion(query);
    }
  };

  // handleOnchnage
  const onChange = (e) => {
    const inputValue = e.target.value;

    setSearchQuery(inputValue);

    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(() => {
      // Only set the debounced search query if inputValue is not an empty string
      if (inputValue.trim() !== '' && inputValue.length <= 100) {
        setDebouncedSearchQuery(inputValue);
      } else if (inputValue.length > 100) {
        enqueueSnackbar('100 character limit exceeded', {
          variant: 'error',
          autoHideDuration: 1000,
        });
      }
    }, 500);
  };

  const handleInputFocus = () => {
    setSeeAllRecentSearch(false);
    setIsFocus(true);
    setLocalSearchResult(null);
    setTriggerSearch(false);
  };

  return (
    <div
      className="hidden md:block relative transition-all ease-in-out"
      ref={containerRef}
    >
      <div
        className="relative z-40 mx-auto w-full md:w-[400px] xl:w-[500px] bg-[#2c2e2d] rounded-full"
        style={{ boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px' }}
      >
        <form
          onSubmit={handleSubmit}
          className="flex items-center justify-between w-full"
        >
          <Input
            className="text-white border-none outline-none bg-transparent focus:outline-none focus:border-none focus-visible:ring-0 text-center"
            type="text"
            value={searchQuery}
            onFocus={handleInputFocus}
            onChange={onChange}
            placeholder="Ask anything or search"
          />

          {isFocus && (
            <Button
              className={`absolute right-0 top-0 self-stretch p-0 w-12 flex justify-center items-center rounded-full focus:outline-none shadow-sm shadow-[#2c2c2c] transition-all ease-in-out ${
                searchQuery !== ''
                  ? ' bg-mary-theme hover:bg-mary-theme'
                  : 'bg-[#333333] text-black hover:bg-[#333333]'
              }`}
            >
              <Send className="w-5 h-5" />
            </Button>
          )}
        </form>
      </div>
      {isFocus && (
        <div className="absolute top-[37px] w-full rounded-2xl bg-[#2c2c2c] text-white shadow-xl space-y-3 pt-2 z-50 pb-4 max-h-[60vh] transition-all ease-in-out overflow-y-auto">
          {!localSearchResult && !seeAllRecentSearch && searchQuery === '' && (
            <AiSuggestions handleSubmit={aiHandleSubmit} />
          )}

          {searchQuery !== '' && !localSearchResult && (
            <div className="space-y-3">
              <SearchSuggestions
                title=""
                icon="search"
                result={suggestions?.query}
                handleSubmit={(query) => handleSubmitSuggestion(query)}
                isLoading={suggestions?.query ? false : true}
              />
              <SearchSuggestions
                title="Ask Mary AI"
                icon="ai"
                result={suggestions?.ai}
                handleSubmit={aiHandleSubmit}
                isLoading={suggestions?.ai ? false : true}
              />
              <SearchResult
                title={'Strain'}
                result={resultSuggestions?.strains}
                slug="strain"
                SeeAllUrl="/strains"
                isLoading={resultSuggestions?.strains ? false : true}
              />
              <SearchResult
                title={'Product'}
                result={resultSuggestions?.products}
                slug="product"
                SeeAllUrl="/products"
                isLoading={resultSuggestions?.products ? false : true}
              />
              <SearchResult
                title={'Vendors'}
                result={resultSuggestions?.vendors}
                slug="vendors"
                SeeAllUrl="/vendors"
                isLoading={resultSuggestions?.vendors ? false : true}
              />
            </div>
          )}

          {/* search result */}
          {localSearchResult && (
            <div>
              <SearchResult
                title={'Strain'}
                result={localSearchResult?.strains}
                slug="strain"
                SeeAllUrl="/strains"
                isLoading={localSearchResult?.strains ? false : true}
              />
              {localSearchResult?.products.map((products) => (
                <SearchResult
                  title={products?.category}
                  result={products?.products}
                  slug="product"
                  SeeAllUrl="/products"
                  isLoading={localSearchResult?.products ? false : true}
                />
              ))}

              <SearchResult
                title={'Vendors'}
                result={localSearchResult?.vendors}
                slug="vendors"
                SeeAllUrl="/vendors"
                isLoading={localSearchResult?.vendors ? false : true}
              />
            </div>
          )}

          {/* recent search  */}
          {!localSearchResult &&
            !seeAllRecentSearch &&
            searchQuery === '' &&
            ((user && searchHistory?.length > 0) ||
              (!user && recentSearches.length > 0)) && (
              <RecentSearch
                title="Recent"
                result={
                  user && searchHistory?.length > 0
                    ? searchHistory
                    : recentSearches.slice(0, 5)
                }
                clearAll={handleClearAll}
                deleteSearch={deleteSearch}
                seeAllBtn={
                  (user && searchHistory?.length > 4) ||
                  (!user && recentSearches.length > 4)
                }
                handleSeeAll={() => setSeeAllRecentSearch(true)}
                handleSubmit={handleRecentSearch}
              />
            )}

          {!localSearchResult &&
            seeAllRecentSearch &&
            ((user && AllSearchHistory?.history?.length > 0) ||
              (!user && recentSearches.length > 0)) && (
              <RecentSearch
                title="Recent"
                result={user ? AllSearchHistory?.history : recentSearches}
                clearAll={handleClearAll}
                deleteSearch={deleteSearch}
                seeAllBtn={false}
                handleSeeAll={() => setSeeAllRecentSearch(true)}
                handleSubmit={handleRecentSearch}
              />
            )}
        </div>
      )}
    </div>
  );
};

export default SearchDesktop;
