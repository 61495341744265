import StrainCard from '@/common-components/strain/StrainCard';
import { getAllFolders } from '@/features/stashFolder/folderSlice';
import { useGetStrainsByTypeQuery } from '@/redux/api/strainApi';
import StrainCardLoader from '@/skeleton/StrainCardLoader';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useSearchParams } from 'react-router-dom';

const Search = () => {
  // hooks
  const dispatch = useDispatch();
  let [searchParams] = useSearchParams();
  // eslint-disable-next-line no-unused-vars
  const query = searchParams.get('query');

  // localState
  const [isUpdate, setisUpdate] = useState(false);

  // strain api call
  const { data: strainData, isLoading: strainLoading } =
    useGetStrainsByTypeQuery();

  useEffect(() => {
    dispatch(getAllFolders());
  }, [dispatch, isUpdate]);

  return (
    <div className="text-white">
      <div>
        {strainLoading && <StrainCardLoader NumberOfCard={6} />}
        {!strainLoading &&
          strainData?.map((strainType, index) => (
            <div key={index} className="mb-6">
              <div className="flex justify-between items-center p-2">
                <h2 className="text-xl font-bold text-white">
                  {strainType?.type?.charAt(0).toUpperCase() +
                    strainType?.type?.slice(1)}{' '}
                  Strains
                </h2>
                <Link
                  to={`/strains?type=${strainType?.type}`}
                  className="text-sm ml-2 text-[#62AF41]"
                >
                  View All
                </Link>
              </div>
              <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-4">
                {strainType?.data?.map((strain, index) => (
                  <StrainCard
                    itemType="strain"
                    key={strain._id}
                    id={strain._id}
                    title={strain?.title}
                    father={strain?.father?.title ?? 'Not Found'}
                    mother={strain?.mother?.title ?? 'Not found'}
                    type={strain?.type}
                    imageUrl={strain?.strainImg?.url}
                    business={strain?.business?.id}
                    onUpdate={() => setisUpdate(!isUpdate)}
                  />
                ))}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Search;
