import React, { useEffect, useState } from 'react';
import Meta from '../components/SEO/Meta';
import { CustomPagination } from '@/common-components/mary-pagination/MaryPagination';
import BreederCardLoader from '../skeleton/BreederCardLoader';
import { enqueueSnackbar } from 'notistack';
import BussinessCard from '../components/CommonComponent/AddBusinesses/BussinessCard';
import { useGetBusinessQuery } from '../redux/api/businessApi';

const AllBrands = () => {
  // local State
  const [page, setPage] = useState(1);
  const [isUpdate, setIsUpdate] = useState(false);

  // Use RTK Query hook to fetch business data
  const { data, error, isLoading } = useGetBusinessQuery({
    bussinessType: 'Brand',
    page,
  });

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error?.message || 'Error fetching data', {
        variant: 'error',
      });
    }
  }, [error]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleUpdate = () => {
    setIsUpdate(!isUpdate);
  };

  return (
    <>
      <Meta title={'Brands'} siteLink={'/Brand'} />
      <div className="">
        <div className="flex flex-col gap-5 mt-10 mb-20">
          {isLoading && <BreederCardLoader NumberOfCard={20} />}
          <div className="grid xs:grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
            {!isLoading &&
              data?.vendors?.map((brand, index) => (
                <BussinessCard
                  loading={isLoading}
                  key={index}
                  Data={brand}
                  isUpdate={isUpdate}
                  OnUpdate={handleUpdate}
                />
              ))}
          </div>
          <div>
            <CustomPagination
              count={data?.pagination?.totalPages || 1}
              page={page}
              onChange={handlePageChange}
              variant="outlined"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AllBrands;
