import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getUserData } from './userAddedDataService';

const initialState = {
    userMethods: [],
    userReason: [],
    userFeelings: [],
    userFlavours: [],
    userConsumingPlace: [],
    userConsumingWith: []

};

export const getUserDataThunk = createAsyncThunk(
    'userAddedData/addConsumption',
    async (data, thunkAPI) => {
        try {
            const res = await getUserData(data);
            thunkAPI.dispatch(addUserData(res));
        } catch (error) {
            return error;
        }
    }
);

const userAddedDataSlice = createSlice({
    name: 'userAddedData',
    initialState,
    reducers: {
        addUserData: (state, action) => {
            state.userMethods = action.payload.userMethods;
            state.userReason = action.payload.userReason;
            state.userFeelings = action.payload.userFeelings;
            state.userFlavours = action.payload.userFlavours;
            state.userConsumingPlace = action.payload.userConsumingPlace;
            state.userConsumingWith = action.payload.userConsumingWith;


        },
    },
});

export const { addUserData } = userAddedDataSlice.actions;
export default userAddedDataSlice.reducer;
