import React from 'react';
import { GoPlus } from 'react-icons/go';
import { HiOutlineSquare3Stack3D } from 'react-icons/hi2';

const Related = ({ setmessage, relatedQuestions }) => {
  return (
    <div className=" flex flex-col gap-3">
      <div className=" flex items-center gap-2">
        <HiOutlineSquare3Stack3D color="#ffffff" size={18} />
        <h4 className="text-white  text-xl">Related</h4>
      </div>

      <ul className=" flex flex-col gap-2 text-white">
        {relatedQuestions?.map((item, index) => (
          <li
            key={index}
            className=" flex justify-between items-center gap-2 border-b-[1px] border-[#232524] py-2"
          >
            <p>{item}</p>
            <div className=" w-[100px] flex items-center justify-end ">
              <GoPlus
                className=" cursor-pointer"
                onClick={() => setmessage(item)}
                color="#62AF41"
                size={22}
              />
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Related;
