import { formatDistanceToNow, startOfMinute } from 'date-fns';
import React from 'react';
import { IoTimeOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';

const RecentConversation = ({ data }) => {
  // hooks
  const navigate = useNavigate();

  const userMessage = data.messages?.find((data) => data.role === 'user');
  const assistantMessage = data.messages?.find(
    (data) => data.role === 'assistant'
  );

  const userContent = userMessage
    ? userMessage.content
    : 'No user message found';
  const assistantContent = assistantMessage
    ? assistantMessage.content
    : 'No assistant message found';

  const assistantMsg = data?.messages.find((data) => data.role === 'assistant');

  const googleImages = assistantMsg?.braveSearchResults[0]?.images;

  // Handler for image load error
  const handleImageError = (event) => {
    event.target.style.display = 'none'; // Hides the image element if it fails to load
  };

  return (
    <div className="text-white flex flex-col gap-2 px-5 py-8 border-t-[1px] border-[#323433]">
      <h2
        onClick={() => navigate(`/ai-search?search=${data?._id}`)}
        className="text-2xl font-semibold"
      >
        {userContent}
      </h2>
      <p className="text-[#8d9191] text-xl line-clamp-2 ">{assistantContent}</p>
      <div className="flex justify-start items-start gap-2 overflow-y-auto scrollbar-hide">
        {googleImages?.length &&
          googleImages.map((item, index) => (
            // eslint-disable-next-line jsx-a11y/img-redundant-alt
            <img
              key={index}
              className="w-20 h-20 rounded-sm  object-cover"
              src={item}
              alt="Image"
              onError={handleImageError}
            />
          ))}
      </div>
      <div>
        <div className="flex items-center justify-start gap-1 text-[#8d9191]">
          <IoTimeOutline size={18} color="#8d9191" />
          {formatDistanceToNow(
            startOfMinute(new Date(data?.userLocalDateTime || data?.createdAt)),
            { addSuffix: true }
          )}
        </div>
      </div>
    </div>
  );
};

export default RecentConversation;
