import React, { useEffect, useState } from 'react';
import { MdClose } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { MdLanguage, MdEmail, MdFacebook } from 'react-icons/md';
import { FaTelegram, FaDiscord, FaLinkedin, FaInstagram } from 'react-icons/fa';
import { RiDraggable } from 'react-icons/ri';

const TwitterIcon = ({ size }) => (
  <svg width={size} height={size} viewBox="0 0 512 512" fill="currentColor">
    <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8l164.9-188.5L26.8 48h145.6l100.5 132.9L389.2 48zm-24.8 373.8h39.1L151.1 88h-42l255.3 333.8z"></path>
  </svg>
);

const getIconForPlatform = (platform) => {
  switch (platform) {
    case 'website':
      return <MdLanguage size="28" />;
    case 'email':
      return <MdEmail size="28" />;
    case 'facebook':
      return <MdFacebook size="28" />;
    case 'twitter':
      return <TwitterIcon size="28" />;
    case 'discord':
      return <FaDiscord size="28" />;
    case 'telegram':
      return <FaTelegram size="28" />;
    case 'linkedIn':
      return <FaLinkedin size="28" />;
    case 'instagram':
      return <FaInstagram size="28" />;
    // Add more cases as needed for other platforms
    default:
      return null;
  }
};

const SocialLinksModal = ({
  isOpen,
  onClose,
  aboutUs,
  socialLinks,
  currentBusiness,
}) => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    setItems(socialLinks);
  }, [socialLinks]);

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const newItems = Array.from(items);
    const [removed] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, removed);

    setItems(newItems);

    localStorage.setItem(
      `reorderedSocialLinks_${currentBusiness?._id}`,
      JSON.stringify(newItems)
    );
  };

  useEffect(() => {
    const storageKey = `reorderedSocialLinks_${currentBusiness?._id}`;
    const storedOrder = JSON.parse(localStorage.getItem(storageKey));

    setItems(storedOrder?.length > 0 ? storedOrder : socialLinks);
  }, [socialLinks, currentBusiness?._id]);

  const userId = useSelector((state) => state.auth.user?._id);

  function formatUrl(url) {
    if (!url?.startsWith('http://') && !url?.startsWith('https://')) {
      return 'https://' + url; // Default to HTTPS for security
    }
    return url;
  }

  function linkify(text) {
    const urlRegex =
      /(\b(?:https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|]|\bwww\.[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|]|\b[-A-Z0-9+&@#/%?=~_|]+\.[A-Z]{2,4}[-A-Z0-9+&@#/%?=~_|]*)/gi;
    return text?.replace(urlRegex, function (url) {
      let href = url;
      if (!/^(?:https?|ftp|file):\/\//i.test(url)) {
        href = 'http://' + url;
      }
      return `<a href="${href}" target="_blank" rel="noopener noreferrer" class="text-blue-500 hover:text-blue-600 underline">${url}</a>`;
    });
  }

  const formattedDescription = linkify(aboutUs);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center p-4 mr-2 z-50">
      <div className="bg-[#212121] rounded-lg shadow-lg overflow-hidden w-full max-w-md">
        <div className="flex justify-between items-center p-2 px-4">
          <span className="text-white font-semibold text-xl">About</span>
          <button onClick={onClose} className="text-white">
            <MdClose size="24" />
          </button>
        </div>
        <hr className="seemorHr" />
        <div className="overflow-y-auto" style={{ maxHeight: '400px' }}>
          {aboutUs && (
            <div className="px-4 py-2 text-gray-200">
              <p
                style={{ whiteSpace: 'pre-line' }}
                dangerouslySetInnerHTML={{ __html: formattedDescription }}
              ></p>
            </div>
          )}
          <span className="px-4 py-2 font-semibold text-xl text-white">
            Links
          </span>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="socialLinks">
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className="px-4 py-4"
                >
                  {items.map((link, index) => (
                    <Draggable
                      key={link.id}
                      draggableId={link.id}
                      index={index}
                      isDragDisabled={currentBusiness.createdby !== userId} // Disable drag if user is not the creator
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className={`flex justify-start items-center py-2 gap-2 ${
                            snapshot.isDragging
                              ? 'bg-gray-700' && 'opacity-0.5'
                              : 'bg-transparent'
                          }`}
                        >
                          <a
                            href={formatUrl(link.link)}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="flex items-center text-gray-300 hover:text-gray-500 gap-4"
                          >
                            <RiDraggable />
                            {getIconForPlatform(link.platform)}{' '}
                            {/* Render icon based on link.id */}
                            <div className="flex flex-col">
                              <span className="capitalize">
                                {link.platform}
                              </span>
                              <span>{link.link}</span>
                            </div>{' '}
                            {/* Display the link */}
                          </a>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </div>
    </div>
  );
};

export default SocialLinksModal;
