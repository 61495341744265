import { Link } from "react-router-dom";
import { Button } from "@/core/ui/button";
import { Card, CardContent } from "@/core/ui/card";
import {
  Brain,
  Building2,
  CircleDollarSign,
  FileText,
  Leaf,
  MessageSquare,
  ShoppingCart,
  Users,
  Menu,
} from "lucide-react";
import { useState, useCallback } from "react";

const useScrollToSection = () => {
  return useCallback((sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, []);
};

export default function LandingPage() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const scrollToSection = useScrollToSection();

  return (
    <div className="min-h-screen bg-black text-white">
      {/* Header */}
      <header className="border-b border-gray-700 bg-black fixed w-full z-50">
        <div className="container mx-auto px-4 py-4 flex flex-col md:flex-row md:items-center md:justify-between">
          <div className="flex items-center justify-between w-full md:w-auto">
            <img
              src="https://hebbkx1anhila5yf.public.blob.vercel-storage.com/maryLogo.9b031a4a35b9a7230df4-QJcpxwFYGjo2lVFDUEnFtrNBaL1ycL.png"
              alt="Mary Logo"
              width={50}
              height={50}
              className="w-auto h-10"
            />

            <div className="md:hidden">
              <Button
                onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                variant="ghost"
                size="icon"
              >
                <Menu className="h-6 w-6" />
              </Button>
            </div>
          </div>
          <nav
            className={`${
              mobileMenuOpen ? "block" : "hidden"
            } md:flex gap-6 bg-black w-full md:w-auto mt-4 md:mt-0`}
          >
            <button
              onClick={() => {
                scrollToSection("individuals");
                setMobileMenuOpen(false);
              }}
              className="block py-2 md:py-0 hover:text-green-400 transition"
            >
              For Individuals
            </button>
            <button
              onClick={() => {
                scrollToSection("businesses");
                setMobileMenuOpen(false);
              }}
              className="block py-2 md:py-0 hover:text-green-400 transition"
            >
              For Businesses
            </button>
            <button
              onClick={() => {
                scrollToSection("about");
                setMobileMenuOpen(false);
              }}
              className="block py-2 md:py-0 hover:text-green-400 transition"
            >
              About
            </button>
            <Link to="/login">
              <Button className="mt-4 md:mt-0 md:ml-4 bg-green-600 hover:bg-green-700 w-full md:w-auto">
                Developer
              </Button>
            </Link>
          </nav>
        </div>
      </header>

      {/* Hero Section with Animated Background */}
      <section className="relative overflow-hidden pt-20">
        <div className="absolute inset-0 z-0">
          <div className="absolute inset-0 bg-gradient-to-r from-green-900 to-blue-900 opacity-30"></div>
          <div className="absolute inset-0 bg-[url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0DovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1IiBoZWlnaHQ9IjUiPgo8cmVjdCB3aWR0aD0iNSIgaGVpZ2h0PSI1IiBmaWxsPSIjMjIyIj48L3JlY3Q+CjxwYXRoIGQ9Ik0wIDVMNSAwWk02IDRMNCA2Wk0tMSAxTDEgLTFaIiBzdHJva2U9IiMzMzMiIHN0cm9rZS13aWR0aD0iMSI+PC9wYXRoPgo8L3N2Zz4=')] opacity-10 animate-[grain_8s_steps(10)_infinite]"></div>
        </div>
        <div className="container mx-auto px-4 py-20 text-center relative z-10">
          <h1 className="text-4xl md:text-6xl font-bold mb-6 text-transparent bg-clip-text bg-gradient-to-r from-green-400 to-blue-500">
            A better way to buy and sell CBD
          </h1>
          <p className="text-xl text-gray-300 mb-12 max-w-2xl mx-auto">
            Connect directly with producers, find products that meet your needs,
            and track your experience
          </p>
          <div className="relative max-w-4xl mx-auto">
            {/* <img
              src="https://hebbkx1anhila5yf.public.blob.vercel-storage.com/lockScreenImage.99c740530a405ce377cc-u2fWtU4mkDu88k2cUdxANdLTzMqycz.png"
              alt="Mary App Interface"
              width={800}
              height={600}
              className="rounded-lg shadow-2xl"
            /> */}
          </div>
        </div>
      </section>

      {/* For Individuals Section */}
      <section
        id="individuals"
        className="py-20 bg-gradient-to-br from-green-900 via-black to-black"
      >
        <div className="container mx-auto px-4">
          <h2 className="text-3xl md:text-5xl font-bold mb-12 text-center bg-clip-text text-transparent bg-gradient-to-r from-green-400 to-blue-500">
            For Individuals - A Better Way to Buy CBD
          </h2>
          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
            {[
              {
                icon: <Users className="w-8 h-8 text-green-400" />,
                title: "Connect with Producers",
                description:
                  "Build relationships with trusted CBD producers",
              },
              {
                icon: <FileText className="w-8 h-8 text-green-400" />,
                title: "Find Perfect Products",
                description: "Discover products tailored to your preferences",
              },
              {
                icon: <MessageSquare className="w-8 h-8 text-green-400" />,
                title: "Track Your Sessions",
                description:
                  "Keep detailed records of your CBD experiences",
              },
              {
                icon: <Brain className="w-8 h-8 text-green-400" />,
                title: "Ask Mary AI",
                description: "Get personalized recommendations and answers",
              },
            ].map((feature, index) => (
              <Card
                key={index}
                className="bg-black bg-opacity-50 border-green-800 hover:border-green-400 transition-all duration-300 transform hover:-translate-y-2 hover:shadow-lg hover:shadow-green-500/20 group"
              >
                <CardContent className="p-6">
                  <div className="mb-4 flex justify-center transition-transform duration-300 transform group-hover:scale-110">
                    {feature.icon}
                  </div>
                  <h3 className="text-xl font-semibold mb-2 text-center text-green-400">
                    {feature.title}
                  </h3>
                  <p className="text-gray-300 text-center">
                    {feature.description}
                  </p>
                </CardContent>
              </Card>
            ))}
          </div>
        </div>
      </section>

      {/* For Businesses Section */}
      <section
        id="businesses"
        className="py-20 bg-gradient-to-bl from-green-900 via-black to-black"
      >
        <div className="container mx-auto px-4">
          <h2 className="text-3xl md:text-5xl font-bold mb-12 text-center bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-green-500">
            For Businesses - A Better Way to Sell CBD
          </h2>
          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
            {[
              {
                icon: <Building2 className="w-8 h-8 text-blue-400" />,
                title: "Create Your Profile",
                description: "Build your brand presence in the CBD market",
              },
              {
                icon: <ShoppingCart className="w-8 h-8 text-blue-400" />,
                title: "Sell Products & Memberships",
                description: "Manage your inventory and subscription offerings",
              },
              {
                icon: <CircleDollarSign className="w-8 h-8 text-blue-400" />,
                title: "Offer Special Deals",
                description:
                  "Create promotions to attract and retain customers",
              },
              {
                icon: <Leaf className="w-8 h-8 text-blue-400" />,
                title: "Understand Your Customers",
                description:
                  "Gain insights into customer preferences and behavior",
              },
            ].map((feature, index) => (
              <Card
                key={index}
                className="bg-black bg-opacity-50 border-blue-800 hover:border-blue-400 transition-all duration-300 transform hover:-translate-y-2 hover:shadow-lg hover:shadow-blue-500/20 group"
              >
                <CardContent className="p-6">
                  <div className="mb-4 flex justify-center transition-transform duration-300 transform group-hover:scale-110">
                    {feature.icon}
                  </div>
                  <h3 className="text-xl font-semibold mb-2 text-center text-blue-400">
                    {feature.title}
                  </h3>
                  <p className="text-gray-300 text-center">
                    {feature.description}
                  </p>
                </CardContent>
              </Card>
            ))}
          </div>
        </div>
      </section>

      {/* Founder Letter Section */}
      <section
        id="about"
        className="py-20 bg-gradient-to-r from-green-900 to-blue-900"
      >
        <div className="container mx-auto px-4">
          <div className="max-w-4xl mx-auto bg-black bg-opacity-80 p-8 rounded-lg shadow-2xl">
            <div className="flex flex-col md:flex-row items-center gap-12">
              <div className="md:w-1/3">
                <img
                  src="https://hebbkx1anhila5yf.public.blob.vercel-storage.com/willImg.c0d3d37cdc5cda7571ab.jpg-yPiKVcDAjZ1BhHq3kVzw56ysmdWwu1.jpeg"
                  alt="Founder"
                  width={300}
                  height={300}
                  className="rounded-full shadow-lg"
                />
              </div>
              <div className="md:w-2/3">
                <h2 className="text-3xl md:text-4xl font-bold mb-6 text-center md:text-left text-green-400">
                  A Word From Our Founder
                </h2>
                <div className="space-y-4 text-gray-300">
                  <p>
                    In the past, purchasing CBD was straightforward but
                    limited: you bought what your local supplier had. Today,
                    even though legal CBD is accessible to many, the
                    experience often lacks transparency and feels overwhelming,
                    impersonal, and disconnected due to a complex regulatory
                    environment distancing producers from consumers and a flood
                    of mediocre products.
                  </p>
                  <p>
                    Yet, despite these challenges, the CBD community
                    continues to thrive powered by businesses and individuals
                    across the globe.
                  </p>
                  <p>
                    To support them we created Mary, a better way to buy and
                    sell CBD.
                  </p>
                  <p>
                    We've created a platform where consumers can directly
                    connect with producers, discover products that meet their
                    specific needs, and access tools to verify product
                    authenticity, track usage, and understand their CBD
                    consumption better.
                  </p>
                  <p>
                    Our business model supports producers in reaching a broader
                    audience, connecting with customers, selling their products
                    and memberships effectively, and leveraging insightful data
                    to enhance their operations.
                  </p>
                  <p>
                    We invite you to join us in creating a better way to buy and
                    sell CBD.
                  </p>
                  <div className="pt-4">
                    <p className="font-semibold text-green-400">Will Oseroff</p>
                    <p className="text-gray-400">Founder</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="border-t border-gray-700 py-8 bg-black">
        <div className="container mx-auto px-4 text-center text-gray-400">
          <p>© Plant Intelligence Research Organization</p>
        </div>
      </footer>
    </div>
  );
}
